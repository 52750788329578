import React, { useState, useEffect } from "react";
import { Text, Box, Button } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { EditIcon } from "components/common/CustomIcons";
import { ConfirmDeleteLoanProduct, LoanProductConfig } from "components/modals";
import { DateTimeFormat } from "../../constants";
import { Table } from "antd";
import { RootState } from "types";
import { DeleteFilled } from "@ant-design/icons";
import { isReadOnly } from "helpers/utilFunctions";
import { Loader } from "components/common";
import {
  getLoanProducts,
  updateUserSettings,
} from "redux/actions/settingsActions";

const columns = [
  {
    dataIndex: "name",
    key: "name",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Product name
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "createdOn",
    key: "createdOn",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Created On
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "defaultInterest",
    key: "defaultInterest",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
          {"%"}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Default Interest
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "defaultAmount",
    key: "defaultAmount",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Default Amount
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "defaultTenure",
    key: "defaultTenure",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Default Tenure
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "editAction",
  },
  {
    dataIndex: "defaultAction",
  },
  {
    dataIndex: "deleteAction",
  },
];
export default function LoanProductTable() {
  const [showModal, setShowModal] = useState(false);
  const [loanProductRecord, setLoanProductRecord] = useState({});
  const [deleteRecord, setDeleteRecord] = useState(undefined);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [defaultProductId, setDefaultProductId] = useState("");
  const dispatch = useDispatch();

  const setting = useSelector((state: RootState) => state.settingsReducer);
  const defaultProductExternalId =
    setting?.settingData?.data?.defaultLoanProduct;
  const { loanProducts, requestLoading } = setting;
  const data: any[] | undefined = [];
  const readonly = isReadOnly();
  const xJwt = localStorage.getItem("x_jwt");

  const onEdit = (record: any, e: any) => {
    e.stopPropagation();
    setLoanProductRecord(record);
    setShowModal(true);
  };

  const onDelete = (record: any, e: any) => {
    e.stopPropagation();
    setDeleteRecord(record?._id);
    setShowConfirmationModal(true);
  };

  //map through user setting and add to data
  for (let i = 0; i < loanProducts?.length; i++) {
    data.push({
      key: loanProducts[i]?._id,
      name: loanProducts[i]?.name,
      createdOn: moment(loanProducts[i]?.createdAt).format(DateTimeFormat),
      defaultInterest: loanProducts[i]?.defaultInterest,
      defaultAmount: loanProducts[i]?.defaultAmount,
      defaultTenure: loanProducts[i]?.defaultTenure,
      defaultAction: (
        <>
          {loanProducts[i]?.isDefault ||
          defaultProductExternalId === loanProducts[i]?.externalId ? (
            <Box
              py={2}
              px={4}
              width={"max-content"}
              bgColor={"var(--light-blue)"}
              borderRadius={8}
              textAlign={"center"}
            >
              <Text fontWeight={"medium"}>Default Product</Text>
            </Box>
          ) : (
            <Button
              variant="secondary"
              d={
                loanProducts[i]?.isDefault ? "none" : readonly ? "none" : "flex"
              }
              onClick={e => {
                setDefaultProductId(loanProducts[i]?._id);
                dispatch(
                  updateUserSettings(xJwt, "defaultProduct", {
                    productId: loanProducts[i]?._id,
                  })
                );
              }}
              isLoading={
                requestLoading && defaultProductId === loanProducts[i]?._id
              }
            >
              Make Default
            </Button>
          )}
        </>
      ),
      editAction: (
        <Box
          className="edit-cont"
          d={readonly ? "none" : "flex"}
          onClick={e => onEdit(loanProducts[i], e)}
        >
          <EditIcon />
        </Box>
      ),
      deleteAction: (
        <Box
          className="delete-cont"
          d={readonly || loanProducts.length === 1 ? "none" : "flex"}
          onClick={e => onDelete(loanProducts[i], e)}
        >
          <DeleteFilled
            style={{ color: "var(--danger-500)", fontSize: "1.1rem" }}
          />
        </Box>
      ),
    });
  }

  useEffect(() => {
    if (defaultProductId !== "") {
      dispatch(getLoanProducts(xJwt));
    }
  }, [xJwt, dispatch, defaultProductId]);

  return (
    <>
      {requestLoading && !data.length ? (
        <Loader size="md" />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
        />
      )}

      <LoanProductConfig
        showModal={showModal}
        setShowModal={setShowModal}
        loanProduct={loanProductRecord}
      />

      <ConfirmDeleteLoanProduct
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
        product={deleteRecord}
      />
    </>
  );
}
