import moment from "moment";
import React, { useState } from "react";
import { Text, Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { formatNumberAmount, isReadOnly } from "helpers/utilFunctions";
import { EditIcon } from "components/common/CustomIcons";
import { DeleteFilled } from "@ant-design/icons";
import { LoanOfferConfig, ConfirmDeleteLoanOffer } from "components/modals";
import { DateTimeFormat } from "../../constants";
import { Switch, Table } from "antd";
import { RootState } from "types";

const columns = [
  {
    dataIndex: "interestRate",
    key: "interestRate",
    render: (text: number) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}%
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Interest rate
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "tenure",
    key: "tenure",
    render: (text: number) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
          {text > 1 ? " Months" : " Month"}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Tenure
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "maxAmount",
    key: "maxAmount",
    render: (text: number) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {formatNumberAmount(text)}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Max amount
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "minAmount",
    key: "minAmount",
    render: (text: number) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {formatNumberAmount(text)}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Min amount
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "createdOn",
    key: "createdOn",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Created On
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "loanProduct",
    key: "loanProduct",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Loan Product
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "editAction",
  },
  {
    dataIndex: "disableAction",
  },
  {
    dataIndex: "deleteAction",
  },
];
export default function LoanOffersTable() {
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [editRecord, setEditRecord] = useState(undefined);
  const [deleteRecord, setDeleteRecord] = useState(undefined);
  const [stateToggleActive, setStateToggleActive] = useState(false);
  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const loanOffers = settingsState?.loanOffers;
  const loanProducts = settingsState?.loanProducts;
  const readonly = isReadOnly();
  const onEdit = (record: any, e: any) => {
    e.stopPropagation();
    setEditRecord(record);
    setShowModal(true);
  };

  const onDelete = (record: any, e: any) => {
    e.stopPropagation();
    setDeleteRecord(record);
    setShowConfirmationModal(true);
  };

  const data: any[] | undefined = [];
  for (let i = 0; i < loanOffers?.length; i++) {
    data.push({
      key: loanOffers[i]?._id,
      interestRate: loanOffers[i]?.interestRate,
      tenure: loanOffers[i]?.tenure,
      maxAmount: loanOffers[i]?.maxAmount,
      minAmount: loanOffers[i]?.minAmount,
      createdOn: moment(loanOffers[i]?.createdAt).format(DateTimeFormat),
      loanProduct: loanProducts.find(
        product => product.externalId === loanOffers[i].loanProduct
      )?.name,
      editAction: (
        <Box
          className="edit-cont"
          d={readonly ? "none" : "flex"}
          onClick={e => onEdit(loanOffers[i], e)}
        >
          <EditIcon />
        </Box>
      ),
      disableAction: (
        <Box
          className="delete-cont"
          d={readonly ? "none" : "flex"}
          onClick={e => {
            setStateToggleActive(true);
            onDelete(loanOffers[i], e);
          }}
        >
          <Switch
            size="small"
            checked={loanOffers[i].enabled}
            className="colending-toggle"
          />
        </Box>
      ),
      deleteAction: (
        <Box
          className="delete-cont"
          d={readonly ? "none" : "flex"}
          onClick={e => {
            setStateToggleActive(false);
            onDelete(loanOffers[i], e);
          }}
        >
          <DeleteFilled
            style={{ color: "var(--danger-500)", fontSize: "1.1rem" }}
          />
        </Box>
      ),
    });
  }
  return (
    <>
      <Table
        // rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
      />
      <LoanOfferConfig
        showModal={showModal}
        setShowModal={setShowModal}
        isEdit={editRecord}
      />
      <ConfirmDeleteLoanOffer
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
        offer={deleteRecord}
        isStateToggle={stateToggleActive}
      />
    </>
  );
}
