import { getSlaState } from "constants/helper";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "types";
import MainLoader from "./MainLoader";

type Props = {
  children: React.ReactNode;
};

const SlaGuide = (props: Props) => {
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const { settingData, isLoading } = setting;
  const history = useHistory();
  const currentUrl = useLocation();
  const slaState = getSlaState();

  //
  const handleRedirect = () => {
    if (slaState) return null;
    return history.push("/sla");
  };

  useEffect(() => {
    // not signed SLA
    if (!settingData.data?.hasSignedSLA && !isLoading) {
      handleRedirect();
    }
  }, [currentUrl, isLoading]);

  return (
    <>
      {isLoading && <MainLoader />}
      {props.children}
    </>
  );
};

export default SlaGuide;
