import { useState, useEffect } from "react";
let timer = 60;
let timePassed = 0;
let timeLeft = 60;
let timerId: NodeJS.Timer;

/**
 *
 * @param max maximum limit of timer in seconds
 * @returns @function start : start the countdown timer
 */
export function CountDownUtils({ max }: { max?: number }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [counter, setCounter] = useState(max || timer);

  let timeFn = () => {
    timerId = setInterval(() => {
      timePassed += 1;
      timeLeft = (max || timer) - timePassed;
      setCounter(timeLeft);
    }, 1000);
  };
  useEffect(() => {
    if (isEnabled) {
      setIsBusy(true);
      timeFn();
    }
  }, [isEnabled]);

  useEffect(() => {
    if (counter < 1) {
      clearInterval(timerId);
      setIsBusy(false);
      timeLeft = max || timer;
      timePassed = 0;
      setCounter(max || timer);
    }
  });
  return {
    start: () => {
      setIsEnabled(true);
    },
    reset: () => {
      timeLeft = max || timer;
      timePassed = 0;
      setCounter(max || timer);
    },
    stop: () => {
      clearInterval(timerId);
      setIsBusy(false);
      timeLeft = max || timer;
      timePassed = 0;
      setCounter(max || timer);
    },
    count: counter,
    isBusy,
  };
}
