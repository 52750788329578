import { message } from "antd";
import axios from "axios";
import {
  TRANSACTIONS_TABLE_ACTION_SUCCESS,
  TRANSACTIONS_TABLE_ACTION_FAILURE,
  TRANSACTIONS_REQUEST_LOADING_TRUE,
  TRANSACTIONS_REQUEST_LOADING_FALSE,
  GET_DISBURSEMENTS_TRANSACTIONS_SUCCESS,
  GET_DISBURSEMENTS_TRANSACTIONS_FAILURE,
  GET_SMS_TRANSACTIONS_SUCCESS,
  GET_USSD_TRANSACTIONS_SUCCESS,
  GET_REMITA_TRANSACTIONS_SUCCESS,
  GET_WITHDRAWAL_TRANSACTIONS_SUCCESS,
  ERROR_401_ACTION,
  UPDATE_TRANSACTIONS_DOWNLOAD_LIST,
  UPDATE_DISBURSEMENTS_DOWNLOAD_LIST,
  UPDATE_REMITA_DOWNLOAD_LIST,
  UPDATE_USSD_DOWNLOAD_LIST,
  UPDATE_SMS_DOWNLOAD_LIST,
  UPDATE_WITHDRAWALS_DOWNLOAD_LIST,
} from "../actions/actionTypes";

const txUrl = process.env.REACT_APP_TXURL;

// get All Transactions
export const getAllTransactions =
  (xJwt: any, accountId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${xJwt}`,
      },
    };
    dispatch({
      type: TRANSACTIONS_REQUEST_LOADING_TRUE,
    });
    try {
      const res = await axios.get(
        `${txUrl}/payments?accountId=${accountId}&populate=Purchases&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: TRANSACTIONS_TABLE_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_TRANSACTIONS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: TRANSACTIONS_TABLE_ACTION_FAILURE,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// get more transactions
export const getMoreTransactions =
  (xJwt: any, accountId: any, lastId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${xJwt}`,
      },
    };
    dispatch({
      type: TRANSACTIONS_REQUEST_LOADING_TRUE,
    });
    try {
      const res = await axios.get(
        `${txUrl}/payments?accountId=${accountId}&populate=Purchases&lastId=${lastId}&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: TRANSACTIONS_TABLE_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_TRANSACTIONS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: TRANSACTIONS_TABLE_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// filter All Transactions
export const filterAllTransactions =
  (xJwt: any, accountId: any, fromDate: any, toDate: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${xJwt}`,
      },
    };
    dispatch({
      type: TRANSACTIONS_REQUEST_LOADING_TRUE,
    });
    try {
      const res = await axios.get(
        `${txUrl}/payments?accountId=${accountId}&from=${fromDate}&to=${toDate}&populate=Purchases&desc=true&sort=-_id&limit=9999`,
        config
      );
      dispatch({
        type: TRANSACTIONS_TABLE_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_TRANSACTIONS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: TRANSACTIONS_TABLE_ACTION_FAILURE,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// get disbursement transactions
export const getDisbursementTransactions =
  (xJwt: any, accountId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${xJwt}`,
      },
    };
    dispatch({
      type: TRANSACTIONS_REQUEST_LOADING_TRUE,
    });
    try {
      const loanDisbursementProductId =
        process.env.REACT_APP_LOAN_DISBURSEMENT_PRODUCT_ID;
      console.log(loanDisbursementProductId);
      const res = await axios.get(
        `${txUrl}/purchases?accountId=${accountId}&product=${loanDisbursementProductId}&status=completed&select=frontendStatus,purchaseReference,cost,merchantFee,customerFee,createdAt,totalCost,description,currency,productsDetails,deliveryLog&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: GET_DISBURSEMENTS_TRANSACTIONS_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_DISBURSEMENTS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: GET_DISBURSEMENTS_TRANSACTIONS_FAILURE,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// get more disbursement transactions
export const getMoreDisbursementTransactions =
  (xJwt: any, accountId: any, lastId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${xJwt}`,
      },
    };
    dispatch({
      type: TRANSACTIONS_REQUEST_LOADING_TRUE,
    });
    try {
      const loanDisbursementProductId =
        process.env.REACT_APP_LOAN_DISBURSEMENT_PRODUCT_ID;
      const res = await axios.get(
        `${txUrl}/purchases?accountId=${accountId}&product=${loanDisbursementProductId}&lastId=${lastId}&status=completed&select=frontendStatus,purchaseReference,cost,merchantFee,customerFee,createdAt,totalCost,description,currency,productsDetails,deliveryLog&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: GET_DISBURSEMENTS_TRANSACTIONS_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_DISBURSEMENTS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: GET_DISBURSEMENTS_TRANSACTIONS_FAILURE,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// get sms transactions
export const getSmsTransactions =
  (xJwt: any, accountId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${xJwt}`,
      },
    };
    dispatch({
      type: TRANSACTIONS_REQUEST_LOADING_TRUE,
    });
    try {
      const res = await axios.get(
        `${txUrl}/purchases?accountId=${accountId}&product=61510230ac43b4002907c064&status=completed&select=frontendStatus,purchaseReference,cost,merchantFee,customerFee,createdAt,totalCost,description,currency,productsDetails,deliveryLog&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: GET_SMS_TRANSACTIONS_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_SMS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: TRANSACTIONS_REQUEST_LOADING_FALSE,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// get more sms transactions
export const getMoreSmsTransactions =
  (xJwt: any, accountId: any, lastId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${xJwt}`,
      },
    };
    dispatch({
      type: TRANSACTIONS_REQUEST_LOADING_TRUE,
    });
    try {
      const res = await axios.get(
        `${txUrl}/purchases?accountId=${accountId}&product=61510230ac43b4002907c064&lastId=${lastId}&status=completed&select=frontendStatus,purchaseReference,cost,merchantFee,customerFee,createdAt,totalCost,description,currency,productsDetails,deliveryLog&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: GET_SMS_TRANSACTIONS_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_SMS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: TRANSACTIONS_REQUEST_LOADING_FALSE,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// get remita transactions
export const getRemitaTransactions =
  (xJwt: any, accountId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${xJwt}`,
      },
    };
    dispatch({
      type: TRANSACTIONS_REQUEST_LOADING_TRUE,
    });
    try {
      const res = await axios.get(
        `${txUrl}/purchases?accountId=${accountId}&product=60d38908a64ed700280362e8&status=completed&select=frontendStatus,purchaseReference,cost,merchantFee,customerFee,createdAt,totalCost,description,currency,productsDetails,deliveryLog&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: GET_REMITA_TRANSACTIONS_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_REMITA_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: TRANSACTIONS_REQUEST_LOADING_FALSE,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// get more remita transactions
export const getMoreRemitaTransactions =
  (xJwt: any, accountId: any, lastId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${xJwt}`,
      },
    };
    dispatch({
      type: TRANSACTIONS_REQUEST_LOADING_TRUE,
    });
    try {
      const res = await axios.get(
        `${txUrl}/purchases?accountId=${accountId}&product=60d38908a64ed700280362e8&lastId=${lastId}&status=completed&select=frontendStatus,purchaseReference,cost,merchantFee,customerFee,createdAt,totalCost,description,currency,productsDetails,deliveryLog&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: GET_REMITA_TRANSACTIONS_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_REMITA_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: TRANSACTIONS_REQUEST_LOADING_FALSE,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// get Ussd transactions
export const getUssdTransactions =
  (xJwt: any, accountId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${xJwt}`,
      },
    };
    dispatch({
      type: TRANSACTIONS_REQUEST_LOADING_TRUE,
    });
    try {
      const res = await axios.get(
        `${txUrl}/purchases?accountId=${accountId}&product=614f38d2b7136c0029a51fad&status=completed&select=frontendStatus,purchaseReference,cost,merchantFee,customerFee,createdAt,totalCost,description,currency,productsDetails,deliveryLog&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: GET_USSD_TRANSACTIONS_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_USSD_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: TRANSACTIONS_REQUEST_LOADING_FALSE,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// get more Ussd transactions
export const getMoreUssdTransactions =
  (xJwt: any, accountId: any, lastId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${xJwt}`,
      },
    };
    dispatch({
      type: TRANSACTIONS_REQUEST_LOADING_TRUE,
    });
    try {
      const res = await axios.get(
        `${txUrl}/purchases?accountId=${accountId}&product=614f38d2b7136c0029a51fad&lastId=${lastId}&status=completed&select=frontendStatus,purchaseReference,cost,merchantFee,customerFee,createdAt,totalCost,description,currency,productsDetails,deliveryLog&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: GET_USSD_TRANSACTIONS_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_USSD_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: TRANSACTIONS_REQUEST_LOADING_FALSE,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// get Withdrawal transactions
export const getWithdrawalTransactions =
  (xJwt: any, accountId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${xJwt}`,
      },
    };
    dispatch({
      type: TRANSACTIONS_REQUEST_LOADING_TRUE,
    });
    try {
      const res = await axios.get(
        `${txUrl}/purchases?accountId=${accountId}&product=6157842b101720572946579b&status=completed&select=frontendStatus,purchaseReference,cost,merchantFee,customerFee,createdAt,totalCost,description,currency,productsDetails,deliveryLog&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: GET_WITHDRAWAL_TRANSACTIONS_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_WITHDRAWALS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: TRANSACTIONS_REQUEST_LOADING_FALSE,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// get more Withdrawal transactions
export const getMoreWithdrawalTransactions =
  (xJwt: any, accountId: any, lastId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${xJwt}`,
      },
    };
    dispatch({
      type: TRANSACTIONS_REQUEST_LOADING_TRUE,
    });
    try {
      const res = await axios.get(
        `${txUrl}/purchases?accountId=${accountId}&product=6157842b101720572946579b&lastId=${lastId}&status=completed&select=frontendStatus,purchaseReference,cost,merchantFee,customerFee,createdAt,totalCost,description,currency,productsDetails,deliveryLog&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: GET_WITHDRAWAL_TRANSACTIONS_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_WITHDRAWALS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: TRANSACTIONS_REQUEST_LOADING_FALSE,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// filter transactions grouped
export const filterTransactions =
  (
    xJwt: any,
    accountId: any,
    product: any,
    fromDate: any,
    toDate: any,
    tab: "disbursements" | "remita" | "ussd" | "sms" | "withdrawals"
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${xJwt}`,
      },
    };
    dispatch({
      type: TRANSACTIONS_REQUEST_LOADING_TRUE,
    });
    try {
      const res = await axios.get(
        `${txUrl}/purchases?accountId=${accountId}&product=${product}&from=${fromDate}&to=${toDate}&status=completed&select=frontendStatus,purchaseReference,cost,merchantFee,customerFee,createdAt,totalCost,description,currency,productsDetails,deliveryLog&desc=true&sort=-_id&limit=9999`,
        config
      );
      switch (tab) {
        case "disbursements":
          dispatch({
            type: GET_DISBURSEMENTS_TRANSACTIONS_SUCCESS,
            payload: res?.data,
          });
          dispatch({
            type: UPDATE_DISBURSEMENTS_DOWNLOAD_LIST,
            payload: res?.data?.data,
          });
          break;
        case "remita":
          dispatch({
            type: GET_REMITA_TRANSACTIONS_SUCCESS,
            payload: res?.data,
          });
          dispatch({
            type: UPDATE_REMITA_DOWNLOAD_LIST,
            payload: res?.data?.data,
          });
          break;
        case "ussd":
          dispatch({
            type: GET_USSD_TRANSACTIONS_SUCCESS,
            payload: res?.data,
          });
          dispatch({
            type: UPDATE_USSD_DOWNLOAD_LIST,
            payload: res?.data?.data,
          });
          break;
        case "sms":
          dispatch({
            type: GET_SMS_TRANSACTIONS_SUCCESS,
            payload: res?.data,
          });
          dispatch({
            type: UPDATE_SMS_DOWNLOAD_LIST,
            payload: res?.data?.data,
          });
          break;
        case "withdrawals":
          dispatch({
            type: GET_WITHDRAWAL_TRANSACTIONS_SUCCESS,
            payload: res?.data,
          });
          dispatch({
            type: UPDATE_WITHDRAWALS_DOWNLOAD_LIST,
            payload: res?.data?.data,
          });
          break;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: TRANSACTIONS_REQUEST_LOADING_FALSE,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };
