import React from "react";
import { ProcessingLoansTable } from "components/tables";
import { LoanTabFilters } from "components/common";

export default function ProcessingLoansTabPane() {
  return (
    <LoanTabFilters
      loanTab="processing"
      loanStatus="approved"
      processStatus="disbursed"
      disbursementStatus="processing"
    >
      <ProcessingLoansTable />
    </LoanTabFilters>
  );
}
