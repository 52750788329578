import { message } from "antd";
import axios from "axios";
import { handleError, handleResponse } from "./apiUtils";

export const baseUrl = process.env.REACT_APP_BASE_URL;
export const baseTxUrl = process.env.REACT_APP_TXURL;

export const saveToSheet = async (signupData: string) => {
  try {
    const response = await axios.post(
      `https://api.apispreadsheets.com/data/18162/`,
      signupData
    );
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const getLender = async (client: string) => {
  try {
    const response = await axios.get(
      `${baseUrl}/organization?client=${client}`
    );
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const registerCustomer = async (phoneNumber: string, xtag: string) => {
  try {
    const response = await axios.post(
      `${baseUrl}/register?x-tag=${xtag}&phonenumber=${phoneNumber}`
    );
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const saveUserConsent = async (
  userId: string,
  xtag: string,
  token: string
) => {
  const config: any = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(
      `${baseUrl}/users/${userId}/consent?x-tag=${xtag}`,
      { consent: "yes" },
      config
    );
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const getLoanOptions = async (
  userId: string,
  xtag: string,
  token: string,
  offer: string,
  offerId: string
) => {
  const config: any = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const body: any = {
    offer: offer,
    _id: offerId,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/users/${userId}/loanoptions?x-tag=${xtag}`,
      body,
      config
    );
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const calculateLoan = async (
  xtag: string,
  loanProduct: number,
  loanAmount: number | string,
  tenure: number,
  clientId: string
) => {
  const body: any = {
    loanProduct,
    loanAmount,
    tenure,
    clientId,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/calculate?x-tag=${xtag}`,
      body
    );
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const acceptLoanTerms = async (
  loanAmount: string,
  xtag: string,
  userId: string,
  eligibilityId: string,
  token: string
) => {
  const config: any = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const body: any = {
    amount: loanAmount,
    _id: eligibilityId,
    channel: "WEB",
  };
  try {
    const response = await axios.post(
      `${baseUrl}/v1/users/${userId}/accept?x-tag=${xtag}`,
      body,
      config
    );
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const sendOTP = async (xtag: string, phoneNumber: string) => {
  const body: any = {
    phonenumber: phoneNumber,
  };
  try {
    const response = await axios.post(`${baseUrl}/sendotp?x-tag=${xtag}`, body);
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const verifyOTP = async (
  xtag: string,
  phoneNumber: string,
  otp: string
) => {
  const body: any = {
    phonenumber: phoneNumber,
    otp: otp,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/verifyotp?x-tag=${xtag}`,
      body
    );
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const disburseLoan = async (
  xtag: string,
  userId: string,
  token: string,
  firstName: string,
  lastName: string,
  email: string
) => {
  const config: any = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const body: any = {
    firstname: firstName,
    lastname: lastName,
    email: email,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/v1/users/${userId}/disburse?x-tag=${xtag}`,
      body,
      config
    );
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const sendEmailOtp = async (email: string) => {
  let config = {
    headers: {
      "x-tag": "veend",
    },
  };
  try {
    const { data } = await axios.post(
      `${baseTxUrl}/v1/request-otp`,
      { email: email },
      config
    );
    return { data };
  } catch (error: any) {
    const err = error.response;
    return { error: err };
  }
};

export const saveLendingLicense = async (url: string) => {
  let config = {
    headers: {
      "x-tag": "veend",
    },
  };
  try {
    const xTag = localStorage.getItem("x_jwt");
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/upload-license`,
      { lendingLicenseUrl: url },
      {
        headers: {
          "x-tag": "veend",
          "x-jwt": xTag,
        },
      }
    );
    return { data: response.data };
  } catch (error: any) {
    const err = error.response;
    return { error: err };
  }
};

export const verifyDirectorInfo = async (dataToSend: any) => {
 
  try {
    const xTag = localStorage.getItem("x_jwt");
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/kyc-director`,
      { kycData: dataToSend },
      {
        headers: {
          "x-tag": "veend",
          "x-jwt": xTag,
        },
      }
    );
    return { data: response.data };
  } catch (error: any) {
    const err = error.response.data;
    return {error: true, errMessage: err.message}
  }
};

export const verifyBusinessInfo = async (dataToSend: any) => {
 
  try {
    const xTag = localStorage.getItem("x_jwt");
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/kyc-business`,
      { kycData: dataToSend },
      {
        headers: {
          "x-tag": "veend",
          "x-jwt": xTag,
        },
      }
    );
    return { data: response.data };
  } catch (error: any) {
    const err = error.response.data;
    return { error: true, errMessage: err.message };
  }
};

export const completeVerification = async () => {
 
  try {
    const xTag = localStorage.getItem("x_jwt");
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/kyc-complete`,
      { kycData: {}},
      {
        headers: {
          "x-tag": "veend",
          "x-jwt": xTag,
        },
      }
    );
    return { data: response.data };
  } catch (error: any) {
    const err = error.response;
    return { error: err };
  }
};