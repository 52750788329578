import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
import { PlusIcon } from "./CustomIcons";
import lampImg from "../../assets/lamp.png";
import { Link } from "react-router-dom";
import { Button } from "antd";
import UploadContactsButton from "./UploadContactsButton";

export default function EmptyCampaign() {
  return (
    <Flex
      direction="column"
      flex="1"
      alignItems="center"
      justifyContent="center"
      gap="4"
      py="10"
    >
      <Image src={lampImg} objectFit="cover" alt="Lamp" />

      <Text fontSize={{ base: "md", md: "xl" }} mb="4" align="center">
        You have not created any campaign yet!
      </Text>

      <Flex wrap="wrap" gap="4" align="center" justify="center">
        <Link to="/sms-automation/new-campaign">
          <Button type="primary" size="large" icon={<PlusIcon />}>
            Create New Campaign
          </Button>
        </Link>

        <UploadContactsButton />
      </Flex>
    </Flex>
  );
}
