import React from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { OTPInput } from "components/common";
import {
  disable2FA,
  getUserProfile,
  verify2FACode,
} from "redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import { message } from "antd";
// import { disable2FA, verify2FACode } from "@/services/settings";

type MfaSteps = "password" | "install" | "start" | "enter-code" | "success";

type Props = {
  isForDisable?: boolean;
  onNext: (dir: "success") => void;
  setMfa?: (mfa: MfaSteps | null) => void;
  setDisable2FAProcess?: React.Dispatch<React.SetStateAction<boolean>>;
};

const EnterCode = ({
  onNext,
  isForDisable,
  setMfa,
  setDisable2FAProcess,
}: Props) => {
  const dispatch = useDispatch();
  const userData = useSelector(
    (state: RootState) => state.authReducer.loginDataTwo
  );
  const [isVerifying, setIsVerifying] = React.useState(false);
  const [verificationCode, setVerificationCode] = React.useState("");

  const onContinue = () => {
    onNext("success");
  };

  const verifyCode = async () => {
    setIsVerifying(true);
    await verify2FACode(
      userData?._id,
      userData?.accounts?.[0],
      verificationCode,
      async () => {
        isForDisable
          ? dispatch(
              disable2FA(userData?._id, userData?.accounts?.[0], () => {
                dispatch(getUserProfile());
                message.success("2FA disabled successfully");
                setDisable2FAProcess?.(false);
                setMfa?.(null);
              })
            )
          : (() => {
              dispatch(getUserProfile());
              onContinue();
            })();
      }
    );
    setIsVerifying(false);
  };

  return (
    <Box>
      <Flex
        flexDir="column"
        alignItems="center"
        px={{ base: "0", md: "20px", lg: "40px" }}
      >
        <Text
          mb="16px"
          fontWeight="600"
          textAlign="center"
          color="neutral.500"
          fontSize={["20px", "20px", "24px", "28px"]}
        >
          Enter code
        </Text>
        <Text type="02" mb="50px" textAlign="center">
          Enter the 6-digit code generated by your Google Authenticator.
        </Text>
        <Box w="100%">
          <OTPInput
            numInputs={6}
            onComplete={() => verifyCode()}
            onChange={(code: string) => setVerificationCode(code)}
          />
        </Box>
      </Flex>

      <Box h="50px" />

      <Flex
        gap={2}
        mb="40px"
        alignItems={"center"}
        justifyContent={{ md: "flex-end" }}
        flexDir={["column-reverse", null, "row"]}
        px={{ base: "0", md: "20px", lg: "40px" }}
      >
        <Button
          variant="solid"
          onClick={verifyCode}
          isLoading={isVerifying}
          isDisabled={verificationCode.length !== 6}
        >
          Verify
        </Button>
      </Flex>
    </Box>
  );
};

export default EnterCode;
