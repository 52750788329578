import { InfoCircleOutlined } from "@ant-design/icons";
import { Box, Flex, Heading, HStack, Text, Tooltip } from "@chakra-ui/react";
import { Switch } from "antd";
import { isReadOnly } from "helpers/utilFunctions";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserSettings } from "redux/actions/settingsActions";
import { RootState } from "types";

const AccountPreferenceForm = () => {
  const { settingData } = useSelector(
    (state: RootState) => state.settingsReducer
  );

  return (
    <Box mt="6">
      <AccountPreferenceToggle
        title="USSD"
        description="Activate USSD channel for loan origination"
        defaultChecked={!settingData?.data?.ussdOffline}
        tab="ussd"
        tip="Enabling USSD will allow you to originate loan via USSD channel."
      />
      <AccountPreferenceToggle
        title="Top-up loans"
        description="Allow customers to take top-up loans"
        defaultChecked={settingData?.data?.topUp === "yes"}
        tab="topUpLoan"
        tip="Enabling topup will allow your consistent borrowers to be 
        eligible for additional loan while they still have active loan."
      />
      <AccountPreferenceToggle
        title="Repayment reminder"
        description="Activate repayment reminder"
        defaultChecked={settingData?.data?.activateRepaymentReminder}
        tab="activateRepaymentReminder"
        tip="Enabling repayment reminder will allow your borrowers to get reminders for their repayments"
      />
    </Box>
  );
};

export default AccountPreferenceForm;

interface PreferenceProps {
  title: string;
  description: string;
  defaultChecked: boolean;
  tab: "topUpLoan" | "ussd" | "activateRepaymentReminder";
  tip: string;
}

const AccountPreferenceToggle = ({
  title,
  description,
  defaultChecked,
  tab,
  tip,
}: PreferenceProps) => {
  const [checked, setChecked] = useState<boolean>(defaultChecked);
  const readonly = isReadOnly();
  const dispatch = useDispatch();
  const xJwt = localStorage.getItem("x_jwt");

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  // const isUssd = tab === "ussd";

  return (
    <HStack justify="space-between" align="center" mb="8">
      <Box>
        <Flex align="center" mb="4">
          <Heading
            as="h3"
            mr="2"
            fontSize={{ base: "14px", md: "18px" }}
            lineHeight={{ base: "18px", md: "22px" }}
          >
            {title}
          </Heading>

          <Tooltip
            hasArrow
            label={tip}
            fontSize="12px"
            lineHeight="16px"
            p="2"
            color="#E8E8E8"
            bg="#25282B"
          >
            <InfoCircleOutlined style={{ fontSize: "14px" }} />
          </Tooltip>
        </Flex>

        <Text
          fontSize={{ base: "12px", md: "16px" }}
          lineHeight={{ base: "16px", md: "20px" }}
        >
          {description}
        </Text>
      </Box>

      <Switch
        checked={checked}
        disabled={readonly}
        onChange={value => {
          dispatch(updateUserSettings(xJwt, tab, { enabled: value }));
          setChecked(value);
        }}
        className="colending-toggle"
      />
    </HStack>
  );
};
