import React, { useEffect } from "react";
import { Text, Button, Box } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { getPayingEmployers } from "redux/actions/toolsActions";
import { Table } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import _ from "lodash";
import { CSVLink } from "react-csv";
import moment from "moment";
import { DashboardHeader } from "../../components/common";
import { PayingEmployersTourSteps } from "../../constants/tourSteps";
import { RootState } from "../../types";
import { DateTimeFormat } from "../../constants";
import { Loader } from "components/common";

const columns = [
  {
    dataIndex: "_id",
    key: "_id",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Employer
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "count",
    key: "count",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          No of Payments
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "date",
    key: "date",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Date
        </Text>
      </Box>
    ),
  },
];

export default function PayingEmployers() {
  const dispatch = useDispatch();
  const eligibilityState = useSelector(
    (state: RootState) => state.toolsReducer
  );
  const payingEmployersList = eligibilityState?.payingEmployersList;
  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const userData = useSelector(
    (state: RootState) => state.authReducer.loginDataTwo
  );
  const info = settingsState?.settingData?.data?.info;
  const xJwt = localStorage.getItem("x_jwt");
  const loading = eligibilityState?.requestLoading;
  const isEmpty = _.isEmpty(payingEmployersList);

  useEffect(() => {
    dispatch(getPayingEmployers(xJwt));
  }, [dispatch, xJwt]);

  const data: any[] | undefined = [];

  for (let i = 0; i < payingEmployersList?.length; i++) {
    const item = payingEmployersList[i];
    data?.push({
      key: item._id,
      _id: `${item._id}`,
      count: item.count,
      date: moment(item.date).format(DateTimeFormat),
    });
  }

  return (
    <Box px="4" py="5">
      <DashboardHeader
        title="View Paying Employers"
        description="A list of paying employers"
        tourSteps={PayingEmployersTourSteps}
      />

      <Box mt="3" p="3" className="loan-tab-pane">
        <Box className="transactions-table-header">
          <Button
            variant="action"
            mr="3"
            rightIcon={<DownloadOutlined />}
            isDisabled={loading || isEmpty}
            className="download-btn"
          >
            {isEmpty ? (
              "Download"
            ) : (
              <CSVLink
                data={payingEmployersList}
                filename={`${
                  info?.businessName || userData?.businessName || ""
                }_VeendHQAutoLending.paying-employers_${moment().format(
                  "YYYY/MM/DD/HH/mm"
                )}.csv`}
                target="_blank"
                className="no-hover"
              >
                Download
              </CSVLink>
            )}
          </Button>
        </Box>
        {loading ? (
          <Loader size="lg" />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              defaultPageSize: 50,
              hideOnSinglePage: true,
              position: ["bottomLeft"],
            }}
          />
        )}
      </Box>
    </Box>
  );
}
