import { RepaymentsResponse } from "types/responses";
import {
  REPAYMENTS_ACTION_SUCCESS,
  REPAYMENTS_ACTION_FAILURE,
  REQUEST_LOADING_TRUE,
  UPDATE_REPAYMENTS_DOWNLOAD_LIST,
} from "../actions/actionTypes";

export type RepaymentState = {
  requestLoading: boolean;
  repayments: RepaymentsResponse;
  repaymentsDownloadList: RepaymentsResponse["data"]["repayments"];
};

const initialState: RepaymentState = {
  requestLoading: false,
  repayments: {} as RepaymentsResponse,
  repaymentsDownloadList: [] as RepaymentsResponse["data"]["repayments"],
};

function repaymentReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  const { type, payload } = action;

  switch (type) {
    case REPAYMENTS_ACTION_SUCCESS:
      return {
        ...state,
        repayments: payload,
        isLoading: false,
      };
    case REPAYMENTS_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestLoading: false,
      };
    case UPDATE_REPAYMENTS_DOWNLOAD_LIST:
      return {
        ...state,
        repaymentsDownloadList: payload,
        requestLoading: false,
      };
    case REQUEST_LOADING_TRUE:
      return {
        ...state,
        requestLoading: true,
      };
    default:
      return state;
  }
}

export default repaymentReducer;
