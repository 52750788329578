import React, { useEffect, useState } from "react";
import { Modal, Tabs } from "antd";
import { Button, Box } from "@chakra-ui/react";
import "../tabs/styles/borrowerTab.css";
import BorrowerProfile from "components/BorrowerProfile";
import { BorrowerLoansTable } from "components/tables";
import { useDispatch, useSelector } from "react-redux";
import { getBorrowersDetails } from "redux/actions/borrowerActions";
import { PreapproveBorrower } from "./";
// @ts-ignore
import JSONViewer from "react-json-viewer";
import { Loader } from "components/common";
import { RootState } from "types";
import { isReadOnly } from "helpers/utilFunctions";

interface IBorrowerDetailsProps {
  showModal: boolean;
  id: any;
  setShowModal: (bool: boolean) => void;
}

const { TabPane } = Tabs;

export default function BorrowerDetails({
  showModal,
  setShowModal,
  id,
}: IBorrowerDetailsProps) {
  const dispatch = useDispatch();
  const [preapproveModal, setPreapproveModal] = useState(false);
  const borrowerState = useSelector(
    (state: RootState) => state.borrowerReducer
  );
  const isLoading = borrowerState?.borrowerProfileLoading;
  const borrower = borrowerState?.borrowerDetailsData?.data?.[0];
  const borrowerLoan = borrower?.loans;
  const eligibilitys = borrower?.eligibilitys;
  const xTag = localStorage.getItem("x_jwt");
  const readonly = isReadOnly();

  const handleOk = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const afterClose = () => {
    dispatch(getBorrowersDetails(id, xTag));
  };

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getBorrowersDetails(id, xTag));
    }
  }, [dispatch, id, xTag]);

  return (
    <>
      <Modal
        visible={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={afterClose}
        footer={null}
        width="98%"
        className="loan-profile-modal"
      >
        <Box className="borrower-details-top">
          <Button mr="3" my="2" disabled>
            Comment
          </Button>
          <Button
            mr="3"
            my="2"
            variant="secondary"
            disabled={isLoading || readonly}
            onClick={() => setPreapproveModal(true)}
          >
            Pre-approve
          </Button>
          <Button mr="3" my="2" variant="action" disabled>
            Send Notification
          </Button>
          <Button mr="3" my="2" variant="outlineYellow" disabled>
            Attachment
          </Button>
        </Box>
        {/* Tabs start */}
        <Tabs defaultActiveKey="1">
          <TabPane tab="Borrower Details" key="1">
            <BorrowerProfile borrower={borrower} />
          </TabPane>
          <TabPane tab="Loans" key="2">
            <Box className="borrower-loans">
              {isLoading && <Loader />}
              <BorrowerLoansTable borrowerLoan={borrowerLoan} />
            </Box>
          </TabPane>
          <TabPane tab="Eligibility Log" key="3">
            <Box className="log-tab-pane">
              {isLoading && <Loader />}
              {eligibilitys?.[eligibilitys?.length - 1]?.log === null ? (
                "no log"
              ) : (
                <JSONViewer
                  json={eligibilitys?.[eligibilitys?.length - 1]?.log}
                />
              )}
            </Box>
          </TabPane>
        </Tabs>
        {/* Tabs end */}
      </Modal>
      <PreapproveBorrower
        showModal={preapproveModal}
        setShowModal={setPreapproveModal}
        id={id}
        borrower={borrower}
        afterClose={afterClose}
      />
    </>
  );
}
