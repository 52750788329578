import {
  ERROR_401_ACTION,
  GET_BANKS_ACTION_SUCCESS,
  WITHDRAW_FUNDS_ACTION_SUCCESS,
  WITHDRAW_FUNDS_ACTION_FAILED,
  CREATE_ACCOUNT_ACTION_SUCCESS,
  CREATE_ACCOUNT_ACTION_FAILED,
  GET_USERID_ACTION_SUCCESS,
  GET_USERID_ACTION_FAILED,
} from "../actions/actionTypes";

export interface GeneralState {
  authError: boolean;
  banksList: {};
  withdrawFunds: {};
  createAccount: {};
  userId: { users: string[] };
}

const initialState = {
  authError: false,
  banksList: {},
  withdrawFunds: {},
  createAccount: {},
  userId: {},
};

function generalReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case ERROR_401_ACTION:
      return {
        ...state,
        authError: true,
      };
    case GET_BANKS_ACTION_SUCCESS:
      return {
        ...state,
        banksList: payload,
      };
    case WITHDRAW_FUNDS_ACTION_SUCCESS:
      return {
        ...state,
        withdrawFunds: payload,
      };
    case WITHDRAW_FUNDS_ACTION_FAILED:
      return {
        ...state,
        withdrawFunds: payload,
      };
    case CREATE_ACCOUNT_ACTION_SUCCESS:
      return {
        ...state,
        createAccount: payload,
      };
    case CREATE_ACCOUNT_ACTION_FAILED:
      return {
        ...state,
        createAccount: payload,
      };
    case GET_USERID_ACTION_SUCCESS:
      return {
        ...state,
        userId: payload,
      };
    case GET_USERID_ACTION_FAILED:
      return {
        ...state,
        userId: payload,
      };
    default:
      return state;
  }
}

export default generalReducer;
