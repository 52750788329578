import { InputField, SMSInputField } from "components/common";
import { useFormikContext } from "formik";
import { CampaignFields } from "views/main/smsAutomation/CreateNewCampaign";

export default function CampaignContentForm() {
  const { values, handleBlur, handleChange, errors } =
    useFormikContext<CampaignFields>();

  return (
    <>
      <InputField
        inputType="text"
        name="name"
        hasLabel
        label="Campaign name"
        placeholder="July Marketing"
        value={values.name}
        onBlur={() => handleBlur}
        onChange={handleChange}
        error={errors.name}
      />

      <InputField
        inputType="number"
        name="amount"
        hasLabel
        label="Number of messages at a time"
        placeholder="500"
        value={values.amount}
        onBlur={() => handleBlur}
        onChange={handleChange}
        error={errors.amount}
      />

      <SMSInputField />
    </>
  );
}
