import { Box, Flex, IconButton, Image, Text, toast } from "@chakra-ui/react";
import { InputField, SelectInput } from "components/common";
import VeendButton from "components/common/Button";
import KycStepHeader from "components/modals/KycModal/KycStepHeader";
import FileStack from "components/common/FileStack";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import CloudUploadImg from "assets/cil_cloud-upload.png";
import { idTypes } from "../../constants";
import { verifyDirectorInfo } from "helpers/api";
import { getUserSettings } from "redux/actions/settingsActions";
import { DeletePhoneIcon } from "components/common/CustomIcons";
import { message } from "antd";
import moment from 'moment'

type Props = {
  handleCurrentStep?: (num: 0 | 1) => void;
  currentStep: number;
};

const DirectorInformation = ({ handleCurrentStep, currentStep }: Props) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [allowSkip, setAllowSkip] = useState(false);

  const setting = useSelector((state: RootState) => state.settingsReducer);
  const { settingData, isLoading } = setting;

  const { values, setFieldValue, handleChange, handleSubmit, isSubmitting } =
    useFormik({
      initialValues: {
        bvn: settingData.data?.directorInfo?.bvn || "",
        idType: idTypes.find(
          item => item.value === settingData.data?.directorInfo?.idType
        ) || { label: "", value: "" },
        idNumber: settingData.data?.directorInfo?.idNumber || "",
        dateOfBirth: 
          settingData.data?.directorInfo?.dateOfBirth
            ?.split("-")
            .reverse()
            .join("-") || "",
        idFile: settingData.data?.directorInfo?.idFile || "",
        dlLastName: settingData.data?.directorInfo?.dlLastName || "",
        dlFirstName: settingData.data?.directorInfo?.dlFirstName || "",
        passportLastName:
          settingData.data?.directorInfo?.passportLastName || "",
        pvcLastName: settingData.data?.directorInfo?.pvcLastName || "",
        pvcState: settingData.data?.directorInfo?.pvcState || "",
      },
      async onSubmit(values) {
        const { dateOfBirth, bvn, idType, idFile, idNumber } = values;

        const formattedDateOfBirth = moment(dateOfBirth).format('DD-MM-YYYY') 

        const dataToSend: Record<string, string | null | boolean> = {
          bvn: values.bvn,
          idType: values.idType.value,
          idNumber: values.idNumber,
          dateOfBirth: formattedDateOfBirth,

        };

        if (idFile) {
          dataToSend.idFile = idFile;
        }

        if (values.idType.value === "drivers_license") {
          dataToSend.dlLastName = values.dlLastName;
          dataToSend.dlFirstName = values.dlFirstName;
          dataToSend.dlDateofBirth = formattedDateOfBirth;
        }
        if (values.idType.value === "passport") {
          dataToSend.passportLastName = values.passportLastName;
        }
        if (values.idType.value === "voters_card") {
          dataToSend.pvcLastName = values.pvcLastName;
          dataToSend.pvcState = values.pvcState;
        }

        if (settingData.data?.directorInfo?.isVerified) {
          dataToSend.isForReverification = true;
        }
        const { error, data, errMessage } = await verifyDirectorInfo(
          dataToSend
        );
        if (error) {
          message.error(errMessage);
        }
        if (data) {
          dispatch(getUserSettings(localStorage.getItem("x_jwt")));
          handleCurrentStep && handleCurrentStep(1);
        }
      },
      enableReinitialize: true,
    });

  return (
    <Box>
      <KycStepHeader title="Director’s Information" currentStep={currentStep} />

      <form onSubmit={handleSubmit}>
        <InputField
          label="Director’s BVN"
          placeholder="Enter your director’s BVN"
          name="bvn"
          onChange={handleChange}
          value={values.bvn}
          hasLabel
          error={false}
          inputType="text"
        />

        <InputField
          label="Director’s date of birth"
          placeholder="Enter your director’s date of birth"
          name="dateOfBirth"
          onChange={handleChange}
          inputType="date"
          value={values.dateOfBirth}
          hasLabel
          error={false}
        />

        <SelectInput
          label="Director’s ID Type"
          id="idType"
          onChange={setFieldValue}
          options={idTypes}
          value={values.idType}
          placeholder="Select an ID"
          error={false}
          hasLabel
        />
        <InputField
          label="ID number"
          placeholder={
            values.idType.value === "nin"
              ? "Enter virtual NIN"
              : values.idType.value === "phone_number"
              ? "Enter NIN phone number"
              : "Enter ID number"
          }
          name="idNumber"
          onChange={handleChange}
          value={values.idNumber}
          inputType="text"
          hasLabel
          desc={
            values?.idType?.value === "nin" ? (
              <>
                <Text mb="5px" >To generate a virtual NIN</Text>
                <Text fontWeight={400}>
                  . Dial *346*3*customer NIN*696739# on your mobile phone
                </Text>
                <Text fontWeight={400}>
                  . Use NIMC official mobile app
                </Text>
              </>
            ) : values?.idType?.value === "phone_number" ? (
              <Text mt="2" type="footer" fontWeight={400} text="">
                Enter the phone number associated with your NIN
              </Text>
            ) : (
              ""
            )
          }
          error={false}
        />

        {values.idType.value === "drivers_license" && (
          <>
            <InputField
              label="Last name on drivers license"
              placeholder="Enter last name on drivers license"
              name="dlLastName"
              onChange={handleChange}
              value={values.dlLastName}
              hasLabel
              error={false}
              inputType="text"
              // isRequired
            />
            <InputField
              label="First name on drivers license"
              hasLabel
              placeholder="Enter first name on drivers license"
              name="dlFirstName"
              onChange={handleChange}
              value={values.dlFirstName}
              error={false}
              inputType="text"
              // isRequired
            />
          </>
        )}

        {values.idType.value === "passport" && (
          <>
            <InputField
              label="Last name on passport"
              hasLabel
              placeholder="Enter ID number"
              name="passportLastName"
              onChange={handleChange}
              value={values.passportLastName}
              error={false}
              inputType="text"
              // isRequired
            />
          </>
        )}

        {values.idType.value === "voters_card" && (
          <>
            <InputField
              label="Last name on voters card"
              placeholder="Enter last name on voters card"
              name="pvcLastName"
              onChange={handleChange}
              value={values.pvcLastName}
              hasLabel
              error={false}
              inputType="text"
            />
            <InputField
              label="State registered"
              placeholder="State"
              name="pvcState"
              onChange={handleChange}
              value={values.pvcState}
              hasLabel
              error={false}
              inputType="text"
            />
          </>
        )}
        <FileStack
          open={open}
          setOpen={setOpen}
          accept={["image/*", "application/pdf"]}
          onUploadComplete={res => {
            setFieldValue("idFile", res.filesUploaded?.[0]?.url);
          }}
        />
        {/* @ts-ignore */}
        {values.idFile.length || settingData.data?.businessInfo?.idFile ? (
          <Box position={"relative"}>
            <Box>Copy of ID</Box>

            <Box position={"relative"} w={["100%", "50%"]}>
              <Image
                src={values.idFile}
                style={{ width: "100%", border: "none" }}
              />
              <IconButton
                bg="error.50"
                aria-label="delete"
                _hover={{
                  bg: "error.50",
                }}
                position={"absolute"}
                top={0}
                right={[0, -10]}
                onClick={() => {
                  setFieldValue("idFile", "");
                }}
              >
                <DeletePhoneIcon boxSize={"24px"} />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box>Copy of ID</Box>
            <Box
              className="custom-upload-input"
              onClick={() => setOpen(true)}
              cursor="pointer"
            >
              <Box className="custom-upload-over-lay">
                <Image src={CloudUploadImg} />
                <Text fontSize="md" fontWeight="500">
                  Upload document here or{" "}
                  <Text as="span" color="#130AD8">
                    Browse
                  </Text>
                </Text>
                <Text fontSize="12px">
                  Supported file types: JPG, PNG, PDF. Max file size: 2MB
                </Text>
              </Box>
            </Box>
          </Box>
        )}

        <Flex
          justifyContent={"flex-end"}
          flexDirection={{ base: "column", md: "row" }}
          mt="50px"
          gap={4}
        >
          <VeendButton
            colorScheme={"primary"}
            type={"submit"}
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            w="full"
          >
            Save & Continue
          </VeendButton>
          <VeendButton
            colorScheme={"primary"}
            type={"button"}
            isDisabled={isSubmitting}
            w="full"
            onClick={() => {
              handleCurrentStep && handleCurrentStep(1);
            }}
            display={sessionStorage.getItem("allow-skip") ? "flex" : "none"}
            variant="outline"
          >
            Skip
          </VeendButton>
        </Flex>
      </form>
    </Box>
  );
};

export default DirectorInformation;
