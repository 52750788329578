import { Flex, Button } from "@chakra-ui/react";
import { Form, Select } from "antd";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getAmountSpentOnCampaigns,
  getCampaignsSummary,
} from "redux/actions/smsAutomationActions";
import { RootState } from "types";
import CampaignStatsCard from "./CampaignStatsCard";
import { GroupIcon, MoneyIcon } from "./CustomIcons";

export default function CapmaignStats() {
  const history = useHistory();
  const dispatch = useDispatch();
  const campaignsState = useSelector(
    (state: RootState) => state.smsAutomationReducer
  );
  const campaignsSummary = campaignsState?.campaignsSummary;
  const amountSpentOnCampaign = campaignsState?.amountSpentOnCampaign;
  const isAmountSpentLoading = campaignsState?.isAmountSpentLoading;

  const [filterAmountBy, setFilterAmountBy] = useState("month");

  const xJwt = localStorage.getItem("x_jwt");

  useEffect(() => {
    dispatch(getCampaignsSummary(xJwt));
  }, [dispatch, xJwt]);

  useEffect(() => {
    dispatch(getAmountSpentOnCampaigns(xJwt, filterAmountBy));
  }, [dispatch, filterAmountBy, xJwt]);

  return (
    <Flex gap={{ base: 4, md: 8 }} wrap={{ base: "wrap", md: "nowrap" }} mb="4">
      <CampaignStatsCard
        value={campaignsSummary.uploads}
        description={`Uploaded contact${
          campaignsSummary.uploads > 1 ? "s" : ""
        }`}
        Icon={GroupIcon}
        viewBtn={
          <Button
            variant={"outline"}
            color={"#060089"}
            borderColor={"#060089"}
            isDisabled={campaignsSummary.uploads === 0}
            onClick={() => history.push("/sms-automation/uploaded-contacts")}
          >
            View
          </Button>
        }
      />

      <CampaignStatsCard
        value={`₦${amountSpentOnCampaign}`}
        description="Amount spent on campaigns"
        Icon={MoneyIcon}
        filter={
          <Formik
            initialValues={{ filter: filterAmountBy }}
            onSubmit={values => console.log(values)}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <Select
                  loading={isAmountSpentLoading}
                  id="filter"
                  value={values.filter}
                  style={{ width: 120 }}
                  size="small"
                  onChange={value => {
                    setFieldValue("filter", value);
                    setFilterAmountBy(value);
                  }}
                  options={[
                    { value: "day", label: "Today" },
                    { value: "week", label: "This Week" },
                    { value: "month", label: "This Month" },
                    { value: "annual", label: "This Year" },
                    { value: "all-time", label: "Total" },
                  ]}
                />
              </Form>
            )}
          </Formik>
        }
      />
    </Flex>
  );
}
