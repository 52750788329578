import React, { useEffect, useState } from "react";
import { Box, Button, Text } from "@chakra-ui/react";
import { PlusCircleFilled, SyncOutlined } from "@ant-design/icons";
import { Radio } from "antd";
import {
  DownloadEligibilities,
  DashboardHeader,
} from "../../components/common";
import { GetEligibilityModal } from "../../components/modals";
import { EligibilityTable } from "../../components/tables";
import { IEligibilityStatus, RootState } from "../../types";
import { useSelector, useDispatch } from "react-redux";
import {
  getEligibility,
  getNextEligibility,
} from "../../redux/actions/toolsActions";
import { popLastId, isReadOnly } from "../../helpers/utilFunctions";
import { BulkEligibilityTourSteps } from "../../constants/tourSteps";

export default function BulkEligibility() {
  const dispatch = useDispatch();
  const eligibilityState = useSelector(
    (state: RootState) => state.toolsReducer
  );
  const eligibilityRes = eligibilityState?.eligibilityData;
  const [showEligibilityModal, setShowEligibilityModal] = useState(false);
  const [searchParam, setSearchParam] =
    useState<IEligibilityStatus["tab"]>("all");
  const [lastIds, setLastIds] = useState<(string | null)[]>([null]);
  const xJwt = localStorage.getItem("x_jwt");
  const readonly = isReadOnly();

  const openEligibilityTool = () => {
    setShowEligibilityModal(true);
  };

  const onRadioToggle = (e: any) => {
    setSearchParam(e.target.value);
  };

  const getEligibilities = (refresh?: any) => {
    if (refresh) {
      setLastIds([null]);
    }
    dispatch(getEligibility(xJwt, searchParam));
  };
  const loadNextPage = () => {
    dispatch(getNextEligibility(xJwt, searchParam, eligibilityRes?.lastId));
  };

  const loadPrevPage = () => {
    if (lastIds.length === 3) {
      dispatch(getEligibility(xJwt, searchParam));
      setLastIds([null]);
    } else {
      dispatch(
        getNextEligibility(xJwt, searchParam, lastIds[lastIds.length - 3])
      );
      popLastId(lastIds, setLastIds);
    }
  };

  useEffect(() => {
    setLastIds([null]);
    getEligibilities();
  }, [searchParam]); //eslint-disable-line

  useEffect(() => {
    if (eligibilityRes !== undefined && eligibilityRes?.lastId !== undefined) {
      setLastIds([...lastIds, eligibilityRes?.lastId]);
    }
  }, [eligibilityRes]); //eslint-disable-line

  return (
    <>
      <Box px="4" py="5">
        {/* Page top start */}
        <DashboardHeader
          title="Get user eligibilities"
          description="Upload phone numbers to process eligibilities"
          actionButtons={
            <Button
              mr="3"
              className="upload-numbers-btn"
              leftIcon={<PlusCircleFilled />}
              onClick={openEligibilityTool}
              disabled={readonly}
            >
              Upload Numbers
            </Button>
          }
          tourSteps={BulkEligibilityTourSteps}
        />

        {/* Page top end */}
        {/* Table start */}
        <Box mt="3" className="loan-tab-pane">
          <Box className="transactions-table-header">
            <Radio.Group
              onChange={onRadioToggle}
              value={searchParam}
              className="eligibilities-radio"
            >
              <Radio value="all">All</Radio>
              <Radio value="pending">Pending</Radio>
              <Radio value="processing">Processing</Radio>
              <Radio value="failed">Non-Eligible users</Radio>
              <Radio value="completed">Eligible users</Radio>
            </Radio.Group>
            <Box d="flex">
              <Text
                fontSize={{ base: "xs", md: "sm" }}
                className="refresh-eligibilities"
                onClick={e => getEligibilities(e)}
              >
                <Box as={SyncOutlined} mr="2" />
                Refresh
              </Text>
              <DownloadEligibilities tab={searchParam} />
            </Box>
          </Box>
          <EligibilityTable tab={searchParam} />
          {!eligibilityRes?.isLastPage && eligibilityRes?.data?.length > 0 ? (
            <Box className="pagination-items">
              {lastIds.length <= 2 ? null : (
                //eslint-disable-next-line
                <a onClick={loadPrevPage} className="pagination-item-link prev">
                  Previous page
                </a>
              )}
              {/* eslint-disable-next-line */}
              <a onClick={loadNextPage} className="pagination-item-link next">
                Next page
              </a>
            </Box>
          ) : null}
        </Box>
      </Box>
      <GetEligibilityModal
        showModal={showEligibilityModal}
        setShowModal={setShowEligibilityModal}
      />
    </>
  );
}
