import axios from "axios";
import { message } from "antd";
import { ERROR_401_ACTION, SHOW_LICENSE_UPLOAD_MODAL } from "./actionTypes";
import { UPLOADED_CONTACTS_LIMIT_PER_PAGE } from "constants/index";

const baseUrl = process.env.REACT_APP_BASE_URL;

axios.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  "x-no-cache": "yes",
  Expires: "0",
};

// Close the staged phone numbers modal
export const showLendingLicenseModal =
  (state: boolean) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    dispatch({
      type: SHOW_LICENSE_UPLOAD_MODAL,
      payload: state,
    });
  };
