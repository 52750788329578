import React from "react";
import { Modal, Upload, message } from "antd";
import type { UploadProps } from "antd";
import { Heading, Text, Box, Center } from "@chakra-ui/react";
import {
  FileBGIcon,
  PenBGIcon,
  TXTFileIcon,
} from "components/common/CustomIcons";
import { useDispatch, useSelector } from "react-redux";
import {
  closeUploadContactsModal,
  openAddManuallyModal,
  openStagedPhoneNumbersModal,
} from "redux/actions/smsAutomationActions";
import { RootState } from "types";

export default function AddRecipientsModal() {
  const dispatch = useDispatch();
  const campaignsState = useSelector(
    (state: RootState) => state.smsAutomationReducer
  );
  const showModal = campaignsState?.showUploadContactsModal;

  function showManualForm() {
    // Close the current modal
    dispatch(closeUploadContactsModal());

    // Show the addManually form
    dispatch(openAddManuallyModal());
  }

  const uploadTXTProps: UploadProps = {
    name: "file",
    accept: ".txt",
    showUploadList: false,

    beforeUpload: file => {
      const isTXTFile = file.type === "text/plain";

      if (!isTXTFile) {
        message.error("You can only upload a .TXT file");
      } else {
        message.info("Importing numbers...", 0);

        const reader = new FileReader();

        reader.onload = function () {
          const numbersFromFile: ArrayBuffer | string | null = reader.result;

          const phoneNumbers = numbersFromFile
            ?.toString()
            .replace(/(?:\r\n|\r|\n)/g, ",")
            .split(",")
            .filter(n => n) // Remove empty elements
            .map(n => n.trim()) // Trim off the whitespaces
            .filter((item, position, self) => self.indexOf(item) === position); // Remove duplicated contacts

          // Open the staging modal
          dispatch(openStagedPhoneNumbersModal(phoneNumbers));
          message.destroy();
        };

        reader.readAsText(file);
      }

      // Prevent server upload
      return false;
    },
  };

  const uploadCSVProps: UploadProps = {
    name: "file",
    accept: ".csv",
    showUploadList: false,

    beforeUpload: file => {
      const isCSVFile = file.type === "text/csv";

      if (!isCSVFile) {
        message.error("You can only upload a .CSV file");
      } else {
        message.info("Importing numbers...", 0);

        const reader = new FileReader();

        reader.onload = function () {
          const numbersFromFile: ArrayBuffer | string | null = reader.result;

          const phoneNumbers = numbersFromFile
            ?.toString()
            .replace(/(?:\r\n|\r|\n)/g, ",")
            .split(",")
            .filter(n => n) // Remove empty elements
            .map(n => n.trim()) // Trim off the whitespaces
            .filter((item, position, self) => self.indexOf(item) === position); // Remove duplicated contacts

          // Open the staging modal
          dispatch(openStagedPhoneNumbersModal(phoneNumbers));
          message.destroy();
        };

        reader.readAsText(file);
      }

      // Prevent server upload
      return false;
    },
  };

  return (
    <Modal
      visible={showModal}
      onCancel={() => dispatch(closeUploadContactsModal())}
      footer={null}
      width="800px"
    >
      <Box textAlign="center">
        <Heading as="h2" mb="8" fontSize={{ base: "md", md: "lg", lg: "xl" }}>
          Add recepients to the database
        </Heading>
      </Box>

      <Box className="add-option-container">
        <Upload {...uploadCSVProps}>
          <Box className="add-option">
            <FileBGIcon />

            <Heading as="h6" fontSize={{ base: "xs", md: "sm", lg: "lg" }}>
              Upload a .CSV file
            </Heading>

            <Text fontSize={{ base: "xs", md: "sm" }} fontWeight="medium">
              Import phone numbers
            </Text>
          </Box>
        </Upload>

        <Upload {...uploadTXTProps}>
          <Box className="add-option">
            <TXTFileIcon />

            <Heading as="h6" fontSize={{ base: "xs", md: "sm", lg: "lg" }}>
              Upload a .TXT file
            </Heading>

            <Text fontSize={{ base: "xs", md: "sm" }} fontWeight="medium">
              Import phone numbers
            </Text>
          </Box>
        </Upload>

        <Box className="add-option" onClick={() => showManualForm()}>
          <PenBGIcon />

          <Heading as="h6" fontSize={{ base: "xs", md: "sm", lg: "lg" }}>
            Add manually
          </Heading>

          <Text fontSize={{ base: "xs", md: "sm" }} fontWeight="medium">
            Paste a list of phone numbers
          </Text>
        </Box>
      </Box>

      <Center fontWeight="bold">
        Download sample file&nbsp;
        <a
          onClick={() => dispatch(closeUploadContactsModal())}
          className="sample-link-download"
          href="/sample_files/sample_numbers_csv.csv"
          download
        >
          .csv,
        </a>
        &nbsp;
        <a
          onClick={() => dispatch(closeUploadContactsModal())}
          className="sample-link-download"
          href="/sample_files/sample_numbers_txt.txt"
          download
        >
          .txt
        </a>
      </Center>

      <Center fontSize="12px">
        Please note that uploading contacts may attract data referencing cost for each phone number successfully uploaded.
      </Center>
    </Modal>
  );
}
