// import { useQuery } from "@chakra-ui/react";
import { MainLoader } from "components/common";
import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

type Props = {};

const Handover = (props: Props) => {
  const query = useLocation();
  const authorization = new URLSearchParams(query.search).get("authorization");
  const email = new URLSearchParams(query.search).get("email");
  const tx_jwt = new URLSearchParams(query.search).get("trx");

  const history = useHistory();

  useEffect(() => {
    if (authorization) {
      localStorage.setItem("x_jwt", authorization as string);
      localStorage.setItem("email", email as string);
      localStorage.setItem("tx_jwt", tx_jwt as string);

      history.push("/dashboard");
    }
  }, [authorization]);

  return <MainLoader />;
};

export default Handover;
