import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { RepaymentsTab } from "../../components/tabs";
import { DashboardHeader } from "../../components/common";

export default function Repayments() {
  const xTag = localStorage.getItem("x_jwt");

  useEffect(() => {
    if (xTag === null) {
      window.location.href = "/login";
    }
  }, [xTag]);

  return (
    <>
      <Box px="4" py="5">
        <DashboardHeader
          title="Repayments"
          description="View all repayments on active loans"
        />

        <RepaymentsTab />
      </Box>
    </>
  );
}
