import React from "react";
import * as yup from "yup";
import { Text, Button, Box, HStack, Image } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { InputField } from "components/common";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "redux/actions/authActions";
import { AuthFormWrapper } from "components/common";
import "./styles/auth.css";
import { RootState } from "types";
import LoginImg from "../../assets/auth_img.svg";
import LogoImg from "../../assets/veend-logo.png";
import { Checkbox, Modal } from "antd";
import { TWO_FA_NEEDED_FOR_LOGIN } from "redux/actions/actionTypes";
import EnterTwoFALoginDetails from "./EnterTwoFALoginDetails";
interface Values {
  email: string;
  password: string;
}

export default function Login({ history }: any): JSX.Element {
  const dispatch = useDispatch();
  const authState = useSelector((state: RootState) => state.authReducer);
  const isLoading = authState?.isLoading;
  const [formDetails, setFormDetails] = React.useState<Values>({
    email: "",
    password: "",
  });

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
  };

  const goToResetPasswordPage = () => {
    history.push("/forgot-password");
  };
  const goToRegisterPage = () => {
    history.push("/signup");
  };

  const stopTwoFALogin = () => {
    dispatch({ type: TWO_FA_NEEDED_FOR_LOGIN, payload: false });
  };

  return (
    <Box>
      <HStack spacing="0">
        <Box w="35%" className="login-left">
          <Image src={LogoImg} alt="logo" width={100} />
          <Box className="login-left-desc">
            <Box>
              <Image src={LoginImg} alt="analysis" width={"100%"} />
            </Box>
            <Box>
              <Text fontWeight={500} mt="4">
                Understand your consumers, take advantage of new prospects by
                tracking your timeline, active loans, repaid loans, total
                revenue, average loan balance and more!
              </Text>
            </Box>
          </Box>
        </Box>
        <Box w={["100%", "100%", "65%"]}>
          <Box className="login-right">
            <AuthFormWrapper
              header="Hi, Welcome Back"
              description={
                <Text
                  fontSize={{ base: "xs", lg: "sm", xl: "md" }}
                  fontWeight="medium"
                >
                  Kindly login into your account
                </Text>
              }
            >
              <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={async (values: Values) => {
                  localStorage.setItem("email", values.email);
                  dispatch(loginUser(values.email, values.password));
                }}
                validationSchema={yup.object().shape({
                  email: yup
                    .string()
                    .email()
                    .required("Please provide your email address"),
                  password: yup
                    .string()
                    .required("Please provide your password"),
                })}
              >
                {({ values, handleChange, handleBlur, errors }) => (
                  <Form>
                    <InputField
                      inputType="text"
                      name="email"
                      hasLabel
                      label="Email Address"
                      placeholder="Enter Email Address"
                      value={values.email}
                      onBlur={() => handleBlur}
                      onChange={e => {
                        handleChange(e);
                        handleFormChange(e);
                      }}
                      error={errors.email}
                    />
                    <InputField
                      inputType="password"
                      name="password"
                      hasLabel
                      label="Password"
                      placeholder="Enter Password"
                      value={values.password}
                      onBlur={() => handleBlur}
                      onChange={e => {
                        handleChange(e);
                        handleFormChange(e);
                      }}
                      error={errors.password}
                    />
                    <Box className="password-reset-div">
                      <Checkbox>Keep me signed in</Checkbox>
                      <Text
                        color="primaryBlue"
                        fontSize={{
                          base: "xs",
                          lg: "sm",
                          xl: "md",
                        }}
                        className="password-reset"
                        onClick={goToResetPasswordPage}
                      >
                        Forgot Password?
                      </Text>
                    </Box>
                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      mt="6"
                      isLoading={isLoading}
                      w={"100%"}
                    >
                      Login
                    </Button>
                    <Box mt="10">
                      <Text>
                        New to Veend?
                        <Text
                          color="primaryBlue"
                          fontSize={{
                            base: "xs",
                            lg: "sm",
                            xl: "md",
                          }}
                          as="span"
                          fontWeight={500}
                          ml="2"
                          cursor={"pointer"}
                          onClick={goToRegisterPage}
                        >
                          Create an account
                        </Text>
                      </Text>
                    </Box>
                  </Form>
                )}
              </Formik>
            </AuthFormWrapper>
          </Box>
        </Box>
      </HStack>
      <Modal
        footer={null}
        visible={authState.isTwoFANeededForLogin}
        onOk={stopTwoFALogin}
        onCancel={stopTwoFALogin}
      >
        <EnterTwoFALoginDetails
          loginDetails={{
            email: formDetails.email,
            password: formDetails.password,
          }}
        />
      </Modal>
    </Box>
  );
}
