import React from "react";
import { UploadContactsIcon } from "./CustomIcons";
import { Button } from "antd";
import {
  AddRecipientsModal,
  AddRecipientsManualModal,
} from "components/modals";
import { useDispatch } from "react-redux";
import { openUploadContactsModal } from "redux/actions/smsAutomationActions";
import StagedPhoneNumbersModal from "components/modals/StagedPhoneNumbersModal";

export default function UploadContactsButton() {
  const dispatch = useDispatch();
  return (
    <>
      <Button
        className="outline"
        size="large"
        icon={<UploadContactsIcon />}
        onClick={() => dispatch(openUploadContactsModal())}
      >
        Upload contacts
      </Button>

      <AddRecipientsModal />

      <AddRecipientsManualModal />

      <StagedPhoneNumbersModal />
    </>
  );
}
