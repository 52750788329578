import React, { useEffect } from "react";
import moment from "moment";
import { Form, DatePicker, Radio } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import "./styles/InputFieldStyles.css";
import { Box, Text } from "@chakra-ui/layout";
import { CampaignFields } from "views/main/smsAutomation/CreateNewCampaign";
import { useFormikContext } from "formik";
import { Input } from "@chakra-ui/react";

const FormItem = Form.Item;

interface IDateInput {
  form: any;
  style: any;
  error: any;
  label: string;
  hasLabel: boolean;
  disablePreviousDate: boolean;
  disabled?: boolean;
}

const EndDateInput = ({
  error,
  form: {
    setFieldValue,
    values: { endDate },
  },
  label,
  hasLabel,
  disablePreviousDate,
  disabled,
}: IDateInput) => {
  const { values, handleChange } = useFormikContext<CampaignFields>();

  let wrapperClass = "form-group";

  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  useEffect(() => {
    if (values?.isContinous) {
      setFieldValue("endDate", null);
    }
  }, [setFieldValue, values?.isContinous]);

  return (
    <Box>
      <Box as={FormItem} my={{ base: 2, sm: 6 }} className={wrapperClass}>
        {hasLabel && (
          <Box d="flex" mb="1" className="label-div">
            <Text className="form-label">{label}</Text>

            <Box as={Radio.Group} value={values?.isContinous}>
              <Radio
                value={true}
                name="isContinous"
                id="isContinous"
                onChange={handleChange}
                disabled={disabled}
              >
                Continous
              </Radio>
              <Radio
                value={false}
                name="isContinous"
                id="isContinous"
                onChange={handleChange}
                style={{ marginRight: "0px" }}
                disabled={disabled}
              >
                End at date
              </Radio>
            </Box>
          </Box>
        )}
        <Box my="3">
          <Input
            placeholder={values?.isContinous ? "Continous" : "Select date"}
            onChange={e => setFieldValue("endDate", e.target.value)}
            value={endDate}
            className="date-picker"
            clearIcon={false}
            disabled={values?.isContinous || disabled}
            disabledDate={
              disablePreviousDate
                ? //@ts-ignore
                  current => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current < moment(customDate, "YYYY-MM-DD")
                    );
                  }
                : undefined
            }
            type="date"
          />
        </Box>
        {error && (
          <Box className="error-div">
            <ExclamationCircleFilled className="error-icon" />
            <Text ml="2" className="error-text">
              {error}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default EndDateInput;
