import React from "react";
import { Box } from "@chakra-ui/react";
import MfaDetails from "./MfaDetails";
import MfaSuccess from "./MfaSuccess";

type MfaSteps = "start" | "success";

const Recover2FACode: React.FC = () => {
  const [mfa, setMfa] = React.useState<MfaSteps | null>("start");
  const onSetupMfaComplete = () => setMfa(null);

  const renderMfaBody = () => {
    switch (mfa) {
      case "start":
        return <MfaDetails onNext={() => setMfa("success")} isForReset />;
      case "success":
        return <MfaSuccess isForRecovery onNext={onSetupMfaComplete} />;
      default:
        return <Box />;
    }
  };

  React.useEffect(() => {}, []);

  return <>{renderMfaBody()}</>;
};

export default Recover2FACode;
