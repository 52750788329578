import { SHOW_LICENSE_UPLOAD_MODAL } from "../actions/actionTypes";

export type LendingLicenseState = {
  show: boolean;
  licenseUrl: string;
  lendingLicenseStatus?: "submitted" | "pending" | "approved" | "rejected";
};

const initialState: LendingLicenseState = {
  show: false,
  licenseUrl: "",
  lendingLicenseStatus: undefined,
};

function lendingLicenseReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LICENSE_UPLOAD_MODAL:
      return {
        ...state,
        show: payload,
      };

    default:
      return state;
  }
}

export default lendingLicenseReducer;
