import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import LandingPage from "./LandingPage";
import LoggedInPage from "./LoggedInPage";
import SmsAutomationPage from "./SmsAutomationPage";
import { Error401Modal } from "./../modals";
import { useSelector } from "react-redux";
import { RootState } from "types";
import { Box } from "@chakra-ui/react";

const routesWithSideMenu = [
  "/dashboard",
  "/loans",
  "/borrowers",
  "/balance",
  "/repayments",
  "/settings",
  "/tools",
  "/tools/eligibilities",
  "/tools/eligible-users",
  "/tools/paying-employers",
];

interface IPageLayout {
  location: any;
  children?: any;
}
function PageLayout({ location }: IPageLayout) {
  const genState = useSelector((state: RootState) => state.generalReducer);
  const error401 = genState?.authError;
  const currentUrl = location.pathname;
  const doesURLRequireSideMenu = routesWithSideMenu.includes(currentUrl);
  const isURLForSMSAutomation = currentUrl.includes("/sms-automation");

  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    if (error401 === true) {
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (error401 === true) {
      openModal();
    }
  }, [error401]); //eslint-disable-line
  return (
    <>
      <Layout>
        {doesURLRequireSideMenu ? (
          <LoggedInPage />
        ) : isURLForSMSAutomation ? (
          <SmsAutomationPage />
        ) : (
          <LandingPage />
        )}
      </Layout>

      <Error401Modal showModal={showModal} setShowModal={openModal} />
    </>
  );
}

export default PageLayout;
