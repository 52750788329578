import React, { useEffect } from "react";
import { Text, Box } from "@chakra-ui/react";
import { DotIcon } from "./CustomIcons";
import { useSelector, useDispatch } from "react-redux";
import {
  getCoLendingInterestRate,
  getLenderAtTopOfQueue,
} from "redux/actions/settingsActions";
import { Tooltip } from "antd";
import { RootState } from "types";

interface IColendingDisplay {
  checked?: boolean;
}

export default function ColendingDisplay({ checked }: IColendingDisplay) {
  const dispatch = useDispatch();
  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const dashboardState = useSelector(
    (state: RootState) => state.dashboardReducer
  );
  const colendingInterestRate =
    settingsState?.colendingInterestRate?.interestRate;
  const colendingLeader = settingsState?.colendingLeader?.client;
  const lenderInfo = dashboardState?.operationData?.data?.link;
  const xJwt = localStorage.getItem("x_jwt");
  const lenderName = lenderInfo?.split("/")?.[3];

  useEffect(() => {
    dispatch(getLenderAtTopOfQueue());
    dispatch(getCoLendingInterestRate(xJwt));
  }, [dispatch, xJwt]);

  return (
    <Box className="colending-display">
      <Tooltip
        title={`You are ${
          colendingLeader === lenderName ? "at the" : "not at the"
        } top of the loan origination queue`}
      >
        <Box className="colending-display-inner">
          <DotIcon
            className={`colending-dot ${
              colendingLeader === lenderName
                ? "colending-active"
                : "colending-inactive"
            }`}
            style={{
              width: "0.75rem",
              height: "auto",
              marginRight: "0.5rem",
            }}
          />
          <Text
            color="white"
            className="colending-display-inner"
            fontSize={{ base: "xx-small", md: "xs", lg: "sm" }}
          >
            Originate AI Interest Rate:{" "}
            <Text
              as="span"
              style={{ color: "var(--brand-yellow)", marginLeft: "4px" }}
              fontWeight="semibold"
              fontSize={{ base: "xs", md: "sm", lg: "md" }}
            >
              {colendingInterestRate}%
            </Text>
          </Text>
        </Box>
      </Tooltip>
    </Box>
  );
}
