import React, { useEffect, useState } from "react";
import { Heading, Box, Button, HStack, Text } from "@chakra-ui/react";
import { LoanProductTable } from "components/tables";
import { LoanProductConfig } from "components/modals";
import DTIConfig from "./DTIConfig";
import { isReadOnly } from "helpers/utilFunctions";
import {
  getLoanProducts,
  updateUserSettings,
  getUserSettings,
} from "redux/actions/settingsActions";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "antd";
import { RootState } from "types";
import { MainLoader } from "components/common";
import { showLendingLicenseModal } from "redux/actions/lendingLicenseActions";

export default function LoanProductTabPane() {
  const [showModal, setShowModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const { settingData, requestLoading } = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const { allowMultipleProducts } = settingData.data;
  const xJwt = localStorage.getItem("x_jwt");
  const readonly = isReadOnly();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLoanProducts(xJwt));
  }, [dispatch, xJwt]);

  useEffect(() => {
    setChecked(allowMultipleProducts);
    dispatch(getUserSettings(xJwt));
  }, [allowMultipleProducts, xJwt, dispatch, checked]);

  return (
    <>
      {requestLoading && <MainLoader />}
      <Box className="loan-products-table-box">
        <HStack justify="space-between" p="4">
          <DTIConfig />
          <Button
            onClick={() => {
              if (!settingData?.data?.lendingLicenseUrl)
                return dispatch(showLendingLicenseModal(true));
              setShowModal(true);
            }}
            disabled={readonly}
          >
            New Loan Product
          </Button>
        </HStack>

        <Box className="settings-table-header">
          <Heading as="h4" fontSize={{ base: "xs", md: "sm" }}>
            Loan Products
          </Heading>
        </Box>
        <LoanProductTable />
        <HStack p="4">
          <Text fontWeight={"medium"}>
            {checked ? "Disable" : "Enable"} multiple products for all customers
          </Text>
          <Switch
            checked={checked}
            disabled={readonly}
            onChange={value => {
              dispatch(
                updateUserSettings(xJwt, "loanProduct", {
                  allowMultipleProducts: value,
                })
              );
              setChecked(value);
            }}
            className="colending-toggle"
          />
        </HStack>
      </Box>

      <LoanProductConfig showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
