import React, { useEffect, useState } from "react";
import { Button, Box } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { InputField, Loader } from "../common";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserSettings,
  updateUserSettings,
} from "redux/actions/settingsActions";
import { phoneRegExp } from "../../constants";
import { RootState } from "types";
import { isReadOnly } from "helpers/utilFunctions";
import { getUserProfile } from "redux/actions/authActions";

interface SupportSettingsFormValues {
  supportEmail: string;
  supportPhone: string;
}

function SupportSettingsForm() {
  const dispatch = useDispatch();
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const userSetting = setting?.settingData?.data;
  const xJwt = localStorage.getItem("x_jwt");
  const supportEmail = userSetting?.supportEmail;
  const supportPhone = userSetting?.supportPhone;
  const loading = userSetting?.requestLoading;
  const [submitting, setSubmitting] = useState(false);
  const readonly = isReadOnly();

  useEffect(() => {
    dispatch(getUserSettings(xJwt));
    dispatch(getUserProfile());
  }, [dispatch, xJwt]);

  return (
    <Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={{
              supportEmail: supportEmail,
              supportPhone: supportPhone,
            }}
            onSubmit={({
              supportEmail,
              supportPhone,
            }: SupportSettingsFormValues) => {
              setSubmitting(true);
              let updateBody = {
                supportEmail,
                supportPhone,
              };
              dispatch(updateUserSettings(xJwt, "general", updateBody));
              setSubmitting(false);
            }}
            validationSchema={yup.object().shape({
              supportEmail: yup
                .string()
                .required("please provide a support email"),
              supportPhone: yup
                .string()
                .matches(phoneRegExp, "phone number is not valid"),
              // .required("please provide a phone number"),
            })}
          >
            {({ values, handleChange, handleBlur, errors }) => (
              <Form className="w-lg-75">
                <InputField
                  inputType="text"
                  name="supportEmail"
                  hasLabel
                  label={`Email`}
                  placeholder="Support Email"
                  value={values.supportEmail}
                  onBlur={() => handleBlur}
                  onChange={handleChange}
                  error={errors.supportEmail}
                  desc={`email address where your borrowers can reach you for support and inquiry`}
                  isDisabled={readonly}
                />
                <InputField
                  inputType="text"
                  name="supportPhone"
                  hasLabel
                  label={`Phone Number`}
                  placeholder="Enter phone number"
                  value={values.supportPhone}
                  onBlur={() => handleBlur}
                  onChange={handleChange}
                  error={errors.supportPhone}
                  desc={`phone number where your borrowers can reach you for support and inquiry`}
                  isDisabled={readonly}
                />

                <Button
                  type="submit"
                  className="mt-3"
                  isLoading={submitting}
                  disabled={readonly}
                >
                  Save Changes
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Box>
  );
}

export default SupportSettingsForm;
