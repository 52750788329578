import React from "react";
import { ActiveLoansTable } from "components/tables";
import { LoanTabFilters } from "components/common";

export default function ActiveLoansTabPane() {
  return (
    <LoanTabFilters
      loanTab="active"
      loanStatus="active"
      processStatus="disbursed"
      disbursementStatus="completed"
    >
      <ActiveLoansTable />
    </LoanTabFilters>
  );
}
