import React, { useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";
import { Tabs } from "antd";
import "./styles/settings.css";
import {
  GeneralTabPane,
  LoanProductTabPane,
  WebhooksTabPane,
  AccountDetails,
  LoanOfferTabPane,
  TeamMembersTabPane,
  FeesTabPane,
} from "../../components/tabs";
import { DashboardHeader } from "../../components/common";
import { useLocation } from "react-router-dom";
import { settingsPageSteps } from "../../constants/tourSteps";

const { TabPane } = Tabs;

export default function Settings() {
  const xJwt = localStorage.getItem("x_jwt");
  const location = useLocation<{ tab: string }>();
  const tab = location?.state?.tab ? location?.state?.tab : "1";

  useEffect(() => {
    if (xJwt === null) {
      window.location.href = "/login";
    }
  }, [xJwt]);

  return (
    <>
      <Box px="4" py="5">
        <DashboardHeader
          title="Settings"
          description="Carefully make important decisions"
          tourSteps={settingsPageSteps}
        />
        <Box className="loan-topper"></Box>
        {/* Settings Tabs Start */}
        <Box className="settings-tabs">
          <Tabs defaultActiveKey={tab}>
            <TabPane
              tab={
                <Text as="span" className="general-tabkey">
                  General
                </Text>
              }
              key="1"
            >
              <Box d="flex" mx="0" className="settings-tabs-pane">
                <GeneralTabPane />
              </Box>
            </TabPane>
            <TabPane
              tab={
                <Text as="span" className="loanProduct-tabkey">
                  Loan Products
                </Text>
              }
              key="2"
            >
              <Box className="settings-tabs-pane">
                <LoanProductTabPane />
              </Box>
            </TabPane>
            <TabPane
              tab={
                <Text as="span" className="loanProduct-tabkey">
                  Fees
                </Text>
              }
              key="3"
            >
              <Box className="settings-tabs-pane">
                <FeesTabPane />
              </Box>
            </TabPane>
            <TabPane
              tab={
                <Text as="span" className="loanOffer-tabkey">
                  Loan Offers
                </Text>
              }
              key="4"
            >
              <Box className="settings-tabs-pane">
                <LoanOfferTabPane />
              </Box>
            </TabPane>
            <TabPane
              tab={
                <Text as="span" className="accountDetails-tabkey">
                  Account Details
                </Text>
              }
              key="5"
            >
              <Box className="settings-tabs-pane">
                <AccountDetails />
              </Box>
            </TabPane>
            <TabPane
              tab={
                <Text as="span" className="teamMember-tabkey">
                  Team Members
                </Text>
              }
              key="6"
            >
              <Box className="settings-tabs-pane">
                <TeamMembersTabPane />
              </Box>
            </TabPane>
            <TabPane tab="Webhooks" key="7">
              <Box className="settings-tabs-pane">
                <WebhooksTabPane />
              </Box>
            </TabPane>
            {/* <TabPane tab="API Credentials" key="6">
              <Box className="settings-tabs-pane">
                <ApiCredentialsTabPane />
              </Box>
            </TabPane>
            <TabPane tab="Decision-Rules" key="7">
              <Box className="settings-tabs-pane">
                <DecisionRulesTabPane />
              </Box>
            </TabPane>
            <TabPane tab="Repayment Method" key="8">
              <Box className="settings-tabs-pane">
                <RepaymentMethodTabPane />
              </Box>
            </TabPane>
            <TabPane tab="Third Party Integrations" key="9">
              <Box className="settings-tabs-pane">
                <ThirdPartyIntegrationTabPane />
              </Box>
            </TabPane> */}
          </Tabs>
        </Box>
        {/* Settings Tabs End */}
      </Box>
    </>
  );
}
