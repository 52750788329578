import React from "react";
import { TopupLoansTable } from "components/tables";
import { LoanTabFilters } from "components/common";

export default function TopupLoansTabPane() {
  return (
    <LoanTabFilters
      loanTab="topup"
      loanStatus="active"
      processStatus="disbursed"
      disbursementStatus="completed"
    >
      <TopupLoansTable />
    </LoanTabFilters>
  );
}
