import { DownloadOutlined } from "@ant-design/icons";
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { Modal, Popconfirm, Tabs } from "antd";
import BorrowerProfile from "components/BorrowerProfile";
import {
  formatNumberAmount,
  generateReceipt,
  isReadOnly,
} from "helpers/utilFunctions";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { disburseLoan } from "redux/actions/loanActions";
import { IGeneratePDFBody, RootState } from "types";
import VeendLogo from "../../assets/veend-logo.png";
import { capitalizeString, DateTimeFormat, statusColor } from "../../constants";
import LoanRepayMentSchedule from "./../LoanRepaymentSchedule";
import { RepaymentModal } from "./index";

import { Loader, LoanProfileStat } from "components/common";
// @ts-ignore
import JSONViewer from "react-json-viewer";

interface ILoanProfileProps {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
}
const { TabPane } = Tabs;

export default function LoanProfile({
  showModal,
  setShowModal,
}: ILoanProfileProps) {
  const loanRecord = useSelector((state: RootState) => state.loanReducer);
  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const userData = useSelector(
    (state: RootState) => state.authReducer.loginDataTwo
  );
  const dispatch = useDispatch();
  const [showRepaymentModal, setShowRepaymentModal] = useState(false);
  const logo = settingsState?.settingData?.data?.logo;
  const businessName =
    settingsState?.settingData?.data?.info?.businessName ||
    userData?.businessName ||
    "";
  const record = loanRecord?.loanRecordData;
  const schedule = loanRecord?.loanSchedule?.schedule;
  const isLoading = loanRecord?.loanProfileLoading;
  const xJwt = localStorage.getItem("x_jwt");
  const readonly = isReadOnly();

  const formattedBalance =
    record?.totalOutstanding !== undefined
      ? formatNumberAmount(record?.totalOutstanding)
      : 0;
  const formattedInterestRate =
    record?.interestRate !== undefined ? record?.interestRate : 0;
  const formattedPrincipalAmount =
    record?.amount !== undefined ? formatNumberAmount(record?.amount) : 0;
  const formattedPayment =
    record?.totalRepayment !== undefined
      ? formatNumberAmount(record?.totalRepayment)
      : 0;
  const borrowerDetails = record?.user;
  const disbursementLog = record?.disbursementLog;

  const handleCancel = () => {
    setShowModal(false);
  };

  let loanDetails: IGeneratePDFBody = {
    logoUrl: logo ? logo : VeendLogo,
    dateCreated: moment(record?.createdAt).format(DateTimeFormat),
    borrowerName: `${borrowerDetails?.firstName} ${borrowerDetails?.lastName}`,
    borrowerPhonenumber: `${borrowerDetails?.phoneNumber}`,
    loanPrincipal: `${formattedPrincipalAmount}`,
    interestRate: `${formatNumberAmount(
      record?.totalExpectedRepayment - record?.amount
    )}`,
    tenure: `${record?.tenure}`,
    fee: `${formatNumberAmount(record?.fee)}`,
    totalExpectedRepayment: `${formatNumberAmount(
      record?.totalExpectedRepayment
    )}`,
    repaymentDate: `${moment(record?.repaymentDate).format(DateTimeFormat)}`,
    topup: `${record?.isTopup === true ? "Yes" : "No"}`,
    channel: `${record?.channel}`,
    loanId: record?.loanId,
    businessName: businessName,
    consent: "Yes",
    loanStatus: record?.status,
  };

  const onDisburse = (user: any) => {
    dispatch(disburseLoan(xJwt, user?.token, user?._id));
  };

  const isApproved =
    record?.status === "approved" &&
    record?.process === "approved" &&
    record?.disbursementStatus === "processing"
      ? true
      : false;
  const isActive =
    record?.status === "active" &&
    record?.process === "disbursed" &&
    record?.disbursementStatus === "completed"
      ? true
      : false;

  return (
    <>
      <Modal
        visible={showModal}
        onCancel={handleCancel}
        footer={null}
        width="95%"
        className="loan-profile-modal"
      >
        {isLoading ? (
          <Loader size="xl" />
        ) : (
          <Tabs defaultActiveKey="1">
            <TabPane tab="Loan Details" key="1">
              <Box className="loan-tab-pane">
                <Heading
                  as="h1"
                  mb="2"
                  fontSize={{ base: "xs", md: "sm", lg: "md" }}
                >
                  {borrowerDetails?.firstName} {borrowerDetails?.lastName} -{" "}
                  {borrowerDetails?.phoneNumber}
                </Heading>
                <Text fontSize="xx-small" mb="1">
                  Date Created -{" "}
                  {moment(record?.createdAt).format(DateTimeFormat)}
                </Text>
                <Text fontSize="xx-small">Loan ID - {record?.loanId}</Text>
                <Box className="fancy-bg">
                  <LoanProfileStat
                    label="Principal Amount"
                    value={formattedPrincipalAmount}
                  />
                  <LoanProfileStat
                    label="Interest Rate"
                    value={formattedInterestRate + "%"}
                  />
                  <LoanProfileStat
                    label="Fee"
                    value={formatNumberAmount(record?.fee)}
                  />
                  <LoanProfileStat
                    label="Total Repayment"
                    value={formattedPayment}
                  />
                  <LoanProfileStat
                    label="Outstanding"
                    value={formattedBalance}
                  />
                </Box>
                <Box mx="auto" className="loan-modal-buttons">
                  <Heading
                    as="h3"
                    fontSize={{ base: "xs", md: "sm" }}
                    textAlign="center"
                    mb="6"
                  >
                    Loan status :{" "}
                    <span style={statusColor(record.status)}>
                      {capitalizeString(record.status)}
                    </span>
                  </Heading>
                  <Box mx="auto" className="modal-buttons-cont">
                    <Popconfirm
                      title={`Disburse NGN${formattedPrincipalAmount} to ${borrowerDetails?.phoneNumber}`}
                      onConfirm={() => onDisburse(record.user)}
                      okText="Confirm"
                      cancelText="Cancel"
                    >
                      <Button disabled={!isApproved || readonly}>
                        Disburse
                      </Button>
                    </Popconfirm>
                    <Button
                      size="sm"
                      variant="success"
                      disabled={!isActive || readonly}
                      onClick={() => setShowRepaymentModal(true)}
                    >
                      Make Repayment
                    </Button>
                    <Button
                      size="sm"
                      variant="secondary"
                      rightIcon={<DownloadOutlined />}
                      onClick={() => generateReceipt(loanDetails)}
                    >
                      Receipt
                    </Button>
                  </Box>
                </Box>
                <LoanRepayMentSchedule record={schedule} />
              </Box>
              <RepaymentModal
                showModal={showRepaymentModal}
                setShowModal={setShowRepaymentModal}
                loanRecord={record}
              />
            </TabPane>

            <TabPane tab="Borrower Profile" key="2">
              <Box className="loan-tab-pane">
                <BorrowerProfile borrower={borrowerDetails} />
              </Box>
            </TabPane>
            <TabPane tab="Disbursement Log" key="3">
              <Box className="loan-tab-pane log-tab-pane">
                {disbursementLog === null || disbursementLog === undefined ? (
                  "No log"
                ) : (
                  <JSONViewer json={disbursementLog} />
                )}
              </Box>
            </TabPane>
          </Tabs>
        )}
      </Modal>
    </>
  );
}
