import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDisbursementTransactions,
  getMoreDisbursementTransactions,
} from "redux/actions/transActions";
import moment from "moment";
import { formatNumberAmount } from "helpers/utilFunctions";
import { Text, Box, Link } from "@chakra-ui/react";
import { popLastId } from "helpers/utilFunctions";
import { DateTimeFormat } from "../../constants";
import { Table } from "antd";
import { Loader } from "components/common";
import { RootState } from "types";

const columns = [
  {
    dataIndex: "transactionAmount",
    key: "transactionAmount",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Amount
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "transactionDate",
    key: "transactionDate",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Date
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "narration",
    key: "narration",
    ellipsis: true,
    render: (text: string) => (
      <Box>
        <Text
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="bold"
          title={text}
        >
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Narration
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "fee",
    key: "fee",
    render: (text: string) => (
      <Box>
        <Text
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="bold"
          title={text}
        >
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Fee
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "transactionReference",
    key: "transactionReference",
    ellipsis: true,
    render: (text: string) => (
      <Box>
        <Text
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="bold"
          title={text}
        >
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Reference
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "accountNumber",
    key: "accountNumber",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Account Number
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "accountName",
    key: "accountName",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Account Name
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "bank",
    key: "bank",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Bank
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "status",
    key: "status",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Status
        </Text>
      </Box>
    ),
  },
];

export default function DisbursementsTransactionsTable() {
  const dispatch = useDispatch();
  const transactionState = useSelector(
    (state: RootState) => state.transactionReducer
  );
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const userSetting = setting?.settingData?.data;
  const accountId = userSetting?.apiCredentials?.id;
  //61512e41ac43b4002907ca32
  const transactions = transactionState?.disbursementTransactionsData?.data;
  const loading = transactionState?.requestLoading;
  const [lastIds, setLastIds] = useState<(string | null)[]>([null]);
  const xJwt = localStorage.getItem("tx_jwt");

  useEffect(() => {
    if (accountId) {
      dispatch(getDisbursementTransactions(xJwt, accountId));
    }
  }, [dispatch, accountId, xJwt]);

  useEffect(() => {
    if (
      transactionState?.disbursementTransactionsData !== undefined &&
      transactionState?.disbursementTransactionsData?.lastId !== undefined
    ) {
      setLastIds([
        ...lastIds,
        transactionState?.disbursementTransactionsData?.lastId,
      ]);
    }
  }, [transactionState?.disbursementTransactionsData]); //eslint-disable-line

  const loadNextPage = () => {
    dispatch(
      getMoreDisbursementTransactions(
        xJwt,
        accountId,
        transactionState?.disbursementTransactionsData?.lastId
      )
    );
  };

  const loadPrevPage = () => {
    if (lastIds.length === 3) {
      dispatch(getDisbursementTransactions(xJwt, accountId));
      setLastIds([null]);
    } else {
      dispatch(
        getMoreDisbursementTransactions(
          xJwt,
          accountId,
          lastIds[lastIds.length - 3]
        )
      );
      popLastId(lastIds, setLastIds);
    }
  };

  const data: any[] | undefined = [];
  if (transactions) {
    const nonPendingTransactions = transactions.filter(
      (transaction: any) => transaction?.frontendStatus !== "pending"
    );
    for (let i = 0; i < nonPendingTransactions.length; i++) {
      const transaction = nonPendingTransactions[i];
      const key = transaction?._id;
      const transactionDate = moment(transaction?.createdAt).format(
        DateTimeFormat
      );
      const narration = transaction?.description;
      const transactionAmount = formatNumberAmount(transaction?.cost);
      const transactionReference = transaction?.purchaseReference;
      const fee = formatNumberAmount(transaction?.customerFee);
      const status = transaction?.frontendStatus;
      const accountNumber =
        transaction?.deliveryLog?.beneficiary?.accountNumber;
      const accountName = transaction?.deliveryLog?.beneficiary?.accountName;
      const bank = transaction?.deliveryLog?.bankName;
      data.push({
        key,
        transactionDate,
        narration,
        transactionAmount,
        fee,
        transactionReference,
        status,
        accountNumber,
        accountName,
        bank,
      });
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 50,
            hideOnSinglePage: true,
            position: ["bottomLeft"],
          }}
        />
      )}
      <Box className="pagination-items">
        {lastIds.length <= 2 ? null : (
          <Link onClick={loadPrevPage} className="pagination-item-link prev">
            Previous page
          </Link>
        )}
        {!transactionState?.disbursementTransactionsData?.isLastPage ? (
          <Link onClick={loadNextPage} className="pagination-item-link next">
            Next page
          </Link>
        ) : null}
      </Box>
    </>
  );
}
