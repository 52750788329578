import React from "react";
import { Result } from "antd";
import { Button } from "@chakra-ui/react";
import "./styles/resultStatus.css";

interface ResultStatusProps {
  status: "success" | "warning" | "error";
  title: string;
  subTitle: string;
  btnText: string;
  btnText2?: string;
  redirect: () => void;
  redirect2?: () => void;
  hasSecondBtn?: boolean;
}

export default function ResultStatus({
  status,
  title,
  subTitle,
  btnText,
  redirect,
  redirect2,
  btnText2,
  hasSecondBtn,
}: ResultStatusProps) {
  return hasSecondBtn ? (
    <Result
      status={status}
      title={title}
      subTitle={subTitle}
      className="result-status"
      extra={[
        <Button key="action" onClick={redirect}>
          {btnText}
        </Button>,
        <Button key="subaction" variant="secondary" onClick={redirect2}>
          {btnText2}
        </Button>,
      ]}
    />
  ) : (
    <Result
      status={status}
      title={title}
      subTitle={subTitle}
      className="result-status"
      extra={[
        <Button key="action" onClick={redirect}>
          {btnText}
        </Button>,
      ]}
    />
  );
}
