import React from "react";
import { Tabs } from "antd";
import {
  PaidLoansTabPane,
  ActiveLoansTabPane,
  PendingDisbursementTabPane,
  PendingApprovalTabPane,
  ProcessingLoansTabPane,
  OverdueLoansTabPane,
  PartiallyPaidLoansTabPane,
  PrimeLoansTabPane
} from "components/tabs";
import TopupLoansTabPane from "./TopupLoansTabPane";

const { TabPane } = Tabs;

export default function LoansTab() {
  return (
    <>
      <Tabs defaultActiveKey="1" className="loans-tables-container">
        <TabPane
          tab={<span className="all-loans-tabkey">All Loans</span>}
          key="1"
        >
          <PendingApprovalTabPane />
        </TabPane>
        <TabPane
          tab={<span className="approved-loans-tabkey">Approved Loans</span>}
          key="2"
        >
          <PendingDisbursementTabPane />
        </TabPane>
        <TabPane
          tab={
            <span className="processing-loans-tabkey">Processing Loans</span>
          }
          key="3"
        >
          <ProcessingLoansTabPane />
        </TabPane>
        <TabPane
          tab={<span className="active-loans-tabkey">Active Loans</span>}
          key="4"
        >
          <ActiveLoansTabPane />
        </TabPane>
        <TabPane
          tab={<span className="topup-loans-tabkey">Topup Loans</span>}
          key="5"
        >
          <TopupLoansTabPane />
        </TabPane>
        <TabPane
          tab={
            <span className="partiallyPaid-loans-tabkey">
              Partially Paid Loans
            </span>
          }
          key="6"
        >
          <PartiallyPaidLoansTabPane />
        </TabPane>
        <TabPane
          tab={<span className="paid-loans-tabkey">Fully Paid Loans</span>}
          key="7"
        >
          <PaidLoansTabPane />
        </TabPane>
        <TabPane
          tab={<span className="overdue-loans-tabkey">Overdue Loans</span>}
          key="8"
        >
          <OverdueLoansTabPane />
        </TabPane>
        <TabPane
          tab={<span className="prime-loans-tabkey">Prime Loans</span>}
          key="9"
        >
          <PrimeLoansTabPane />
        </TabPane>
      </Tabs>
    </>
  );
}
