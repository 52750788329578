import { jsPDF } from "jspdf";
import { IGeneratePDFBody } from "types";
import blueRectangle from "../assets/blue-rectangle.jpg";
import { MontserratBoldFont, MontserratNormal } from "../constants/fonts";
import XLSX from "xlsx";
import { flatten } from "lodash";
import countries from './countries.json'

export function formatStringAmount(amount: string): string {
  const formatter =
    parseFloat(amount)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") || "0.00";
  return formatter;
}

export function formatNumberAmount(amount: number): string {
  const formatter =
    amount?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") || "0.00";
  return formatter;
}

export const capitalizeString = (string: string) => {
  return string && string[0].toUpperCase() + string.slice(1);
};

export const popLastId = (
  lastIds: (string | null)[],
  setLastIds: (value: React.SetStateAction<(string | null)[]>) => void
) => {
  let chain = lastIds;
  if (chain.length > 0) {
    let lastIndex = chain.length - 1;
    let poppedChain = chain.filter((lastId, index) => index !== lastIndex);
    let lastPopIndex = poppedChain.length - 1;
    setLastIds(poppedChain.filter((lastId, index) => index !== lastPopIndex));
  }
};

export const getChartDataset = function (
  data: any,
  dataSetLabel: string,
  fieldName: string
) {
  // data is an array
  let taa = new Array(12).fill(0);
  try {
    let monthdata = function (index: any, _data: any) {
      var _index = _data.data.findIndex((x: any) => {
        return x.month === index * 1 + 1;
      });

      return _data.data[_index];
    };

    let clientsIndex = data?.findIndex((x: any) => {
      return x.label === dataSetLabel;
    });

    let count = taa.length * 1;
    let last = count - 1;

    for (let n in taa) {
      if (typeof taa[n] === "number") {
        taa[n] = monthdata(n, data?.[clientsIndex])?.[fieldName] || 0;
      }

      if (parseInt(n) * 1 === last) {
        return taa;
      }
    }
  } catch (err) {
    // console.error(err);
    return taa;
  }
};

// build pdf
export const generateReceipt = (loanData: IGeneratePDFBody) => {
  let {
    logoUrl,
    borrowerName,
    borrowerPhonenumber,
    loanPrincipal,
    interestRate,
    tenure,
    fee,
    dateCreated,
    totalExpectedRepayment,
    topup,
    channel,
    loanId,
    repaymentDate,
    businessName,
    loanStatus,
    consent,
  } = loanData;
  const receiptDoc = new jsPDF("p", "px", "a4", false);
  receiptDoc.addImage(blueRectangle, "JPEG", 0, 0, 447, 150);
  receiptDoc.addImage(logoUrl, "PNG", 337, 35, 80, 80);
  receiptDoc.addFileToVFS("Montserrat.ttf", MontserratNormal);
  receiptDoc.addFileToVFS("Montserrat-Bold.ttf", MontserratBoldFont);
  receiptDoc.addFont("Montserrat.ttf", "Montserrat", "normal");
  receiptDoc.addFont("Montserrat-Bold.ttf", "Montserrat-Bold", "normal");
  receiptDoc.setFont("Montserrat-Bold");
  receiptDoc.setFontSize(24);
  receiptDoc.setTextColor("#FFFFFF");
  receiptDoc.text(
    `${businessName ? businessName.toUpperCase() : "LENDER NAME"}`,
    30,
    76
  );
  receiptDoc.setFontSize(18);
  receiptDoc.setTextColor("#000000");
  receiptDoc.text("LOAN REPORT", 60, 200);
  receiptDoc.setFontSize(10);
  receiptDoc.text("CUSTOMER NAME:", 60, 300);
  receiptDoc.text("CUSTOMER PHONE NUMBER:", 60, 324);
  receiptDoc.text("LOAN PRINCIPAL:", 60, 348);
  receiptDoc.text("LOAN INTEREST:", 60, 372);
  receiptDoc.text("LOAN TENURE:", 60, 396);
  receiptDoc.text("LOAN FEE:", 60, 420);
  receiptDoc.text("TOTAL EXPECTED REPAYMENT:", 60, 444);
  receiptDoc.text("REPAYMENT DATE:", 60, 468);
  receiptDoc.text("TOPUP:", 60, 492);
  receiptDoc.text("CHANNEL:", 60, 516);
  receiptDoc.text("CUSTOMER CONSENT:", 60, 540);
  receiptDoc.text("LOAN STATUS:", 60, 564);
  receiptDoc.setFont("Montserrat");
  receiptDoc.text("DATE CREATED:", 60, 224);
  receiptDoc.text(dateCreated.toUpperCase(), 128, 224);
  receiptDoc.text("LOAN ID:", 60, 240);
  receiptDoc.text(`${loanId}`, 100, 240);
  receiptDoc.text(borrowerName.toUpperCase(), 260, 300);
  receiptDoc.text(borrowerPhonenumber, 260, 324);
  receiptDoc.text(loanPrincipal, 260, 348);
  receiptDoc.text(interestRate, 260, 372);
  receiptDoc.text(tenure, 260, 396);
  receiptDoc.text(fee, 260, 420);
  receiptDoc.text(totalExpectedRepayment, 260, 444);
  receiptDoc.text(repaymentDate.toUpperCase(), 260, 468);
  receiptDoc.text(topup.toUpperCase(), 260, 492);
  receiptDoc.text(channel.toUpperCase(), 260, 516);
  receiptDoc.text(consent.toUpperCase(), 260, 540);
  receiptDoc.text(loanStatus.toUpperCase(), 260, 564);
  receiptDoc.setFontSize(7);
  receiptDoc.text("Powered by VEENDHQ", 186, 618);
  receiptDoc.save(`VeendLoanReceipt_${loanId}_${borrowerPhonenumber}`);
};

// handle csv upload and convert to array of strings
export const convertCsvFile = (file: File, setState: React.Dispatch<any[]>) => {
  /* Boilerplate to set up FileReader */
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = e => {
    /* Parse data */
    const binaryString = e?.target?.result;
    const workBook = XLSX.read(binaryString, {
      type: rABS ? "binary" : "array",
    });
    /* Get first worksheet */
    const worksheetName = workBook.SheetNames[0];
    const worksheet = workBook.Sheets[worksheetName];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    const flattenArr = flatten(data);
    /* remove heading */
    flattenArr.shift();
    /* return array of strings */
    return setState(flattenArr);
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
};

// former upload
// const uploadDriverLicence = (event: any) => {
//   return new Promise((resolve, reject) => {
//     const formDataString = new FormData();
//     formDataString.append("file", event.target.files[0]);
//     formDataString.append("fileName", event.target.files[0].name);
//     setLicenceImage(URL.createObjectURL(event.target.files[0]));
//     axios
//       .post(
//         `https://file.workova.co/upload?x-tag=${uploadxTag}`,
//         formDataString,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `Bearer ${bearer}`,
//           },
//         }
//       )
//       .then(response => {
//         driverLicenceUrl = response?.data?.data;
//         resolve(response.data);
//       })
//       .catch(error => {
//         reject(error);
//       });
//   });
// };

export const goToLoginPage = () => {
  window.location.href = "/login";
};

export const goToHome = () => {
  window.location.href = "https://veendhq.com";
};

// check if x-readonly is true from local storage
export const isReadOnly = () => {
  const readonly = localStorage.getItem("readonly");
  if (readonly && readonly !== "undefined") {
    const access = JSON.parse(readonly) === true ? true : false;
    return access;
  } else {
    return false;
  }
};

export const filterUsernameOut = (text: string, username: string) => {
  return text?.replace("undefined:", "").replace(`${username}:`, "");
};

export const capitalizeFirstLetter = (word: string) => {
  if (!word) return "";

  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getCountries = countries.map(country => ({
  label: country.name,
  value: country.code,
}));

const globalIndustries = [
  "Advertising and marketing",
  "Aerospace and defense",
  "Agriculture",
  "Alternative energy sources",
  "Apparel and Clothing",
  "Aquaculture and fishery",
  "Architecture",
  "Asset management and investment firms",
  "Automotive",
  "Aviation",
  "Banking and Finance",
  "Beauty and personal care",
  "Biomedical engineering and medical devices",
  "Building and construction materials",
  "Business incubators and accelerators",
  "Business process outsourcing (BPO)",
  "Chemicals and plastics",
  "Education",
  "Cloud computing and storage",
  "Commercial printing and packaging",
  "Computer hardware and peripherals",
  "Consulting and professional services",
  "Consumer goods and services",
  "Chemicals and allied products",
  "Cybersecurity and data privacy",
  "Defence and military equipment",
  "Digital marketing and advertising",
  "Distribution and logistics",
  "E-commerce",
  "Electric power generation",
  "Electrical equipment and supplies",
  "Electronic components and accessories",
  "Electronics manufacturing",
  "Employment agencies and staffing services",
  "Energy conservation and management",
  "Energy storage systems",
  "Environmental management and consulting",
  "Event planning and management",
  "Fashion and design",
  "Financial planning and investment",
  "Fintech",
  "Fintech (Bank)",
  "Fintech (Lending)",
  "Fish and seafood processing",
  "Fitness and wellness",
  "Food and beverage",
  "Freight forwarding and customs brokerage",
  "Furniture manufacturing",
  "Gaming",
  "Gas processing and distribution",
  "Green technology and sustainability",
  "Government and public administration",
  "Healthcare",
  "Hospitality",
  "Human resources and recruitment",
  "Import and export",
  "Information and communication technology (ICT) services",
  "Investment banking and securities",
  "Janitorial and cleaning services",
  "Laboratory equipment and supplies",
  "Legal services",
  "Logistics and supply chain management",
  "Management consulting and advisory services",
  "Manufacturing",
  "Market research and analytics",
  "Marine and offshore services",
  "Media and entertainment",
  "Medical equipment and supplies",
  "Medical tourism and healthcare services",
  "Nonprofit",
  "Oil and Gas",
  "Packaging and labelling",
  "Petrochemicals",
  "Pharmaceuticals",
  "Real estate",
  "Renewable energy",
  "Restaurant and food services",
  "Retail",
  "Risk management and insurance",
  "Security services",
  "Software development and programming",
  "Sporting goods and fitness equipment",
  "Sports and recreation",
  "Steel and metal production",
  "Talent management and human capital",
  "Tax consulting and accounting",
  "Telecommunications",
  "Textile manufacturing and dyeing",
  "Textiles and clothing",
  "Trade shows and exhibitions",
  "Transportation",
  "Travel agencies and tour operators",
  "Waste management and recycling",
  "Water supply and treatment",
];

export const Industries = globalIndustries.map(item => ({
  label: item,
  value: item.trim(),
}));

export const userAccess = () => {
  const readonly = localStorage.getItem("readonly");
  if(readonly){
    if(readonly === "undefined"){
      return "full"
    }
    else{
      const access = JSON.parse(readonly) === true ? "partial" : "full";
      return access
    }
  }
};