import React from "react";
import moment from "moment";
import { Form, DatePicker } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import "./styles/InputFieldStyles.css";
import { Box, Text } from "@chakra-ui/layout";
import { Input } from "@chakra-ui/react";

const FormItem = Form.Item;

interface IToDateInput {
  form: any;
  style: any;
  error: any;
  label: string;
  hasLabel: boolean;
}

const ToDateInput = ({
  error,
  form: {
    setFieldValue,
    values: { toDate },
  },
  label,
  hasLabel,
}: IToDateInput) => {
  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  const disabledDate = (current: any) => {
    return current > moment().add(1, "days");
  };

  return (
    <Box>
      <Box as={FormItem} my="3" className={wrapperClass}>
        {hasLabel && (
          <Box d="flex" mb="1" className="label-div">
            <Text className="form-label">{label}</Text>
          </Box>
        )}
        <Box my="2">
          <Input
            onChange={e => setFieldValue("toDate", e.target.value)}
            value={toDate || null}
            className="date-picker"
            disabledDate={disabledDate}
            clearIcon={false}
            type="date"
          />
        </Box>
        {error && (
          <Box className="error-div">
            <ExclamationCircleFilled className="error-icon" />
            <Text ml="2" className="error-text">
              {error}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default ToDateInput;
