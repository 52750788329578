import { message } from "antd";
import axios from "axios";
import {
  BORROWERS_DETAILS_ACTION_FAILURE,
  BORROWERS_DETAILS_ACTION_SUCCESS,
  BORROWERS_TABLE_ACTION_FAILURE,
  BORROWERS_TABLE_ACTION_SUCCESS,
  BORROWER_PROFILE_LOADING_TRUE,
  BORROWER_REQUEST_LOADING_FALSE,
  BORROWER_REQUEST_LOADING_TRUE,
  CREATE_BORROWER_ACTION_FAILURE,
  CREATE_BORROWER_ACTION_SUCCESS,
  DOWNLOAD_BORROWERS_ACTION_FAILURE,
  DOWNLOAD_BORROWERS_ACTION_SUCCESS,
  ERROR_401_ACTION,
  PREAPPROVE_BORROWER_ACTION_FAILURE,
  PREAPPROVE_BORROWER_ACTION_SUCCESS,
} from "./actionTypes";

const baseUrl = process.env.REACT_APP_BASE_URL;

// get borrowers deatils
export const getBorrowersDetails =
  (id: any, xTag: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      dispatch({
        type: BORROWER_PROFILE_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/users?_id=${id}&populate=loans eligibilitys&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: BORROWERS_DETAILS_ACTION_SUCCESS,
        payload: res?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: BORROWERS_DETAILS_ACTION_FAILURE,
          });
          const errorMessage =
            error?.response?.data?.data?.message ||
            error?.response?.data?.message;
          message.error(errorMessage);
        }
      }
      return error;
    }
  };

// Borrowers Table
export const getBorrowersTable =
  (xTag: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      dispatch({
        type: BORROWER_REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/users?select=preApprovedAmount,employer,bankCode,accountNumber,createdAt,email,lastName,firstName,phoneNumber,consent,loans&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: BORROWERS_TABLE_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: DOWNLOAD_BORROWERS_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: BORROWERS_TABLE_ACTION_FAILURE,
          });
          const errorMessage =
            error?.response?.data?.data?.message ||
            error?.response?.data?.message;
          message.error(errorMessage);
        }
      }
      return error;
    }
  };

// Borrowers Table
export const getNextBorrowersPage =
  (xTag: any, lastId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      dispatch({
        type: BORROWER_REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/users?lastId=${lastId}&select=preApprovedAmount,employer,bankCode,accountNumber,createdAt,email,lastName,firstName,phoneNumber,consent,loans&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: BORROWERS_TABLE_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: DOWNLOAD_BORROWERS_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: BORROWERS_TABLE_ACTION_FAILURE,
          });
          const errorMessage =
            error?.response?.data?.data?.message ||
            error?.response?.data?.message;
          message.error(errorMessage);
        }
      }
      return error;
    }
  };

// create borrower
export const createBorrower =
  (borrower: any, xTag: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      const res = await axios.post(
        `${baseUrl}/borrowers?x-tag=${xTag}`,
        JSON.stringify(borrower)
      );
      dispatch({
        type: CREATE_BORROWER_ACTION_SUCCESS,
        payload: res?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: CREATE_BORROWER_ACTION_FAILURE,
          });
          const errorMessage =
            error?.response?.data?.data?.message ||
            error?.response?.data?.message;
          message.error(errorMessage);
        }
      }
      return error;
    }
  };

// preApprove borrower
export const preApproveBorrower =
  (
    xJwt: string | null,
    userId: string,
    borrowerToken: string,
    preApproveBody: any
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${borrowerToken}`,
        "x-jwt": xJwt,
      },
    };
    dispatch({
      type: BORROWER_REQUEST_LOADING_TRUE,
    });
    try {
      const res = await axios.patch(
        `${baseUrl}/v1/users/${userId}?x-tag=1`,
        preApproveBody,
        config
      );
      dispatch({
        type: PREAPPROVE_BORROWER_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
      message.success("Borrower pre-approved");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: PREAPPROVE_BORROWER_ACTION_FAILURE,
          });
          const errorMessage =
            error?.response?.data?.data?.message ||
            error?.response?.data?.message;
          message.error(errorMessage);
        }
      }
      return error;
    }
  };

// download borrowers list
export const downloadBorrower =
  (xJwt: string | null) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    dispatch({
      type: BORROWER_REQUEST_LOADING_TRUE,
    });
    try {
      const res = await axios.get(
        `${baseUrl}/users?select=preApprovedAmount,employer,bankCode,accountNumber,createdAt,email,lastName,firstName,phoneNumber,consent,loans&limit=1000&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: DOWNLOAD_BORROWERS_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: DOWNLOAD_BORROWERS_ACTION_FAILURE,
          });
          const errorMessage =
            error?.response?.data?.data?.message ||
            error?.response?.data?.message;
          message.error(errorMessage);
        }
      }
      return error;
    }
  };

// search borrowers
export const searchBorrowers =
  (xJwt: string | null, searchField: string, searchQuery: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    dispatch({
      type: BORROWER_REQUEST_LOADING_TRUE,
    });
    try {
      const res = await axios.get(
        `${baseUrl}/users?${searchField}=${searchQuery}&select=preApprovedAmount,employer,bankCode,accountNumber,createdAt,email,lastName,firstName,phoneNumber,consent,loans&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: BORROWERS_TABLE_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: DOWNLOAD_BORROWERS_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
      if (res.data?.data.length === 0) {
        return message.error("no record found");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: BORROWER_REQUEST_LOADING_FALSE,
          });
          const errorMessage =
            error?.response?.data?.data?.message ||
            error?.response?.data?.message;
          message.error(errorMessage);
        }
      }
      return error;
    }
  };

// filter borrowers
export const filterBorrowersByDaterange =
  (xTag: any, fromDate: any, toDate: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      dispatch({
        type: BORROWER_REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/users?from=${fromDate}&to=${toDate}&select=preApprovedAmount,employer,bankCode,accountNumber,createdAt,email,lastName,firstName,phoneNumber,consent,loans&desc=true&sort=-_id&limit=500`,
        config
      );
      dispatch({
        type: BORROWERS_TABLE_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: DOWNLOAD_BORROWERS_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: BORROWERS_TABLE_ACTION_FAILURE,
          });
          const errorMessage =
            error?.response?.data?.data?.message ||
            error?.response?.data?.message;
          message.error(errorMessage);
        }
      }
      return error;
    }
  };
