import {
  CAMPAIGNS_LOADING_TRUE,
  CAMPAIGNS_LOADING_FALSE,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGNS_FAILED,
  CAMPAIGNS_REQUEST_LOADING_TRUE,
  CAMPAIGNS_REQUEST_LOADING_FALSE,
  OPEN_ADD_MANUALLY_MODAL,
  CLOSE_ADD_MANUALLY_MODAL,
  OPEN_UPLOAD_CONTACTS_MODAL,
  CLOSE_UPLOAD_CONTACTS_MODAL,
  OPEN_STAGED_PHONE_NUMBERS_MODAL,
  CLOSE_STAGED_PHONE_NUMBERS_MODAL,
  UPLOAD_PHONE_NUMBERS_SUCCESS,
  GET_CAMPAIGNS_SUMMARY_SUCCESS,
  GET_CAMPAIGN_TO_EDIT_SUCCESS,
  RESET_CAMPAIGN_TO_EDIT,
  GET_UPLOADED_CONTACTS_SUCCESS,
  GET_AMOUNT_SPENT_ON_CAMPAIGNS_SUCCESS,
  GET_AMOUNT_SPENT_ON_CAMPAIGNS_LOADING_TRUE,
  GET_AMOUNT_SPENT_ON_CAMPAIGNS_LOADING_FALSE,
} from "../actions/actionTypes";

export type CampaignsState = {
  requestLoading: boolean;
  isLoading: boolean;
  showAddRecipientsManualModal: boolean;
  showUploadContactsModal: boolean;
  showStagedPhoneNumbersModal: boolean;
  campaigns: any[];
  campaignToEdit: any;
  stagedNumbers: any[];
  campaignsSummary: ICampaignsSummary;
  amountSpentOnCampaign: number;
  isAmountSpentLoading: boolean;
  uploadedContacts: IUploadedContacts[];
};

interface ICampaignsSummary {
  month: number;
  total: number;
  uploads: number;
}

export interface IUploadedContacts {
  _id: string;
  number: string;
}

const initialState: CampaignsState = {
  requestLoading: false,
  isLoading: false,
  showAddRecipientsManualModal: false,
  showUploadContactsModal: false,
  showStagedPhoneNumbersModal: false,
  campaigns: [],
  campaignToEdit: null,
  stagedNumbers: [],
  campaignsSummary: { month: 0, total: 0, uploads: 0 },
  isAmountSpentLoading: false,
  amountSpentOnCampaign: 0,
  uploadedContacts: [],
};

function smsAutomationReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  const { type, payload } = action;

  switch (type) {
    case CAMPAIGNS_LOADING_TRUE:
      return {
        ...state,
        isLoading: true,
      };

    case CAMPAIGNS_LOADING_FALSE:
      return {
        ...state,
        isLoading: false,
      };

    case CAMPAIGNS_REQUEST_LOADING_TRUE:
      return {
        ...state,
        requestLoading: true,
      };

    case CAMPAIGNS_REQUEST_LOADING_FALSE:
      return {
        ...state,
        requestLoading: false,
      };

    case GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: payload,
        isLoading: false,
      };
    case GET_CAMPAIGN_TO_EDIT_SUCCESS:
      return {
        ...state,
        campaignToEdit: payload,
        isLoading: false,
      };
    case RESET_CAMPAIGN_TO_EDIT:
      return {
        ...state,
        campaignToEdit: null,
      };
    case GET_CAMPAIGNS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case OPEN_UPLOAD_CONTACTS_MODAL:
      return {
        ...state,
        showUploadContactsModal: true,
      };
    case CLOSE_UPLOAD_CONTACTS_MODAL:
      return {
        ...state,
        showUploadContactsModal: false,
      };
    case OPEN_ADD_MANUALLY_MODAL:
      return {
        ...state,
        showAddRecipientsManualModal: true,
      };
    case CLOSE_ADD_MANUALLY_MODAL:
      return {
        ...state,
        showAddRecipientsManualModal: false,
      };
    case OPEN_STAGED_PHONE_NUMBERS_MODAL:
      return {
        ...state,
        showUploadContactsModal: false,
        showStagedPhoneNumbersModal: true,
        stagedNumbers: payload,
      };
    case CLOSE_STAGED_PHONE_NUMBERS_MODAL:
      return {
        ...state,
        showStagedPhoneNumbersModal: false,
        stagedNumbers: [],
      };
    case UPLOAD_PHONE_NUMBERS_SUCCESS:
      return {
        ...state,
        showAddRecipientsManualModal: false,
        showUploadContactsModal: false,
        showStagedPhoneNumbersModal: false,
        stagedNumbers: [],
      };

    case GET_CAMPAIGNS_SUMMARY_SUCCESS:
      return {
        ...state,
        campaignsSummary: payload,
        requestLoading: false,
      };

    case GET_AMOUNT_SPENT_ON_CAMPAIGNS_LOADING_TRUE:
      return {
        ...state,
        isAmountSpentLoading: true,
      };

    case GET_AMOUNT_SPENT_ON_CAMPAIGNS_LOADING_FALSE:
      return {
        ...state,
        isAmountSpentLoading: false,
      };

    case GET_AMOUNT_SPENT_ON_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        amountSpentOnCampaign: payload,
        isAmountSpentLoading: false,
      };

    case GET_UPLOADED_CONTACTS_SUCCESS:
      return {
        ...state,
        uploadedContacts: payload,
        requestLoading: false,
        isLoading: false,
      };

    default:
      return state;
  }
}

export default smsAutomationReducer;
