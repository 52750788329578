import React, { FC, useState, useEffect } from "react";
import ReactOTP from "react-otp-input";
import classes from "./styles/otp.module.css";

//
type OtpProps = {
  numInputs?: number;
  onComplete?: (otp: string) => void;
  onChange?: (pin: string) => void;
  error?: boolean;
  disable?: boolean;
  isInputSecure?: boolean;
};

//
const InputOTP: FC<OtpProps> = props => {
  const { onChange, numInputs, onComplete, error, disable, isInputSecure } =
    props;
  const [otp, setOtp] = useState("");
  useEffect(() => {
    if (otp.length === numInputs) {
      onComplete && onComplete(otp);
    }
  }, [otp, numInputs]);
  const handleChange = (otp: string) => {
    onChange && onChange(otp);
    setOtp(otp);
  };
  return (
    <section>
      <ReactOTP
        value={otp}
        onChange={handleChange}
        numInputs={numInputs}
        inputStyle={classes.otp__input}
        containerStyle={classes.otp}
        focusStyle={classes.otp__input_focused}
        isDisabled={disable}
        isInputSecure={isInputSecure}
        hasErrored={error}
        isInputNum
      />
    </section>
  );
};
export default InputOTP;
