import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { Stat } from "types";

interface PortfolioStatProps extends Stat {
  legendColor?: string;
}

const PortfolioStat = ({ label, value, legendColor }: PortfolioStatProps) => {
  return (
    <Box className="portfolio-table-item">
      {legendColor ? <Box className="chart-legend" bg={legendColor} /> : null}
      <Text fontWeight="medium" fontSize={{ base: "xs", md: "sm" }}>
        {label}
      </Text>
      <Text ml="auto" fontWeight="bold" fontSize={{ base: "xs", md: "sm" }}>
        ₦{value}
      </Text>
    </Box>
  );
};

export default PortfolioStat;
