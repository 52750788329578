import React, { useState } from "react";
import { DisbursementsTransactionsTable } from "./../tables";
import { DownOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import {
  TransactionsDateRangeWrapper,
  DownloadTransactions,
} from "./../common";
import { Box, Link } from "@chakra-ui/layout";
import { RootState } from "types";

export default function DisbursementTransactionsTabPane() {
  const settingState = useSelector((state: RootState) => state.settingsReducer);
  const userSetting = settingState?.settingData?.data;
  const accountId = userSetting?.apiCredentials?.id;
  const [showDateRange, setShowDateRange] = useState(false);
  const toggleDateRange = () => {
    showDateRange ? setShowDateRange(false) : setShowDateRange(true);
  };
  return (
    <Box>
      <Box className="transactions-table-header">
        <Box className="table-btns">
          <DownOutlined
            className={
              showDateRange
                ? "date-range-toggle-arrow arrow-up"
                : "date-range-toggle-arrow"
            }
          />
          <Link onClick={toggleDateRange} className="date-range-toggle">
            set date range
          </Link>
        </Box>
        <DownloadTransactions tab="disbursements" />
      </Box>
      {showDateRange ? (
        <TransactionsDateRangeWrapper
          accountId={accountId}
          tab="disbursements"
        />
      ) : null}
      <DisbursementsTransactionsTable />
    </Box>
  );
}
