import React, { Dispatch, SetStateAction, useEffect } from "react";
import * as fileStack from "filestack-react";
import { fileStackKey } from "../../constants/index";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onUploadComplete: (res: any) => void;
  accept?: string[];
  maxFile?: number;
};

const FileStack = (props: Props) => {
  const client = React.useMemo(() => fileStack.client, []);

  const fileStackPickerOptions = {
    fromSources: ["local_file_system", "googledrive", "googlephotos"],
    accept: props.accept || ["image/*", ".pdf"],
    maxFiles: props.maxFile || 1,
  };

  const fileStackss = client.init(fileStackKey, {
    ...fileStackPickerOptions,
  });

  const pageImagePicker = fileStackss.picker({
    ...fileStackPickerOptions,
    onUploadDone: (res: any) => {
      props.onUploadComplete && props.onUploadComplete(res);
      props.setOpen(false);
    },
    onCancel: () => {
      props.setOpen(false);
    },
    onClose: () => {
      props.setOpen(false);
    },
  });

  useEffect(() => {
    if (props.open) {
      pageImagePicker.open();
    }
  }, [props.open]);

  return <div></div>;
};

export default FileStack;
