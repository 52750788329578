import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Image,
  Heading,
  Text,
  CircularProgress,
  Center,
} from "@chakra-ui/react";
import React from "react";
import logo from "assets/veend-logo.png";
import CloudUploadImg from "assets/cil_cloud-upload.png";
import "components/common/styles/customFileInput.css";
import { RootState } from "types";
import { useDispatch, useSelector } from "react-redux";
import FileStack from "components/common/FileStack";
import { saveLendingLicense } from "helpers/api";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { showLendingLicenseModal } from "redux/actions/lendingLicenseActions";

type Props = {
  state: boolean;
};

const LendingLicenseUpload = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const { settingData, isLoading } = setting;
  const dispatch = useDispatch();

  const onUploadComplete = async (upload: any) => {
    setLoading(true);
    const { data, error } = await saveLendingLicense(
      upload.filesUploaded[0].url
    );
    setLoading(false);
    if (data && data.status === "success") {
      return window.location.reload();
    }
    message.error(
      error.response?.message ||
        error.response?.data?.message ||
        "An error occured"
    );
  };

  return (
    <Modal
      isOpen={props.state}
      onClose={() => undefined}
      isCentered
      size={"xl"}
    >
      <ModalOverlay />
      <ModalContent>
        {/* <ModalCloseButton /> */}
        <Box
          px={5}
          py={2}
          textAlign="end"
          cursor={"pointer"}
          onClick={() => {
            sessionStorage.removeItem("license-reminder");
            dispatch(showLendingLicenseModal(false));
          }}
        >
          Skip
        </Box>
        <ModalBody>
          <Box py={10} flex={1} maxW="container.sm" mx="auto">
            <Box
              display={"flex"}
              flexDir="column"
              alignItems={"center"}
              justifyContent="center"
              mb={"3rem"}
            >
              <Image
                src={logo}
                alt="veendHq"
                className="login-logo-main"
                width={200}
              />
              <Heading
                fontWeight={500}
                color="#161635"
                fontSize={"16px"}
                mt="16px"
              >
                Operational license
              </Heading>
            </Box>
            <FileStack
              open={open}
              onUploadComplete={onUploadComplete}
              setOpen={setOpen}
            />
            {isLoading || loading ? (
              <Center>
                <CircularProgress
                  color="#060089"
                  boxSize={"16px"}
                  isIndeterminate
                />
              </Center>
            ) : (
              <Box>
                <Box>Copy of operating licence</Box>
                <Box
                  className="custom-upload-input"
                  onClick={() => setOpen(true)}
                  cursor="pointer"
                >
                  <Box className="custom-upload-over-lay">
                    <Image src={CloudUploadImg} />
                    <Text fontSize="md" fontWeight="500">
                      Upload document here or{" "}
                      <Text as="span" color="#130AD8">
                        Browse
                      </Text>
                    </Text>
                    <Text fontSize="12px">
                      Supported file types: JPG, PNG, PDF. Max file size: 2MB
                    </Text>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LendingLicenseUpload;
