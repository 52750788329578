//@ts-nocheck
import React, { useState, useEffect } from "react";
import {
  Heading,
  Box,
  Link,
  Image,
  ListItem,
  List,
  ListIcon,
  Button,
  Container,
  Avatar,
} from "@chakra-ui/react";
import veendLogo from "../../assets/veend-logo.png";
import "./styles/auth.css";
import { useDispatch } from "react-redux";
import { saveDojahDetails } from "redux/actions/authActions";
import { ChevronDownIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import "./styles/getStarted.css";
import { Dropdown, Menu, MenuProps, message } from "antd";
import { useHistory } from "react-router-dom";
import Dojah from "react-dojah";
import KycModal from "components/modals/KycModal";
import { getUserSettings } from "redux/actions/settingsActions";

const requirements = [
  "Director’s Personal Information",
  "Director’s BVN",
  "Selfie of Director",
  "Copy of ID (Director)",
  "Business Information",
  "Copy of CAC certificate",
];

export default function GetStarted(): JSX.Element {
  const appID = process.env.REACT_APP_DOJAH_ID;
  const publicKey = process.env.REACT_APP_DOJAH_KEY;
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

 

  const menu = (
    <Menu
      onClick={info => {
        onMenuClick();
      }}
      style={{ width: "200px", maxWidth: "80vw" }}
    >
      <Menu.Item key="logout">Logout</Menu.Item>
    </Menu>
  );

  const onMenuClick = () => {
    localStorage.clear();
    history.replace("/login");
  };

  useEffect(() => {
    if (!localStorage.getItem("x_jwt") || !localStorage.getItem("tx_jwt")) {
      history.replace("/login");
    }
  }, []);

  useEffect(() => {
    dispatch(getUserSettings(localStorage.getItem("x_jwt")));
  }, []);

  return (
    <Box>
      <Container
        py={8}
        minH="100vh"
        display={{base:"flex", md: "none"}}
        flexDir="column"
        mx="auto"
        maxW="container.xl"
      >
        <Box
          display={"flex"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Link href="https://veendhq.com" target="_blank" rel="noreferrer">
            <Image
              src={veendLogo}
              alt="veendHq"
              className="login-logo-main"
              width={150}
            />
          </Link>
        </Box>
      </Container>
      <KycModal />
    </Box>
  );
}
