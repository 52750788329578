import { TransactionsResponse } from "types/responses";
import {
  TRANSACTIONS_TABLE_ACTION_SUCCESS,
  TRANSACTIONS_TABLE_ACTION_FAILURE,
  TRANSACTIONS_REQUEST_LOADING_TRUE,
  TRANSACTIONS_REQUEST_LOADING_FALSE,
  GET_DISBURSEMENTS_TRANSACTIONS_SUCCESS,
  GET_DISBURSEMENTS_TRANSACTIONS_FAILURE,
  GET_SMS_TRANSACTIONS_SUCCESS,
  GET_SMS_TRANSACTIONS_FAILURE,
  GET_USSD_TRANSACTIONS_SUCCESS,
  GET_USSD_TRANSACTIONS_FAILURE,
  GET_REMITA_TRANSACTIONS_SUCCESS,
  GET_REMITA_TRANSACTIONS_FAILURE,
  GET_WITHDRAWAL_TRANSACTIONS_SUCCESS,
  GET_WITHDRAWAL_TRANSACTIONS_FAILURE,
  UPDATE_TRANSACTIONS_DOWNLOAD_LIST,
  UPDATE_DISBURSEMENTS_DOWNLOAD_LIST,
  UPDATE_REMITA_DOWNLOAD_LIST,
  UPDATE_USSD_DOWNLOAD_LIST,
  UPDATE_SMS_DOWNLOAD_LIST,
  UPDATE_WITHDRAWALS_DOWNLOAD_LIST,
} from "../actions/actionTypes";

type TransactionStateKey =
  | "allTransactionsData"
  | "disbursementTransactionsData"
  | "smsTransactionsData"
  | "ussdTransactionsData"
  | "remitaTransactionsData"
  | "withdrawalTransactionsData";

type TransactionsDownloadKey =
  | "downloadData"
  | "disbursementsDownloadData"
  | "remitaDownloadData"
  | "ussdDownloadData"
  | "smsDownloadData"
  | "withdrawalsDownloadData";

export type TransactionState = {
  isLoading: boolean;
  requestLoading: boolean;
} & {
  [key in TransactionStateKey]: TransactionsResponse;
} &
  {
    [key in TransactionsDownloadKey]: TransactionsResponse["data"];
  };

const initialState: TransactionState = {
  isLoading: true,
  allTransactionsData: {} as TransactionsResponse,
  disbursementTransactionsData: {} as TransactionsResponse,
  smsTransactionsData: {} as TransactionsResponse,
  ussdTransactionsData: {} as TransactionsResponse,
  remitaTransactionsData: {} as TransactionsResponse,
  withdrawalTransactionsData: {} as TransactionsResponse,
  downloadData: [],
  disbursementsDownloadData: [],
  remitaDownloadData: [],
  ussdDownloadData: [],
  smsDownloadData: [],
  withdrawalsDownloadData: [],
  requestLoading: false,
};

function transactionReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case TRANSACTIONS_TABLE_ACTION_SUCCESS:
      return {
        ...state,
        allTransactionsData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case TRANSACTIONS_TABLE_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestLoading: false,
      };
    case GET_DISBURSEMENTS_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        disbursementTransactionsData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case GET_DISBURSEMENTS_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestLoading: false,
      };
    case GET_SMS_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        smsTransactionsData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case GET_SMS_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestLoading: false,
      };
    case GET_USSD_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        ussdTransactionsData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case GET_USSD_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestLoading: false,
      };
    case GET_REMITA_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        remitaTransactionsData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case GET_REMITA_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestLoading: false,
      };
    case GET_WITHDRAWAL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        withdrawalTransactionsData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case GET_WITHDRAWAL_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestLoading: false,
      };
    case TRANSACTIONS_REQUEST_LOADING_TRUE:
      return {
        ...state,
        isLoading: false,
        requestLoading: true,
      };
    case TRANSACTIONS_REQUEST_LOADING_FALSE:
      return {
        ...state,
        isLoading: false,
        requestLoading: false,
      };
    case UPDATE_TRANSACTIONS_DOWNLOAD_LIST:
      return {
        ...state,
        downloadData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case UPDATE_DISBURSEMENTS_DOWNLOAD_LIST:
      return {
        ...state,
        disbursementsDownloadData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case UPDATE_REMITA_DOWNLOAD_LIST:
      return {
        ...state,
        remitaDownloadData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case UPDATE_USSD_DOWNLOAD_LIST:
      return {
        ...state,
        ussdDownloadData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case UPDATE_SMS_DOWNLOAD_LIST:
      return {
        ...state,
        smsDownloadData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case UPDATE_WITHDRAWALS_DOWNLOAD_LIST:
      return {
        ...state,
        withdrawalsDownloadData: payload,
        isLoading: false,
        requestLoading: false,
      };
    default:
      return state;
  }
}

export default transactionReducer;
