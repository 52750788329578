import React, { FC, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyEmail } from "redux/actions/authActions";
import { RootState } from "types";
import InputOTP from "components/common/InputOTP";
import { Heading, Box, Link, Image, Button, Container } from "@chakra-ui/react";
import veendLogo from "../../assets/veend-logo.png";
import checkedLogo from "../../assets/checked.png";
import { Text } from "@chakra-ui/react";
import CustomCard from "components/common/CustomCard";
import { sendEmailOtp } from "helpers/api";
import { message } from "antd";
import { CountDownUtils } from "helpers/timerUtils";

//
const VerifySuccess = () => {
  const history = useHistory();
  const location = useLocation();

  const handleProceed = () => {
    let state: { onboarding: boolean } = location.state as {
      onboarding: boolean;
    };
    if (state?.onboarding) {
      return history.replace(`/login?onboarding=true`);
    }
    return history.replace("/login");
  };

  return (
    <Box
      className="veend-container"
      py={8}
      minH="100vh"
      display="flex"
      flexDir="column"
      justifyContent="center"
    >
      <Box display={"flex"} justifyContent="center">
        <Link href="https://veendhq.com" target="_blank" rel="noreferrer">
          <Image
            src={veendLogo}
            alt="veendHq"
            className="login-logo-main"
            width={200}
          />
        </Link>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        marginTop={5}
      >
        <CustomCard>
          <>
            <Box
              mb="8"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Image
                src={checkedLogo}
                alt="veendHq"
                className="login-logo-main"
                width={91}
              />
              <Heading
                as="h1"
                mt="30px"
                mb="2"
                fontSize={{ base: "md", md: "md", lg: "lg" }}
                textAlign="center"
              >
                Email Verification <br></br> Successful
              </Heading>
            </Box>
            <Box mt={5} display="flex" mb={2}>
              <Button w="100%" onClick={handleProceed} size="lg">
                Login
              </Button>
            </Box>
          </>
        </CustomCard>
      </Box>
    </Box>
  );
};

//
const VerifyEmailOTP: FC = () => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const verifyEmailState = useSelector(
    (state: RootState) => state?.authReducer
  );
  const verifyMailStateSuccess = verifyEmailState?.verifyEmailSuccess;
  // const verifyMailStateFailed = verifyEmailState?.verifyEmailFailed;
  const { start, count, isBusy } = CountDownUtils({});

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  const handleVerifyEmail = async () => {
    if (code.length !== 6) return null;
    let data = {
      email: query.get("email")?.toLowerCase(),
      validationCode: code,
    };
    dispatch(verifyEmail(data));
  };

  const ResendOtp = async () => {
    if (isBusy || loading) return null;
    setLoading(true);
    const { data, error } = await sendEmailOtp(
      query.get("email")?.toLowerCase() as string
    );
    setLoading(false);
    if (error) {
      return message.error(error?.data?.message);
    }
    start();
    return (
      !!data &&
      message.success(
        `OTP sent successfully to ${query.get("email".toLowerCase())}`
      )
    );
  };

  return (
    <>
      {verifyMailStateSuccess ? (
        <VerifySuccess />
      ) : (
        <Container maxW={"container.xl"}>
          <Box
            py={8}
            minH="100vh"
            display="flex"
            flexDir="column"
            justifyContent="center"
          >
            <Box display={"flex"} justifyContent="center">
              <Link href="https://veendhq.com" target="_blank" rel="noreferrer">
                <Image
                  src={veendLogo}
                  alt="veendHq"
                  className="login-logo-main"
                  width={200}
                />
              </Link>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              marginTop={5}
            >
              <Box py="10" className="get-started-card" maxW={400}>
                <Box
                  mb="8"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Heading
                    as="h1"
                    mt="4"
                    mb="2"
                    fontSize={{ base: "md", md: "md", lg: "lg" }}
                  >
                    Verify Email address
                  </Heading>
                  <Heading
                    as="h6"
                    my="2"
                    fontSize="small"
                    color={"#656566"}
                    fontWeight="semibold"
                    textAlign="center"
                  >
                    We just sent a 6-digit code to your email address at{" "}
                    {`${query.get("email")?.toLowerCase()}`}
                  </Heading>
                </Box>
                <Box>
                  <InputOTP
                    numInputs={6}
                    onChange={(pin: string) => setCode(pin)}
                    disable={loading}
                    isInputSecure={true}
                  />
                </Box>
                <Box mt={20} display="flex" flexDirection={"column"} mb={5}>
                  <Button
                    onClick={handleVerifyEmail}
                    size="lg"
                    isFullWidth
                    disabled={
                      code.length !== 6 || verifyEmailState.isVerifyingEmail
                    }
                    isLoading={verifyEmailState.isVerifyingEmail}
                  >
                    Verify
                  </Button>
                  <Text
                    color={isBusy || loading ? "gray.500" : "primaryBlue"}
                    fontWeight="bold"
                    textAlign="center"
                    cursor={isBusy || loading ? "none" : "pointer"}
                    mt={10}
                    onClick={ResendOtp}
                  >
                    {isBusy ? `Resend OTP in ${count}s` : "Resend OTP"}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
};

export default VerifyEmailOTP;
