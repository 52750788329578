import Handover from "views/auth/Handover";
import VerifyEmailOTP from "views/auth/EmailVerifyOTP";
import GetStarted from "views/auth/GetStarted";
import LendingLicenceChecker from "views/auth/LendingLicenseUpload";
import NewSignUp from "views/auth/NewSignup";
import SignSLA from "views/auth/SignSLA";
import error404 from "views/error404";
import {
  Dashboard,
  Loans,
  Borrowers,
  Balance,
  Settings,
  Tools,
  BulkEligibility,
  ViewEligibleUsers,
  PayingEmployers,
  Repayments,
} from "views/main";
import {
  Campaigns,
  Analytics,
  CreateNewCampaign,
  EditCampaign,
  UploadedContacts,
} from "views/main/smsAutomation";
import { IRoutes } from "../types";
import {
  ForgotPassword,
  Login,
  LenderRegister,
  ConsumerRegister,
  ResetPassword,
  VerifyMail,
  LenderSignup,
} from "../views/auth";
import ResetTwoFa from "views/auth/ResetTwofa";

const authLayout = "auth";
const mainLayout = "main";
const smsAutomationLayout = "sms";

export const routes: IRoutes[] = [
  { path: "/", component: Login, layout: authLayout, exact: true },
  { path: "/login", component: Login, layout: authLayout },
  { path: "/recover-twofa", component: ResetTwoFa, layout: authLayout },
  { path: "/setpassword/:id", component: ResetPassword, layout: authLayout },
  { path: "/verify-email", component: VerifyMail, layout: authLayout },
  { path: "/lender-signup", component: LenderRegister, layout: authLayout },
  { path: "/create-lender", component: LenderSignup, layout: authLayout },
  { path: "/consumer-signup", component: ConsumerRegister, layout: authLayout },
  { path: "/forgot-password", component: ForgotPassword, layout: authLayout },
  { path: "/signup", component: NewSignUp, layout: authLayout },
  { path: "/dashboard", component: Dashboard, layout: mainLayout },
  { path: "/loans", component: Loans, layout: mainLayout },
  { path: "/borrowers", component: Borrowers, layout: mainLayout },
  { path: "/repayments", component: Repayments, layout: mainLayout },
  { path: "/balance", component: Balance, layout: mainLayout },
  { path: "/settings", component: Settings, layout: mainLayout },
  { path: "/error404", component: error404, layout: authLayout },
  { path: "/tools", component: Tools, layout: mainLayout, exact: true },
  {
    path: "/tools/eligibilities",
    component: BulkEligibility,
    layout: mainLayout,
  },
  {
    path: "/tools/eligible-users",
    component: ViewEligibleUsers,
    layout: mainLayout,
  },
  {
    path: "/tools/paying-employers",
    component: PayingEmployers,
    layout: mainLayout,
  },
  {
    path: "/sms-automation",
    component: Campaigns,
    layout: smsAutomationLayout,
    exact: true,
  },
  {
    path: "/sms-automation/analytics",
    component: Analytics,
    layout: smsAutomationLayout,
  },
  {
    path: "/sms-automation/edit",
    component: EditCampaign,
    layout: smsAutomationLayout,
  },
  {
    path: "/sms-automation/new-campaign",
    component: CreateNewCampaign,
    layout: smsAutomationLayout,
  },
  {
    path: "/sms-automation/uploaded-contacts",
    component: UploadedContacts,
    layout: smsAutomationLayout,
  },
  {
    path: "/handover",
    component: Handover,
    layout: authLayout,
  },

  {
    path: "/onboarding",
    component: GetStarted,
    layout: authLayout,
  },
  {
    path: "/sla",
    component: SignSLA,
    layout: authLayout,
  },
  {
    path: "/verify-email-otp",
    component: VerifyEmailOTP,
    layout: authLayout,
  },
  // {
  //   path: "/upload-license",
  //   component: LendingLicenceChecker,
  //   layout: authLayout,
  // },
];
