import { Box, Heading, Text } from "@chakra-ui/react";
import { Checkbox, Col, Row } from "antd";
import { StartDateInput, SelectInput, EndDateInput } from "components/common";
import { campaignFrequency } from "../../constants";
import { Field, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { CampaignFields } from "views/main/smsAutomation/CreateNewCampaign";
import { ExclamationCircleFilled } from "@ant-design/icons";
interface IProps {
  isFormForEdit?: boolean;
}

export default function CampaignScheduleForm({
  isFormForEdit = false,
}: IProps) {
  const { values, setFieldValue, handleChange, errors } =
    useFormikContext<CampaignFields>();

  const [isCustomFreq, setIsCustomFreq] = useState(
    values?.frequency?.value === "custom"
  );

  const dateFormat = "DD/MM/YYYY";

  // Change the selectedDays base on the selected frequency...
  useEffect(() => {
    setIsCustomFreq(values?.frequency?.value === "custom");

    const freqDetails = campaignFrequency.find(
      f => f.value === values?.frequency?.value
    );

    if (freqDetails?.selectedDays.length) {
      setFieldValue("selectedDays", freqDetails.selectedDays);
    }
  }, [setFieldValue, values?.frequency]);

  return (
    <Box>
      <Heading as="h5" mb="2" fontSize="md" color="#161635">
        Frequency
      </Heading>
      <Text color="#656566" mb="4">
        Select the days of the week you want your campaigns to run
      </Text>

      <SelectInput
        placeholder="Weekdays"
        onChange={setFieldValue}
        error={errors.frequency}
        options={campaignFrequency}
        value={values.frequency}
        id="frequency"
        isDisabled={isFormForEdit}
      />

      <Box mb="4" className="selected-days">
        <Checkbox.Group value={values.selectedDays}>
          {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day, key) => (
            <Checkbox
              key={key}
              id="selectedDays"
              onChange={handleChange}
              name="selectedDays"
              value={day}
              disabled={!isCustomFreq || isFormForEdit}
            >
              {day}
            </Checkbox>
          ))}
        </Checkbox.Group>

        {errors.selectedDays && (
          <Box className="error-div">
            <ExclamationCircleFilled className="error-icon" />
            <Text className="error-text">{errors.selectedDays}</Text>
          </Box>
        )}
      </Box>

      <Row gutter={32}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Field
            name="startDate"
            type="date"
            component={StartDateInput}
            hasLabel
            label="Start Date"
            value={values.startDate}
            format={dateFormat}
            error={errors.startDate}
            disablePreviousDate
            // disabled={isFormForEdit}
            disabled={true}
          />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Field
            name="endDate"
            type="date"
            component={EndDateInput}
            hasLabel
            label="End Date"
            value={values.endDate}
            format={dateFormat}
            error={errors.endDate}
            disablePreviousDate
            disabled={isFormForEdit}
          />
        </Col>
      </Row>
    </Box>
  );
}
