import { Flex, StackProps, HStack, Text, Image } from "@chakra-ui/react";
// import { useRouter } from "next/router";
// import { resetAuth } from "redux/slices/auth";
// import { useAppDispatch, useAppSelector } from "redux/store";
// import { Routes } from "types/global";
// import { Logout2Icon } from "utils/customIcons";
// import Logo from "assets/images/logo.png";
import VeendHeading from "components/common/Typography/Heading";
import VeendButton from "components/common/Button";
import { Logout2Icon } from "helpers/customIcons";

interface Props {
  title: string;
  currentStep?: number;
}

function KycStepHeader({ title, currentStep = 0 }: Props) {
  // const { record } = useAppSelector(state => state.settings.profile);

  return (
    <>
      <Flex
        gap={4}
        justify="space-between"
        align="center"
        mb="32px"
        display={{ base: "flex", md: "none" }}
      >
        {/* <Image src={Logo} alt="Veend" maxW="80px" w="full" /> */}
        <SideBtns />
      </Flex>

      <Text
        color="#0E002A"
        fontWeight={600}
        mb={4}
        display={{ base: "block", md: "none" }}
      >
        {`Step ${currentStep + 1}/2`}
      </Text>

      <Flex gap={4} justify="space-between" align="center" mb="32px">
        <VeendHeading
          fontWeight={{ base: "400", md: "initial" }}
          fontSize={{ base: "18px", md: "20px", lg: "24px" }}
        >
          {title}
        </VeendHeading>

        <SideBtns display={{ base: "none", md: "flex" }} />
      </Flex>
    </>
  );
}

function SideBtns({ ...props }: StackProps) {
  // const dispatch = useAppDispatch();
  // const router = useRouter();

  function handleLogout() {
    // clear x-tag from localstorage and redirect to login page
      localStorage.clear();
      window.location.href = "/login";
  }

  return (
    <HStack {...props}>
      <VeendButton
        onClick={handleLogout}
        colorScheme="primary"
        variant="ghost"
        size="md"
        rightIcon={<Logout2Icon />}
      >
        Logout
      </VeendButton>
    </HStack>
  );
}

export default KycStepHeader;
