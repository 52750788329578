import { message } from "antd";
import axios from "axios";
import {
  ERROR_401_ACTION,
  WITHDRAW_FUNDS_ACTION_SUCCESS,
  WITHDRAW_FUNDS_ACTION_FAILED,
  CREATE_ACCOUNT_ACTION_FAILED,
  CREATE_ACCOUNT_ACTION_SUCCESS,
  GET_USERID_ACTION_SUCCESS,
  GET_USERID_ACTION_FAILED,
} from "./actionTypes";

// const baseUrl = process.env.REACT_APP_BASE_URL;
const txUrl = process.env.REACT_APP_TXURL;

// withdraw Funds
export const sendMoney =
  (txJwt: string | null, body: any, accountId: string | number) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${txJwt}`,
        "x-tag": "veend-setup",
      },
    };
    try {
      const res = await axios.post(
        `${txUrl}/v2/products/6157842b101720572946579b/purchase?accountId=${accountId}`,
        body,
        config
      );
      dispatch({
        type: WITHDRAW_FUNDS_ACTION_SUCCESS,
        payload: res?.data,
      });
      message.success("Withdrawal successful");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: WITHDRAW_FUNDS_ACTION_FAILED,
            payload: error?.response,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// get user ID
export const getUserId =
  (txJwt: string | null, accountId: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${txJwt}`,
      },
    };
    try {
      const res = await axios.get(
        `${txUrl}/accounts/${accountId}?accountId=${accountId}`,
        config
      );
      dispatch({
        type: GET_USERID_ACTION_SUCCESS,
        payload: res?.data.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: GET_USERID_ACTION_FAILED,
            payload: error?.response,
          });
        }
      }
      return error;
    }
  };

// create dedicated bank account
export const createBankAccount =
  (txJwt: string | null, userId: string, accountId: string, body: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${txJwt}`,
        "x-tag": "veend-setup",
      },
    };
    try {
      const res = await axios.patch(
        `${txUrl}/v1/users/${userId}?accountId=${accountId}`,
        body,
        config
      );
      dispatch({
        type: CREATE_ACCOUNT_ACTION_SUCCESS,
        payload: res?.data.data,
      });
      message.success("Funding account created successfully");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: CREATE_ACCOUNT_ACTION_FAILED,
            payload: error?.response,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };
