import { Box } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

interface Props {
  dependency?: any;
}

export function ScrollToTopPoint({ dependency }: Props) {
  const { pathname } = useLocation();
  const pointRef = useRef(null);

  // Scroll to the top on page change
  useEffect(() => {
    // const parentElement = document.querySelector(".top-point")?.nextElementSibling;

    // @ts-ignore
    const parentElement = pointRef.current?.nextElementSibling;

    parentElement?.scrollBy({
      top: -parentElement?.scrollHeight,
      left: 0,
    });
  }, [dependency, pathname]);

  return <Box className="top-point" ref={pointRef} />;
}
