import React from "react";
import { Table } from "antd";
import { Text, Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import moment from "moment";
import { DateTimeFormat } from "../../constants";
import { IEligibilityStatus, RootState } from "../../types";
import { Loader } from "components/common";

export default function EligibilityTable({ tab }: IEligibilityStatus) {
  const eligibilityState = useSelector(
    (state: RootState) => state.toolsReducer
  );
  const eligibilityRes = eligibilityState?.eligibilityData;
  const eligibilities = eligibilityRes?.data;
  const loading = eligibilityState?.requestLoading;

  const columns =
    tab === "completed"
      ? [
          {
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            render: (text: string) => (
              <Box onClick={e => e.stopPropagation()}>
                <Text
                  fontSize={{ base: "xx-small", md: "xs" }}
                  fontWeight="bold"
                >
                  {text}
                </Text>
                <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
                  Phone number
                </Text>
              </Box>
            ),
          },
          {
            dataIndex: "name",
            key: "name",
            render: (text: string) => (
              <Box>
                <Text
                  fontSize={{ base: "xx-small", md: "xs" }}
                  fontWeight="bold"
                >
                  {text}
                </Text>
                <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
                  Name
                </Text>
              </Box>
            ),
            className: "table-cell-display",
          },
          {
            dataIndex: "eligibilityAmount",
            key: "eligibilityAmount",
            render: (text: string) => (
              <Box>
                <Text
                  fontSize={{ base: "xx-small", md: "xs" }}
                  fontWeight="bold"
                >
                  {text}
                </Text>
                <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
                  Eligible Amount
                </Text>
              </Box>
            ),
          },
          {
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text: string) => (
              <Box>
                <Text
                  fontSize={{ base: "xx-small", md: "xs" }}
                  fontWeight="bold"
                >
                  {text}
                </Text>
                <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
                  Submitted Date
                </Text>
              </Box>
            ),
            className: "table-cell-display table-cell-display--md",
          },
          {
            dataIndex: "status",
            key: "status",
            render: (text: string) => (
              <Box>
                <Text
                  fontSize={{ base: "xx-small", md: "xs" }}
                  fontWeight="bold"
                >
                  {text}
                </Text>
                <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
                  Status
                </Text>
              </Box>
            ),
          },
          {
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (text: string) => (
              <Box>
                <Text
                  fontSize={{ base: "xx-small", md: "xs" }}
                  fontWeight="bold"
                >
                  {text}
                </Text>
                <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
                  Last Updated
                </Text>
              </Box>
            ),
          },
        ]
      : [
          {
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            render: (text: string) => (
              <Box onClick={e => e.stopPropagation()}>
                <Text
                  fontSize={{ base: "xx-small", md: "xs" }}
                  fontWeight="bold"
                >
                  {text}
                </Text>
                <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
                  Phone number
                </Text>
              </Box>
            ),
          },
          {
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text: string) => (
              <Box>
                <Text
                  fontSize={{ base: "xx-small", md: "xs" }}
                  fontWeight="bold"
                >
                  {text}
                </Text>
                <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
                  Submitted Date
                </Text>
              </Box>
            ),
            className: "table-cell-display table-cell-display--md",
          },
          {
            dataIndex: "status",
            key: "status",
            render: (text: string) => (
              <Box>
                <Text
                  fontSize={{ base: "xx-small", md: "xs" }}
                  fontWeight="bold"
                >
                  {text}
                </Text>
                <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
                  Status
                </Text>
              </Box>
            ),
          },
          {
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (text: string) => (
              <Box>
                <Text
                  fontSize={{ base: "xx-small", md: "xs" }}
                  fontWeight="bold"
                >
                  {text}
                </Text>
                <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
                  Last Updated
                </Text>
              </Box>
            ),
          },
        ];

  const data: any[] | undefined = [];

  for (let i = 0; i < eligibilities?.length; i++) {
    data.push({
      key: eligibilities[i]?._id,
      phoneNumber: eligibilities[i]?.phoneNumber,
      name: `${eligibilities[i]?.user?.firstName} ${eligibilities[i]?.user?.lastName}`,
      createdAt: `${moment(eligibilities[i]?.createdAt).format(
        DateTimeFormat
      )}`,
      status: eligibilities[i]?.status,
      eligibilityAmount: eligibilities[i]?.eligibility?.amount,
      updatedAt: `${moment(eligibilities[i]?.updatedAt).format(
        DateTimeFormat
      )}`,
    });
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 50,
            hideOnSinglePage: true,
            position: ["bottomLeft"],
          }}
          className="bulk-eligibility-table"
        />
      )}
    </>
  );
}
