import { SmsAutomationHeader } from "components/common";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Steps, Button, Space } from "antd";
import "../styles/smsAutomation.css";
import { Box, Flex, Stack } from "@chakra-ui/react";
import {
  CampaignContentForm,
  CampaignScheduleForm,
  CampaignReviewForm,
} from "components/forms";
import LaunchCampaignModal from "components/modals/LaunchCampaignModal";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addCampaigns } from "redux/actions/smsAutomationActions";
import validationSchema from "constants/validationSchema";
import { formattedNumberToTwoDigits } from "helpers/formattedNumberToTwoDigits";
import { RootState } from "types";

const { Step } = Steps;

interface ISteps {
  title: string;
  component: JSX.Element;
  validationSchema: any;
}

const steps: ISteps[] = [
  {
    title: "Content",
    component: <CampaignContentForm />,
    validationSchema: validationSchema.campaignContent,
  },
  {
    title: "Schedule",
    component: <CampaignScheduleForm />,
    validationSchema: validationSchema.campaignSchedule,
  },
  {
    title: "Review and submit",
    component: <CampaignReviewForm />,
    validationSchema: {
      ...validationSchema.campaignContent,
      ...validationSchema.campaignSchedule,
    },
  },
];

export interface CampaignFields {
  name: string;
  amount: number;
  message: string;
  savedMsgOnEdit?: string;
  messageIndex: number;
  estimatedCostPerCampaign: number;
  estimatedCostPerDay: number;
  frequency: any;
  selectedDays: string[];
  startDate: string;
  endDate: string | null;
  isContinous: boolean;
}

export default function CreateNewCampaign() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [showLaunchCampaignModal, setShowLaunchCampaignModal] = useState(false);
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const userSetting = setting?.settingData?.data;
  const userData = useSelector(
    (state: RootState) => state.authReducer.loginDataTwo
  );

  const xJwt = localStorage.getItem("x_jwt");

  function next() {
    setPage(page => page + 1);
  }

  function prev() {
    setPage(page => page - 1);
  }

  function handleSubmit() {
    if (page < steps.length - 1) {
      next();
      return;
    } else {
      // Show the summary modal before launch
      setShowLaunchCampaignModal(true);
    }
  }

  function launchCampaign(values: any) {
    const body = {
      name: `${userSetting?.info?.businessName || userData?.businessName}:${
        values?.name
      }`,
      message: values?.message,
      messageIndex: values?.messageIndex,
      amount: values?.amount,
      frequency: values?.frequency?.value,
      startDate: values?.startDate,
      endDate:
        values?.endDate || getEndDateForContinousCampaign(values?.startDate),
      selectedDays: values?.selectedDays,
    };

    dispatch(
      addCampaigns(
        xJwt,
        body,
        userSetting?.info?.businessName || userData?.businessName,
        history
      )
    );

    setShowLaunchCampaignModal(false);
  }

  function getEndDateForContinousCampaign(startDate: string) {
    const date = new Date(startDate);

    // Add a year to the start date
    const year = date.getFullYear() + 1;
    const month = formattedNumberToTwoDigits(date.getMonth() + 1);
    const day = formattedNumberToTwoDigits(date.getDate());

    return `${year}-${month}-${day}`;
  }

  return (
    <Formik
      initialValues={{
        name: "",
        amount: "",

        messageIndex: 0,
        message: "",

        frequency: null,
        selectedDays: [],
        startDate: new Date().toISOString().substring(0, 10),
        endDate: null,
        isContinous: true,
        estimatedCostPerCampaign: "",
        estimatedCostPerDay: "",
      }}
      validationSchema={yup.object().shape(steps[page].validationSchema)}
      onSubmit={() => handleSubmit()}
    >
      <Form>
        <SmsAutomationHeader title="Create New Campaign" />

        <Stack spacing={8} h="full">
          <Flex
            gap="12"
            rowGap="8"
            align="center"
            justifyContent="space-between"
            wrap={{ base: "wrap", lg: "nowrap" }}
            className="campaign-steps"
          >
            <Steps current={page}>
              {steps.map((item, key) => (
                <Step key={key} title={item.title} />
              ))}
            </Steps>

            <Space size="middle" style={{ marginLeft: "auto" }}>
              {page === 0 && (
                <Button
                  size="large"
                  type="ghost"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
              )}
              {page > 0 && (
                <Button size="large" type="ghost" onClick={() => prev()}>
                  Previous
                </Button>
              )}
              {page < steps.length - 1 && (
                <Button size="large" type="primary" htmlType="submit">
                  Next
                </Button>
              )}
              {page === steps.length - 1 && (
                <Button size="large" type="primary" htmlType="submit">
                  Launch campaign
                </Button>
              )}
            </Space>
          </Flex>

          <Box flex="1" className="sms-box">
            <Box className="content-form">{steps[page].component}</Box>
          </Box>
        </Stack>

        <LaunchCampaignModal
          showModal={showLaunchCampaignModal}
          setShowModal={setShowLaunchCampaignModal}
          launchCampaign={launchCampaign}
        />
      </Form>
    </Formik>
  );
}
