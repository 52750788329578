import { DownOutlined } from "@ant-design/icons";
import { Box } from "@chakra-ui/react";
import { Radio } from "antd";
import React, { useState } from "react";
import { ILoanTab } from "types";
import { DateRangeWrapper, DownloadLoans, SearchLoansInput } from ".";

interface LoanTabFiltersProps {
  loanStatus: "pending" | "approved" | "active" | "closed";
  processStatus: "pending" | "approved" | "disbursed";
  disbursementStatus: "processing" | "completed" | "failed";
  loanTab: ILoanTab["loanTab"];
  children: React.ReactNode;
  searchContent?: React.ReactNode;
  allLoans?: boolean;
}

const LoanTabFilters = ({
  loanTab,
  loanStatus,
  processStatus,
  disbursementStatus,
  allLoans,
  children,
  searchContent,
}: LoanTabFiltersProps) => {
  const [showDateRange, setShowDateRange] = useState(false);
  const [searchParam, setSearchParam] = useState("phoneNumber");
  const toggleDateRange = () => {
    showDateRange ? setShowDateRange(false) : setShowDateRange(true);
  };
  const onRadioToggle = (e: any) => {
    setSearchParam(e.target.value);
  };

  return (
    <>
      <DownloadLoans tab={loanTab} />
      <Box className="loan-tab-pane">
        <Box className="transactions-table-header">
          <Box className="search-loans-container">
            {searchContent || (
              <>
                <SearchLoansInput
                  loanStatus={loanStatus}
                  processStatus={processStatus}
                  disbursementStatus={disbursementStatus}
                  searchField={searchParam}
                  isDisabled={showDateRange}
                  allLoans={allLoans}
                  loanTab={loanTab}
                />
                <Box
                  as={Radio.Group}
                  onChange={onRadioToggle}
                  value={searchParam}
                  mt="2"
                >
                  <Radio value="phoneNumber">Phone number</Radio>
                  <Radio value="remitaMandateReference">Mandate</Radio>
                  <Radio value="loanId">Loan ID</Radio>
                </Box>
              </>
            )}
          </Box>
          <Box className="table-btns">
            <DownOutlined
              className={
                showDateRange
                  ? "date-range-toggle-arrow arrow-up"
                  : "date-range-toggle-arrow"
              }
            />
            {/* eslint-disable-next-line */}
            <a onClick={toggleDateRange} className="date-range-toggle">
              set date range
            </a>
          </Box>
        </Box>
        {showDateRange ? <DateRangeWrapper loanTab={loanTab} /> : null}

        {children}
      </Box>
    </>
  );
};

export default LoanTabFilters;
