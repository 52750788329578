import {
  Box,
  Flex,
  IconButton,
  SimpleGrid,
  Image,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import { InputField, SelectInput, TextareaInput } from "components/common";
import { TextField } from "@material-ui/core";
import VeendButton from "components/common/Button";
import KycStepHeader from "components/modals/KycModal/KycStepHeader";
import { getCountries, Industries } from "helpers/utilFunctions";
import { businessIdTypes } from "../../constants";
import FileStack from "components/common/FileStack";
import CloudUploadImg from "assets/cil_cloud-upload.png";
import { completeVerification, verifyBusinessInfo } from "helpers/api";
import { getUserSettings } from "redux/actions/settingsActions";
import { DeletePhoneIcon } from "components/common/CustomIcons";
import { message } from "antd";

type Props = {
  handleCurrentStep?: (num: 0 | 1) => void;
  currentStep: number;
};

const BusinessInformation = ({ handleCurrentStep, currentStep }: Props) => {
  const [open, setOpen] = useState(false);
  // const { settingData } = useAppSelector(state => state.settings.profile);
  const [err, setErr] = useState({ regType: "", idFile: "" });
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const { settingData, isLoading } = setting;
  const dispatch = useDispatch();

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      businessName: "",
      email: "",
      phoneNumber: "",
      industry: {
        label: "",
        value: "",
      },
      country: {
        label: "",
        value: "",
      },
      idType: { label: "", value: "" },
      rcNumber: "",
      idFile: "",
      natureOfBusiness: "",
    },
    async onSubmit(values) {
      // if (settingData.businessInfo?.isVerified) {
      //   dispatch(setIsKYCModalOpen(false));
      //   router.push(Routes.dashboard);
      // }

      if (!values.idType.value) {
        return setErr(err => ({
          ...err,
          regType: "Registration type is required",
        }));
      }

      if (!values.idFile) {
        return setErr(err => ({
          ...err,
          idFile: "Copy of document is required",
        }));
      }

      const dataToSend = {
        isUpdating: false,
        businessName: values.businessName,
        businessEmail: values.email,
        businessPhone: values.phoneNumber,
        industry: values.industry.value,
        country: values.country.value,

        idType: values.idType.value,
        rcNumber: values.rcNumber,
        idFile: values.idFile,
        natureOfBusiness: values.natureOfBusiness,
      };
      const { error, data, errMessage } = await verifyBusinessInfo(dataToSend);
      if (data) {
        await completeVerification();
        dispatch(getUserSettings(localStorage.getItem("x_jwt")));
      }
      if (error) {
        message.error(errMessage);
      }
    },
    enableReinitialize: true,
  });

  return (
    <Box>
      <KycStepHeader title="Business Information" currentStep={currentStep} />

      <form onSubmit={handleSubmit}>
        <SimpleGrid columns={{ base: 1, md: 2 }} columnGap={"27px"}>
          <InputField
            inputType="text"
            name="businessName"
            hasLabel
            label="Business name"
            placeholder="Enter your  business name"
            value={values.businessName}
            // onBlur={() => handleBlur}
            onChange={handleChange}
            error={touched.businessName && errors.businessName}
          />

          <InputField
            inputType="email"
            name="email"
            hasLabel
            label="Business email"
            placeholder="Enter your  business email"
            value={values.email}
            // onBlur={() => handleBlur}
            onChange={handleChange}
            error={touched.email && errors.email}
          />

          <InputField
            inputType="text"
            name="phoneNumber"
            hasLabel
            label="Phone number"
            placeholder="Enter phone number"
            value={values.phoneNumber}
            onChange={handleChange}
            error={false}
          />

          <SelectInput
            options={Industries}
            value={values.industry}
            error={touched.industry && errors.industry}
            onChange={setFieldValue}
            id="industry"
            placeholder="Select industry type"
            label="Industry"
            hasLabel
          />

          <SelectInput
            options={getCountries}
            value={values.country}
            error={touched.country && errors.country}
            onChange={setFieldValue}
            id="country"
            placeholder="Select country"
            label="Country"
            hasLabel
          />
        </SimpleGrid>
        <SelectInput
          label="Type of registration document"
          id="idType"
          onChange={(field, value) => {
            setErr(err => ({ ...err, regType: "" }));
            setFieldValue("idType", value);
          }}
          options={businessIdTypes}
          value={values.idType}
          placeholder="Select type of registration document"
          error={err.regType}
          hasLabel
        />
        <InputField
          label="Certificate Number"
          placeholder={`Enter your ${
            values.idType?.label || "certificate"
          } number`}
          name="rcNumber"
          onChange={handleChange}
          value={values.rcNumber}
          error={false}
          inputType="text"
          hasLabel
        />

        <FileStack
          open={open}
          setOpen={setOpen}
          accept={["image/*", "application/pdf"]}
          onUploadComplete={res => {
            setErr(err => ({ ...err, idFile: "" }));
            setFieldValue("idFile", res.filesUploaded?.[0]?.url);
          }}
        />

        {/* @ts-ignore */}
        {values.idFile.length || settingData.data?.businessInfo?.idFile ? (
          <Box position={"relative"}>
            <Box>Copy of certificate</Box>

            <Box position={"relative"} w={["100%", "50%"]}>
              <Image
                src={values.idFile}
                style={{ width: "100%", border: "none" }}
              />
              <IconButton
                bg="error.50"
                aria-label="delete"
                _hover={{
                  bg: "error.50",
                }}
                position={"absolute"}
                top={0}
                right={[0, -10]}
                onClick={() => {
                  setFieldValue("idFile", "");
                }}
              >
                <DeletePhoneIcon boxSize={"24px"} />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box>Copy of certificate</Box>
            <Box
              className="custom-upload-input"
              onClick={() => setOpen(true)}
              cursor="pointer"
            >
              <Box className="custom-upload-over-lay">
                <Image src={CloudUploadImg} />
                <Text fontSize="md" fontWeight="500">
                  Upload document here or{" "}
                  <Text as="span" color="#130AD8">
                    Browse
                  </Text>
                </Text>
                <Text fontSize="12px">
                  Supported file types: JPG, PNG, PDF. Max file size: 2MB
                </Text>
              </Box>
            </Box>
          </Box>
        )}

        <TextareaInput
          error={false}
          label="Nature of business"
          placeholder="Describe the nature of your business"
          value={values.natureOfBusiness}
          onChange={handleChange}
          onBlur={() => false}
          name="natureOfBusiness"
        />
        <Flex
          justifyContent={"space-between"}
          flexDirection={{ base: "column", md: "row" }}
          mt="50px"
          gap="32px"
        >
          <VeendButton
            colorScheme={"primary"}
            variant={"outline"}
            onClick={() => handleCurrentStep && handleCurrentStep(0)}
            w="full"
            borderColor="primaryBlue"
            color="primaryBlue"
          >
            Previous
          </VeendButton>
          <VeendButton
            colorScheme={"primary"}
            type="submit"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            w="full"
          >
            Save & Continue
          </VeendButton>
        </Flex>
      </form>
    </Box>
  );
};

export default BusinessInformation;
