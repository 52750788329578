// auth action types
export const LOGIN_USER_2FA_LOADING_TRUE = "LOGIN_USER_2FA_LOADING_TRUE";
export const LOGIN_USER_2FA_LOADING_FALSE = "LOGIN_USER_2FA_LOADING_FALSE";
export const ENABLE_2FA_LOADING_TRUE = "ENABLE_2FA_LOADING_TRUE";
export const ENABLE_2FA_LOADING_FALSE = "ENABLE_2FA_LOADING_FALSE";
export const LOGIN_USER_2FA_ACTION_SUCCESS = "LOGIN_USER_2FA_ACTION_SUCCESS";
export const LOGIN_USER_2FA_ACTION_FAILURE = "LOGIN_USER_2FA_ACTION_FAILURE";
export const ENABLE_2FA_ACTION_SUCCESS = "ENABLE_2FA_ACTION_SUCCESS";
export const ENABLE_2FA_ACTION_FAILURE = "ENABLE_2FA_ACTION_FAILURE";
export const GET_USER_PROFILE_ACTION_SUCCESS =
  "GET_USER_PROFILE_ACTION_SUCCESS";
export const GET_USER_PROFILE_ACTION_FAILURE =
  "GET_USER_PROFILE_ACTION_FAILURE";
export const TWO_FA_NEEDED_FOR_LOGIN = "TWO_FA_NEEDED_FOR_LOGIN";
export const LOGIN_USER_ACTION_SUCCESS = "LOGIN_USER_ACTION_SUCCESS";
export const LOGIN_USER_ACTION_FAILURE = "LOGIN_USER_ACTION_FAILURE";
export const SIGNUP_LENDER_ACTION_SUCCESS = "SIGNUP_LENDER_ACTION_SUCCESS";
export const SIGNUP_LENDER_ACTION_FAILURE = "SIGNUP_LENDER_ACTION_FAILURE";
export const RESET_PASSWORD_ACTION_FAILURE = "RESET_PASSWORD_ACTION_FAILURE";
export const RESET_PASSWORD_ACTION_SUCCESS = "RESET_PASSWORD_ACTION_SUCCESS";
export const AUTH_LOADING_TRUE = "AUTH_LOADING_TRUE";
export const AUTH_LOADING_FALSE = "AUTH_LOADING_FALSE";
export const VERIFY_EMAIL_ACTION_SUCCESS = "VERIFY_EMAIL_ACTION_SUCCESS";
export const VERIFY_EMAIL_ACTION_FAILURE = "VERIFY_EMAIL_ACTION_FAILURE";
export const RESET_VERIFY_EMAIL_STATE = "RESET_VERIFY_EMAIL_STATE";
export const VERIFICATION_ONBOARDING_SUCCESS = "VERIFICATION_SUCCESS";
export const VERIFICATION_ONBOARDING_FAILED = "VERIFICATION_FAILED";
export const IS_VERIFICATION_EMAIL_START = "IS_VERIFICATION_EMAIL_START";
export const IS_VERIFICATION_EMAIL_END = "IS_VERIFICATION_EMAIL_END";

// borrower action types
export const BORROWERS_TABLE_ACTION_SUCCESS = "BORROWERS_TABLE_ACTION_SUCCESS";
export const BORROWERS_TABLE_ACTION_FAILURE = "BORROWERS_TABLE_ACTION_FAILURE";
export const BORROWERS_DETAILS_ACTION_SUCCESS =
  "BORROWERS_DETAILS_ACTION_SUCCESS";
export const BORROWERS_DETAILS_ACTION_FAILURE =
  "BORROWERS_DETAILS_ACTION_FAILURE";
export const CREATE_BORROWER_ACTION_SUCCESS = "CREATE_BORROWER_ACTION_SUCCESS";
export const CREATE_BORROWER_ACTION_FAILURE = "CREATE_BORROWER_ACTION_FAILURE";
export const REGISTER_BORROWER_ACTION_SUCCESS =
  "REGISTER_BORROWER_ACTION_SUCCESS";
export const REGISTER_BORROWER_ACTION_FAILURE =
  "REGISTER_BORROWER_ACTION_FAILURE";
export const PREAPPROVE_BORROWER_ACTION_SUCCESS =
  "PREAPPROVE_BORROWER_ACTION_SUCCESS";
export const PREAPPROVE_BORROWER_ACTION_FAILURE =
  "PREAPPROVE_BORROWER_ACTION_FAILURE";
export const BORROWER_REQUEST_LOADING_TRUE = "BORROWER_REQUEST_LOADING_TRUE";
export const BORROWER_PROFILE_LOADING_TRUE = "BORROWER_PROFILE_LOADING_TRUE";
export const BORROWER_REQUEST_LOADING_FALSE = "BORROWER_REQUEST_LOADING_FALSE";
export const DOWNLOAD_BORROWERS_ACTION_SUCCESS =
  "DOWNLOAD_BORROWERS_ACTION_SUCCESS";
export const DOWNLOAD_BORROWERS_ACTION_FAILURE =
  "DOWNLOAD_BORROWERS_ACTION_FAILURE";

// dashboard action types
export const DASHBOARD_ACTION_SUCCESS = "DASHBOARD_ACTION_SUCCESS";
export const DASHBOARD_ACTION_FAILURE = "DASHBOARD_ACTION_FAILURE";
export const BALANCE_ACTION_SUCCESS = "BALANCE_ACTION_SUCCESS";
export const BALANCE_ACTION_FAILURE = "BALANCE_ACTION_FAILURE";
export const OPERATION_ACTION_SUCCESS = "OPERATION_ACTION_SUCCESS";
export const OPERATION_ACTION_FAILURE = "OPERATION_ACTION_FAILURE";
export const EXPECTED_CHART_ACTION_SUCCESS = "EXPECTED_CHART_ACTION_SUCCESS";
export const EXPECTED_CHART_ACTION_FAILURE = "EXPECTED_CHART_ACTION_FAILURE";
export const NEW_CLIENT_CHART_ACTION_SUCCESS =
  "NEW_CLIENT_CHART_ACTION_SUCCESS";
export const NEW_CLIENT_CHART_ACTION_FAILURE =
  "NEW_CLIENT_CHART_ACTION_FAILURE";
export const INCOME_CHART_ACTION_SUCCESS = "INCOME_CHART_ACTION_SUCCESS";
export const INCOME_CHART_ACTION_FAILURE = "INCOME_CHART_ACTION_FAILURE";
export const DISBURSE_CHART_ACTION_SUCCESS = "DISBURSE_CHART_ACTION_SUCCESS";
export const DISBURSE_CHART_ACTION_FAILURE = "DISBURSE_CHART_ACTION_FAILURE";
export const DASH_REQUEST_LOADING_TRUE = "DASH_REQUEST_LOADING_TRUE";
export const DASH_REQUEST_LOADING_FALSE = "DASH_REQUEST_LOADING_FALSE";

// loan action types
export const LOAN_ACTION_SUCCESS = "LOAN_ACTION_SUCCESS";
export const LOAN_ACTION_FAILURE = "LOAN_ACTION_FAILURE";
export const LOAN_APPROVAL_LIST_ACTION_SUCCESS =
  "LOAN_APPROVAL_LIST_ACTION_SUCCESS";
export const LOAN_APPROVAL_LIST_ACTION_FAILURE =
  "LOAN_APPROVAL_LIST_ACTION_FAILURE";
export const PENDING_DISBURSEMENT_ACTION_SUCCESS =
  "PENDING_DISBURSEMENT_ACTION_SUCCESS";
export const PENDING_DISBURSEMENT_ACTION_FAILURE =
  "PENDING_DISBURSEMENT_ACTION_FAILURE";
export const PARTIAL_PAID_ACTION_SUCCESS = "PARTIAL_PAID_ACTION_SUCCESS";
export const PARTIAL_PAID_ACTION_FAILURE = "PARTIAL_PAID_ACTION_FAILURE";
export const PAID_ACTION_SUCCESS = "PAID_ACTION_SUCCESS";
export const PAID_ACTION_FAILURE = "PAID_ACTION_FAILURE";
export const LOAN_RECORD_ACTION_SUCCESS = "LOAN_RECORD_ACTION_SUCCESS";
export const LOAN_RECORD_ACTION_FAILURE = "LOAN_RECORD_ACTION_FAILURE";
export const LOAN_SCHEDULE_ACTION_SUCCESS = "LOAN_SCHEDULE_ACTION_SUCCESS";
export const ACTIVE_LOAN_ACTION_SUCCESS = "ACTIVE_LOAN_ACTION_SUCCESS";
export const ACTIVE_LOAN_ACTION_FAILURE = "ACTIVE_LOAN_ACTION_FAILURE";
export const TOPUP_LOAN_ACTION_SUCCESS = "TOPUP_LOAN_ACTION_SUCCESS";
export const TOPUP_LOAN_ACTION_FAILURE = "TOPUP_LOAN_ACTION_FAILURE";
export const POST_LOAN_ACTION_SUCCESS = "ACTIVE_LOAN_ACTION_SUCCESS";
export const POST_LOAN_ACTION_FAILURE = "ACTIVE_LOAN_ACTION_FAILURE";
export const DISBURSE_LOAN_ACTION_SUCCESS = "DISBURSE_LOAN_ACTION_SUCCESS";
export const DISBURSE_LOAN_ACTION_FAILURE = "DISBURSE_LOAN_ACTION_FAILURE";
export const PROCESSING_DISBURSEMENT_ACTION_SUCCESS =
  "PROCESSING_DISBURSEMENT_ACTION_SUCCESS";
export const PROCESSING_DISBURSEMENT_ACTION_FAILURE =
  "PROCESSING_DISBURSEMENT_ACTION_FAILURE";
export const UPDATE_LOANS_DOWNLOAD_LIST = "UPDATE_LOANS_DOWNLOAD_LIST";
export const UPDATE_APPROVED_LOANS_DOWNLOAD_LIST =
  "UPDATE_APPROVED_LOANS_DOWNLOAD_LIST";
export const UPDATE_PROCESSING_LOANS_DOWNLOAD_LIST =
  "UPDATE_PROCESSING_LOANS_DOWNLOAD_LIST";
export const UPDATE_ACTIVE_LOANS_DOWNLOAD_LIST =
  "UPDATE_ACTIVE_LOANS_DOWNLOAD_LIST";
export const UPDATE_TOPUP_LOANS_DOWNLOAD_LIST =
  "UPDATE_TOPUP_LOANS_DOWNLOAD_LIST";
export const UPDATE_PARTIAL_PAID_LOANS_DOWNLOAD_LIST =
  "UPDATE_PARTIAL_PAID_LOANS_DOWNLOAD_LIST";
export const UPDATE_PAID_LOANS_DOWNLOAD_LIST =
  "UPDATE_PAID_LOANS_DOWNLOAD_LIST";
export const UPDATE_PRIME_LOANS_DOWNLOAD_LIST =
  "UPDATE_PRIME_LOANS_DOWNLOAD_LIST";
export const UPDATE_OVERDUE_LOANS_DOWNLOAD_LIST =
  "UPDATE_OVERDUE_LOANS_DOWNLOAD_LIST";
export const OVERDUE_LOANS_ACTION_SUCCESS = "OVERDUE_LOANS_ACTION_SUCCESS";
export const LOAN_PROFILE_LOADING_TRUE = "LOAN_PROFILE_LOADING_TRUE";

export const PRIME_LOAN_LIST_LOADING_TRUE = "PRIME_LOAN_LIST_LOADING_TRUE";
export const PRIME_LOAN_LIST_ACTION_SUCCESS = "PRIME_LOAN_LIST_ACTION_SUCCESS";
export const PRIME_LOAN_LIST_ACTION_FAILURE = "PRIME_LOAN_LIST_ACTION_FAILURE";

//repayments action types
export const REPAYMENTS_ACTION_SUCCESS = "REPAYMENTS_ACTION_SUCCESS";
export const REPAYMENTS_ACTION_FAILURE = "REPAYMENTS_ACTION_FAILURE";
export const UPDATE_REPAYMENTS_DOWNLOAD_LIST =
  "UPDATE_REPAYMENTS_DOWNLOAD_LIST";

// settings action types
export const USER_SETTINGS_ACTION_SUCCESS = "USER_SETTINGS_ACTION_SUCCESS";
export const USER_SETTINGS_ACTION_FAILURE = "USER_SETTINGS_ACTION_FAILURE";
export const UPDATE_USER_SETTINGS_ACTION_SUCCESS =
  "UPDATE_USER_SETTINGS_ACTION_SUCCESS";
export const UPDATE_USER_SETTINGS_ACTION_FAILURE =
  "UPDATE_USER_SETTINGS_ACTION_FAILURE";
export const VERIFY_ACCOUNT_NUMBER_SUCCESS = "VERIFY_ACCOUNT_NUMBER_SUCCESS";
export const VERIFY_ACCOUNT_LOADING_TRUE = "VERIFY_ACCOUNT_LOADING_TRUE";
export const VERIFY_ACCOUNT_NUMBER_FAILED = "VERIFY_ACCOUNT_NUMBER_FAILED";
export const REQUEST_LOADING_TRUE = "REQUEST_LOADING_TRUE";
export const REQUEST_LOADING_FALSE = "REQUEST_LOADING_FALSE";
export const GET_LOAN_OFFERS_SUCCESS = "GET_LOAN_OFFERS_SUCCESS";
export const GET_LOAN_OFFERS_FAILED = "GET_LOAN_OFFERS_FAILED";
export const CREATE_LOAN_OFFER_SUCCESS = "CREATE_LOAN_OFFER_SUCCESS";
export const CREATE_LOAN_OFFER_FAILED = "CREATE_LOAN_OFFER_FAILED";
export const GET_LOAN_PRODUCT_RECORD_SUCCESS =
  "GET_LOAN_PRODUCT_RECORD_SUCCESS";
export const GET_LOAN_PRODUCT_RECORD_FAILED = "GET_LOAN_PRODUCT_RECORD_FAILED";
export const GET_LOAN_PRODUCTS_SUCCESS = "GET_LOAN_PRODUCTS_SUCCESS";
export const GET_LOAN_PRODUCTS_FAILED = "GET_LOAN_PRODUCTS_FAILED";
export const CREATE_LOAN_PRODUCT_SUCCESS = "CREATE_LOAN_PRODUCT_SUCCESS";
export const UPDATE_LOAN_PRODUCT_SUCCESS = "UPDATE_LOAN_PRODUCT_SUCCESS";
export const UPDATE_LOAN_PRODUCT_FAILED = "UPDATE_LOAN_PRODUCT_FAILED";
export const DELETE_LOAN_PRODUCT_SUCCESS = "DELETE_LOAN_PRODUCT_SUCCESS";
export const DELETE_LOAN_PRODUCT_FAILED = "DELETE_LOAN_PRODUCT_FAILED";
export const UPDATE_LOAN_OFFER_SUCCESS = "UPDATE_LOAN_OFFER_SUCCESS";
export const UPDATE_LOAN_OFFER_FAILED = "UPDATE_LOAN_OFFER_FAILED";
export const DELETE_LOAN_OFFER_SUCCESS = "DELETE_LOAN_OFFER_SUCCESS";
export const GET_TEAM_MEMBERS_SUCCESS = "GET_TEAM_MEMBERS_SUCCESS";
export const GET_TEAM_MEMBERS_FAILED = "GET_TEAM_MEMBERS_FAILED";
export const CREATE_TEAM_MEMBER_SUCCESS = "CREATE_TEAM_MEMBER_SUCCESS";
export const CREATE_TEAM_MEMBER_FAILED = "CREATE_TEAM_MEMBER_FAILED";
export const UPDATE_TEAM_MEMBER_SUCCESS = "UPDATE_TEAM_MEMBER_SUCCESS";
export const UPDATE_TEAM_MEMBER_FAILED = "UPDATE_TEAM_MEMBER_FAILED";
export const DELETE_TEAM_MEMBER_SUCCESS = "DELETE_TEAM_MEMBER_SUCCESS";
export const GET_FEES_SUCCESS = "GET_FEES_SUCCESS";
export const GET_FEES_FAILED = "GET_FEES_FAILED";
export const CREATE_FEE_SUCCESS = "CREATE_FEE_SUCCESS";
export const CREATE_FEE_FAILED = "CREATE_FEE_FAILED";
export const UPDATE_FEE_SUCCESS = "UPDATE_FEE_SUCCESS";
export const UPDATE_FEE_FAILED = "UPDATE_FEE_FAILED";
export const DELETE_FEE_SUCCESS = "DELETE_FEE_SUCCESS";
export const COLENDING_TOGGLE_LOADING_TRUE = "COLENDING_TOGGLE_LOADING_TRUE";
export const COLENDING_TOGGLE_LOADING_FALSE = "COLENDING_TOGGLE_LOADING_FALSE";
export const GET_COLENDING_INTEREST_RATE_SUCCESS =
  "GET_COLENDING_INTEREST_RATE_SUCCESS";
export const GET_COLENDING_LEADER_SUCCESS = "GET_COLENDING_LEADER_SUCCESS";

// transActions types
export const TRANSACTIONS_TABLE_ACTION_SUCCESS =
  "TRANSACTIONS_TABLE_ACTION_SUCCESS";
export const TRANSACTIONS_TABLE_ACTION_FAILURE =
  "TRANSACTIONS_TABLE_ACTION_FAILURE";
export const TRANSACTIONS_REQUEST_LOADING_TRUE =
  "TRANSACTIONS_REQUEST_LOADING_TRUE";
export const TRANSACTIONS_REQUEST_LOADING_FALSE =
  "TRANSACTIONS_REQUEST_LOADING_FALSE";
export const GET_DISBURSEMENTS_TRANSACTIONS_SUCCESS =
  "GET_DISBURSEMENTS_TRANSACTIONS_SUCCESS";
export const GET_DISBURSEMENTS_TRANSACTIONS_FAILURE =
  "GET_DISBURSEMENTS_TRANSACTIONS_FAILURE";
export const GET_SMS_TRANSACTIONS_SUCCESS = "GET_SMS_TRANSACTIONS_SUCCESS";
export const GET_SMS_TRANSACTIONS_FAILURE = "GET_SMS_TRANSACTIONS_FAILURE";
export const GET_USSD_TRANSACTIONS_SUCCESS = "GET_USSD_TRANSACTIONS_SUCCESS";
export const GET_USSD_TRANSACTIONS_FAILURE = "GET_USSD_TRANSACTIONS_FAILURE";
export const GET_REMITA_TRANSACTIONS_SUCCESS =
  "GET_REMITA_TRANSACTIONS_SUCCESS";
export const GET_REMITA_TRANSACTIONS_FAILURE =
  "GET_REMITA_TRANSACTIONS_FAILURE";
export const GET_WITHDRAWAL_TRANSACTIONS_SUCCESS =
  "GET_WITHDRAWAL_TRANSACTIONS_SUCCESS";
export const GET_WITHDRAWAL_TRANSACTIONS_FAILURE =
  "GET_WITHDRAWAL_TRANSACTIONS_FAILURE";
export const UPDATE_TRANSACTIONS_DOWNLOAD_LIST =
  "UPDATE_TRANSACTIONS_DOWNLOAD_LIST";
export const UPDATE_DISBURSEMENTS_DOWNLOAD_LIST =
  "UPDATE_DISBURSEMENTS_DOWNLOAD_LIST";
export const UPDATE_REMITA_DOWNLOAD_LIST = "UPDATE_REMITA_DOWNLOAD_LIST";
export const UPDATE_USSD_DOWNLOAD_LIST = "UPDATE_USSD_DOWNLOAD_LIST";
export const UPDATE_SMS_DOWNLOAD_LIST = "UPDATE_SMS_DOWNLOAD_LIST";
export const UPDATE_WITHDRAWALS_DOWNLOAD_LIST =
  "UPDATE_WITHDRAWALS_DOWNLOAD_LIST";

// general action types
export const ERROR_401_ACTION = "ERROR_401_ACTION";
export const GET_BANKS_ACTION_SUCCESS = "GET_BANKS_ACTION_SUCCESS";
export const GET_BANKS_ACTION_FAILED = "GET_BANKS_ACTION_FAILED";
export const WITHDRAW_FUNDS_ACTION_SUCCESS = "WITHDRAW_FUNDS_ACTION_SUCCESS";
export const WITHDRAW_FUNDS_ACTION_FAILED = "WITHDRAW_FUNDS_ACTION_FAILED";
export const CREATE_ACCOUNT_ACTION_SUCCESS = "CREATE_ACCOUNT_ACTION_SUCCESS";
export const CREATE_ACCOUNT_ACTION_FAILED = "CREATE_ACCOUNT_ACTION_FAILED";
export const GET_USERID_ACTION_SUCCESS = "GET_USERID_ACTION_SUCCESS";
export const GET_USERID_ACTION_FAILED = "GET_USERID_ACTION_FAILED";

// lender tools types
export const ELIGIBILITY_LOADING_TRUE = "ELIGIBILITY_LOADING_TRUE";
export const ELIGIBILITY_LOADING_FALSE = "ELIGIBILITY_LOADING_FALSE";
export const GET_ELIGIBLE_USER_SUCCESS = "GET_ELIGIBLE_USER_SUCCESS";
export const GET_ELIGIBILITY_ACTION_SUCCESS = "GET_ELIGIBILITY_ACTION_SUCCESS";
export const GET_ELIGIBLE_USERS_SUCCESS = "GET_ELIGIBLE_USERS_SUCCESS";
export const GET_PAYING_EMPLOYERS_SUCCESS = "GET_PAYING_EMPLOYERS_SUCCESS";

// SMS Automation action types
export const CAMPAIGNS_LOADING_TRUE = "CAMPAIGNS_LOADING_TRUE";
export const CAMPAIGNS_LOADING_FALSE = "CAMPAIGNS_LOADING_FALSE";
export const CAMPAIGNS_REQUEST_LOADING_TRUE = "CAMPAIGNS_REQUEST_LOADING_TRUE";
export const CAMPAIGNS_REQUEST_LOADING_FALSE =
  "CAMPAIGNS_REQUEST_LOADING_FALSE";
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS";
export const GET_CAMPAIGNS_FAILED = "GET_CAMPAIGNS_FAILED";
export const OPEN_ADD_MANUALLY_MODAL = "OPEN_ADD_MANUALLY_MODAL";
export const CLOSE_ADD_MANUALLY_MODAL = "CLOSE_ADD_MANUALLY_MODAL";
export const OPEN_UPLOAD_CONTACTS_MODAL = "OPEN_UPLOAD_CONTACTS_MODAL";
export const CLOSE_UPLOAD_CONTACTS_MODAL = "CLOSE_UPLOAD_CONTACTS_MODAL";
export const OPEN_STAGED_PHONE_NUMBERS_MODAL =
  "OPEN_STAGED_PHONE_NUMBERS_MODAL";
export const CLOSE_STAGED_PHONE_NUMBERS_MODAL =
  "CLOSE_STAGED_PHONE_NUMBERS_MODAL";
export const UPLOAD_PHONE_NUMBERS_SUCCESS = "UPLOAD_PHONE_NUMBERS_SUCCESS";
export const GET_CAMPAIGNS_SUMMARY_SUCCESS = "GET_CAMPAIGNS_SUMMARY_SUCCESS";
export const GET_AMOUNT_SPENT_ON_CAMPAIGNS_LOADING_TRUE =
  "GET_AMOUNT_SPENT_ON_CAMPAIGNS_LOADING_TRUE";
export const GET_AMOUNT_SPENT_ON_CAMPAIGNS_LOADING_FALSE =
  "GET_AMOUNT_SPENT_ON_CAMPAIGNS_LOADING_FALSE";
export const GET_AMOUNT_SPENT_ON_CAMPAIGNS_SUCCESS =
  "GET_AMOUNT_SPENT_ON_CAMPAIGNS_SUCCESS";
export const GET_CAMPAIGN_TO_EDIT_SUCCESS = "GET_CAMPAIGN_TO_EDIT_SUCCESS";
export const RESET_CAMPAIGN_TO_EDIT = "RESET_CAMPAIGN_TO_EDIT";
export const GET_UPLOADED_CONTACTS_SUCCESS = "GET_UPLOADED_CONTACTS_SUCCESS";

// lending license
export const SHOW_LICENSE_UPLOAD_MODAL = "SHOW_LICENSE_UPLOAD_MODAL";
