import React, { useEffect } from "react";
import { PieChartOutlined } from "@ant-design/icons";
import { Doughnut, defaults } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardData } from "../../redux/actions/dashboardAction";
import { Box, Text } from "@chakra-ui/layout";
import { RootState } from "types";

defaults.plugins.legend.display = false;

export default function DoughnutChart({shouldShow = true}: {shouldShow?: boolean}) {
  const dashboardData = useSelector(
    (state: RootState) => state.dashboardReducer
  );
  const dispatch = useDispatch();
  const dataChart = dashboardData?.data?.data;
  const outStanding = shouldShow ? dataChart?.outstanding : 0;
  const repaid = shouldShow ? dataChart?.repaid : 0;
  let data = {
    labels: ["Outstanding", "Repaid"],
    responsive: true,
    datasets: [
      {
        label: "Portfolio data",
        data: [outStanding, repaid],
        backgroundColor: ["#060088", "#F9AC22"],
        hoverOffset: 3,
      },
    ],
  };

  let options = {
    cutout: "70%",
  };
  const xTag = localStorage.getItem("x_jwt");

  useEffect(() => {
    dispatch(getDashboardData(xTag));
  }, [dispatch, xTag]);

  if (!dataChart?.outstanding && !dataChart?.repaid) {
    return (
      <Box textAlign="center">
        <PieChartOutlined className="pie-icon" />
        <Text>No data</Text>
      </Box>
    );
  } else {
    return <Doughnut data={data} width={20} height={20} options={options} />;
  }
}
