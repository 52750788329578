import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  Box,
  Center,
  Text,
  Image,
  Heading,
} from "@chakra-ui/react";
import { ScrollToTopPoint } from "components/common/ScrollToTop";
import BusinessInformation from "components/forms/BusinessInformation";
import DirectorInformation from "components/forms/DirectorInformation";
// import { selectIsKYCModalOpen } from "redux/slices/settings";
// import { VeendHeading, VeendText } from "components/global";
import Logo from "assets/logo-white.png";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "types";
import { useHistory } from "react-router-dom";

function KycModal() {
  

  const [currentStep, setCurrentStep] = useState(0);
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const { settingData, isLoading } = setting;
  const history = useHistory()

  const handleCurrentStep = (num: 0 | 1) => {
    setCurrentStep(num);
  };

  useEffect(()=>{
    if(settingData.data?.isKYCDone){
      return history.replace('/dashboard')
    }
    if(settingData.data?.directorInfo?.isVerified){
      setCurrentStep(1)
    }

  },[settingData])

  return (
    <Modal
      isOpen={true}
      onClose={() => {}}
      isCentered={true}
      closeOnOverlayClick={true}
      preserveScrollBarGap={true}
      scrollBehavior="inside"
    >
      <ModalOverlay backdropBrightness={100} background="rgba(0,0,0,0.2)" />
      <ModalContent
        my={0}
        mx={{ base: 0, md: 4 }}
        h="100vh"
        w="full"
        maxW="1240px"
        borderTopRadius={{ base: "32px", md: "8px" }}
        borderBottomRadius={{ base: "0px", md: "8px" }}
        alignSelf={{ base: "flex-end", md: "center" }}
      >
        <ModalBody p={[4, 4, 6]}>
          <Flex h="full">
            <Flex
              flexDir="column"
              gap="36px"
              display={{ base: "none", md: "flex" }}
              maxW={{ md: "200px", lg: "295px" }}
              px={{ base: "20px", lg: "30px" }}
              py="60px"
              borderRadius="8px"
              bgColor="rgba(6, 0, 137, 0.8)"
              alignItems="flex-start"
              overflowY="auto"
              sx={{ "::-webkit-scrollbar": { display: "none" } }}
            >
              <Center p={3} borderRadius="4px" bgColor="white">
                <Text color="primary.500" fontWeight={500}>
                 
                    {`Step ${currentStep + 1}/2`}
                  
                </Text>
              </Center>

              <Box flex={1}>
                <Heading
                  color="white"
                  fontWeight={600}
                  fontSize={{ base: "18px", md: "20px", lg: "24px" }}
                  mb={4}
                >
                  
                    <>
                      {currentStep === 0 && "You’re almost there 😁"}
                      {currentStep === 1 && "And its a wrap!"}
                      {/* {currentStep === 1 && "You’re almost there 😁"} */}
                    </>
               
                </Heading>
                <Text color="white">
                 
                    <>
                      {currentStep === 0 &&
                        "It takes approximately 5 mins to complete this process"}
                      {/* {currentStep === 1 && */}
                      {/* "It takes approximately 5 mins to complete this process"} */}
                      {currentStep === 1 &&
                        "Please check through to ensure you have completed all requirements"}
                    </>
                  
                </Text>
              </Box>

              <Box>
                <Image src={Logo} alt="Veend Logo" maxW="120px" />
              </Box>
            </Flex>

            <ScrollToTopPoint dependency={currentStep} />

            <Box
              flex={1}
              overflowY="auto"
              py="24px"
              px={{ base: "0px", md: "30px", lg: "60px" }}
              sx={{ "::-webkit-scrollbar": { display: "none" } }}
            >
                <>
                  {currentStep === 0 && (
                    <DirectorInformation
                      handleCurrentStep={handleCurrentStep}
                      currentStep={currentStep}
                    />
                  )}
                  {currentStep === 1 && (
                    <BusinessInformation
                      handleCurrentStep={handleCurrentStep}
                      currentStep={currentStep}
                    />
                    
                  )}
                  {/* {currentStep === 2 && (
                    <ReviewVerificationSubmission
                      handleCurrentStep={handleCurrentStep}
                      currentStep={currentStep}
                    />
                  )} */}
                </>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default KycModal;
