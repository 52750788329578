import React from "react";
import moment from "moment";
import { Form, DatePicker } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import "./styles/InputFieldStyles.css";
import { Box, Text } from "@chakra-ui/layout";
import { Input } from "@chakra-ui/react";

const FormItem = Form.Item;

interface IDateInput {
  form: any;
  style: any;
  error: any;
  label: string;
  hasLabel: boolean;
}

const DateInput = ({
  error,
  form: {
    setFieldValue,
    values: { dateOfBirth },
  },
  label,
  hasLabel,
}: IDateInput) => {
  let wrapperClass = "form-group my-3";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  return (
    <Box>
      <FormItem className={wrapperClass}>
        {hasLabel && (
          <Box
            mb="1"
            d="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="label-div"
          >
            <Text className="form-label">{label}</Text>
          </Box>
        )}
        <Box my="2">
          <Input
            onChange={e => setFieldValue("dateOfBirth", e.target.value)}
            value={dateOfBirth || ""}
            className="date-picker"
            clearIcon={false}
            type="date"
          />
        </Box>
        {error && (
          <Box className="error-div">
            <ExclamationCircleFilled className="error-icon" />
            <Text ml="2" className="error-text">
              {error}
            </Text>
          </Box>
        )}
      </FormItem>
    </Box>
  );
};
export default DateInput;
