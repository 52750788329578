import { Box, Text } from "@chakra-ui/react";
import { DateTimeFormat } from "../constants";
import { Loan } from "types/responses";
import moment from "moment";

export const getDefaultColumnsData = (loan: Loan) => {
  return {
    key: loan._id,
    phoneNumber: loan.user?.phoneNumber,
    name: `${loan.user?.firstName} ${loan.user?.lastName}`,
    employer: loan.user?.employer,
    submitted: `${moment(loan.createdAt).format(DateTimeFormat)}`,
    principal: loan.amount,
    interest: loan.interestAmount,
    salaryAmount: loan.user?.salaryAmount,
    eligibleAmount: loan.eligibility?.amount,
    fee: loan.fee,
    installmentFee: loan.instalmentFee,
  };
};

export const defaultColumns = [
  {
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    render: (text: string) => (
      <Box onClick={e => e.stopPropagation()}>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Phone number
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "name",
    key: "name",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Name
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "employer",
    key: "employer",
    render: (text: string) => (
      <Box>
        <Text
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="bold"
          title={text}
        >
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Employer
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "salaryAmount",
    key: "salaryAmount",
    render: (text: string) => (
      <Box>
        <Text
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="bold"
          title={text}
        >
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Salary Amount
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "eligibleAmount",
    key: "eligibleAmount",
    render: (text: string) => (
      <Box>
        <Text
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="bold"
          title={text}
        >
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Eligible Amount
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "submitted",
    key: "submitted",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Submitted On
        </Text>
      </Box>
    ),
    className: "table-cell-display",
    sorter: (a: any, b: any) => a.submitted - b.submitted,
  },
  {
    dataIndex: "principal",
    key: "principal",
    render: (text: string, loans: any) => (
      <Box>
        <Text
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="bold"
          color={loans?.isTopUp === true ? "#060088" : "#000"}
          title={loans?.isTopUp === true ? "Top Up" : "New"}
        >
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Principal
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "interest",
    key: "interest",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Interest
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "fee",
    key: "fee",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Fee
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "installmentFee",
    key: "installmentFee",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Installment Fee
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
];
