import { Box, Heading, HStack, Text } from "@chakra-ui/react";
import React from "react";

interface LoanOptionCardProps {
  header: string;
  description: string;
  value: React.ReactNode;
  actionButtons?: React.ReactNode;
  headerActionButtons?: React.ReactNode;
}

const LoanOptionCard = ({
  header,
  description,
  value,
  actionButtons,
  headerActionButtons,
}: LoanOptionCardProps) => {
  return (
    <Box w="100%" className="loan-request-link">
      <HStack mb="4" justify="space-between">
        <Heading as="h4" fontSize={{ base: "xs", md: "sm" }} fontWeight="bold">
          {header}
        </Heading>

        {headerActionButtons ?? null}
      </HStack>
      <Text fontSize={{ base: "xx-small", md: "xs" }}>{description}</Text>
      <Box className="request-link">
        <Text
          fontWeight="semibold"
          fontSize={{ base: "xx-small", md: "xs" }}
          color="primaryBlue"
        >
          {value}
        </Text>
      </Box>
      {actionButtons ?? null}
    </Box>
  );
};

export default LoanOptionCard;
