//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Box, Button, Container } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import HelloSign from "hellosign-embedded";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { ERROR_401_ACTION } from "redux/actions/actionTypes";
import veendLogo from "../../assets/veend-logo.png";
import errorIcon from "../../assets/error_circle.png";
import { Link, Image, Text } from "@chakra-ui/react";
import CustomCard from "components/common/CustomCard";
import { RetryIcon } from "components/common/CustomIcons";
import { MainLoader } from "components/common";
import "./styles/auth.css";
import { getUserSettings } from "redux/actions/settingsActions";
import { saveSlaState } from "../../constants/helper";
import VerificationGuide from "components/common/VerificationGuide";
import { RootState } from "types";
import LendingLicenceChecker from "components/common/LendingLicenceChecker";

export default function SignSLA(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [err, setErr] = React.useState<"close" | "error" | undefined>();
  const [loading, setLoading] = React.useState(false);
  const [signed, setSigned] = React.useState(false);
  const [timerStart, setTimerStart] = useState(false);
  const client = new HelloSign();
  const xTag = localStorage.getItem("x_jwt");
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const { settingData, isLoading } = setting;

  const initSign = async () => {
    if (!xTag) return history.push("/login");
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/sign-sla`,
        null,
        {
          headers: {
            "x-tag": "veend",
            "x-jwt": xTag,
          },
        }
      );
      const { status, data } = response.data;
      setLoading(false);
      if (status !== "success") return null;
      client.open(data.url, {
        clientId: process.env.REACT_APP_HELLOSIGN_CLIENT,
        skipDomainVerification: true,
      });
    } catch (error) {
      setLoading(false);
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        }
        if (
          [
            "SLA signed already",
            "This request has already been signed",
          ].includes(error.response?.data?.message)
        ) {
          saveSlaState("Y");
          history.push("/dashboard");
          return;
        }
        setErr("error");
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };
  client.on("sign", data => {
    setSigned(true);
    message.success("Document signed");
  });
  client.on("finish", () => {
    setTimerStart(true);
  });

  client.on("error", () => {
    message.error("Process has been terminated");
    setErr("error");
  });

  client.on("close", () => {
    if (!signed) {
      setErr("closed");
    }
  });

  useEffect(() => {
    if (!isLoading && settingData.data.isKYCDone) {
      initSign();
    }
  }, [isLoading]);

  useEffect(() => {
    // attempt verification after 5sec
    let timerId: NodeJS.Timeout;
    if (timerStart) {
      timerId = setTimeout(() => {
        dispatch(getUserSettings(xTag));
        initSign();
      }, 5000);
    }
    return () => clearTimeout(timerId);
  }, [timerStart]);

  return (
    <VerificationGuide>
      {loading || timerStart ? (
        <Box bg="white">
          <MainLoader />
        </Box>
      ) : err ? (
        <Container maxW={"container.xl"}>
          <Box
            py={8}
            minH="100vh"
            display="flex"
            flexDir="column"
            justifyContent="center"
          >
            <Box display={"flex"} justifyContent="center">
              <Link href="https://veendhq.com" target="_blank" rel="noreferrer">
                <Image
                  src={veendLogo}
                  alt="veendHq"
                  className="login-logo-main"
                  width={200}
                />
              </Link>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              marginTop={5}
            >
              <CustomCard>
                <>
                  <Box
                    mb="8"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    {err === "error" && (
                      <Image
                        src={errorIcon}
                        alt="veendHq"
                        className="login-logo-main"
                        width={91}
                        mb="30px"
                      />
                    )}

                    <Text mb="2" fontSize="small" textAlign="center">
                      {err === "error"
                        ? " Sorry an error has occurred, and signing of the agreement document failed in the process. Please try again"
                        : "Application closed"}
                    </Text>
                  </Box>
                  <Box mt={5} display="flex" mb={2}>
                    <Button w="100%" onClick={initSign}>
                      <RetryIcon />
                      Retry
                    </Button>
                  </Box>
                </>
              </CustomCard>
            </Box>
          </Box>
        </Container>
      ) : (
        <></>
      )}
    </VerificationGuide>
  );
}
