import { extendTheme } from "@chakra-ui/react";
import { ButtonStyles as Button } from "./components/ButtonStyles";
import { InputStyles as Input } from "./components/InputStyles";

export const AppTheme = extendTheme({
  colors: {
    primaryBlue: "#060088",
    primaryYellow: "#F9AC22",
    mystic: "#e9eef1",
    whisper: "#ebebeb",
    white: "#fff",
  },
  fonts: {
    heading: "Montserrat",
    body: "Montserrat",
  },
  components: {
    Button,
    Input,
  },
});
