import React from "react";
import { PendingApprovalTable } from "components/tables";
import { LoanTabFilters } from "components/common";

export default function PendingApprovalTabPane() {
  return (
    <LoanTabFilters
      loanTab="all"
      loanStatus="active"
      processStatus="approved"
      disbursementStatus="completed"
      allLoans
    >
      <PendingApprovalTable />
    </LoanTabFilters>
  );
}
