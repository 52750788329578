import React from "react";
import { Box, Heading, Image } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import eligibilityToolBg from "../../assets/eligibility-tool-bg.png";

interface ToolsCardProps {
  title: string;
  redirectTo: string;
  bgColor: string;
  bgImage?: string;
}

const ToolsCard = ({ title, bgColor, redirectTo, bgImage }: ToolsCardProps) => {
  const history = useHistory();

  return (
    <Box
      ml="0"
      bg={bgColor}
      className="tools-card eligibility-card"
      onClick={() => history.push(redirectTo)}
    >
      <Heading as="h2" fontSize={{ base: "md", md: "lg", lg: "xl" }}>
        {title}
      </Heading>
      <Image
        src={bgImage || eligibilityToolBg}
        alt="thumbs-up-icon"
        className="eligibility-tool-img"
      />
    </Box>
  );
};

export default ToolsCard;
