import React, { useState } from "react";
import { PrimeLoansTable } from "components/tables";
import { Radio } from "antd";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { searchOverdueLoan } from "redux/actions/loanActions";
import { Input } from "antd";
import { LoanTabFilters } from "components/common";
import { Box } from "@chakra-ui/layout";

const { Search } = Input;

interface ISearchValues {
  searchQuery: string;
}

export default function PrimeLoansTabPane() {
  const [searchParam, setSearchParam] = useState("phoneNumber");
  const dispatch = useDispatch();
  const xJwt = localStorage.getItem("x_jwt");
  const onRadioToggle = (e: any) => {
    setSearchParam(e.target.value);
  };

  return (
    <LoanTabFilters
      loanTab="prime"
      loanStatus="closed"
      processStatus="disbursed"
      disbursementStatus="completed"
      searchContent={
        <Box>
          <Formik
            initialValues={{
              searchQuery: "",
            }}
            validationSchema={yup.object().shape({
              searchQuery: yup.string().required(),
            })}
            onSubmit={(values: ISearchValues) => {
              dispatch(
                searchOverdueLoan(xJwt, searchParam, values.searchQuery)
              );
            }}
          >
            {({ values, handleChange, submitForm }) => (
              <Form>
                <Search
                  placeholder="Search loans"
                  onChange={handleChange}
                  value={values.searchQuery}
                  name="searchQuery"
                  required
                  onSearch={submitForm}
                  enterButton
                  className="custom-search-input"
                />
              </Form>
            )}
          </Formik>
          <Box
            as={Radio.Group}
            onChange={onRadioToggle}
            value={searchParam}
            mt="2"
          >
            <Radio value="phoneNumber">Phone number</Radio>
            <Radio value="loanId">Loan ID</Radio>
          </Box>
        </Box>
      }
    >
      <PrimeLoansTable />
    </LoanTabFilters>
  );
}
