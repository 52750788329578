import {
  BalanceResponse,
  ChartResponse,
  OperationsResponse,
  PortfolioResponse,
} from "types/responses";
import {
  DASHBOARD_ACTION_SUCCESS,
  DASHBOARD_ACTION_FAILURE,
  BALANCE_ACTION_SUCCESS,
  BALANCE_ACTION_FAILURE,
  OPERATION_ACTION_SUCCESS,
  OPERATION_ACTION_FAILURE,
  TRANSACTIONS_TABLE_ACTION_SUCCESS,
  TRANSACTIONS_TABLE_ACTION_FAILURE,
  EXPECTED_CHART_ACTION_SUCCESS,
  EXPECTED_CHART_ACTION_FAILURE,
  NEW_CLIENT_CHART_ACTION_SUCCESS,
  NEW_CLIENT_CHART_ACTION_FAILURE,
  INCOME_CHART_ACTION_SUCCESS,
  INCOME_CHART_ACTION_FAILURE,
  DISBURSE_CHART_ACTION_SUCCESS,
  DISBURSE_CHART_ACTION_FAILURE,
  DASH_REQUEST_LOADING_TRUE,
  DASH_REQUEST_LOADING_FALSE,
} from "../actions/actionTypes";

export interface DashboardState {
  isLoading: boolean;
  data: PortfolioResponse;
  balanceData: BalanceResponse;
  operationData: OperationsResponse;
  transactionData: ChartResponse;
  expectedChartData: ChartResponse;
  newClientData: ChartResponse;
  incomeData: ChartResponse;
  disburseData: ChartResponse;
  requestLoading: boolean;
}

const initialState: DashboardState = {
  isLoading: true,
  data: {} as PortfolioResponse,
  balanceData: {} as BalanceResponse,
  operationData: {} as OperationsResponse,
  transactionData: {} as ChartResponse,
  expectedChartData: {} as ChartResponse,
  newClientData: {} as ChartResponse,
  incomeData: {} as ChartResponse,
  disburseData: {} as ChartResponse,
  requestLoading: false,
};

function dashboardReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case DASHBOARD_ACTION_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
      };
    case DASHBOARD_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case BALANCE_ACTION_SUCCESS:
      return {
        ...state,
        balanceData: payload,
        isLoading: false,
      };
    case BALANCE_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case OPERATION_ACTION_SUCCESS:
      return {
        ...state,
        operationData: payload,
        isLoading: false,
      };
    case OPERATION_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case TRANSACTIONS_TABLE_ACTION_SUCCESS:
      return {
        ...state,
        transactionData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case TRANSACTIONS_TABLE_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestLoading: false,
      };
    case EXPECTED_CHART_ACTION_SUCCESS:
      return {
        ...state,
        expectedChartData: payload,
        isLoading: false,
      };
    case EXPECTED_CHART_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case NEW_CLIENT_CHART_ACTION_SUCCESS:
      return {
        ...state,
        newClientData: payload,
        isLoading: false,
      };
    case NEW_CLIENT_CHART_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case INCOME_CHART_ACTION_SUCCESS:
      return {
        ...state,
        incomeData: payload,
        isLoading: false,
      };
    case INCOME_CHART_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case DISBURSE_CHART_ACTION_SUCCESS:
      return {
        ...state,
        disburseData: payload,
        isLoading: false,
      };
    case DISBURSE_CHART_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case DASH_REQUEST_LOADING_TRUE:
      return {
        ...state,
        requestLoading: true,
      };
    case DASH_REQUEST_LOADING_FALSE:
      return {
        ...state,
        requestLoading: false,
      };
    default:
      return state;
  }
}

export default dashboardReducer;
