import "../common/styles/inputotp.css";
import ReactOTP from "react-otp-input";
import React, { FC, useState, useEffect } from "react";

type OtpProps = {
  numInputs?: number;
  onComplete?: (otp: string) => void;
  onChange?: (pin: string) => void;
  disable?: boolean;
  isInputSecure?: boolean;
};

const OTPInput: FC<OtpProps> = props => {
  const [otp, setOtp] = useState("");
  const { onChange, numInputs, onComplete, disable, isInputSecure } = props;

  useEffect(() => {
    if (otp.length === numInputs) {
      onComplete?.(otp);
    }
  }, [otp, numInputs]);

  const handleChange = (otp: string) => {
    onChange?.(otp);
    setOtp(otp);
  };
  return (
    <section>
      <ReactOTP
        value={otp}
        shouldAutoFocus
        isDisabled={disable}
        isInputNum={true}
        numInputs={numInputs}
        onChange={handleChange}
        containerStyle={"otp"}
        isInputSecure={isInputSecure}
        inputStyle={"otp__input"}
        focusStyle={"otp__input_focused"}
      />
    </section>
  );
};
export default OTPInput;
