import {
  EligibileUsersResponse,
  PayingEmployersResponse,
  ProcessedEligibilitiesResponse,
  UserEligibilityResponse,
} from "types/responses";
import {
  ELIGIBILITY_LOADING_TRUE,
  ELIGIBILITY_LOADING_FALSE,
  GET_ELIGIBILITY_ACTION_SUCCESS,
  GET_ELIGIBLE_USER_SUCCESS,
  GET_ELIGIBLE_USERS_SUCCESS,
  GET_PAYING_EMPLOYERS_SUCCESS,
} from "../actions/actionTypes";

export interface ToolsState {
  eligibilityData: ProcessedEligibilitiesResponse;
  eligibleUserData: UserEligibilityResponse["data"];
  eligibleBorrowersList: EligibileUsersResponse["data"];
  payingEmployersList: PayingEmployersResponse["data"];
  requestLoading: boolean;
}

const initialState: ToolsState = {
  eligibilityData: {} as ProcessedEligibilitiesResponse,
  eligibleUserData: {} as UserEligibilityResponse["data"],
  eligibleBorrowersList: [],
  payingEmployersList: [],
  requestLoading: false,
};

function toolsReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case ELIGIBILITY_LOADING_TRUE:
      return {
        ...state,
        requestLoading: true,
      };
    case ELIGIBILITY_LOADING_FALSE:
      return {
        ...state,
        requestLoading: false,
      };
    case GET_ELIGIBILITY_ACTION_SUCCESS:
      return {
        ...state,
        eligibilityData: payload,
      };
    case GET_ELIGIBLE_USER_SUCCESS:
      return {
        ...state,
        eligibleUserData: payload,
      };
    case GET_ELIGIBLE_USERS_SUCCESS:
      return {
        ...state,
        eligibleBorrowersList: payload,
        requestLoading: false,
      };
    case GET_PAYING_EMPLOYERS_SUCCESS:
      return {
        ...state,
        payingEmployersList: payload,
        requestLoading: false,
      };
    default:
      return state;
  }
}

export default toolsReducer;
