import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MainLoader, ResultStatus } from "components/common";
import { verifyEmail } from "redux/actions/authActions";
import { Box, Text } from "@chakra-ui/layout";
import { RootState } from "types";

const VerifyMail = () => {
  const dispatch = useDispatch();
  const verifyEmailState = useSelector(
    (state: RootState) => state?.authReducer
  );
  const verifyMailStateSuccess = verifyEmailState?.verifyEmailSuccess;
  const verifyMailStateFailed = verifyEmailState?.verifyEmailFailed;

  const goToLoginPage = () => {
    window.location.href = "/login";
  };

  const renderResult = () => {
    if (verifyMailStateSuccess === true) {
      return (
        <ResultStatus
          status="success"
          title="Email verified successfully"
          subTitle=" "
          btnText="Go to Login"
          redirect={goToLoginPage}
        />
      );
    } else if (verifyMailStateFailed === true) {
      return (
        <ResultStatus
          status="error"
          title="Email verification failed"
          subTitle="An unexpected error occured"
          btnText="Try Again"
          redirect={() => window.location.reload()}
        />
      );
    }
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  const handleVerifyEmail = async () => {
    let data = {
      email: query.get("email"),
      validationCode: query.get("validationCode"),
    };
    dispatch(verifyEmail(data));
  };

  React.useEffect(() => {
    handleVerifyEmail();
  }, []); //eslint-disable-line

  return (
    <>
      {!verifyMailStateFailed && !verifyMailStateSuccess && (
        <Box d="grid" h="100vh" placeItems="center" px="4">
          <Box textAlign="center" w={["100%", "100%", "320px"]}>
            <MainLoader />
            <Text>Verifying email address, kindly wait.</Text>
          </Box>
        </Box>
      )}

      {renderResult()}
    </>
  );
};

export default VerifyMail;
