import React from "react";
import { PendingDisbursementTable } from "components/tables";
import { LoanTabFilters } from "components/common";

export default function PendingDisbursementTabPane() {
  return (
    <LoanTabFilters
      loanTab="approved"
      loanStatus="approved"
      processStatus="approved"
      disbursementStatus="processing"
    >
      <PendingDisbursementTable />
    </LoanTabFilters>
  );
}
