import { CopyIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "types";

type Props = {};

const AddManualQr = (props: Props) => {
  const user2FAData = useSelector(
    (state: RootState) => state.authReducer.twoFAInfo
  );

  return (
    <Box>
      <Flex
        flexDir="column"
        alignItems="center"
        px={{ base: "0", md: "20px", lg: "40px" }}
      >
        <Text
          mb="16px"
          fontWeight="600"
          textAlign="center"
          fontSize={["20px", "20px", "24px", "28px"]}
        >
          Add Manually
        </Text>
        <Text type="02" mb="50px" textAlign="center">
          Click on the setup key option in your Google Authenticator, and paste
          the keys you have below to start generating tokens
        </Text>
        <Flex
          gap={2}
          w="100%"
          p={"10px"}
          bg="neutral.50"
          borderRadius="4px"
          alignItems={"center"}
          justifyContent="space-between"
          border="1px solid neutral.50"
        >
          <Text fontSize="16px" fontWeight="600" color={"#060089"}>
            {String(user2FAData?.secret)}
          </Text>
          <IconButton
            aria-label=""
            width="24px"
            height="24px"
            as={CopyIcon}
            bgColor="transparent"
            text={user2FAData?.secret || ""}
            _hover={{ bgColor: "transparent" }}
          />
        </Flex>
      </Flex>
      <Box h="80px" />
    </Box>
  );
};

export default AddManualQr;
