import React from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Button, Heading, Text, Box } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { InputField } from "components/common";
import { loginUser } from "redux/actions/authActions";
import { RootState } from "types";

interface IError401ModalProps {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
}

interface IReloginFormValues {
  password: string;
}

export default function Error401Modal({
  showModal,
  setShowModal,
}: IError401ModalProps) {
  const dispatch = useDispatch();
  const authState = useSelector((state: RootState) => state.authReducer);
  const isLoading = authState?.isLoading;


  const goToLoginPage = () => {
    localStorage.clear();
    window.location.href = "/login";
  };
  const handleCancel = () => {
    setShowModal(false);
    goToLoginPage()
  };
  const email = localStorage.getItem("email");
  return (
    <Modal
      visible={showModal}
      onCancel={handleCancel}
      footer={null}
      width="50%"
      className="loan-config-modal"
    >
      <Box textAlign="center">
        <Heading as="h2" mb="3" fontSize={{ base: "xs", md: "sm", lg: "md" }}>
          Session Expired - Please login again
        </Heading>
        <Text fontSize={{ base: "xs", md: "sm" }} fontWeight="medium">
          enter password for this email:{" "}
          <Text
            as="span"
            fontWeight="semibold"
            fontStyle="italic"
          >{`${email}`}</Text>
        </Text>
      </Box>
      <Formik
        initialValues={{
          password: "",
        }}
        onSubmit={(values: IReloginFormValues) => {
          if (email !== null) {
            dispatch(loginUser(email, values.password));
          }
        }}
        validationSchema={yup.object().shape({
          password: yup.string().required("Please enter your password"),
        })}
      >
        {({ values, handleChange, handleBlur, errors }) => (
          <Box as={Form} textAlign="center">
            <InputField
              inputType="password"
              name="password"
              placeholder="Enter password"
              value={values.password}
              onBlur={() => handleBlur}
              onChange={handleChange}
              error={errors.password}
              required
            />

            <Button type="submit" mt="2" isLoading={isLoading}>
              Login
            </Button>
            <Text
              color="primaryBlue"
              fontSize={{
                base: "xs-small",
                md: "xs",
                lg: "sm",
              }}
              align="center"
              mt="4"
              fontWeight="semibold"
              cursor="pointer"
              onClick={goToLoginPage}
            >
              Login with a different email
            </Text>
          </Box>
        )}
      </Formik>
    </Modal>
  );
}
