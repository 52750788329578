import React, { useState } from "react";
import { Step } from "react-joyride";
import { Box, Heading, Text } from "@chakra-ui/react";
import { OnboardingToggler, OnboardingTourguide } from ".";

interface DashboardHeaderProps {
  title: string;
  description?: string;
  tourSteps?: Step[];
  actionButtons?: React.ReactNode;
}

const DashboardHeader = ({
  title,
  description,
  tourSteps,
  actionButtons,
}: DashboardHeaderProps) => {
  const [runTour, setRunTour] = useState(false);

  return (
    <>
      {tourSteps ? (
        <OnboardingTourguide
          steps={tourSteps}
          run={runTour}
          setRun={setRunTour}
        />
      ) : null}

      <Box className="loan-topper">
        <Box className="loan-top-text">
          <Heading as="h2" mb="2" fontSize="md" color="primaryBlue">
            {title}
          </Heading>

          {description ? <Text fontSize="xs">{description}</Text> : null}
        </Box>

        <Box className="button-cont">
          {actionButtons ?? null}

          {tourSteps ? (
            <OnboardingToggler checked={runTour} setChecked={setRunTour} />
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default DashboardHeader;
