import axios from "axios";
import { Moment } from "moment";
import {
  REPAYMENTS_ACTION_SUCCESS,
  REPAYMENTS_ACTION_FAILURE,
  ERROR_401_ACTION,
  UPDATE_REPAYMENTS_DOWNLOAD_LIST,
  REQUEST_LOADING_TRUE,
} from "./actionTypes";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getRepaymentsData =
  (xJwt: string | null, from?: string | Moment, to?: string | Moment) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });

      const res = await axios.get(
        `${baseUrl}/loans/repayments?limit=9999${from ? `&from=${from}` : ""}${
          to ? `&to=${to}` : ""
        }`,
        config
      );

      dispatch({
        type: REPAYMENTS_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_REPAYMENTS_DOWNLOAD_LIST,
        payload: res?.data?.data?.repayments,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REPAYMENTS_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };
