import React, { useState } from "react";
import { Tabs } from "antd";
import { Button, Box } from "@chakra-ui/react";
// import { AddBorrowerForm } from "../forms";
import { BorrowersTable } from "../tables";
import "./styles/borrowerTab.css";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import {
  SearchBorrowersInput,
  BorrowerDateRangeWrapper,
} from "components/common";
import { Radio } from "antd";
import { DownOutlined, DownloadOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import { RootState } from "types";

const { TabPane } = Tabs;

export default function BorrowerTab() {
  const [searchParam, setSearchParam] = useState("phoneNumber");
  const borrowerState = useSelector(
    (state: RootState) => state.borrowerReducer
  );
  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const userData = useSelector(
    (state: RootState) => state.authReducer.loginDataTwo
  );
  const info = settingsState?.settingData?.data?.info;
  const download_list = borrowerState?.borrowersDownloadList;
  const isLoading = borrowerState?.requestLoading;
  const isListEmpty = isEmpty(download_list);
  const [showDateRange, setShowDateRange] = useState(false);
  const toggleDateRange = () => {
    showDateRange ? setShowDateRange(false) : setShowDateRange(true);
  };
  const onRadioToggle = (e: any) => {
    setSearchParam(e.target.value);
  };

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Borrowers" key="1">
        <Box className="borrowers-table-box">
          <Box py="3" d="flex" flexDir="row-reverse">
            <Button
              variant="action"
              rightIcon={<DownloadOutlined />}
              isDisabled={isListEmpty || isLoading}
              className="download-borrowers-btn"
            >
              {isListEmpty ? (
                "Download"
              ) : (
                <CSVLink
                  data={download_list}
                  filename={`${
                    info?.businessName || userData?.businessName || ""
                  }_VeendHQAutoLending.borrowers.csv`}
                  target="_blank"
                  className="no-hover"
                >
                  Download
                </CSVLink>
              )}
            </Button>
          </Box>
          <Box className="transactions-table-header">
            <Box className="search-borrowers-container">
              <SearchBorrowersInput
                searchField={searchParam}
                isDisabled={showDateRange}
              />
              <Box
                as={Radio.Group}
                onChange={onRadioToggle}
                value={searchParam}
                mt="2"
              >
                <Radio value="phoneNumber">Phone number</Radio>
              </Box>
              <Box
                as={Radio.Group}
                onChange={onRadioToggle}
                value={searchParam}
                mt="2"
              >
                <Radio value="employer">Employer</Radio>
              </Box>
            </Box>
            <Box className="table-btns">
              <DownOutlined
                className={
                  showDateRange
                    ? "date-range-toggle-arrow arrow-up"
                    : "date-range-toggle-arrow"
                }
              />
              {/* eslint-disable-next-line */}
              <a onClick={toggleDateRange} className="date-range-toggle">
                set date range
              </a>
            </Box>
          </Box>
          {showDateRange ? <BorrowerDateRangeWrapper /> : null}
          <BorrowersTable />
        </Box>
      </TabPane>
      {/* <TabPane tab="Add Borrower" key="2">
        <Box className="borrower-details">
          <AddBorrowerForm />
        </Box>
      </TabPane> */}
    </Tabs>
  );
}
