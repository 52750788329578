import React from "react";
import { Text, Button, Box } from "@chakra-ui/react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { InputField } from "components/common";
import { forgotPasswordRequest } from "redux/actions/authActions";
import { AuthFormWrapper } from "components/common";
import "./styles/auth.css";

interface Values {
  email: string;
}
export default function ForgotPassword() {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = React.useState(false);

  const postResetPassword = (values: Values) => {
    setSubmitting(true);
    let data = {
      email: values.email,
    };
    return dispatch(forgotPasswordRequest(data));
  };
  return (
    <Box d="grid" h="100vh" placeItems="center" px="4">
      <Box textAlign="center" w={["100%", "100%", "350px"]}>
        <AuthFormWrapper
          header="Reset Your Password"
          description={
            <Text
              fontSize={{ base: "xs", lg: "sm", xl: "sm" }}
              fontWeight="medium"
            >
              Enter your email address and {`we'll`} send you the instructions
              to reset your password
            </Text>
          }
          footerLinks={[
            { title: "Nevermind! Take me back to Sign in", to: "/login" },
          ]}
        >
          <Formik
            initialValues={{ email: "" }}
            onSubmit={async (values: Values) => {
              setSubmitting(true);
              postResetPassword(values);
              setSubmitting(false);
            }}
            validationSchema={yup.object().shape({
              email: yup
                .string()
                .email()
                .required("Please provide your email address"),
            })}
          >
            {({ values, handleChange, handleBlur, errors }) => (
              <Form>
                <InputField
                  inputType="text"
                  name="email"
                  placeholder="Enter Email Address"
                  value={values.email}
                  onBlur={() => handleBlur}
                  onChange={handleChange}
                  error={errors.email}
                />

                <Button
                  type="submit"
                  variant="primary"
                  size="md"
                  mt="1"
                  isLoading={submitting}
                >
                  Reset Password
                </Button>
              </Form>
            )}
          </Formik>
        </AuthFormWrapper>
      </Box>
    </Box>
  );
}
