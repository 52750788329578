import React, { useState } from "react";
import { RemitaTransactionsTable } from "./../tables";
import { DownOutlined } from "@ant-design/icons";
import {
  TransactionsDateRangeWrapper,
  DownloadTransactions,
} from "./../common";
import { useSelector } from "react-redux";
import { Box } from "@chakra-ui/layout";
import { RootState } from "types";

export default function RemitaTransactionsTabPane() {
  const settingState = useSelector((state: RootState) => state.settingsReducer);
  const userSetting = settingState?.settingData?.data;
  const accountId = userSetting?.apiCredentials?.id;
  const [showDateRange, setShowDateRange] = useState(false);
  const toggleDateRange = () => {
    showDateRange ? setShowDateRange(false) : setShowDateRange(true);
  };

  return (
    <Box>
      <Box className="transactions-table-header">
        <Box d="flex" alignItems="center">
          <DownOutlined
            className={
              showDateRange
                ? "date-range-toggle-arrow arrow-up"
                : "date-range-toggle-arrow"
            }
          />
          {/* eslint-disable-next-line */}
          <a onClick={toggleDateRange} className="date-range-toggle">
            set date range
          </a>
        </Box>
        <DownloadTransactions tab="remita" />
      </Box>
      {showDateRange ? (
        <TransactionsDateRangeWrapper accountId={accountId} tab="remita" />
      ) : null}
      <RemitaTransactionsTable />
    </Box>
  );
}
