import { digitRegExp, phoneRegExp } from "./index";
import * as yup from "yup";

// validation object shapes for forms throughout the app
const validationSchema = {
  lenderSignup: {
    firstName: yup.string().required("Please provide your first name"),
    lastName: yup.string().required("Please provide your last name"),
    // businessName: yup.string().required("Please provide your business name"),
    email: yup.string().email().required("Please provide your email address"),
    phoneNumber: yup
      .string()
      .required("Please provide your phone number")
      .matches(phoneRegExp, "phone number is not valid")
      .min(8, "number too short")
      .max(15, "number too long"),
    password: yup.string().required("Please provide your password"),
    // confirmPassword: yup
    // .string()
    // .oneOf([yup.ref("password"), null], "Passwords must match")
    // .required()
    // .label("Confirm Password"),
  },
  contactInfoStep: {
    firstName: yup.string().required("Please provide your first name"),
    lastName: yup.string().required("Please provide your last name"),
    email: yup.string().email().required("Please provide your email address"),
    dateOfBirth: yup.date().required("Please select a date").nullable(),
    mobileNumber: yup
      .string()
      .required("Please provide your Mobile number")
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(9, "number too short")
      .max(15, "number too long"),
    bvn: yup
      .string()
      .required("Please provide your BVN")
      .matches(phoneRegExp, "BVN is not valid")
      .min(11, "BVN cannot be less than 11 digits")
      .max(11, "BVN cannot be more than 11 digits"),
  },
  companyInfoStep: {
    companyName: yup.string().required("Please provide your company name"),
    RCnumber: yup.string().required("Please provide your RC number"),
    monthlyDisbursement: yup
      .string()
      .required("Please provide your project monthly disbursement"),
    companyCity: yup.string().required("Please provide your company city"),
    companyAddress: yup
      .string()
      .required("Please provide your company address"),
    country: yup.mixed().required("Please select a country"),
    state: yup.mixed().required("Please select a state"),
    password: yup.string().required("Please enter a password"),
    confirmPassword: yup.string().required("Please confirm your password"),
  },
  bankInfoStep: {
    bankAccountNumber: yup
      .string()
      .required("Please provide your bank account number"),
    bank: yup.string().required("Please select your bank"),
  },
  addBorrower: {
    firstName: yup.string().required("Please provide your first name"),
    lastName: yup.string().required("Please provide your last name"),
    dateOfBirth: yup.date().required("Please select a date").nullable(),
    email: yup.string().email().required("Please provide your email address"),
    mobileNumber: yup
      .string()
      .required("Please provide your Mobile number")
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(9, "number too short")
      .max(15, "number too long"),
    alternateMobileNumber: yup
      .string()
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(9, "number too short")
      .max(15, "number too long"),
    address: yup.string().required("Please provide your Address"),
    // country: yup.mixed().required("Please select your country"),
    // state: yup.mixed().required("Please select your state"),
    nextOfKinName: yup.string().required("Please provide next of kin name"),
    nextOfKinNumber: yup
      .string()
      .required("Please provide next of kin number")
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(9, "number too short")
      .max(15, "number too long"),
    nextOfKinAddress: yup.string(),
    nextOfKinEmail: yup
      .string()
      .email()
      .required("Please provide your next of kin email address"),
    accountNumber: yup
      .string()
      .required("Please provide your account number")
      .matches(digitRegExp, "Account number is not valid")
      .min(10, "number too short")
      .max(10, "number too long"),
    bankName: yup.mixed().required("Please select your bank"),
    bvn: yup
      .string()
      .required("Please provide bank verification number")
      .matches(phoneRegExp, "bvn is not valid")
      .min(11, "number too short")
      .max(11, "number too long"),
    employerName: yup.string().required("Please provide employer name"),
    employerIndustry: yup.mixed(),
    employerAddress: yup.string().required("Please provide employer address"),
    // employerCountry: yup.mixed().required("Please select employer country"),
    // employerState: yup.mixed().required("Please select employer state"),
    employerNumber: yup
      .string()
      .required("Please provide employer number")
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(9, "number too short")
      .max(15, "number too long"),
    employerEmail: yup
      .string()
      .email()
      .required("Please provide your employer email address"),
    salaryAmount: yup
      .string()
      .required("Please provide your salary amount")
      .matches(phoneRegExp, "Salary Amount is not valid"),
    salaryPaymentDate: yup.date().required("Please select a date").nullable(),
  },
  loanProfile: {
    firstName: yup.string().required("Please provide your first name"),
    lastName: yup.string().required("Please provide your last name"),
    email: yup.string().email().required("Please provide your email address"),
    mobileNumber: yup
      .string()
      .required("Please provide your Mobile number")
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(9, "number too short")
      .max(15, "number too long"),
    nextofKinName: yup.string().required("Please provide next of kin name"),
    nextofKinAddress: yup
      .string()
      .required("Please provide next of kin address"),
    nextofKinNumber: yup
      .string()
      .required("Please provide next of kin number")
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(9, "number too short")
      .max(15, "number too long"),
    employerName: yup.string().required("Please provide employer name"),
    employerCategory: yup.string().required("Please provide employer category"),
    employerAddress: yup.string().required("Please provide employer address"),
    employerNumber: yup
      .string()
      .required("Please provide employer number")
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(9, "number too short")
      .max(15, "number too long"),
    salaryPaymentDate: yup
      .string()
      .required("Please provide salary payment date"),
    salaryAmount: yup.string().required("Please provide salary amount"),
  },
  generalSettings: {
    domainName: yup.string(),
    caption: yup.string(),
    mobileNumber: yup
      .string()
      .required("Please provide your mobile number")
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(9, "number too short")
      .max(15, "number too long"),
    email: yup.string().email().required("Please provide your email address"),
    title: yup.string(),
    decisionEngine: yup.boolean(),
  },
  loanProductConfig: {
    productName: yup.string().required("Please enter a product name"),
    defaultInterestRate: yup
      .number()
      .max(100)
      .min(0)
      .required("Please enter default interest rate"),
    // maxInterestRate: yup
    //   .number()
    //   .max(100)
    //   .min(1)
    //   .required("Please enter max interest rate"),
    minInterestRate: yup
      .number()
      .max(100)
      .min(0)
      .required("Please enter min interest rate"),
    defaultTenure: yup
      .number()
      .max(12)
      .min(1)
      .required("Please enter default tenure"),
    maxTenure: yup.number().max(12).min(1).required("Please enter max tenure"),
    minTenure: yup.number().max(12).min(1).required("Please enter min tenure"),
    defaultAmount: yup.number().required("Please enter default amount"),
    maxAmount: yup.number().required("Please enter max amount"),
    minAmount: yup.number().required("Please enter min amount"),
  },
  loanOfferConfig: {
    interestRate: yup
      .number()
      .max(100)
      .min(1)
      .required("Please enter interest rate"),
    tenure: yup.number().max(12).min(1).required("Please enter tenure"),
    maxAmount: yup.number().required("Please enter max amount"),
    minAmount: yup.number().required("Please enter min amount"),
  },
  decisionRules: {
    minAmount: yup
      .string()
      .required("Please enter min amount")
      .matches(digitRegExp, "Please enter a valid figure"),
    maxAmount: yup
      .string()
      .required("Please enter max amount")
      .matches(digitRegExp, "Please enter a valid figure"),
    consecutiveSalaries: yup
      .string()
      .required("Please provide consecutive salaries")
      .matches(digitRegExp, "Please enter a valid figure"),
    pastMonthIncome: yup
      .string()
      .required("Please provide past monthly income")
      .matches(digitRegExp, "Please enter a valid figure"),
    employer: yup.string(),
  },
  createFundingAccount: {
    BVN: yup
      .string()
      .required("Please provide Bank Verification Number")
      .matches(digitRegExp, "BVN is not valid")
      .min(11, "number too short")
      .max(11, "number too long"),
    rcNumber: yup
      .string()
      .required("Please provide your RC Number")
      .matches(digitRegExp, "RC Number is not valid"),
    address: yup.string().required("Please provide your Address"),
    addressState: yup.string().required("Please provide your Address State"),
  },
  preapproveBorrower: {
    preApprovedAmount: yup
      .string()
      .required("Please provide pre-approved amount")
      .matches(digitRegExp, "Amount not valid"),
    firstName: yup.string().required("Please provide borrower First Name"),
    lastName: yup.string().required("Please provide borrower Last Name"),
    email: yup.string().email(),
    salaryPaymentDate: yup.date().required("Please select a date").nullable(),
    bvn: yup
      .string()
      .required("Please provide Bank Verification Number")
      .matches(digitRegExp, "BVN is not valid")
      .min(11, "number too short")
      .max(11, "number too long"),
    accountNumber: yup
      .string()
      .required("Please provide borrower account number")
      .matches(digitRegExp, "Account number is not valid")
      .min(10, "number too short")
      .max(10, "number too long"),
    salaryAmount: yup
      .string()
      .required("Please provide salary amount")
      .matches(digitRegExp, "Amount is not valid"),
    bank: yup.object({ value: yup.string().required("Please select a bank") }),
    employer: yup.string(),
  },
  campaignContent: {
    name: yup.string().trim().required("Campaign name is required"),
    amount: yup
      .number()
      .positive("Invalid input")
      .integer("Invalid input")
      .max(5000, "Number of messages at a time cannot be more than 5000")
      .required("Number of messages at a time is required"),
    message: yup.string().trim().required("Message is required"),
  },
  campaignSchedule: {
    frequency: yup
      .string()
      .transform(obj => obj?.value)
      .required("Frequency is required"),
    isContinous: yup.boolean().required(),
    startDate: yup
      .date()
      .nullable()
      .required("Please choose a start date for your campaign"),
    endDate: yup
      .date()
      .nullable()
      .when("isContinous", {
        is: false,
        then: yup
          .date()
          .nullable()
          .required("Please choose the end date for your campaign"),
      })
      .when("startDate", (startDate, schema) => {
        if (startDate) {
          const dayAfter = new Date(startDate.getTime() + 86400000);

          return schema.min(
            dayAfter,
            "End date must be greater than the start date"
          );
        }

        return schema;
      }),
    selectedDays: yup
      .array()
      .nullable()
      .when("frequency", {
        is: "custom",
        then: yup
          .array()
          .min(1, "Please choose at least a day")
          .required("Please choose at least a day"),
      }),
  },
};

export default validationSchema;
