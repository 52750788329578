import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTransactions,
  getMoreTransactions,
} from "redux/actions/transActions";
import moment from "moment";
import { formatNumberAmount } from "helpers/utilFunctions";
import { Link, Text, Box } from "@chakra-ui/react";
import { popLastId } from "helpers/utilFunctions";
import { DateTimeFormat } from "../../constants";
import { Table } from "antd";
import { Loader } from "components/common";
import { RootState } from "types";

const columns = [
  {
    dataIndex: "transactionAmount",
    key: "transactionAmount",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Transaction Amount
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "narration",
    key: "narration",
    ellipsis: true,
    render: (text: string) => (
      <Box>
        <Text
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="bold"
          title={text}
        >
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Narration
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "customerFee",
    key: "customerFee",
    render: (text: string) => (
      <Box>
        <Text
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="bold"
          title={text}
        >
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Customer fee
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "merchantFee",
    key: "merchantFee",
    ellipsis: true,
    render: (text: string) => (
      <Box>
        <Text
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="bold"
          title={text}
        >
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Merchant fee
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "previousBalance",
    key: "previousBalance",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Previous Balance
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "newBalance",
    key: "newBalance",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          New Balance
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "transactionDate",
    key: "transactionDate",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Transaction Date
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "transactionReference",
    key: "transactionReference",
    ellipsis: true,
    render: (text: string) => (
      <Box>
        <Text
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="bold"
          title={text}
        >
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Transaction Reference
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "id",
    key: "id",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          ID
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "status",
    key: "status",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Status
        </Text>
      </Box>
    ),
  },
];

export default function TransactionsTable() {
  const dispatch = useDispatch();
  const transactions = useSelector(
    (state: RootState) => state.transactionReducer
  );
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const userSetting = setting?.settingData?.data;
  const accountId = userSetting?.apiCredentials?.id;
  //61512e41ac43b4002907ca32
  const transaction = transactions?.allTransactionsData?.data;
  const loading = transactions?.requestLoading;
  const [lastIds, setLastIds] = useState<(string | null)[]>([null]);
  const xJwt = localStorage.getItem("tx_jwt");

  useEffect(() => {
    if (accountId) {
      dispatch(getAllTransactions(xJwt, accountId));
    }
  }, [dispatch, accountId, xJwt]);

  useEffect(() => {
    if (
      transactions?.allTransactionsData !== undefined &&
      transactions?.allTransactionsData?.lastId !== undefined
    ) {
      setLastIds([...lastIds, transactions?.allTransactionsData?.lastId]);
    }
  }, [transactions?.allTransactionsData]); //eslint-disable-line

  const loadNextPage = () => {
    dispatch(
      getMoreTransactions(
        xJwt,
        accountId,
        transactions?.allTransactionsData?.lastId
      )
    );
  };

  const loadPrevPage = () => {
    if (lastIds.length === 3) {
      dispatch(getAllTransactions(xJwt, accountId));
      setLastIds([null]);
    } else {
      dispatch(
        getMoreTransactions(xJwt, accountId, lastIds[lastIds.length - 3])
      );
      popLastId(lastIds, setLastIds);
    }
  };

  const data: any[] | undefined = [];
  if (transaction) {
    for (let i = 0; i < transaction.length; i++) {
      const transactions = transaction[i];
      const key = transactions?._id;
      const transactionDate = moment(transactions?.createdAt).format(
        DateTimeFormat
      );
      const previousBalance =
        transactions?.previousBalance !== undefined
          ? formatNumberAmount(transactions?.previousUserBalance)
          : 0;
      const newBalance =
        transactions?.newBalance !== undefined
          ? formatNumberAmount(transactions?.newUserBalance)
          : 0;
      const narration =
        transactions["Purchase.productsDetails"].narration ||
        transactions["Purchase.productsDetails"].description ||
        transactions.description;
      const transactionAmount = transactions?.amount;
      const transactionReference =
        transactions["Purchase.purchaseReference"] || transactions?.reference;
      const id = transactions?._id;
      const uniqId = `${key}`;
      const status = transactions?.status;
      const customerFee = transactions?.["Purchase.customerFee"];
      const merchantFee = transactions?.["Purchase.merchantFee"];

      data.push({
        key,
        transactionDate,
        previousBalance,
        newBalance,
        narration,
        uniqId,
        transactionAmount,
        transactionReference,
        id,
        status,
        customerFee,
        merchantFee,
      });
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 50,
            hideOnSinglePage: true,
            position: ["bottomLeft"],
          }}
        />
      )}
      <Box className="pagination-items">
        {lastIds.length <= 2 ? null : (
          <Link onClick={loadPrevPage} className="pagination-item-link prev">
            Previous page
          </Link>
        )}
        {!transactions?.allTransactionsData?.isLastPage ? (
          <Link onClick={loadNextPage} className="pagination-item-link next">
            Next page
          </Link>
        ) : null}
      </Box>
    </>
  );
}
