import axios from "axios";
import { message } from "antd";
import {
  LOAN_ACTION_SUCCESS,
  LOAN_ACTION_FAILURE,
  LOAN_APPROVAL_LIST_ACTION_SUCCESS,
  LOAN_APPROVAL_LIST_ACTION_FAILURE,
  PENDING_DISBURSEMENT_ACTION_SUCCESS,
  PENDING_DISBURSEMENT_ACTION_FAILURE,
  PAID_ACTION_SUCCESS,
  LOAN_RECORD_ACTION_SUCCESS,
  LOAN_RECORD_ACTION_FAILURE,
  ACTIVE_LOAN_ACTION_SUCCESS,
  ACTIVE_LOAN_ACTION_FAILURE,
  TOPUP_LOAN_ACTION_SUCCESS,
  TOPUP_LOAN_ACTION_FAILURE,
  POST_LOAN_ACTION_SUCCESS,
  POST_LOAN_ACTION_FAILURE,
  ERROR_401_ACTION,
  DISBURSE_LOAN_ACTION_SUCCESS,
  REQUEST_LOADING_TRUE,
  REQUEST_LOADING_FALSE,
  PROCESSING_DISBURSEMENT_ACTION_SUCCESS,
  PROCESSING_DISBURSEMENT_ACTION_FAILURE,
  LOAN_SCHEDULE_ACTION_SUCCESS,
  UPDATE_LOANS_DOWNLOAD_LIST,
  UPDATE_APPROVED_LOANS_DOWNLOAD_LIST,
  UPDATE_ACTIVE_LOANS_DOWNLOAD_LIST,
  UPDATE_PAID_LOANS_DOWNLOAD_LIST,
  UPDATE_PROCESSING_LOANS_DOWNLOAD_LIST,
  OVERDUE_LOANS_ACTION_SUCCESS,
  UPDATE_OVERDUE_LOANS_DOWNLOAD_LIST,
  LOAN_PROFILE_LOADING_TRUE,
  UPDATE_TOPUP_LOANS_DOWNLOAD_LIST,
  PARTIAL_PAID_ACTION_SUCCESS,
  UPDATE_PARTIAL_PAID_LOANS_DOWNLOAD_LIST,
  PARTIAL_PAID_ACTION_FAILURE,
  PRIME_LOAN_LIST_ACTION_SUCCESS,
  PRIME_LOAN_LIST_ACTION_FAILURE,
  UPDATE_PRIME_LOANS_DOWNLOAD_LIST,
} from "./actionTypes";
import { ILoanTab } from "../../types";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getLoanData =
  (xTag: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      const res = await axios.get(`${baseUrl}/loans?x-tag=1`, config);
      dispatch({
        type: LOAN_ACTION_SUCCESS,
        payload: res?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: LOAN_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// get all loans
export const getPendingApproval =
  (xJwt: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/findloans?populate=user eligibility&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: LOAN_APPROVAL_LIST_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: LOAN_APPROVAL_LIST_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// get next all loans
export const getNextAllLoans =
  (xJwt: any, lastId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/findloans?populate=user eligibility&lastId=${lastId}&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: LOAN_APPROVAL_LIST_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: LOAN_APPROVAL_LIST_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// pending disbursement list
export const getPendingDisbursement =
  (xTag: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/findloans?status=approved&process=approved&disbursementStatus=processing&desc=true&sort=-_id&populate=user eligibility`,
        config
      );
      dispatch({
        type: PENDING_DISBURSEMENT_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_APPROVED_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: PENDING_DISBURSEMENT_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// load next pending disbursement list
export const getNextPendingDisbursementPage =
  (xTag: any, lastId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/findloans?status=approved&process=approved&disbursementStatus=processing&populate=user eligibility&lastId=${lastId}&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: PENDING_DISBURSEMENT_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_APPROVED_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: PENDING_DISBURSEMENT_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// paid list
export const getPaidList =
  (xTag: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/findloans?process=disbursed&disbursementStatus=completed&totalOutstanding=0&populate=user eligibility&desc=true&sort=-repaymentDate`,
        config
      );
      dispatch({
        type: PAID_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_PAID_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
        }
      }
      return error;
    }
  };

// load next paid list
export const loadNextPaidList =
  (xTag: any, lastId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/findloans?process=disbursed&disbursementStatus=completed&totalOutstanding=0&populate=user eligibility&lastId=${lastId}&desc=true&sort=-repaymentDate`,
        config
      );
      dispatch({
        type: PAID_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_PAID_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
        }
      }
      return error;
    }
  };

// Loan Record
export const getLoanRecord =
  (loan_id: any, xJwt: any, loanID: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: LOAN_PROFILE_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/findloans/${loan_id}?populate=user eligibility`,
        config
      );
      dispatch({
        type: LOAN_RECORD_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
      const schedule = await axios.get(`${baseUrl}/loan/${loanID}`, config);
      dispatch({
        type: LOAN_SCHEDULE_ACTION_SUCCESS,
        payload: schedule?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: LOAN_RECORD_ACTION_FAILURE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// Active Loan
export const getActiveLoan =
  (xTag: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/findloans?status=active&process=disbursed&disbursementStatus=completed&populate=user eligibility&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: ACTIVE_LOAN_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_ACTIVE_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: ACTIVE_LOAN_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// get next Active Loans
export const getNextActiveLoansPage =
  (xTag: any, lastId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/findloans?status=active&process=disbursed&disbursementStatus=completed&populate=user eligibility&lastId=${lastId}&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: ACTIVE_LOAN_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_ACTIVE_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: ACTIVE_LOAN_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// Partially paid Loans
export const getPartiallyPaidLoans =
  (xTag: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(`${baseUrl}/loans/partially-paid`, config);
      dispatch({
        type: PARTIAL_PAID_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
      dispatch({
        type: UPDATE_PARTIAL_PAID_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data?.partiallyPaidLoans,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: PARTIAL_PAID_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// Topup Loans
export const getTopupLoans =
  (xTag: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/findloans?isTopup=true&disbursementStatus=completed&populate=user eligibility&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: TOPUP_LOAN_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_TOPUP_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: TOPUP_LOAN_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// get next Topup Loans
export const getNextTopupLoansPage =
  (xTag: any, lastId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/findloans?isTopup=true&disbursementStatus=completed&populate=user eligibility&lastId=${lastId}&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: TOPUP_LOAN_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_TOPUP_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: TOPUP_LOAN_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// get Processing Disbursement Loan
export const getProcessingDisbursementLoan =
  (xJwt: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/findloans?status=approved&process=disbursed&populate=user eligibility&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: PROCESSING_DISBURSEMENT_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_PROCESSING_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: PROCESSING_DISBURSEMENT_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// get more Processing Disbursement Loan
export const getMoreProcessingDisbursementLoan =
  (xJwt: any, lastId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/findloans?status=approved&process=disbursed&populate=user eligibility&lastId=${lastId}&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: PROCESSING_DISBURSEMENT_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_PROCESSING_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: PROCESSING_DISBURSEMENT_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// Loan Actions
// only repayment works for now
export const postLoanAction =
  (xTag: any, loanActionData: any, _id: any, dispatchAction?: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      const res = await axios.post(
        `${baseUrl}/loan-actions?x-tag=1`,
        loanActionData,
        config
      );
      dispatch({
        type: POST_LOAN_ACTION_SUCCESS,
        payload: res?.data,
      });
      message.success("Processing repayment");
      dispatchAction(getLoanRecord(_id, xTag, loanActionData?.loanId));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: POST_LOAN_ACTION_FAILURE,
          });
          message.error("Repayment failed");
        }
      }
      return error;
    }
  };

// Disburse Loan
export const disburseLoan =
  (xJwt: any, borrowerToken: string, borrowerId: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${borrowerToken}`,
        "x-jwt": xJwt,
        "x-tag": "1",
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.post(
        `${baseUrl}/users/${borrowerId}/dashboardDisburse`,
        {},
        config
      );
      dispatch({
        type: DISBURSE_LOAN_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
      message.success("Processing disbursement");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// filter loans
export const filterLoansByDateRange =
  (xJwt: any, loanTab: ILoanTab["loanTab"], fromDate: any, toDate: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    const searchQuery = {
      all: `${baseUrl}/findloans?from=${fromDate}&to=${toDate}&populate=user eligibility&desc=true&sort=-_id&limit=9999`,
      approved: `${baseUrl}/findloans?status=approved&process=approved&disbursementStatus=processing&from=${fromDate}&to=${toDate}&populate=user eligibility&desc=true&sort=-_id&limit=9999`,
      processing: `${baseUrl}/loans/pending-disbursed-over-period?from=${fromDate}&to=${toDate}&limit=9999`,
      active: `${baseUrl}/findloans?status=active&process=disbursed&disbursementStatus=completed&from=${fromDate}&to=${toDate}&populate=user eligibility&desc=true&sort=-_id&limit=9999`,
      // active: `${baseUrl}/loans/active-over-period?from=${fromDate}&to=${toDate}&limit=9999`,
      topup: `${baseUrl}/findloans?isTopup=true&disbursementStatus=completed&from=${fromDate}&to=${toDate}&populate=user eligibility&desc=true&sort=-_id&limit=9999`,
      partial: `${baseUrl}/loans/partially-paid?from=${fromDate}&to=${toDate}&limit=9999`,
      paid: `${baseUrl}/loans/paid-over-period?from=${fromDate}&to=${toDate}&limit=9999`,
      overdue: `${baseUrl}/loans/arrears?from=${fromDate}&to=${toDate}&limit=9999`,
      prime: `${baseUrl}/primeLoans?from=${fromDate}&to=${toDate}&populate=user eligibility&desc=true&sort=-_id&limit=9999`,
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(searchQuery[loanTab], config);
      switch (loanTab) {
        case "all":
          dispatch({
            type: LOAN_APPROVAL_LIST_ACTION_SUCCESS,
            payload: res?.data,
          });
          dispatch({
            type: UPDATE_LOANS_DOWNLOAD_LIST,
            payload: res?.data?.data,
          });
          break;
        case "approved":
          dispatch({
            type: PENDING_DISBURSEMENT_ACTION_SUCCESS,
            payload: res?.data,
          });
          dispatch({
            type: UPDATE_APPROVED_LOANS_DOWNLOAD_LIST,
            payload: res?.data?.data,
          });
          break;
        case "processing":
          let processingData = {
            data: res?.data?.data?.processingLoans,
            isLastPage: true,
          };
          dispatch({
            type: PROCESSING_DISBURSEMENT_ACTION_SUCCESS,
            payload: processingData,
          });
          dispatch({
            type: UPDATE_PROCESSING_LOANS_DOWNLOAD_LIST,
            payload: processingData.data,
          });
          break;
        case "active":
          // let activeData = {
          //   data: res?.data?.data?.activeLoans,
          //   isLastPage: true,
          // };
          dispatch({
            type: ACTIVE_LOAN_ACTION_SUCCESS,
            payload: res?.data,
            // payload: activeData,
          });
          dispatch({
            type: UPDATE_ACTIVE_LOANS_DOWNLOAD_LIST,
            payload: res?.data?.data,
            // payload: activeData?.data,
          });
          break;
        case "topup":
          dispatch({
            type: TOPUP_LOAN_ACTION_SUCCESS,
            payload: res?.data,
          });
          dispatch({
            type: UPDATE_TOPUP_LOANS_DOWNLOAD_LIST,
            payload: res?.data?.data,
          });
          break;
        case "partial":
          dispatch({
            type: PARTIAL_PAID_ACTION_SUCCESS,
            payload: res?.data?.data,
          });
          dispatch({
            type: UPDATE_PARTIAL_PAID_LOANS_DOWNLOAD_LIST,
            payload: res?.data?.data?.partiallyPaidLoans,
          });
          break;
        case "paid":
          let paidData = {
            data: res?.data?.data?.paidLoans,
            isLastPage: true,
          };
          dispatch({
            type: PAID_ACTION_SUCCESS,
            payload: paidData,
          });
          dispatch({
            type: UPDATE_PAID_LOANS_DOWNLOAD_LIST,
            payload: paidData?.data,
          });
          break;
        case "overdue":
          dispatch({
            type: OVERDUE_LOANS_ACTION_SUCCESS,
            payload: res?.data?.data,
          });
          dispatch({
            type: UPDATE_OVERDUE_LOANS_DOWNLOAD_LIST,
            payload: res?.data?.data?.overdueLoans,
          });
          break;
        case "prime":
          dispatch({
            type: PRIME_LOAN_LIST_ACTION_SUCCESS,
            payload: res?.data,
          });
          dispatch({
            type: UPDATE_PRIME_LOANS_DOWNLOAD_LIST,
            payload: res?.data?.data,
          });
          break;
        default:
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// search loans
export const searchLoans =
  (
    xJwt: any,
    loanStatus: "pending" | "approved" | "active" | "closed",
    processStatus: "pending" | "approved" | "disbursed",
    disbursementStatus: "processing" | "completed" | "failed",
    searchField: string,
    searchQuery: string,
    totalOutstanding?: number
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    let paidSearch = `${baseUrl}/findloans?${searchField}=${searchQuery}&populate=user eligibility&status=${loanStatus}&process=${processStatus}&disbursementStatus=${disbursementStatus}&totalOutstanding=${totalOutstanding}&desc=true&sort=-repaymentDate`;
    let notPaidSearch = `${baseUrl}/findloans?${searchField}=${searchQuery}&populate=user eligibility&status=${loanStatus}&process=${processStatus}&disbursementStatus=${disbursementStatus}&desc=true&sort=-_id`;
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        totalOutstanding === undefined ? notPaidSearch : paidSearch,
        config
      );
      if (
        loanStatus === "approved" &&
        processStatus === "approved" &&
        disbursementStatus === "processing"
      ) {
        dispatch({
          type: PENDING_DISBURSEMENT_ACTION_SUCCESS,
          payload: res?.data,
        });
        dispatch({
          type: UPDATE_APPROVED_LOANS_DOWNLOAD_LIST,
          payload: res?.data?.data,
        });
      } else if (
        loanStatus === "active" &&
        processStatus === "disbursed" &&
        disbursementStatus === "completed"
      ) {
        dispatch({
          type: ACTIVE_LOAN_ACTION_SUCCESS,
          payload: res?.data,
        });
        dispatch({
          type: UPDATE_ACTIVE_LOANS_DOWNLOAD_LIST,
          payload: res?.data?.data,
        });
      } else if (
        totalOutstanding === 0 &&
        processStatus === "disbursed" &&
        disbursementStatus === "completed"
      ) {
        dispatch({
          type: PAID_ACTION_SUCCESS,
          payload: res?.data,
        });
        dispatch({
          type: UPDATE_PAID_LOANS_DOWNLOAD_LIST,
          payload: res?.data?.data,
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// search topup loans
export const searchTopupLoans =
  (xJwt: any, searchField: string, searchQuery: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    let searchAll = `${baseUrl}/findloans?isTopup=true&disbursementStatus=completed&${searchField}=${searchQuery}&populate=user eligibility&desc=true&sort=-_id`;
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(searchAll, config);

      dispatch({
        type: TOPUP_LOAN_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_TOPUP_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// search partially paid loans
export const searchPartialLoans =
  (xJwt: any, searchField: string, searchQuery: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    let searchAll = `${baseUrl}/loans/partially-paid?${searchField}=${searchQuery}`;
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(searchAll, config);

      dispatch({
        type: PARTIAL_PAID_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
      dispatch({
        type: UPDATE_PARTIAL_PAID_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data?.partiallyPaidLoans,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// search all loans
export const searchAllLoans =
  (xJwt: any, searchField: string, searchQuery: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    let searchAll = `${baseUrl}/findloans?${searchField}=${searchQuery}&populate=user eligibility&desc=true&sort=-_id`;
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(searchAll, config);

      dispatch({
        type: LOAN_APPROVAL_LIST_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// search processing disbursement loans
export const searchProcessingDisbursementLoans =
  (xJwt: any, searchField: string, searchQuery: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    let searchProcessing = `${baseUrl}/findloans?status=approved&process=disbursed&${searchField}=${searchQuery}&populate=user eligibility&desc=true&sort=-_id`;
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(searchProcessing, config);

      dispatch({
        type: PROCESSING_DISBURSEMENT_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_PROCESSING_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// search loans with phone number
export const searchLoansWithPhonenumber =
  (
    xJwt: any,
    loanStatus: "pending" | "approved" | "active" | "closed",
    processStatus: "pending" | "approved" | "disbursed",
    disbursementStatus: "processing" | "completed" | "failed",
    searchQuery: string,
    totalOutstanding?: number
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const userData = await axios.get(
        `${baseUrl}/users?phoneNumber=${searchQuery}&desc=true&sort=-_id`,
        config
      );
      if (userData?.data?.data.length === 0) {
        dispatch({
          type: REQUEST_LOADING_FALSE,
        });
        return message.error("no record found");
      }
      let paidSearch = `${baseUrl}/findloans?user=${userData?.data?.data?.[0]?._id}&populate=user eligibility&status=${loanStatus}&process=${processStatus}&disbursementStatus=${disbursementStatus}&totalOutstanding=${totalOutstanding}&desc=true&sort=-repaymentDate`;
      let notPaidSearch = `${baseUrl}/findloans?user=${userData?.data?.data?.[0]?._id}&populate=user eligibility&status=${loanStatus}&process=${processStatus}&disbursementStatus=${disbursementStatus}&desc=true&sort=-_id`;
      const res = await axios.get(
        totalOutstanding === undefined ? notPaidSearch : paidSearch,
        config
      );
      if (
        loanStatus === "approved" &&
        processStatus === "approved" &&
        disbursementStatus === "processing"
      ) {
        dispatch({
          type: PENDING_DISBURSEMENT_ACTION_SUCCESS,
          payload: res?.data,
        });
        dispatch({
          type: UPDATE_APPROVED_LOANS_DOWNLOAD_LIST,
          payload: res?.data?.data,
        });
      } else if (
        loanStatus === "active" &&
        processStatus === "disbursed" &&
        disbursementStatus === "completed"
      ) {
        dispatch({
          type: ACTIVE_LOAN_ACTION_SUCCESS,
          payload: res?.data,
        });
        dispatch({
          type: UPDATE_ACTIVE_LOANS_DOWNLOAD_LIST,
          payload: res?.data?.data,
        });
      } else if (
        totalOutstanding === 0 &&
        processStatus === "disbursed" &&
        disbursementStatus === "completed"
      ) {
        dispatch({
          type: PAID_ACTION_SUCCESS,
          payload: res?.data,
        });
        dispatch({
          type: UPDATE_PAID_LOANS_DOWNLOAD_LIST,
          payload: res?.data?.data,
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// search partially paid loans with phone number
export const searchPartialLoansWithPhonenumber =
  (xJwt: any, searchQuery: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const userData = await axios.get(
        `${baseUrl}/users?phoneNumber=${searchQuery}&desc=true&sort=-_id`,
        config
      );
      if (userData?.data?.data.length === 0) {
        dispatch({
          type: REQUEST_LOADING_FALSE,
        });
        return message.error("no record found");
      }
      let paidSearch = `${baseUrl}/loans/partially-paid?user=${userData?.data?.data?.[0]?._id}&populate=user eligibility&desc=true&sort=-repaymentDate`;
      const res = await axios.get(paidSearch, config);
      dispatch({
        type: PARTIAL_PAID_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
      dispatch({
        type: UPDATE_PARTIAL_PAID_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data?.partiallyPaidLoans,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// search topup loans with phone number
export const searchTopupLoansWithPhonenumber =
  (xJwt: any, searchQuery: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const userData = await axios.get(
        `${baseUrl}/users?phoneNumber=${searchQuery}&desc=true&sort=-_id`,
        config
      );
      if (userData?.data?.data.length === 0) {
        dispatch({
          type: REQUEST_LOADING_FALSE,
        });
        return message.error("no record found");
      }
      let searchAll = `${baseUrl}/findloans?isTopup=true&disbursementStatus=completed&user=${userData?.data?.data?.[0]?._id}&populate=user eligibility&desc=true&sort=-_id`;
      const res = await axios.get(searchAll, config);
      dispatch({
        type: TOPUP_LOAN_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_TOPUP_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// search all loans with phone number
export const searchAllLoansWithPhonenumber =
  (xJwt: any, searchQuery: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const userData = await axios.get(
        `${baseUrl}/users?phoneNumber=${searchQuery}&desc=true&sort=-_id`,
        config
      );
      if (userData?.data?.data.length === 0) {
        dispatch({
          type: REQUEST_LOADING_FALSE,
        });
        return message.error("no record found");
      }
      let searchAll = `${baseUrl}/findloans?user=${userData?.data?.data?.[0]?._id}&populate=user eligibility&desc=true&sort=-_id`;
      const res = await axios.get(searchAll, config);
      dispatch({
        type: LOAN_APPROVAL_LIST_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// search processing disbursement loans with phone number
export const searchProcessingDisbursementLoansWithPhonenumber =
  (xJwt: any, searchQuery: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const userData = await axios.get(
        `${baseUrl}/users?phoneNumber=${searchQuery}&desc=true&sort=-_id`,
        config
      );
      if (userData?.data?.data.length === 0) {
        dispatch({
          type: REQUEST_LOADING_FALSE,
        });
        return message.error("no record found");
      }
      let searchAll = `${baseUrl}/findloans?status=approved&process=disbursed&user=${userData?.data?.data?.[0]?._id}&populate=user eligibility&desc=true&sort=-_id`;
      const res = await axios.get(searchAll, config);
      dispatch({
        type: PROCESSING_DISBURSEMENT_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_PROCESSING_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// get overdue loans
export const getOverdueLoan =
  (xJwt: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(`${baseUrl}/loans/arrears`, config);
      dispatch({
        type: OVERDUE_LOANS_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
      dispatch({
        type: UPDATE_OVERDUE_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data?.overdueLoans,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// get more overdue loans
export const getMoreOverdueLoan =
  (xJwt: any, pageNumber: number) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/loans/arrears?page=${pageNumber}`,
        config
      );
      dispatch({
        type: OVERDUE_LOANS_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
      dispatch({
        type: UPDATE_OVERDUE_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data?.overdueLoans,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// search overdue loans
export const searchOverdueLoan =
  (xJwt: any, searchParam: string, searchQuery: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/loans/arrears?${searchParam}=${searchQuery}`,
        config
      );
      dispatch({
        type: OVERDUE_LOANS_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
      dispatch({
        type: UPDATE_OVERDUE_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data?.overdueLoans,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error("No loans found");
        }
      }
      return error;
    }
  };

// get prime loans
export const getPrimeLoans =
  (xJwt: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/primeloans?populate=user eligibility&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: PRIME_LOAN_LIST_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: UPDATE_PRIME_LOANS_DOWNLOAD_LIST,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: PRIME_LOAN_LIST_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };
