import { Box, Flex, HStack, Heading, Text } from "@chakra-ui/react";
import { Modal, Switch, SwitchProps } from "antd";
import {
  ConfirmPassword,
  InstallMfa,
  MfaDetails,
  EnterCode,
  MfaSuccess,
} from "components/security";
import { set } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { disable2FA } from "redux/actions/authActions";
import { RootState } from "types";

type Props = {};

type MfaSteps = "password" | "install" | "start" | "enter-code" | "success";

type PreferenceProps = SwitchProps & {
  title: string;
  description: string;
  defaultChecked: boolean;
};

const SecurityToggle = ({
  title,
  description,
  onChange,
  defaultChecked,
}: PreferenceProps) => {
  const [checked, setChecked] = React.useState<boolean>(defaultChecked);

  React.useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <HStack justify="space-between" align="center" mb="8">
      <Box>
        <Flex align="center" mb="4">
          <Heading
            as="h3"
            mr="2"
            fontSize={{ base: "14px", md: "18px" }}
            lineHeight={{ base: "18px", md: "22px" }}
          >
            {title}
          </Heading>
        </Flex>

        <Text
          width={{ base: "100%", md: "60%" }}
          fontSize={{ base: "12px", md: "16px" }}
          lineHeight={{ base: "16px", md: "20px" }}
        >
          {description}
        </Text>
      </Box>

      <Switch
        checked={checked}
        onChange={onChange}
        className="colending-toggle"
      />
    </HStack>
  );
};

const SecurityTab = (props: Props) => {
  const dispatch = useDispatch();
  const [mfa, setMfa] = React.useState<MfaSteps | null>(null);
  const [toggleMfa, setToggleMfa] = React.useState<boolean>(false);
  const [disable2FAProcess, setDisable2FAProcess] = React.useState(false);
  const userData = useSelector(
    (state: RootState) => state.authReducer.loginDataTwo
  );

  const checkIf2FAIsAlreadyEnabled = () => {
    if (userData?.twoFactor) return true;
    if (!!mfa) return true;
    return false;
  };

  const startMfaProcess = async (e: boolean) => {
    if (userData?.twoFactor) {
      if (!e) {
        setToggleMfa(true);
        setMfa("enter-code");
        setDisable2FAProcess(true);
      } else {
        setMfa(null);
      }
    } else {
      if (e) {
        setToggleMfa(true);
        setMfa("password");
      } else {
        setMfa("enter-code");
        setDisable2FAProcess(true);
      }
    }
  };

  const onSetupMfaComplete = () => setMfa(null);

  const renderMfaBody = () => {
    switch (mfa) {
      case "password":
        return <ConfirmPassword onNext={() => setMfa("install")} />;
      case "install":
        return <InstallMfa onNext={() => setMfa("start")} />;
      case "start":
        return <MfaDetails onNext={() => setMfa("enter-code")} />;
      case "enter-code":
        return (
          <EnterCode
            setMfa={setMfa}
            onNext={dir => setMfa(dir)}
            isForDisable={disable2FAProcess}
            setDisable2FAProcess={setDisable2FAProcess}
          />
        );
      case "success":
        return <MfaSuccess onNext={onSetupMfaComplete} />;
      default:
        return <Box />;
    }
  };

  const handleOk = () => {
    setMfa(null);
  };

  const handleCancel = async () => {
    if (mfa !== "success" && userData?.twoFactor) {
      dispatch(disable2FA(userData?._id, userData?.accounts?.[0]));
    }

    setMfa(null);
    setDisable2FAProcess(false);
    setToggleMfa(false);
  };

  return (
    <Box mt="6">
      <SecurityToggle
        checked={toggleMfa}
        title="Two Factor Authentication"
        description="This adds an extra layer of security to your account by generating a 
        secure code on your device for approving transactions."
        defaultChecked={checkIf2FAIsAlreadyEnabled()}
        onChange={e => startMfaProcess(e)}
      />
      <Modal
        visible={toggleMfa && !!mfa}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        width={window.innerWidth >= 768 ? "50%" : "100%"}
      >
        {renderMfaBody()}
      </Modal>
    </Box>
  );
};

export default SecurityTab;
