import React, { useEffect, useState } from "react";
import { Text, Box } from "@chakra-ui/react";
import moment from "moment";
import { LoanProfile } from "components/modals";
import { useDispatch, useSelector } from "react-redux";
import { getLoanRecord } from "redux/actions/loanActions";
import { Table } from "antd";
import { DateTimeFormat } from "../../constants";
import { Loader } from "components/common";
import { RootState } from "types";
import { getRepaymentsData } from "redux/actions/repaymentActions";

const columns = [
  {
    dataIndex: "name",
    key: "name",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Name
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    render: (text: string) => (
      <Box onClick={e => e.stopPropagation()}>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Phone number
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Created At
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "transactionAmount",
    key: "transactionAmount",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Transaction Amount
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "disburseDate",
    key: "disburseDate",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Disburse Date
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "deductionDate",
    key: "deductionDate",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Deduction Date
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },

  {
    dataIndex: "repaymentDate",
    key: "repaymentDate",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Repayment Date
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "remitaMandateReference",
    key: "remitaMandateReference",
    render: (text: string) => (
      <Box onClick={e => e.stopPropagation()}>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Mandate
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "loanId",
    key: "loanId",
    render: (text: string) => (
      <Box onClick={e => e.stopPropagation()}>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Loan ID
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "customerId",
    key: "customerId",
    render: (text: string) => (
      <Box onClick={e => e.stopPropagation()}>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Customer ID
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "status",
    key: "status",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Status
        </Text>
      </Box>
    ),
  },
];

export default function RepaymentsTable() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const repaymentState = useSelector(
    (state: RootState) => state.repaymentReducer
  );
  const repaymentsData = repaymentState.repayments;
  const repayments = repaymentsData?.data?.repayments;
  const loading = repaymentState?.requestLoading;
  const xJwt = localStorage.getItem("x_jwt");

  const openLoanProfile = () => {
    setShowModal(true);
  };

  useEffect(() => {
    dispatch(getRepaymentsData(xJwt));
  }, [dispatch, xJwt]);

  const data: any[] | undefined = [];

  for (let i = 0; i < repayments?.length; i++) {
    data?.push({
      key: repayments[i].loan?._id,
      phoneNumber: repayments[i]?.phoneNumber,
      name: `${repayments[i]?.firstName} ${repayments[i]?.lastName}`,
      createdAt: `${moment(repayments[i]?.createdAt).format(DateTimeFormat)}`,
      remitaMandateReference: repayments[i]?.remitaMandateReference
        ? repayments[i]?.remitaMandateReference
        : "no mandate",
      loanId: repayments[i]?.loanId,
      customerId: repayments[i]?.customerId,
      deductionDate: `${moment(repayments[i]?.deductionDate).format(
        DateTimeFormat
      )}`,
      disburseDate: `${moment(repayments[i]?.disburseDate).format(
        DateTimeFormat
      )}`,
      status: repayments[i]?.status,
      transactionAmount: repayments[i]?.transactionAmount,
      repaymentDate: `${moment(repayments[i]?.loan?.repaymentDate).format(
        DateTimeFormat
      )}`,
    });
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 50,
            hideOnSinglePage: true,
            position: ["bottomLeft"],
          }}
          onRow={(record: any, rowIndex) => {
            return {
              onClick: e => {
                dispatch(getLoanRecord(record.key, xJwt, record.loanId));
                openLoanProfile();
              },
            };
          }}
          className="row-select"
        />
      )}

      <LoanProfile showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
