import {
  Box,
  Flex,
  Text,
  Image,
  Alert,
  Center,
  Spinner,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "types";

const ScanQr = () => {
  const userData = useSelector((state: RootState) => state.authReducer);

  return (
    <Box>
      <Flex
        flexDir="column"
        alignItems="center"
        px={{ base: "0", md: "20px", lg: "40px" }}
      >
        <Center boxSize="150px">
          {userData.isLoading ? (
            <Spinner
              w="50px"
              h="50px"
              speed="0.65s"
              color="primary.500"
              emptyColor="primary.50"
            />
          ) : !userData?.twoFAInfo?.QR ? (
            <Alert
              status="error"
              variant="subtle"
              textAlign="center"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <AlertIcon boxSize="40px" mr={0} />

              <AlertDescription mt={4}>
                <Text fontSize={"xs"}>
                  Cannot fetch QR Code, please try again
                </Text>
              </AlertDescription>
            </Alert>
          ) : (
            <Image src={userData?.twoFAInfo?.QR} alt="QR Code" />
          )}
        </Center>
        <Text
          mb="16px"
          mt="38px"
          fontWeight="600"
          textAlign="center"
          color="neutral.500"
          fontSize={["20px", "20px", "24px", "28px"]}
        >
          Scan QR code
        </Text>
        <Text type="02" mb="32px" textAlign="center">
          Launch Google Authenticator from your device, and scan the QR code
        </Text>
      </Flex>
      <Box h="50px" />
    </Box>
  );
};

export default ScanQr;
