import React from "react";
import { Heading, HeadingProps } from "@chakra-ui/react";

interface Props extends HeadingProps {
  title?: string;
  type?: "06" | "05" | "04" | "03" | "02" | "01" | "sub-reg" | "sub-md";
}

const VeendHeading = ({ title, type = "06", children, ...props }: Props) => {
  const _getFontFace = () => {
    switch (type) {
      case "06":
        return { fontSize: "24px", fontWeight: 700, lineHeight: "29px" };
      case "05":
        return { fontSize: "28px", fontWeight: 700, lineHeight: "34px" };
      case "04":
        return { fontSize: "32px", fontWeight: 700, lineHeight: "38px" };
      case "03":
        return { fontSize: "36px", fontWeight: 700 };
      case "02":
        return { fontSize: "48px", fontWeight: 500 };
      case "01":
        return { fontSize: "48px", fontWeight: 500 };

      // sub headings
      case "sub-reg":
        return { fontSize: "20px", fontWeight: 450 };
      case "sub-reg":
        return { fontSize: "20px", fontWeight: 450 };
      default:
        return { fontSize: "24px", fontWeight: 700 };
    }
  };
  return (
    <Heading
      {..._getFontFace()}
      letterSpacing={"0.05rem"}
      color={props.color || "neutral.500"}
      {...props}
    >
      {title || children}
    </Heading>
  );
};

export default VeendHeading;
