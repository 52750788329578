import React, { useEffect } from "react";
import { Layout, Button } from "antd";
import { ArrowBackIcon, VeendIcon } from "components/common/CustomIcons";
import { Link, Route, Switch } from "react-router-dom";
import { routes } from "routes";
import { IRoutes, RootState } from "types";
import { Flex, Avatar, Text, Box } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getUserSettings } from "redux/actions/settingsActions";
import { getOperation } from "redux/actions/dashboardAction";
import { getUserProfile } from "redux/actions/authActions";

const { Header, Content } = Layout;

export default function SmsAutomationPage() {
  const dispatch = useDispatch();
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const userSetting = setting?.settingData?.data;
  const userData = useSelector(
    (state: RootState) => state.authReducer.loginDataTwo
  );

  const xTag = localStorage.getItem("x_jwt");

  useEffect(() => {
    dispatch(getUserSettings(xTag));
    dispatch(getOperation(xTag));
    dispatch(getUserProfile());
  }, [dispatch, xTag]);

  const getRoutes = (routes: IRoutes[]) => {
    return routes.map((props, index) => {
      if (props.layout === "sms") {
        return (
          <Route
            path={props.path}
            component={props.component}
            key={index}
            exact={props.exact}
          />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <Layout className="site-layout sms-site-layout">
      <Header className="sms-page-header">
        <Box className="brand-logo-cont">
          <VeendIcon />
        </Box>

        <Box className="right-nav">
          <Box mr={{ base: "4", lg: "8" }}>
            <Link to="/dashboard">
              <Button type="primary" icon={<ArrowBackIcon />}>
                Back to Dashboard
              </Button>
            </Link>
          </Box>

          <Flex alignItems="center" gap="2">
            <Avatar
              size="sm"
              name={
                userSetting?.info?.businessName || userData?.businessName || ""
              }
              src={userSetting?.logo}
            />
            <Text
              fontSize={{ base: "xs", md: "md" }}
              fontWeight="semibold"
              color="#7C7C88"
              className="business-name"
            >
              {userSetting?.info?.businessName || userData?.businessName || ""}
            </Text>
          </Flex>
        </Box>
      </Header>
      <Content className="sms-page-content">
        {<Switch>{getRoutes(routes)}</Switch>}
      </Content>
    </Layout>
  );
}
