import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Button } from "antd";
import { InstallMfaIcon } from "components/common/CustomIcons";

type Props = {
  onNext: () => void;
};

const InstallMfa = ({ onNext }: Props) => {
  const onContinue = () => {
    onNext();
  };
  return (
    <Box>
      <Flex
        flexDir="column"
        alignItems="center"
        px={{ base: "0", md: "20px", lg: "40px" }}
      >
        <InstallMfaIcon boxSize={"120px"} />
        <Text
          mb="16px"
          mt="38px"
          fontWeight="600"
          textAlign="center"
          color="neutral.500"
          text="Install Google Authenticator"
          fontSize={["20px", "20px", "24px", "28px"]}
        />
        <Text type="02" mb="32px" textAlign="center">
          You need the google authenticator app to setup the two-factor
          authentication. Download from either apple or play store and setup the
          google authenticator app to proceed.
        </Text>
      </Flex>

      <Box h="50px" />

      <Flex
        mb="40px"
        alignItems={"center"}
        justifyContent={{ md: "flex-end" }}
        flexDir={["column-reverse", null, "row"]}
        px={{ base: "0", md: "20px", lg: "40px" }}
      >
        <Button onClick={onContinue}>I have Installed it</Button>
      </Flex>
    </Box>
  );
};

export default InstallMfa;
