import {
  LoanRecordResponse,
  LoansCountsResponse,
  LoansResponse,
} from "types/responses";
import {
  LOAN_ACTION_SUCCESS,
  LOAN_ACTION_FAILURE,
  LOAN_APPROVAL_LIST_ACTION_SUCCESS,
  LOAN_APPROVAL_LIST_ACTION_FAILURE,
  PENDING_DISBURSEMENT_ACTION_SUCCESS,
  PENDING_DISBURSEMENT_ACTION_FAILURE,
  PAID_ACTION_SUCCESS,
  PAID_ACTION_FAILURE,
  LOAN_RECORD_ACTION_SUCCESS,
  LOAN_RECORD_ACTION_FAILURE,
  ACTIVE_LOAN_ACTION_FAILURE,
  ACTIVE_LOAN_ACTION_SUCCESS,
  POST_LOAN_ACTION_SUCCESS,
  POST_LOAN_ACTION_FAILURE,
  REQUEST_LOADING_TRUE,
  DISBURSE_LOAN_ACTION_SUCCESS,
  PROCESSING_DISBURSEMENT_ACTION_SUCCESS,
  PROCESSING_DISBURSEMENT_ACTION_FAILURE,
  LOAN_SCHEDULE_ACTION_SUCCESS,
  REQUEST_LOADING_FALSE,
  UPDATE_LOANS_DOWNLOAD_LIST,
  UPDATE_APPROVED_LOANS_DOWNLOAD_LIST,
  UPDATE_PROCESSING_LOANS_DOWNLOAD_LIST,
  UPDATE_ACTIVE_LOANS_DOWNLOAD_LIST,
  UPDATE_PAID_LOANS_DOWNLOAD_LIST,
  OVERDUE_LOANS_ACTION_SUCCESS,
  UPDATE_OVERDUE_LOANS_DOWNLOAD_LIST,
  LOAN_PROFILE_LOADING_TRUE,
  TOPUP_LOAN_ACTION_SUCCESS,
  TOPUP_LOAN_ACTION_FAILURE,
  UPDATE_TOPUP_LOANS_DOWNLOAD_LIST,
  PARTIAL_PAID_ACTION_FAILURE,
  PARTIAL_PAID_ACTION_SUCCESS,
  UPDATE_PARTIAL_PAID_LOANS_DOWNLOAD_LIST,
  PRIME_LOAN_LIST_ACTION_SUCCESS,
  UPDATE_PRIME_LOANS_DOWNLOAD_LIST,
} from "../actions/actionTypes";

type LoanStateKey =
  | "loanApprovalData"
  | "disbursementData"
  | "paidData"
  | "partiallyPaidData"
  | "activeLoanData"
  | "topupLoanData"
  | "postLoanActionData"
  | "processingDisbursementData"
  | "overdueLoansData"
  | "primeLoansData";

type LoanDownloadKey =
  | "loansDownloadList"
  | "approvedLoansDownloadList"
  | "processingLoansDownloadList"
  | "activeLoansDownloadList"
  | "topupLoansDownloadList"
  | "partiallyPaidLoansDownloadList"
  | "paidLoansDownloadList"
  | "overdueLoansDownloadList"
  | "primeLoansDownloadList";

export type LoanState = {
  isLoading: boolean;
  loanData: LoansCountsResponse;
  loanRecordData: LoanRecordResponse["data"];
  loanSchedule: { schedule: {} };
  requestLoading: boolean;
  loanProfileLoading: boolean;
} & {
  [key in LoanStateKey]: LoansResponse;
} &
  {
    [key in LoanDownloadKey]: LoansResponse["data"];
  };

const initialState: LoanState = {
  isLoading: true,
  loanData: {} as LoansCountsResponse,
  loanApprovalData: {} as LoansResponse,
  disbursementData: {} as LoansResponse,
  partiallyPaidData: {} as LoansResponse,
  paidData: {} as LoansResponse,
  loanRecordData: {} as LoanRecordResponse["data"],
  activeLoanData: {} as LoansResponse,
  topupLoanData: {} as LoansResponse,
  postLoanActionData: {} as LoansResponse,
  processingDisbursementData: {} as LoansResponse,
  overdueLoansData: {} as LoansResponse,
  loanSchedule: {} as { schedule: {} },
  requestLoading: false,
  loanProfileLoading: false,
  loansDownloadList: [] as LoansResponse["data"],
  approvedLoansDownloadList: [] as LoansResponse["data"],
  processingLoansDownloadList: [] as LoansResponse["data"],
  activeLoansDownloadList: [] as LoansResponse["data"],
  topupLoansDownloadList: [] as LoansResponse["data"],
  partiallyPaidLoansDownloadList: [] as LoansResponse["data"],
  primeLoansDownloadList: [] as LoansResponse["data"],
  paidLoansDownloadList: [] as LoansResponse["data"],
  overdueLoansDownloadList: [] as LoansResponse["data"],
  primeLoansData: {} as LoansResponse,
};

function loanReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case LOAN_ACTION_SUCCESS:
      return {
        ...state,
        loanData: payload,
        isLoading: false,
      };
    case LOAN_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case LOAN_APPROVAL_LIST_ACTION_SUCCESS:
      return {
        ...state,
        loanApprovalData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case LOAN_APPROVAL_LIST_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case PENDING_DISBURSEMENT_ACTION_SUCCESS:
      return {
        ...state,
        disbursementData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case PENDING_DISBURSEMENT_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestLoading: false,
      };
    case PARTIAL_PAID_ACTION_SUCCESS:
      return {
        ...state,
        partiallyPaidData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case PARTIAL_PAID_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestLoading: false,
      };
    case PAID_ACTION_SUCCESS:
      return {
        ...state,
        paidData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case PAID_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestLoading: false,
      };
    case LOAN_RECORD_ACTION_SUCCESS:
      return {
        ...state,
        loanRecordData: payload,
        isLoading: false,
        loanProfileLoading: false,
        requestLoading: false,
      };
    case LOAN_RECORD_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        loanProfileLoading: false,
        requestLoading: false,
      };
    case LOAN_SCHEDULE_ACTION_SUCCESS:
      return {
        ...state,
        loanSchedule: payload,
        requestLoading: false,
      };
    case ACTIVE_LOAN_ACTION_SUCCESS:
      return {
        ...state,
        activeLoanData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case ACTIVE_LOAN_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case TOPUP_LOAN_ACTION_SUCCESS:
      return {
        ...state,
        topupLoanData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case TOPUP_LOAN_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestLoading: false,
      };
    case POST_LOAN_ACTION_SUCCESS:
      return {
        ...state,
        postLoanActionData: payload,
        isLoading: false,
      };
    case POST_LOAN_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case REQUEST_LOADING_TRUE:
      return {
        ...state,
        requestLoading: true,
      };
    case LOAN_PROFILE_LOADING_TRUE:
      return {
        ...state,
        loanProfileLoading: true,
      };
    case REQUEST_LOADING_FALSE:
      return {
        ...state,
        requestLoading: false,
      };
    case DISBURSE_LOAN_ACTION_SUCCESS:
      return {
        ...state,
        requestLoading: false,
      };
    case PROCESSING_DISBURSEMENT_ACTION_SUCCESS:
      return {
        ...state,
        processingDisbursementData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case PROCESSING_DISBURSEMENT_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestLoading: false,
      };
    case UPDATE_LOANS_DOWNLOAD_LIST:
      return {
        ...state,
        loansDownloadList: payload,
        requestLoading: false,
      };
    case UPDATE_APPROVED_LOANS_DOWNLOAD_LIST:
      return {
        ...state,
        approvedLoansDownloadList: payload,
        requestLoading: false,
      };
    case UPDATE_PROCESSING_LOANS_DOWNLOAD_LIST:
      return {
        ...state,
        processingLoansDownloadList: payload,
        requestLoading: false,
      };
    case UPDATE_ACTIVE_LOANS_DOWNLOAD_LIST:
      return {
        ...state,
        activeLoansDownloadList: payload,
        requestLoading: false,
      };
    case UPDATE_TOPUP_LOANS_DOWNLOAD_LIST:
      return {
        ...state,
        topupLoansDownloadList: payload,
        requestLoading: false,
      };
    case UPDATE_PARTIAL_PAID_LOANS_DOWNLOAD_LIST:
      return {
        ...state,
        partiallyPaidLoansDownloadList: payload,
        requestLoading: false,
      };
    case UPDATE_PAID_LOANS_DOWNLOAD_LIST:
      return {
        ...state,
        paidLoansDownloadList: payload,
        requestLoading: false,
      };
    case UPDATE_PRIME_LOANS_DOWNLOAD_LIST:
      return {
        ...state,
        primeLoansDownloadList: payload,
        requestLoading: false,
      };
    case UPDATE_OVERDUE_LOANS_DOWNLOAD_LIST:
      return {
        ...state,
        overdueLoansDownloadList: payload,
        requestLoading: false,
      };
    case OVERDUE_LOANS_ACTION_SUCCESS:
      return {
        ...state,
        overdueLoansData: payload,
        requestLoading: false,
      };

    case PRIME_LOAN_LIST_ACTION_SUCCESS:
      return {
        ...state,
        primeLoansData: payload,
        requestLoading: false,
      };
    default:
      return state;
  }
}

export default loanReducer;
