import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Text, Box, Link } from "@chakra-ui/react";
import moment from "moment";
import { BorrowerDetails } from "components/modals";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadBorrower,
  getBorrowersDetails,
  getBorrowersTable,
  getNextBorrowersPage,
} from "redux/actions/borrowerActions";
import { DateTimeFormat } from "../../constants";
import { popLastId } from "helpers/utilFunctions";
import { Loader } from "components/common";
import { RootState } from "types";

const columns = [
  {
    dataIndex: "name",
    key: "name",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Name
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "created",
    key: "created",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Created On
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    render: (text: string) => (
      <Box onClick={e => e.stopPropagation()}>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Mobile Number
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "employer",
    key: "employer",
    render: (text: string) => (
      <Box>
        <Text
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="bold"
          title={text}
        >
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Employer
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "preApprovedAmount",
    key: "preApprovedAmount",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Eligible Amount
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "loanCount",
    key: "loanCount",
    render: (text: any) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text.length}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Loan Count
        </Text>
      </Box>
    ),
  },
];

export default function BorrowersTable() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [lastIds, setLastIds] = useState<(string | null)[]>([null]);
  const [id, setId] = useState(undefined);
  const borrower = useSelector((state: RootState) => state.borrowerReducer);
  const borrowers = borrower?.borrowerData;
  const borrowerData = borrowers?.data;
  const loading = borrower?.requestLoading;
  const xTag = localStorage.getItem("x_jwt");

  useEffect(() => {
    dispatch(getBorrowersTable(xTag));
    dispatch(downloadBorrower(xTag));
  }, [dispatch, xTag]);

  useEffect(() => {
    if (borrowers !== undefined && borrowers?.lastId !== undefined) {
      setLastIds([...lastIds, borrowers?.lastId]);
    }
  }, [borrowers]); //eslint-disable-line

  const openBorrowerProfile = () => {
    setShowModal(true);
  };

  const loadNextPage = () => {
    dispatch(getNextBorrowersPage(xTag, borrowers?.lastId));
  };

  const loadPrevPage = () => {
    if (lastIds.length === 3) {
      dispatch(getBorrowersTable(xTag));
      setLastIds([null]);
    } else {
      dispatch(getNextBorrowersPage(xTag, lastIds[lastIds.length - 3]));
      popLastId(lastIds, setLastIds);
    }
  };

  const data: any[] | undefined = [];
  // loop through the loans and push them into the data array with uniqe id
  if (borrowerData) {
    for (let i = 0; i < borrowerData?.length; i++) {
      const loan = borrowerData[i];
      const key = loan?._id;
      const name = `${loan?.firstName} ${loan?.lastName}`;
      const created = moment(loan?.createdAt).format(DateTimeFormat);
      const phoneNumber = loan?.phoneNumber;
      const employer = loan?.employer;
      const preApprovedAmount = loan?.preApprovedAmount;
      const loanCount = loan?.loans;
      const uniqId = `${key}-${id}`;
      data.push({
        key,
        name,
        created,
        phoneNumber,
        employer,
        preApprovedAmount,
        loanCount,
        uniqId,
      });
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Table
          onRow={(record: any, rowIndex) => {
            return {
              onClick: e => {
                setId(record.key);
                dispatch(getBorrowersDetails(record.key, xTag));
                openBorrowerProfile();
              },
            };
          }}
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 50,
            hideOnSinglePage: true,
            position: ["bottomLeft"],
          }}
          className="borrowers-table row-select"
        />
      )}
      {!borrowers?.isLastPage ? (
        <Box className="pagination-items">
          {lastIds.length <= 2 ? null : (
            <Link onClick={loadPrevPage} className="pagination-item-link prev">
              Previous page
            </Link>
          )}
          <Link onClick={loadNextPage} className="pagination-item-link next">
            Next page
          </Link>
        </Box>
      ) : null}
      <BorrowerDetails
        showModal={showModal}
        setShowModal={setShowModal}
        id={id}
      />
    </>
  );
}
