import { MainLoader, SmsAutomationHeader } from "components/common";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Steps, Button, Space } from "antd";
import "../styles/smsAutomation.css";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { CampaignReviewForm } from "components/forms";
import LaunchCampaignModal from "components/modals/LaunchCampaignModal";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  editCampaign,
  getCampaignToEdit,
} from "redux/actions/smsAutomationActions";
import validationSchema from "constants/validationSchema";
import { RootState } from "types";
import { campaignFrequency } from "../../../constants";
import { filterUsernameOut } from "helpers/utilFunctions";
import { getUserSettings } from "redux/actions/settingsActions";

const { Step } = Steps;

export default function EditCampaign() {
  const history = useHistory();
  const dispatch = useDispatch();
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const userSetting = setting?.settingData?.data;
  const [showLaunchCampaignModal, setShowLaunchCampaignModal] = useState(false);
  const [campaignID, setCampaignID] = useState<any>(null);

  const userData = useSelector(
    (state: RootState) => state.authReducer.loginDataTwo
  );
  const campaignsState = useSelector(
    (state: RootState) => state.smsAutomationReducer
  );
  const campaignToEdit = campaignsState?.campaignToEdit;
  const isLoading = campaignsState?.isLoading;

  const xJwt = localStorage.getItem("x_jwt");

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const id = query.get("id");
    setCampaignID(id);

    dispatch(getUserSettings(xJwt));
    dispatch(getCampaignToEdit(xJwt, id));
  }, [dispatch, history.location.search, xJwt]);

  function getFreqLabel(value: string) {
    const freq = campaignFrequency.find(f => f.value === value);

    return freq?.label || "Select...";
  }

  function saveChanges(values: any) {
    const body = {
      name: `${userSetting?.info?.businessName || userData?.businessName}:${
        values?.name
      }`,
      message: values?.message,
      amount: values?.amount,
    };

    dispatch(editCampaign(xJwt, campaignID, body, dispatch, history));

    setShowLaunchCampaignModal(false);
  }

  return (
    <>
      {isLoading && !campaignToEdit ? (
        <MainLoader />
      ) : (
        <Formik
          initialValues={{
            name: filterUsernameOut(
              campaignToEdit?.name,
              userSetting?.info?.businessName || userData?.businessName
            ),
            amount: campaignToEdit?.arguments?.campaign?.amount,
            message: campaignToEdit?.arguments?.campaign?.message,

            // Use to handle the default message
            messageIndex: -1,
            savedMsgOnEdit: campaignToEdit?.arguments?.campaign?.message,

            frequency: {
              value:
                campaignToEdit?.arguments?.campaign?.frequency || "Select...",
              label: getFreqLabel(
                campaignToEdit?.arguments?.campaign?.frequency
              ),
            },
            selectedDays: campaignToEdit?.arguments?.campaign?.selectedDays,
            startDate: campaignToEdit?.arguments?.campaign?.startDate,
            endDate: campaignToEdit?.arguments?.campaign?.endDate,
            isContinous: !campaignToEdit?.arguments?.campaign?.endDate,
          }}
          validationSchema={yup.object().shape({
            ...validationSchema.campaignContent,
            ...validationSchema.campaignSchedule,
          })}
          onSubmit={() => setShowLaunchCampaignModal(true)}
        >
          <Form>
            <SmsAutomationHeader title="Edit Campaign" />

            <Stack spacing={8} h="full">
              <Flex
                gap="12"
                rowGap="8"
                align="center"
                justifyContent="space-between"
                wrap={{ base: "wrap", lg: "nowrap" }}
                className="campaign-steps"
              >
                <Steps>
                  <Step title="Content" status="finish" />
                  <Step title="Schedule" status="finish" />
                  <Step title="Review and submit" status="finish" />
                </Steps>

                <Space size="middle" style={{ marginLeft: "auto" }}>
                  <Button
                    size="large"
                    type="ghost"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>

                  <Button size="large" type="primary" htmlType="submit">
                    Save and Launch
                  </Button>
                </Space>
              </Flex>

              <Box flex="1" className="sms-box">
                <Box className="content-form">
                  <CampaignReviewForm isFormForEdit={true} />
                </Box>
              </Box>
            </Stack>

            <LaunchCampaignModal
              showModal={showLaunchCampaignModal}
              setShowModal={setShowLaunchCampaignModal}
              launchCampaign={saveChanges}
              isFormForEdit={true}
            />
          </Form>
        </Formik>
      )}
    </>
  );
}
