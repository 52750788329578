// import { whiten } from "@chakra-ui/theme-tools";

export const InputStyles = {
  // The styles all Inputs have in common
  baseStyle: {
    field: {},
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    search: {
      field: {
        h: "28px",
        boxShadow: "0 0 0 1px #060088",
        border: "none",
        _hover: {
          boxShadow: "0 0 0 1.5px #3C5F95",
          border: "none",
        },
        _active: {
          boxShadow: "0 0 0 1.5px #3C5F95",
          border: "none",
        },
        _focus: {
          boxShadow: "0 0 0 1.5px #3C5F95",
          border: "none",
        },
      },
    },
  },
  // default values for `size` and `variant`
  defaultProps: {},
};
