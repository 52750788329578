import { LoginResponse } from "types/responses";
import {
  LOGIN_USER_ACTION_FAILURE,
  LOGIN_USER_ACTION_SUCCESS,
  SIGNUP_LENDER_ACTION_SUCCESS,
  SIGNUP_LENDER_ACTION_FAILURE,
  RESET_PASSWORD_ACTION_SUCCESS,
  RESET_PASSWORD_ACTION_FAILURE,
  AUTH_LOADING_TRUE,
  AUTH_LOADING_FALSE,
  VERIFY_EMAIL_ACTION_FAILURE,
  VERIFY_EMAIL_ACTION_SUCCESS,
  RESET_VERIFY_EMAIL_STATE,
  VERIFICATION_ONBOARDING_SUCCESS,
  IS_VERIFICATION_EMAIL_START,
  IS_VERIFICATION_EMAIL_END,
  LOGIN_USER_2FA_ACTION_FAILURE,
  LOGIN_USER_2FA_ACTION_SUCCESS,
  LOGIN_USER_2FA_LOADING_TRUE,
  LOGIN_USER_2FA_LOADING_FALSE,
  ENABLE_2FA_ACTION_FAILURE,
  ENABLE_2FA_ACTION_SUCCESS,
  GET_USER_PROFILE_ACTION_SUCCESS,
  GET_USERID_ACTION_FAILED,
  TWO_FA_NEEDED_FOR_LOGIN,
} from "../actions/actionTypes";

export interface I2FAInfoResponse {
  twoFactor?: boolean;
  secret: string;
  QR: string;
  recoveryCode?: string;
}
export interface AuthState {
  isLoading: boolean;
  login: LoginResponse;
  isTwoFANeededForLogin: boolean;
  isLoading2FALogin: boolean;
  loginDataTwo: any;
  twoFAInfo: I2FAInfoResponse;
  signup: { message: string };
  signupSuccess: boolean;
  signupFailed: boolean;
  resetPasswordSuccess: boolean;
  resetPasswordFailed: boolean;
  verifyEmailSuccess: boolean;
  verifyEmailFailed: boolean;
  verification: any;
  isVerifyingEmail: boolean;
}

const initialState: AuthState = {
  isLoading: false,
  isLoading2FALogin: false,
  isTwoFANeededForLogin: false,
  login: {} as LoginResponse,
  loginDataTwo: {},
  twoFAInfo: {} as I2FAInfoResponse,
  signup: {} as { message: string },
  signupSuccess: false,
  signupFailed: false,
  resetPasswordSuccess: false,
  resetPasswordFailed: false,
  verifyEmailSuccess: false,
  verifyEmailFailed: false,
  verification: {},
  isVerifyingEmail: false,
};

function authReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_USER_ACTION_SUCCESS:
      return {
        ...state,
        login: payload,
      };
    case LOGIN_USER_ACTION_FAILURE:
      return {
        ...state,
      };
    case TWO_FA_NEEDED_FOR_LOGIN:
      return {
        ...state,
        isTwoFANeededForLogin: payload,
      };
    case LOGIN_USER_2FA_ACTION_SUCCESS:
      return {
        ...state,
        loginDataTwo: payload,
      };
    case LOGIN_USER_2FA_ACTION_FAILURE:
      return {
        ...state,
      };
    case ENABLE_2FA_ACTION_SUCCESS:
      return {
        ...state,
        twoFAInfo: payload,
      };
    case ENABLE_2FA_ACTION_FAILURE:
      return {
        ...state,
      };
    case GET_USER_PROFILE_ACTION_SUCCESS:
      return {
        ...state,
        loginDataTwo: payload,
      };
    case GET_USERID_ACTION_FAILED:
      return {
        ...state,
      };
    case SIGNUP_LENDER_ACTION_SUCCESS:
      return {
        ...state,
        signup: payload,
        signupSuccess: true,
        isLoading: false,
      };
    case SIGNUP_LENDER_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        signup: payload,
        signupFailed: true,
      };
    case RESET_PASSWORD_ACTION_FAILURE:
      return {
        ...state,
        resetPasswordFailed: true,
      };
    case RESET_PASSWORD_ACTION_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: true,
      };
    case AUTH_LOADING_TRUE:
      return {
        ...state,
        isLoading: true,
        signup: { message: "" },
      };
    case AUTH_LOADING_FALSE:
      return {
        ...state,
        isLoading: false,
      };
    case LOGIN_USER_2FA_LOADING_TRUE:
      return {
        ...state,
        isLoading2FALogin: true,
      };
    case LOGIN_USER_2FA_LOADING_FALSE:
      return {
        ...state,
        isLoading2FALogin: false,
      };
    case IS_VERIFICATION_EMAIL_START:
      return {
        ...state,
        isVerifyingEmail: true,
      };
    case IS_VERIFICATION_EMAIL_END:
      return {
        ...state,
        isVerifyingEmail: false,
      };
    case VERIFY_EMAIL_ACTION_SUCCESS:
      return {
        ...state,
        verifyEmailSuccess: true,
        isVerifyingEmail: false,
      };
    case VERIFY_EMAIL_ACTION_FAILURE:
      return {
        ...state,
        verifyEmailFailed: true,
        isVerifyingEmail: false,
      };
    case RESET_VERIFY_EMAIL_STATE: {
      return {
        ...state,
        verifyEmailFailed: false,
        verifyEmailSuccess: false,
      };
    }
    case VERIFICATION_ONBOARDING_SUCCESS: {
      return {
        ...state,
        verification: payload,
      };
    }

    default:
      return state;
  }
}

export default authReducer;
