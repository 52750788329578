import React from "react";
import moment from "moment";
import { Form, DatePicker } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import "./styles/InputFieldStyles.css";
import { Box, Text } from "@chakra-ui/layout";
import { Input } from "@chakra-ui/react";

const FormItem = Form.Item;

interface IDateInput {
  form: any;
  style: any;
  error: any;
  label: string;
  hasLabel: boolean;
  disablePreviousDate?: boolean;
  disabled?: boolean;
}

const StartDateInput = ({
  error,
  form: {
    setFieldValue,
    values: { startDate },
  },
  label,
  hasLabel,
  disablePreviousDate,
  disabled,
}: IDateInput) => {
  let wrapperClass = "form-group";

  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  return (
    <Box as={FormItem} my={{ base: 2, sm: 6 }} className={wrapperClass}>
      {hasLabel && (
        <Box d="flex" mb="1" className="label-div">
          <Text className="form-label">{label}</Text>
        </Box>
      )}

      <Box my="3">
        <Input
          onChange={e => setFieldValue("startDate", e.target.value)}
          value={startDate || null}
          className="date-picker"
          clearIcon={false}
          disabled={disabled}
          disabledDate={
            disablePreviousDate
              ? //@ts-ignore
                current => {
                  let customDate = moment().format("YYYY-MM-DD");
                  return current && current < moment(customDate, "YYYY-MM-DD");
                }
              : undefined
          }
          type="date"
        />
      </Box>

      {error && (
        <Box className="error-div">
          <ExclamationCircleFilled className="error-icon" />
          <Text ml="2" className="error-text">
            {error}
          </Text>
        </Box>
      )}
    </Box>
  );
};
export default StartDateInput;
