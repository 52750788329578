import React from "react";
import { Text, Box } from "@chakra-ui/react";
import { Switch } from "antd";

interface IOnboardingToggler {
  checked: boolean;
  setChecked: (bool: boolean) => void;
}

export default function OnboardingToggler({
  checked,
  setChecked,
}: IOnboardingToggler) {
  const handleOnboardToggler = () => {
    setChecked(!checked);
  };

  return (
    <Box className="onboarding-toggler">
      {" "}
      <Switch
        size="small"
        checked={checked}
        onChange={handleOnboardToggler}
        className="tourguide-toggle"
      />
      <Text ml="2" fontSize={{ base: "xx-small", lg: "xs" }}>
        Tour guide
      </Text>
    </Box>
  );
}
