import React from "react";
import { Stat } from "types";
import { Box, Heading, Text } from "@chakra-ui/react";

const LoanActivityStat = ({ label, value }: Stat) => {
  return (
    <Box w="50%" mb="4">
      <Heading as="h5" fontSize="xx-small" fontWeight="semibold" mb="2">
        {label}
      </Heading>
      <Text fontWeight="bold" fontSize={{ base: "sm", md: "md" }}>
        {value}
      </Text>
    </Box>
  );
};

export default LoanActivityStat;
