import {
  Flex,
  ButtonGroup,
  IconButton,
  Text,
  Button,
  Icon,
} from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BiReset } from "react-icons/bi";
import { CampaignFields } from "views/main/smsAutomation/CreateNewCampaign";
import TextareaInput from "./TextareaInput";
import { useSelector } from "react-redux";
import { RootState } from "types";
import { useEffect } from "react";
import { capitalizeFirstLetter } from "helpers/utilFunctions";

interface IProps {
  isFormForEdit?: boolean;
}

function SMSInputField({ isFormForEdit = false }: IProps) {
  const { values, handleBlur, handleChange, setFieldValue, errors } =
    useFormikContext<CampaignFields>();

  const dashboardState = useSelector(
    (state: RootState) => state.dashboardReducer
  );
  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const userSetting = settingsState?.settingData?.data;

  const isUssdOn = !userSetting?.ussdOffline;
  const ussdCode = `*347*780${
    userSetting?.ussdCode ? "*" + userSetting?.ussdCode : ""
  }#`;
  const loanLink = dashboardState?.operationData?.data?.link;
  const firstName = userSetting?.info?.firstName;
  const lastName = userSetting?.info?.lastName;

  const username =
    capitalizeFirstLetter(firstName) + " " + capitalizeFirstLetter(lastName);

  function resetMessage() {
    setFieldValue("message", values.savedMsgOnEdit);
    setFieldValue("messageIndex", -1);
  }

  function getMessage(index: number) {
    return predefinedMessages[index];
  }

  const predefinedMessages = [
    isUssdOn
      ? `Meet your urgent needs with a quick loan from ${username}. Dial ${ussdCode} or visit ${loanLink} to apply TODAY!`
      : `Meet your urgent needs with a quick loan from ${username}. Visit ${loanLink} to apply TODAY!`,
    isUssdOn
      ? `Get a quick loan from ${username}. Dial ${ussdCode} or visit ${loanLink} to apply now!`
      : `Get a quick loan from ${username}. Visit ${loanLink} to apply now!`,
    isUssdOn
      ? `Get an instant loan with easy repayment plans from ${username}. Dial ${ussdCode} or visit ${loanLink} to apply.`
      : `Get an instant loan with easy repayment plans from ${username}. Visit ${loanLink} to apply.`,
  ];

  useEffect(() => {
    if (values.messageIndex === 0)
      setFieldValue("message", predefinedMessages[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setFieldValue,
    values.message,
    isUssdOn,
    ussdCode,
    loanLink,
    firstName,
    lastName,
    username,
    values.messageIndex,
  ]);

  return (
    <TextareaInput
      className="sms-input-field"
      name="message"
      hasLabel
      label="Message"
      placeholder="Type in your message"
      value={values.message}
      onBlur={() => handleBlur}
      onChange={handleChange}
      error={errors.message}
      rows={8}
      isDisabled={true}
      topInfo={
        <Flex gap={2} alignItems={"center"}>
          <Text className="form-label">
            Change message ({values.messageIndex + 1} of{" "}
            {predefinedMessages.length})
          </Text>

          <ButtonGroup size="xs">
            <IconButton
              variant={"outline"}
              color="primaryBlue"
              borderColor="primaryBlue"
              borderWidth={1}
              icon={<IoIosArrowBack />}
              aria-label={"Navigate to the previous message"}
              disabled={values.messageIndex <= 0}
              onClick={() => {
                setFieldValue("messageIndex", values.messageIndex - 1);
                setFieldValue("message", getMessage(values.messageIndex - 1));
              }}
            />
            <IconButton
              variant={"outline"}
              color="primaryBlue"
              borderColor="primaryBlue"
              borderWidth={1}
              icon={<IoIosArrowForward />}
              aria-label={"Navigate to the next message"}
              disabled={values.messageIndex + 1 >= predefinedMessages.length}
              onClick={() => {
                setFieldValue("messageIndex", values.messageIndex + 1);
                setFieldValue("message", getMessage(values.messageIndex + 1));
              }}
            />

            {isFormForEdit && (
              <Button onClick={resetMessage}>
                <Text display={["none", "block"]}>Reset</Text>
                <Icon as={BiReset} display={["block", "none"]} />
              </Button>
            )}
          </ButtonGroup>
        </Flex>
      }
    />
  );
}

export default SMSInputField;
