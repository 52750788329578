import React from "react";
import { Input } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import "./styles/InputFieldStyles.css";
import { Box, Text, Flex } from "@chakra-ui/layout";

interface TextareaInputProps {
  name: string;
  onChange: (e: any) => void;
  onBlur: () => void;
  placeholder: string;
  value?: string;
  error: any;
  hasLabel?: boolean;
  label?: string;
  isDisabled?: boolean;
  required?: boolean;
  rows?: number;
  desc?: string;
  topInfo?: React.ReactNode;
  className?: string;
}

const { TextArea } = Input;

const TextareaInput = ({
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  hasLabel,
  label,
  isDisabled,
  required,
  rows,
  desc,
  topInfo,
  className,
}: TextareaInputProps) => {
  let fieldClassName = "custom-textarea";
  if (className) {
    fieldClassName += ` ${className}`;
  }

  return (
    <Box my={4} className="form-group">
      {hasLabel && (
        <Flex mb="1" alignItems="center" gap={2} className="label-div">
          <Text className="form-label" flex={1}>
            {label}
          </Text>

          {topInfo}
        </Flex>
      )}
      <Box
        as={TextArea}
        name={name}
        my={2}
        placeholder={placeholder}
        className={fieldClassName}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={isDisabled}
        required={required}
        rows={rows ? rows : 4}
      />
      {error && (
        <Box className="error-div">
          <ExclamationCircleFilled className="error-icon" />
          <p className="error-text">{error}</p>
        </Box>
      )}
      {desc && (
        <Box className="input-desc">
          <p className="input-desc-text">{desc}</p>
        </Box>
      )}
    </Box>
  );
};

export default TextareaInput;
