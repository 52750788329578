import React from "react";
import {
  Heading,
  Button,
  useBreakpointValue,
  Box,
  HStack,
  Link,
  Image,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { InputField, ResultStatus } from "components/common";
import veendLogo from "../../assets/veend-logo.png";
import "./styles/auth.css";
import { useSelector, useDispatch } from "react-redux";
import { lenderSignup, saveToSheet } from "redux/actions/authActions";
import validationSchema from "constants/validationSchema";
import { goToLoginPage } from "../../helpers/utilFunctions";
import { RootState } from "types";

interface SignupValues {
  firstName: string;
  lastName: string;
  businessName: string;
  email: string;
  phoneNumber: string;
  password: string;
}

export default function LenderSignup(): JSX.Element {
  const signupState = useSelector((state: RootState) => state.authReducer);
  const signupSuccess = signupState?.signupSuccess;
  const signupFailed = signupState?.signupFailed;
  const signup = signupState?.signup;
  const emailExists = signup?.message?.includes("This email already exist")
    ? true
    : false;
  const isLoading = signupState?.isLoading;
  const dispatch = useDispatch();
  // post to server
  const postSignup = (values: SignupValues) => {
    let signupData = {
      firstName: values.firstName,
      lastName: values.lastName,
      businessName: values.businessName,
      email: values.email,
      phonenumber: values.phoneNumber,
      password: values.password,
    };
    return dispatch(lenderSignup(signupData));
  };

  const size = useBreakpointValue({ base: "sm", md: "sm", lg: "md" });

  const renderResult = () => {
    if (signupSuccess === true) {
      return (
        <ResultStatus
          status="success"
          title="Sign up successful"
          subTitle="Please check your inbox for verification email"
          btnText="Go to Login"
          redirect={goToLoginPage}
        />
      );
    } else if (signupFailed === true) {
      return (
        <ResultStatus
          status="error"
          title="Sign up failed"
          subTitle={`${signup?.message}`}
          btnText="Try Again"
          redirect={() => window.location.reload()}
          hasSecondBtn={emailExists}
          btnText2={"Go to Login"}
          redirect2={goToLoginPage}
        />
      );
    }
  };

  return (
    <>
      <Box>
        <HStack spacing="0">
          <Box w={["100%", "100%", "57%"]} className="signup-form">
            <Box py="4" textAlign="center">
              <Box textAlign="center" className="logo-img-container">
                <Link
                  href="https://veendhq.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={veendLogo}
                    alt="veendHq"
                    className="login-logo-main"
                  />
                </Link>
              </Box>
              <Heading
                as="h1"
                mt="4"
                mb="2"
                fontSize={{ base: "md", md: "md", lg: "lg" }}
              >
                Create your VeendHQ Lending Account
              </Heading>
              <Box my="3" className="signup-form-group">
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    businessName: "",
                    email: "",
                    phoneNumber: "",
                    password: "",
                  }}
                  onSubmit={async (values: SignupValues) => {
                    let lenderData = {
                      data: {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        businessName: values.businessName,
                        email: values.email,
                        phoneNumber: values.phoneNumber,
                      },
                    };
                    postSignup(values);
                    saveToSheet(JSON.stringify(lenderData));
                  }}
                  validationSchema={yup
                    .object()
                    .shape(validationSchema.lenderSignup)}
                >
                  {({ values, handleChange, handleBlur, errors }) => (
                    <Form>
                      <Box className="form-row">
                        <Box className="half-form">
                          <InputField
                            inputType="text"
                            name="firstName"
                            hasLabel
                            label="First Name"
                            placeholder="Enter First Name"
                            value={values.firstName}
                            onBlur={() => handleBlur}
                            onChange={handleChange}
                            error={errors.firstName}
                            required
                          />
                        </Box>
                        <Box className="half-form">
                          <InputField
                            inputType="text"
                            name="lastName"
                            hasLabel
                            label="Last Name"
                            placeholder="Enter Last Name"
                            value={values.lastName}
                            onBlur={() => handleBlur}
                            onChange={handleChange}
                            error={errors.lastName}
                            required
                          />
                        </Box>
                      </Box>
                      <Box className="form-row">
                        <Box className="half-form">
                          <InputField
                            inputType="text"
                            name="businessName"
                            hasLabel
                            label="Registered Company Name"
                            placeholder="Enter Registered Company Name"
                            value={values.businessName}
                            onBlur={() => handleBlur}
                            onChange={handleChange}
                            error={errors.businessName}
                            required
                          />
                        </Box>
                        <Box className="half-form">
                          <InputField
                            inputType="text"
                            name="phoneNumber"
                            hasLabel
                            label="Phone Number"
                            placeholder="Enter Phone Number"
                            value={values.phoneNumber}
                            onBlur={() => handleBlur}
                            onChange={handleChange}
                            error={errors.phoneNumber}
                            required
                          />
                        </Box>
                      </Box>

                      <Box className="form-row">
                        <Box className="half-form">
                          <InputField
                            inputType="email"
                            name="email"
                            hasLabel
                            label="Email Address"
                            placeholder="Enter Email Address"
                            value={values.email}
                            onBlur={() => handleBlur}
                            onChange={handleChange}
                            error={errors.email}
                            required
                          />
                        </Box>
                        <Box className="half-form">
                          <InputField
                            inputType="password"
                            name="password"
                            hasLabel
                            label="Password"
                            placeholder="Enter Password"
                            value={values.password}
                            onBlur={() => handleBlur}
                            onChange={handleChange}
                            error={errors.password}
                            required
                          />
                        </Box>
                      </Box>

                      <Button
                        type="submit"
                        variant="primary"
                        size={size}
                        mt="5"
                        isLoading={isLoading}
                      >
                        Register
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Box>
          <Box w="43%" className="signup-img"></Box>
        </HStack>
      </Box>
      {renderResult()}
    </>
  );
}
