import React from "react";
import { Input, Select } from "antd";
import { Box, Text } from "@chakra-ui/react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { allCountriesCode } from "../../helpers/countries";

import "./styles/inputPhone.css";

interface InputFieldProps {
  name: string;
  onChange: (e: any) => void;
  onBlur: () => void;
  placeholder: string;
  value?: string | number;
  error: any;
  inputType: string;
  hasLabel?: boolean;
  label?: string;
  isDisabled?: boolean;
  defaultValue?: string;
  prefix?: any;
  required?: boolean;
  desc?: string;
}

const InputPhoneField = ({
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  inputType,
  hasLabel,
  label,
  isDisabled,
  defaultValue,
  prefix,
  required,
  desc,
}: InputFieldProps) => {
  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }
  return (
    <Box my="0" className={wrapperClass}>
      {hasLabel && (
        <Box
          d="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
          mb="0"
          className="label-div"
        >
          <Text className="form-label">{label}</Text>
        </Box>
      )}
      <Box
        as={Input}
        type={inputType}
        name={name}
        my="2"
        className="custom-phone-input"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={isDisabled}
        defaultValue={defaultValue}
        prefix={prefix}
        required={required}
        addonBefore={
          <Select
            defaultValue="+234"
            bordered={false}
            showSearch
            options={allCountriesCode}
            filterOption={(input, option) =>
              (option!.label as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA!.label as string)
                .toLowerCase()
                .localeCompare((optionB!.label as string).toLowerCase())
            }
            style={{ width: "80px" }}
            onSelect={(val, opt) => {}}
            optionLabelProp="value"
            id="dialCode"
          />
        }
      />
      {error && (
        <Box className="error-div">
          <ExclamationCircleFilled className="error-icon" />
          <Text className="error-text">{error}</Text>
        </Box>
      )}
      {desc && (
        <Box className="input-desc">
          <Text className="input-desc-text">{desc}</Text>
        </Box>
      )}
    </Box>
  );
};

export default InputPhoneField;
