import React from "react";
import { Stat } from "types";
import { Box, GridItem, Text } from "@chakra-ui/react";

interface LoanStatCardProps extends Stat {
  icon: React.ReactNode;
}

const LoanStatCard = ({ label, value, icon }: LoanStatCardProps) => {
  return (
    <GridItem w="100%" h="140px" m="0" className="loan-card">
      {icon}

      <Box ml="3">
        <Text fontWeight="bold" fontSize="lg">
          {value}
        </Text>
        <Text fontSize="xs" fontWeight="semibold">
          {label}
        </Text>
      </Box>
    </GridItem>
  );
};

export default LoanStatCard;
