import { CampaignFrequency } from "types";

export const baseUrl = "https://api.veendhq.com";

export const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/; //eslint-disable-line

export const commaSeparatedPhoneRegExp =
  /^[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9](,*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9])*$/; //eslint-disable-line

export const digitRegExp = /^[0-9]*$/;

export const zeroToOneDigitRegExp = /^(0.0*[1-9](\d+)?)$/;

export const floatingPointRegExp = /[+-]?([0-9]*[.])?[0-9]+/;

export const COST_PER_SMS = 4;

export const UPLOADED_CONTACTS_LIMIT_PER_PAGE = 50;

export const uploadxTag =
  "OTkzMDY5ZWU2ZjBiOWQzNTJhZWI0MTAzMjBjOTEyMzUzZWIxODI1MzM5NzY1MTU5YmFhZWU2NDRiNGQzYzg1YS8vLy8vLzg4NjY=";

export const bearer =
  "Yzg0MDI4YzVlZmUzODIxNjY0N2I3ZmNkNzNhNDEwODNiNWMxM2UyNWM4ZDhhZjEyNzA4MjBjNTYyNDJhNjkyMi8vLy8vLzE1Njc=";

export const fileStackKey = "AqimS73ASB2NND5mKOgfQz";

export const uploadUrl = "https://file.workova.co/upload";

export const industryList = [
  {
    value: "Accounting ",
    label: "Accounting ",
  },
  {
    value: "Airlines/Aviation",
    label: "Airlines/Aviation",
  },
  {
    value: "Alternative Dispute Resolution",
    label: "Alternative Dispute Resolution",
  },
  {
    value: "Alternative Medicine",
    label: "Alternative Medicine",
  },
  {
    value: "Animation",
    label: "Animation",
  },
  {
    value: "Apparel/Fashion",
    label: "Apparel/Fashion",
  },
  {
    value: "Architecture/Planning",
    label: "Architecture/Planning",
  },
  {
    value: "Arts/Crafts",
    label: "Arts/Crafts",
  },
  {
    value: "Automotive",
    label: "Automotive",
  },
  {
    value: "Aviation/Aerospace",
    label: "Aviation/Aerospace",
  },
  {
    value: "Banking/Mortgage",
    label: "Banking/Mortgage",
  },
  {
    value: "Biotechnology/Greentech",
    label: "Biotechnology/Greentech",
  },
  {
    value: "Broadcast Media",
    label: "Broadcast Media",
  },
  {
    value: "Building Materials",
    label: "Building Materials",
  },
  {
    value: "Business Supplies/Equipment",
    label: "Business Supplies/Equipment",
  },
  {
    value: "Capital Markets/Hedge Fund/Private Equity",
    label: "Capital Markets/Hedge Fund/Private Equity",
  },
  {
    value: "Chemicals",
    label: "Chemicals",
  },
  {
    value: "Civic/Social Organization",
    label: "Civic/Social Organization",
  },
  {
    value: "Civil Engineering",
    label: "Civil Engineering",
  },
  {
    value: "Commercial Real Estate",
    label: "Commercial Real Estate",
  },
  {
    value: "Computer Games",
    label: "Computer Games",
  },
  {
    value: "Computer Hardware",
    label: "Computer Hardware",
  },
  {
    value: "Computer Networking",
    label: "Computer Networking",
  },
  {
    value: "Computer Software/Engineering",
    label: "Computer Software/Engineering",
  },
  {
    value: "Computer/Network Security",
    label: "Computer/Network Security",
  },
  {
    value: "Construction",
    label: "Construction",
  },
  {
    value: "Consumer Electronics",
    label: "Consumer Electronics",
  },
  {
    value: "Consumer Goods",
    label: "Consumer Goods",
  },
  {
    value: "Consumer Services",
    label: "Consumer Services",
  },
  {
    value: "Cosmetics",
    label: "Cosmetics",
  },
  {
    value: "Dairy",
    label: "Dairy",
  },
  {
    value: "Defense/Space",
    label: "Defense/Space",
  },
  {
    value: "Design",
    label: "Design",
  },
  {
    value: "E-Learning",
    label: "E-Learning",
  },
  {
    value: "Education Management",
    label: "Education Management",
  },
  {
    value: "Electrical/Electronic Manufacturing",
    label: "Electrical/Electronic Manufacturing",
  },
  {
    value: "Entertainment/Movie Production",
    label: "Entertainment/Movie Production",
  },
  {
    value: "Environmental Services",
    label: "Environmental Services",
  },
  {
    value: "Events Services",
    label: "Events Services",
  },
  {
    value: "Executive Office",
    label: "Executive Office",
  },
  {
    value: "Facilities Services",
    label: "Facilities Services",
  },
  {
    value: "Farming",
    label: "Farming",
  },
  {
    value: "Financial Services",
    label: "Financial Services",
  },
  {
    value: "Fine Art",
    label: "Fine Art",
  },
  {
    value: "Fishery",
    label: "Fishery",
  },
  {
    value: "Food Production",
    label: "Food Production",
  },
  {
    value: "Food/Beverages",
    label: "Food/Beverages",
  },
  {
    value: "Fundraising",
    label: "Fundraising",
  },
  {
    value: "Furniture",
    label: "Furniture",
  },
  {
    value: "Gambling/Casinos",
    label: "Gambling/Casinos",
  },
  {
    value: "Glass/Ceramics/Concrete",
    label: "Glass/Ceramics/Concrete",
  },
  {
    value: "Government Administration",
    label: "Government Administration",
  },
  {
    value: "Government Relations",
    label: "Government Relations",
  },
  {
    value: "Graphic Design/Web Design",
    label: "Graphic Design/Web Design",
  },
  {
    value: "Health/Fitness",
    label: "Health/Fitness",
  },
  {
    value: "Higher Education/Acadamia",
    label: "Higher Education/Acadamia",
  },
  {
    value: "Hospital/Health Care",
    label: "Hospital/Health Care",
  },
  {
    value: "Hospitality",
    label: "Hospitality",
  },
  {
    value: "Human Resources/HR",
    label: "Human Resources/HR",
  },
  {
    value: "Import/Export",
    label: "Import/Export",
  },
  {
    value: "Individual/Family Services",
    label: "Individual/Family Services",
  },
  {
    value: "Industrial Automation",
    label: "Industrial Automation",
  },
  {
    value: "Information Services",
    label: "Information Services",
  },
  {
    value: "Information Technology/IT",
    label: "Information Technology/IT",
  },
  {
    value: "Insurance",
    label: "Insurance",
  },
  {
    value: "International Affairs",
    label: "International Affairs",
  },
  {
    value: "International Trade/Development",
    label: "International Trade/Development",
  },
  {
    value: "Internet",
    label: "Internet",
  },
  {
    value: "Investment Banking/Venture",
    label: "Investment Banking/Venture",
  },
  {
    value: "Investment Management/Hedge Fund/Private Equity",
    label: "Investment Management/Hedge Fund/Private Equity",
  },
  {
    value: "Judiciary",
    label: "Judiciary",
  },
  {
    value: "Law Enforcement",
    label: "Law Enforcement",
  },
  {
    value: "Law Practice/Law Firms",
    label: "Law Practice/Law Firms",
  },
  {
    value: "Legal Services",
    label: "Legal Services",
  },
  {
    value: "Legislative Office",
    label: "Legislative Office",
  },
  {
    value: "Leisure/Travel",
    label: "Leisure/Travel",
  },
  {
    value: "Library",
    label: "Library",
  },
  {
    value: "Logistics/Procurement",
    label: "Logistics/Procurement",
  },
  {
    value: "Luxury Goods/Jewelry",
    label: "Luxury Goods/Jewelry",
  },
  {
    value: "Machinery",
    label: "Machinery",
  },
  {
    value: "Management Consulting",
    label: "Management Consulting",
  },
  {
    value: "Maritime",
    label: "Maritime",
  },
  {
    value: "Market Research",
    label: "Market Research",
  },
  {
    value: "Marketing/Advertising/Sales",
    label: "Marketing/Advertising/Sales",
  },
  {
    value: "Mechanical or Industrial Engineering",
    label: "Mechanical or Industrial Engineering",
  },
  {
    value: "Media Production",
    label: "Media Production",
  },
  {
    value: "Medical Equipment",
    label: "Medical Equipment",
  },
  {
    value: "Medical Practice",
    label: "Medical Practice",
  },
  {
    value: "Mental Health Care",
    label: "Mental Health Care",
  },
  {
    value: "Military Industry",
    label: "Military Industry",
  },
  {
    value: "Mining/Metals",
    label: "Mining/Metals",
  },
  {
    value: "Motion Pictures/Film",
    label: "Motion Pictures/Film",
  },
  {
    value: "Museums/Institutions",
    label: "Museums/Institutions",
  },
  {
    value: "Music",
    label: "Music",
  },
  {
    value: "Nanotechnology",
    label: "Nanotechnology",
  },
  {
    value: "Newspapers/Journalism",
    label: "Newspapers/Journalism",
  },
  {
    value: "Non-Profit/Volunteering",
    label: "Non-Profit/Volunteering",
  },
  {
    value: "Oil/Energy/Solar/Greentech",
    label: "Oil/Energy/Solar/Greentech",
  },
  {
    value: "Online Publishing",
    label: "Online Publishing",
  },
  {
    value: "Other Industry",
    label: "Other Industry",
  },
  {
    value: "Outsourcing/Offshoring",
    label: "Outsourcing/Offshoring",
  },
  {
    value: "Package/Freight Delivery",
    label: "Package/Freight Delivery",
  },
  {
    value: "Packaging/Containers",
    label: "Packaging/Containers",
  },
  {
    value: "Paper/Forest Products",
    label: "Paper/Forest Products",
  },
  {
    value: "Performing Arts",
    label: "Performing Arts",
  },
  {
    value: "Pharmaceuticals",
    label: "Pharmaceuticals",
  },
  {
    value: "Philanthropy",
    label: "Philanthropy",
  },
  {
    value: "Photography",
    label: "Photography",
  },
  {
    value: "Plastics",
    label: "Plastics",
  },
  {
    value: "Political Organization",
    label: "Political Organization",
  },
  {
    value: "Primary/Secondary Education",
    label: "Primary/Secondary Education",
  },
  {
    value: "Printing",
    label: "Printing",
  },
  {
    value: "Professional Training",
    label: "Professional Training",
  },
  {
    value: "Program Development",
    label: "Program Development",
  },
  {
    value: "Public Relations/PR",
    label: "Public Relations/PR",
  },
  {
    value: "Public Safety",
    label: "Public Safety",
  },
  {
    value: "Publishing Industry",
    label: "Publishing Industry",
  },
  {
    value: "Railroad Manufacture",
    label: "Railroad Manufacture",
  },
  {
    value: "Ranching",
    label: "Ranching",
  },
  {
    value: "Real Estate/Mortgage",
    label: "Real Estate/Mortgage",
  },
  {
    value: "Recreational Facilities/Services",
    label: "Recreational Facilities/Services",
  },
  {
    value: "Religious Institutions",
    label: "Religious Institutions",
  },
  {
    value: "Renewables/Environment",
    label: "Renewables/Environment",
  },
  {
    value: "Research Industry",
    label: "Research Industry",
  },
  {
    value: "Restaurants",
    label: "Restaurants",
  },
  {
    value: "Retail Industry",
    label: "Retail Industry",
  },
  {
    value: "Security/Investigations",
    label: "Security/Investigations",
  },
  {
    value: "Semiconductors",
    label: "Semiconductors",
  },
  {
    value: "Shipbuilding",
    label: "Shipbuilding",
  },
  {
    value: "Sporting Goods",
    label: "Sporting Goods",
  },
  {
    value: "Sports",
    label: "Sports",
  },
  {
    value: "Staffing/Recruiting",
    label: "Staffing/Recruiting",
  },
  {
    value: "Supermarkets",
    label: "Supermarkets",
  },
  {
    value: "Telecommunications",
    label: "Telecommunications",
  },
  {
    value: "Textiles",
    label: "Textiles",
  },
  {
    value: "Think Tanks",
    label: "Think Tanks",
  },
  {
    value: "Tobacco",
    label: "Tobacco",
  },
  {
    value: "Translation/Localization",
    label: "Translation/Localization",
  },
  {
    value: "Transportation",
    label: "Transportation",
  },
  {
    value: "Utilities",
    label: "Utilities",
  },
  {
    value: "Venture Capital/VC",
    label: "Venture Capital/VC",
  },
  {
    value: "Veterinary",
    label: "Veterinary",
  },
  {
    value: "Warehousing",
    label: "Warehousing",
  },
  {
    value: "Wholesale",
    label: "Wholesale",
  },
  {
    value: "Wine/Spirits",
    label: "Wine/Spirits",
  },
  {
    value: "Wireless",
    label: "Wireless",
  },
  {
    value: "Writing/Editing",
    label: "Writing/Editing",
  },
];

export const DateTimeFormat = "MMM Do YYYY, HH:mm";

export const dateInputFormat = "YYYY-MM-DD";

export const disburseColor = (status: any) => {
  if (status === "processing") {
    return { color: "#FFBF00", width: "0.65rem", height: "0.65rem" };
  } else if (status === "approved" || status === "completed") {
    return { color: "#118d4e", width: "0.65rem", height: "0.65rem" };
  } else {
    return { color: "#ffa288", width: "0.65rem", height: "0.65rem" };
  }
};

export const statusColor = (status: string) => {
  if (status === "active") {
    return { color: "#118d4e" };
  } else if (status === "closed") {
    return { color: "#ffa288" };
  } else {
    return { color: "#FFBF00" };
  }
};

export const capitalizeString = (string: string) => {
  return string && string[0].toUpperCase() + string.slice(1);
};

export const banksList: Record<string, string> = {
  "101": "Providus Bank",
  "214": "FIRST CITY MONUMENT BANK PLC",
  "215": "UNITY BANK PLC",
  "221": "STANBIC IBTC BANK PLC",
  "232": "STERLING BANK PLC",
  "301": "JAIZ BANK",
  "304": "Stanbic Mobile",
  "305": "PAYCOM",
  "307": "Ecobank Mobile",
  "309": "FBN MOBILE",
  "311": "Parkway",
  "315": "GTBank Mobile Money",
  "322": "ZENITH Mobile",
  "323": "ACCESS MONEY",
  "401": "Aso Savings and Loans",
  "526": "PARALLEX BANK",
  "552": "NPF Microfinance Bank",
  "601": "FSDH Merchant Bank",
  "100033": "PalmPay",
  "044": "ACCESS BANK NIGERIA",
  "014": "AFRIBANK NIGERIA PLC",
  "063": "ACCESS BANK PLC (DIAMOND)",
  "050": "ECOBANK NIGERIA PLC",
  "084": "ENTERPRISE BANK LIMITED",
  "070": "FIDELITY BANK PLC",
  "011": "FIRST BANK PLC",
  "058": "GTBANK PLC",
  "030": "HERITAGE BANK",
  "082": "KEYSTONE BANK PLC",
  "076": "POLARIS BANK PLC",
  "068": "STANDARD CHARTERED BANK NIGERIA LIMITED",
  "032": "UNION BANK OF NIGERIA PLC",
  "033": "UNITED BANK FOR AFRICA PLC",
  "035": "WEMA BANK PLC",
  "057": "ZENITH BANK PLC",
  "023": "Citibank",
  "090175": "RUBIES MFB (HIGHSTREET)",
  "090115": "TCF MFB",
  "090267": "Kuda Microfinance Bank",
  "000026": "TAJ BANK",
  "070010": "ABBEY MORTGAGE BANK",
  "090317": "PatrickGold MFB",
  "090281": "Mint-Finex MFB",
  "090328": "EYOWO MICROFINANCE BANK",
};

export const ProductKeys = {
  disbursements:
    process.env.REACT_APP_LOAN_DISBURSEMENT_PRODUCT_ID ||
    "65d878bdbdf2c20ae9b4df47,5a9297612032791140ff390f",
  remita: "60d38908a64ed700280362e8",
  ussd: "614f38d2b7136c0029a51fad",
  sms: "61510230ac43b4002907c064",
  withdrawals: "6157842b101720572946579b",
};

export const campaignFrequency: CampaignFrequency[] = [
  {
    value: "salaryperiod",
    label: "Salary period",
    selectedDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  },
  {
    value: "pre-salaryperiod",
    label: "Pre-salary period",
    selectedDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  },
  {
    value: "weekdays",
    label: "Weekdays",
    selectedDays: ["Mon", "Tue", "Wed", "Thu", "Fri"],
  },
  {
    value: "weekend",
    label: "Weekend",
    selectedDays: ["Fri", "Sat", "Sun"],
  },
  {
    value: "daily",
    label: "Daily",
    selectedDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  },
  {
    value: "custom",
    label: "Custom",
    selectedDays: [],
  },
];

export const idTypes = [
  {
    label: "NIN",
    value: "nin",
  },
  {
    label: "NIN Phone Number",
    value: "phone_number",
  },
  {
    label: "Passport",
    value: "passport",
  },
  {
    label: "Driver's License",
    value: "drivers_license",
  },

  {
    label: "Voter's Card",
    value: "voters_card",
  },
];

export const businessIdTypes = [
  {
    label: "BN",
    value: "BN",
  },
  {
    label: "RC",
    value: "RC",
  },
  {
    label: "IT",
    value: "IT",
  },

  {
    label: "LL",
    value: "LL",
  },
  {
    label: "LLP",
    value: "LLP",
  },
];
