import React from "react";
import { Box } from "@chakra-ui/react";

type Props = {
  children: React.ReactNode;
};

const CustomCard = (props: Props) => {
  return (
    <Box py="10" className="get-started-card" maxW={400}>
      {props.children}
    </Box>
  );
};

export default CustomCard;
