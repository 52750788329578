import { message } from "antd";
import axios from "axios";
import {
  DASHBOARD_ACTION_SUCCESS,
  DASHBOARD_ACTION_FAILURE,
  BALANCE_ACTION_SUCCESS,
  BALANCE_ACTION_FAILURE,
  OPERATION_ACTION_SUCCESS,
  OPERATION_ACTION_FAILURE,
  TRANSACTIONS_TABLE_ACTION_SUCCESS,
  TRANSACTIONS_TABLE_ACTION_FAILURE,
  EXPECTED_CHART_ACTION_SUCCESS,
  EXPECTED_CHART_ACTION_FAILURE,
  NEW_CLIENT_CHART_ACTION_SUCCESS,
  NEW_CLIENT_CHART_ACTION_FAILURE,
  INCOME_CHART_ACTION_SUCCESS,
  INCOME_CHART_ACTION_FAILURE,
  DISBURSE_CHART_ACTION_SUCCESS,
  DISBURSE_CHART_ACTION_FAILURE,
  ERROR_401_ACTION,
  GET_BANKS_ACTION_SUCCESS,
  GET_BANKS_ACTION_FAILED,
  DASH_REQUEST_LOADING_TRUE,
} from "./actionTypes";

const baseUrl = process.env.REACT_APP_BASE_URL;
const txUrl = process.env.REACT_APP_TXURL;

// Dashboard
export const getDashboardData =
  (xTag: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      const res = await axios.get(`${baseUrl}/portfolio?x-tag=1`, config);
      dispatch({
        type: DASHBOARD_ACTION_SUCCESS,
        payload: res?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: DASHBOARD_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// balance
export const getBalance =
  (xTag: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      const res = await axios.get(`${baseUrl}/balance?x-tag=1`, config);
      dispatch({
        type: BALANCE_ACTION_SUCCESS,
        payload: res?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: BALANCE_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// Operations
export const getOperation =
  (xTag: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      const res = await axios.get(`${baseUrl}/operations?x-tag=1`, config);
      dispatch({
        type: OPERATION_ACTION_SUCCESS,
        payload: res?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: OPERATION_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// Transactions Table
export const getTransactionsTable =
  (xJwt: any, accountId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${xJwt}`,
      },
    };
    dispatch({
      type: DASH_REQUEST_LOADING_TRUE,
    });
    try {
      const res = await axios.get(
        `${txUrl}/payments?accountId=${accountId}&populate=Purchases&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: TRANSACTIONS_TABLE_ACTION_SUCCESS,
        payload: res?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: TRANSACTIONS_TABLE_ACTION_FAILURE,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// get more transactions
export const getMoreTransactions =
  (xJwt: any, accountId: any, lastId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${xJwt}`,
      },
    };
    dispatch({
      type: DASH_REQUEST_LOADING_TRUE,
    });
    try {
      const res = await axios.get(
        `${txUrl}/payments?accountId=${accountId}&populate=Purchases&lastId=${lastId}&desc=true&sort=-_id`,
        config
      );
      dispatch({
        type: TRANSACTIONS_TABLE_ACTION_SUCCESS,
        payload: res?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: TRANSACTIONS_TABLE_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// expected chart
export const getExpectedChart =
  (xTag: any, id: number) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      const res = await axios.get(`${baseUrl}/chart/${id}?x-tag=1`, config);
      dispatch({
        type: EXPECTED_CHART_ACTION_SUCCESS,
        payload: res?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: EXPECTED_CHART_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// new client chart
export const getNewClientChart =
  (xTag: any, id: number) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      const res = await axios.get(`${baseUrl}/chart/${id}?x-tag=1`, config);
      dispatch({
        type: NEW_CLIENT_CHART_ACTION_SUCCESS,
        payload: res?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: NEW_CLIENT_CHART_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// income chart
export const getIncomeChart =
  (xTag: any, id: number) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      const res = await axios.get(`${baseUrl}/chart/${id}?x-tag=1`, config);
      dispatch({
        type: INCOME_CHART_ACTION_SUCCESS,
        payload: res?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: INCOME_CHART_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// disburse chart
export const getDisburseChart =
  (xTag: any, id: number) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
      },
    };
    try {
      const res = await axios.get(`${baseUrl}/chart/${id}?x-tag=1`, config);
      dispatch({
        type: DISBURSE_CHART_ACTION_SUCCESS,
        payload: res?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: DISBURSE_CHART_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// get banks list
export const getBanksList =
  (accountID: string, txToken: string | null) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        Authorization: `Bearer ${txToken}`,
        "x-tag": `1`,
      },
    };
    try {
      const res = await axios.post(
        `${txUrl}/v1/products/5a9297612032791140ff390f/variations?accountId=${accountID}`,
        {},
        config
      );
      dispatch({
        type: GET_BANKS_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: GET_BANKS_ACTION_FAILED,
          });
        }
      }
      return error;
    }
  };
