import React from "react";
import { Modal } from "antd";
import { Heading, Text, Button, Box, HStack } from "@chakra-ui/react";
import { Form, Formik, Field } from "formik";
import { InputField, SalaryDateInput, SelectInput } from "components/common";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import moment from "moment";
import { IOptions, RootState } from "types";
import validationSchema from "constants/validationSchema";
import { preApproveBorrower } from "redux/actions/borrowerActions";
import { banksList } from "../../constants";

interface IPreapproveBorrowerProps {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
  id: string;
  afterClose: () => void;
  borrower: any;
}

interface IPreapproveForm {
  preApprovedAmount: string;
  firstName: string;
  lastName: string;
  email: string;
  salaryAmount: string;
  salaryPaymentDate: moment.Moment | null;
  bvn: string;
  accountNumber: string;
  bank: IOptions;
  employer: string;
}

export default function PreapproveBorrower({
  showModal,
  setShowModal,
  id,
  afterClose,
  borrower,
}: IPreapproveBorrowerProps) {
  const dispatch = useDispatch();
  const borrowerState = useSelector(
    (state: RootState) => state.borrowerReducer
  );
  const loadingReq = borrowerState?.requestLoading;
  const phoneNumber = borrower?.phoneNumber;
  const token = borrower?.token;
  const firstName = borrower?.firstName;
  const lastName = borrower?.lastName;
  const email = borrower?.email;
  const bvn = borrower?.bvn;
  const salaryAmount = borrower?.salaryAmount;
  const salaryPaymentDate = borrower?.salaryPaymentDate;
  const preApprovedAmount = borrower?.preApprovedAmount;
  const employer = borrower?.employer;
  const accountNumber = borrower?.accountNumber;
  const bankCode = borrower?.bankCode;

  const populateBanksArray = () => {
    let options = [];
    for (const bankCode in banksList) {
      const selectObj = {
        value: `${bankCode}`,
        label: `${banksList[bankCode]}`,
      };
      options.push(selectObj);
    }
    return options;
  };
  const banks_list = populateBanksArray();
  const bank = banks_list.find(item => item.value === bankCode);
  const dateFormat = "DD/MM/YYYY";
  const xJwt = localStorage.getItem("x_jwt");
  const handleOk = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal
        visible={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={afterClose}
        footer={null}
        width="80%"
        className="loan-profile-modal"
      >
        <Box mb="4" textAlign="center">
          <Heading as="h1" mb="4" fontSize={{ base: "sm", md: "lg", lg: "xl" }}>
            Pre-approve this borrower
          </Heading>
          <Text fontSize={{ base: "xx-small", md: "xs", lg: "sm" }}>
            Phone number -{" "}
            <Text as="span" fontWeight="semibold">{`${phoneNumber}`}</Text>
          </Text>
        </Box>
        <Formik
          initialValues={{
            preApprovedAmount: preApprovedAmount ? preApprovedAmount : "",
            firstName: firstName ? firstName : "",
            lastName: lastName ? lastName : "",
            email: email ? email : "",
            salaryAmount: salaryAmount ? salaryAmount : "",
            salaryPaymentDate: salaryPaymentDate
              ? moment(salaryPaymentDate)
              : null,
            bvn: bvn ? bvn : "",
            accountNumber: accountNumber ? accountNumber : "",
            bank: {
              value: bank ? bank.value : "",
              label: bank ? bank.label : "",
            },
            employer: employer ? employer : "",
          }}
          enableReinitialize
          onSubmit={async (values: IPreapproveForm) => {
            let preApproveBody = {
              preApprovedAmount: values.preApprovedAmount,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              salaryAmount: values.salaryAmount,
              salaryPaymentDate: values.salaryPaymentDate,
              bvn: values.bvn,
              accountNumber: values.accountNumber,
              bankCode: values.bank.value,
              employer: values.employer,
            };
            dispatch(preApproveBorrower(xJwt, id, token, preApproveBody));
            setShowModal(false);
          }}
          validationSchema={yup
            .object()
            .shape(validationSchema.preapproveBorrower)}
        >
          {({ values, handleChange, handleBlur, errors, setFieldValue }) => (
            <Form>
              <HStack justify="space-between">
                <Box className="half-form">
                  <InputField
                    inputType="text"
                    name="firstName"
                    hasLabel
                    label="First Name"
                    placeholder="Enter First Name"
                    value={values.firstName}
                    onBlur={() => handleBlur}
                    onChange={handleChange}
                    error={errors.firstName}
                  />
                </Box>
                <Box className="half-form">
                  <InputField
                    inputType="text"
                    name="lastName"
                    hasLabel
                    label="Last Name"
                    placeholder="Enter Last Name"
                    value={values.lastName}
                    onBlur={() => handleBlur}
                    onChange={handleChange}
                    error={errors.lastName}
                  />
                </Box>
              </HStack>
              <HStack justify="space-between">
                <Box className="half-form">
                  <InputField
                    inputType="text"
                    name="email"
                    hasLabel
                    label="Email"
                    placeholder="Enter Email"
                    value={values.email}
                    onBlur={() => handleBlur}
                    onChange={handleChange}
                    error={errors.email}
                  />
                </Box>
                <Box className="half-form">
                  <InputField
                    inputType="text"
                    name="preApprovedAmount"
                    hasLabel
                    label="Pre-approved Amount"
                    placeholder="Enter Pre-approved Amount"
                    value={values.preApprovedAmount}
                    onBlur={() => handleBlur}
                    onChange={handleChange}
                    error={errors.preApprovedAmount}
                  />
                </Box>
              </HStack>
              <HStack justify="space-between">
                <Box className="half-form">
                  <InputField
                    inputType="text"
                    name="accountNumber"
                    hasLabel
                    label="Account Number"
                    placeholder="Enter Account Number"
                    value={values.accountNumber}
                    onBlur={() => handleBlur}
                    onChange={handleChange}
                    error={errors.accountNumber}
                    isDisabled={accountNumber !== undefined ? true : false}
                  />
                </Box>
                <Box className="half-form">
                  <SelectInput
                    hasLabel
                    label="Bank Name"
                    placeholder="Select Bank"
                    onChange={setFieldValue}
                    error={errors.bank?.value}
                    options={banks_list}
                    value={values.bank}
                    isDisabled={bankCode !== undefined ? true : false}
                    id="bank"
                  />
                </Box>
              </HStack>

              <HStack justify="space-between">
                <Box className="half-form">
                  <InputField
                    inputType="text"
                    name="bvn"
                    hasLabel
                    label="BVN"
                    placeholder="Enter BVN"
                    value={values.bvn}
                    onBlur={() => handleBlur}
                    onChange={handleChange}
                    error={errors.bvn}
                    isDisabled={bvn !== undefined ? true : false}
                  />
                </Box>
                <Box className="half-form">
                  <InputField
                    inputType="text"
                    name="salaryAmount"
                    hasLabel
                    label="Salary Amount"
                    placeholder="Enter Salary Amount"
                    value={values.salaryAmount}
                    onBlur={() => handleBlur}
                    onChange={handleChange}
                    error={errors.salaryAmount}
                  />
                </Box>
              </HStack>

              <HStack justify="space-between">
                <Box className="half-form">
                  <Field
                    name="salaryPaymentDate"
                    type="date"
                    component={SalaryDateInput}
                    hasLabel
                    label="Salary Payment Date"
                    value={values.salaryPaymentDate}
                    format={dateFormat}
                    error={errors.salaryPaymentDate}
                  />
                </Box>
                <Box className="half-form">
                  <InputField
                    inputType="text"
                    name="employer"
                    hasLabel
                    label="Employer"
                    placeholder="Enter Employer"
                    value={values.employer}
                    onBlur={() => handleBlur}
                    onChange={handleChange}
                    error={errors.employer}
                  />
                </Box>
              </HStack>
              <Box style={{ width: "100%", textAlign: "center" }}>
                <Button
                  type="submit"
                  variant="primary"
                  mt="2"
                  size="md"
                  isLoading={loadingReq}
                >
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}
