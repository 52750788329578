function headwayWidget() {
  const HeadwayScript = document.createElement("script");
  HeadwayScript.async = true;
  HeadwayScript.src = "https://cdn.headwayapp.co/widget.js";
  document.head.appendChild(HeadwayScript);

  var config = {
    selector: ".nav-notification",
    account: "7OVZVx",
    trigger: ".headway-trigger",
    translations: {
      title: "Product Updates",
      labels: { new: "News", improvement: "Updates", fix: "Fixes" },
      footer: "Read more 👉",
    },
  };
  HeadwayScript.onload = function () {
    window.Headway.init(config);
  };
}

export default headwayWidget;
