import {
  Box,
  Button,
  Heading,
  HStack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { message, Tabs } from "antd";
import {
  // CountsBarChart,
  DisburseBarChart,
  DoughnutChart,
  IncomeBarChart,
  NewClientsLineChart,
  RepaymentBarChart,
} from "components/charts";
import {
  BalanceCard,
  ColendingDisplay,
  DashboardHeader,
  LoanActivityStat,
  LoanOptionCard,
  MainLoader,
  PortfolioStat,
} from "components/common";
import { ConfirmColendingToggle, CreateAccountModal } from "components/modals";
import {
  formatNumberAmount,
  isReadOnly,
  userAccess,
} from "helpers/utilFunctions";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getUserId } from "redux/actions/generalActions";
import { RootState } from "types";
import { dashboardPageSteps } from "../../constants/tourSteps";
import {
  getBalance,
  getDashboardData,
  getOperation,
} from "../../redux/actions/dashboardAction";
import "./styles/dashboard.css";

const { TabPane } = Tabs;

export default function Dashboard() {
  const [showAccountModal, setShowAccountModal] = useState(false);
  const data = useSelector((state: RootState) => state.dashboardReducer);
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const generalState = useSelector((state: RootState) => state.generalReducer);
  const userId = generalState?.userId?.users?.[0];
  const userSetting = setting?.settingData?.data;
  const supportEmail = userSetting?.supportEmail;
  const supportPhone = userSetting?.supportPhone;
  const orignateAi = userSetting?.colending;
  const accountId = userSetting?.apiCredentials?.id;
  const [copied, setCopied] = useState(false);
  // const [checked, setChecked] = useState(true);
  const dispatch = useDispatch();
  const dashboardData = data?.data;
  const balanceData = data?.balanceData?.data;
  const operation = data?.operationData?.data;
  const loading = data?.isLoading;
  const info = userSetting?.info;
  const [colending, setColending] = useState(false);
  const history = useHistory();
  const toast = useToast();

  const xTag = localStorage.getItem("x_jwt");
  const txJwt = localStorage.getItem("tx_jwt");

  //eslint-disable-next-line
  operation !== undefined
    ? localStorage.setItem(
        "loan-application-link",
        operation?.link.toLowerCase()
      )
    : null;

  const goToLoanProduct = () => history.push("/settings", { tab: "2" });

  useEffect(() => {
    dispatch(getDashboardData(xTag));
    dispatch(getBalance(xTag));
    dispatch(getOperation(xTag));
  }, [dispatch, xTag]);

  useEffect(() => {
    if (xTag === null) {
      window.location.href = "/login";
    }
  }, [xTag]);

  useEffect(() => {
    if (accountId !== undefined) {
      dispatch(getUserId(txJwt, accountId));
    }
  }, [dispatch, txJwt, accountId]);

  useEffect(() => {
    const isSupportEmpty = isEmpty(supportEmail) || isEmpty(supportPhone);
    if (
      userSetting &&
      !loading &&
      isSupportEmpty &&
      !toast.isActive("update-support")
    ) {
      toast({
        title: "Add Support Contact",
        id: "update-support",
        description:
          "Please update your support contact details in the Settings page, under General tab",
        status: "info",
        duration: null,
        isClosable: true,
        variant: "top-accent",
        position: "top-right",
      });
    }
  }, [userSetting, supportEmail, supportPhone, loading, toast]);

  // copy function to copy link to clipboard
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
  const handleModal = () => {
    setShowAccountModal(true);
  };

  const onModalClose = () => {
    dispatch(getBalance(xTag));
  };

  const formattedBalance =
    balanceData?.balance !== undefined
      ? formatNumberAmount(balanceData?.balance)
      : 0;
  const formattedTotal =
    dashboardData?.data?.total !== undefined
      ? formatNumberAmount(dashboardData?.data?.total)
      : 0;
  const formattedRepaid =
    dashboardData?.data?.repaid !== undefined
      ? formatNumberAmount(dashboardData?.data?.repaid)
      : 0;
  const formattedOustanding =
    dashboardData?.data?.outstanding !== undefined
      ? formatNumberAmount(dashboardData?.data?.outstanding)
      : 0;
  const formattedGrossLoan =
    dashboardData?.data?.loanActivities?.grossLoanPortfolio !== undefined
      ? formatNumberAmount(
          dashboardData?.data?.loanActivities?.grossLoanPortfolio
        )
      : 0;
  // const formattedTotalDisburged =
  //   dashboardData?.data?.loanActivities?.totalLoansDisbursed !== undefined
  //     ? formatNumberAmount(
  //         dashboardData?.data?.loanActivities?.totalLoansDisbursed
  //       )
  //     : 0;
  const formattedTotalRevenue =
    dashboardData?.data?.loanActivities?.grossRevenue !== undefined
      ? formatNumberAmount(dashboardData?.data?.loanActivities?.grossRevenue)
      : 0;
  const formattedLoanBalance =
    dashboardData?.data?.loanActivities?.avgLoanBalance !== undefined
      ? formatNumberAmount(dashboardData?.data?.loanActivities?.avgLoanAmount)
      : 0;
  const formattedFullyPaidSum =
    dashboardData?.data?.loanActivities?.fullyPaidLoanSum !== undefined
      ? formatNumberAmount(
          dashboardData?.data?.loanActivities?.fullyPaidLoanSum
        )
      : 0;

  const formattedvalueAtRisk =
    dashboardData?.data?.loanActivities?.valueAtRisk !== undefined
      ? formatNumberAmount(dashboardData?.data?.loanActivities?.valueAtRisk)
      : 0;
  const formattedFeesSum =
    dashboardData?.data?.loanActivities?.totalFees !== undefined
      ? formatNumberAmount(dashboardData?.data?.loanActivities?.totalFees)
      : 0;
  const formattedInterestOutstandingSum =
    dashboardData?.data?.loanActivities?.interestOutstanding !== undefined
      ? formatNumberAmount(
          dashboardData?.data?.loanActivities?.interestOutstanding
        )
      : 0;
  const formattedPrincipalOutstandingSum =
    dashboardData?.data?.loanActivities?.principalOutstanding !== undefined
      ? formatNumberAmount(
          dashboardData?.data?.loanActivities?.principalOutstanding
        )
      : 0;

  const canViewBalances = useCallback(
    (val?: string | number, returnVal?: boolean) => {
      let view = false;

      if (operation && operation.link) {
        let linksArr = operation.link.split("/");
        if (
          userAccess() === "full" &&
          linksArr.includes(
            process.env.REACT_APP_MASK_LENDER_READ_ONLY || "dummycov2"
          )
        ) {
          view = true;
        }
      }

      if (returnVal) {
        return view ? (val as string) : "****";
      }

      return view;
    },
    [operation]
  );

  return (
    <>
      {loading && <MainLoader />}

      <Box px="4" py="5">
        <DashboardHeader
          title="Welcome to your dashboard"
          tourSteps={dashboardPageSteps}
        />

        {/* Row start */}
        <HStack
          spacing="0"
          justify="space-between"
          align="flex-start"
          pt="3"
          mb="8"
          wrap="wrap"
        >
          {/* Balance Box Start */}
          <BalanceCard
            balance={canViewBalances(formattedBalance, true) as string}
            account={balanceData?.account}
            bgColor="#060088"
          >
            {!balanceData?.account?.number ? (
              <Button
                onClick={handleModal}
                variant="secondary"
                className="create-account-btn"
              >
                Create Dedicated Account
              </Button>
            ) : (
              <ColendingDisplay />
            )}
          </BalanceCard>

          {/* Balance Box End */}
          {/* Portfolio Box */}
          <Box
            mt={["16px !important", "16px !important", "0 !important"]}
            mb="3"
            w={["100%", "100%", "41.5%"]}
          >
            <Box className="portfolio-box">
              <Text
                fontSize={{ base: "xs", md: "sm" }}
                fontWeight="bold"
                color="black"
              >
                Your Portfolio
              </Text>
              <Box d="flex" mt="4" className="portfolio-item">
                <Box className="donut-chart">
                  <DoughnutChart shouldShow={canViewBalances() as boolean} />
                </Box>
                <Box className="portfolio-table">
                  <PortfolioStat
                    label="Repaid"
                    value={canViewBalances(formattedRepaid, true) as string}
                    legendColor="#f9ac22"
                  />
                  <PortfolioStat
                    label="Outstanding"
                    value={canViewBalances(formattedOustanding, true) as string}
                    legendColor="#060088"
                  />

                  <Box pt="4" borderTop="1px" borderColor="#28293d">
                    <PortfolioStat
                      label="Total"
                      value={canViewBalances(formattedTotal, true) as string}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* Portfolio Box End*/}
        </HStack>
        {/* Row end */}
        {/* Row Start */}
        <HStack
          spacing="0"
          justify="space-between"
          align="flex-start"
          wrap="wrap"
          pt="3"
          mb="3"
        >
          {/* Loan Options start */}
          <Box mb="3" w={["100%", "100%", "56%"]}>
            <VStack
              w="100%"
              align="flex-start"
              spacing="10"
              className="loan-options"
            >
              <LoanOptionCard
                header="Loan Request Link"
                description="Share your loan request link for customers to be able to apply
                  for a loan directly from you."
                value={operation?.link.toLowerCase()}
                actionButtons={
                  <CopyToClipboard
                    onCopy={handleCopy}
                    text={`${operation?.link.toLowerCase()}`}
                  >
                    <Button
                      mt="3"
                      onClick={() => message.success("link copied")}
                      disabled={copied}
                    >
                      Copy
                    </Button>
                  </CopyToClipboard>
                }
              />

              <LoanOptionCard
                header="Loan Application USSD"
                description="Share your loan request Code for customers to be able to apply
                  for a loan directly from you."
                value={
                  <Text
                    as="span"
                    fontSize={{ base: "xs", md: "sm" }}
                    fontWeight="semibold"
                    color="primaryBlue"
                  >
                    {`*347*780*${
                      userSetting?.ussdCode !== undefined
                        ? userSetting?.ussdCode
                        : 0o00
                    }#`}
                  </Text>
                }
                actionButtons={
                  <>
                    {/* <UssdToggler checked={checked} setChecked={setChecked} /> */}
                  </>
                }
              />

              <LoanOptionCard
                header="Loan Interest Rate"
                headerActionButtons={
                  <Button variant="secondary" onClick={goToLoanProduct}>
                    Edit
                  </Button>
                }
                description="Your default loan interest rate. you can change this by
                  editing loan product in settings."
                value={
                  <Text
                    as="span"
                    fontWeight="semibold"
                    fontSize={{ base: "50px", md: "60px", lg: "72px" }}
                    color="primaryBlue"
                  >
                    {operation?.interestRate}%
                  </Text>
                }
              />
            </VStack>
          </Box>
          {/* Loan Options end */}
          {/* Loan Activities start */}
          <Box w={["100%", "100%", "41.5%"]}>
            <Box className="loan-activities">
              <Heading
                as="h4"
                fontSize={{ base: "xs", md: "sm" }}
                fontWeight="bold"
                mb="3"
              >
                Loan Activities
              </Heading>
              <Box d="flex" flexWrap="wrap">
                <LoanActivityStat
                  label="Gross Loan Portfolio"
                  value={`₦${canViewBalances(formattedGrossLoan, true)}`}
                />
                <LoanActivityStat
                  label="Net Loan Portfolio"
                  value={`₦${canViewBalances(formattedOustanding, true)}`}
                />

                <LoanActivityStat
                  label="Fully Paid Loans"
                  value={`₦${canViewBalances(formattedFullyPaidSum, true)}`}
                />
                <LoanActivityStat
                  label="Value at Risk"
                  value={`₦${canViewBalances(formattedvalueAtRisk, true)}`}
                />

                <LoanActivityStat
                  label="Total Principal Outstanding"
                  value={`₦${canViewBalances(
                    formattedPrincipalOutstandingSum,
                    true
                  )}`}
                />
                <LoanActivityStat
                  label="Total Interest Outstanding"
                  value={`₦${canViewBalances(
                    formattedInterestOutstandingSum,
                    true
                  )}`}
                />

                <LoanActivityStat
                  label="Gross Revenue (All time)"
                  value={`₦${canViewBalances(formattedTotalRevenue, true)}`}
                />
                <LoanActivityStat
                  label="Average Loan Amount"
                  value={`₦${canViewBalances(formattedLoanBalance, true)}`}
                />
                <LoanActivityStat
                  label="Gross Fees"
                  value={`₦${canViewBalances(formattedFeesSum, true)}`}
                />
                <LoanActivityStat
                  label="Net Fees"
                  value={`₦${canViewBalances(formattedFeesSum, true)}`}
                />
                <LoanActivityStat
                  label="Active Loans"
                  value={
                    dashboardData?.data !== undefined
                      ? (canViewBalances(
                          dashboardData?.data?.loanActivities?.noOfActiveLoans,
                          true
                        ) as string)
                      : 0
                  }
                />
                <LoanActivityStat
                  label="Loans in Arrears"
                  value={
                    dashboardData?.data !== undefined
                      ? (canViewBalances(
                          dashboardData?.data?.loanActivities?.loansInArrears,
                          true
                        ) as string)
                      : 0
                  }
                />
                <LoanActivityStat
                  label="Number of Customers"
                  value={
                    dashboardData?.data !== undefined
                      ? (canViewBalances(
                          dashboardData?.data?.loanActivities?.noOfCustomers,
                          true
                        ) as string)
                      : 0
                  }
                />
                <LoanActivityStat
                  label="Number of Customers with Active Loans"
                  value={
                    dashboardData?.data !== undefined
                      ? (canViewBalances(
                          dashboardData?.data?.loanActivities
                            ?.noOfCustomersWithActiveLoans,
                          true
                        ) as string)
                      : 0
                  }
                />
              </Box>
            </Box>
          </Box>
          {/* Loan Activities end */}
        </HStack>
        {/* Row end */}
        {/* Chart Tabs Start */}
        {canViewBalances() && (
          <Box className="dashboard-charts-tab">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Expected Vs Actual Repayment" key="1">
                <Box className="dashboard-charts-pane">
                  <RepaymentBarChart />
                </Box>
              </TabPane>
              <TabPane tab="New Clients" key="2">
                <Box className="dashboard-charts-pane">
                  <NewClientsLineChart />
                </Box>
              </TabPane>
              <TabPane tab="Income" key="3">
                <Box className="dashboard-charts-pane">
                  <IncomeBarChart />
                </Box>
              </TabPane>
              <TabPane tab="Loan Disburse" key="4">
                <Box className="dashboard-charts-pane">
                  {<DisburseBarChart />}
                </Box>
              </TabPane>
              {/* Counts and values commented out */}
              {/* <TabPane tab="Counts & Values" key="5">
                <Box className="dashboard-charts-pane">
                  <CountsBarChart />
                </Box>
              </TabPane> */}
            </Tabs>
          </Box>
        )}

        {/* Chart Tabs End */}
      </Box>
      <CreateAccountModal
        setShowModal={setShowAccountModal}
        showModal={showAccountModal}
        userId={userId}
        accountId={accountId}
        info={info}
        afterClose={onModalClose}
      />
      <ConfirmColendingToggle
        showModal={colending}
        setShowModal={setColending}
        status={orignateAi}
      />
    </>
  );
}
