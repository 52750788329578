import React from "react";
import { Stack } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";

interface LoaderProps {
  size?: "sm" | "md" | "lg" | "xs" | "xl";
}

const Loader = ({ size = "lg" }: LoaderProps) => {
  return (
    <Stack align="center" justify="center" my="2.5">
      <Spinner size={size} color="primaryBlue" />
    </Stack>
  );
};

export default Loader;
