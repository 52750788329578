import React from "react";
import { Modal } from "antd";
import { Heading, Button, Box } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { deleteTeamMember } from "redux/actions/settingsActions";

interface IConfirmDeleteTeamMemberProps {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
  record: {
    email: string | undefined;
    giroAccountID: string | undefined;
  };
}

export default function ConfirmDeleteTeamMember({
  showModal,
  setShowModal,
  record,
}: IConfirmDeleteTeamMemberProps) {
  const dispatch = useDispatch();
  const xJwt = localStorage.getItem("x_jwt");
  const { email, giroAccountID } = record;
  const handleCancel = () => {
    setShowModal(false);
  };

  const handleDelete = () => {
    dispatch(deleteTeamMember(xJwt, email, giroAccountID, dispatch));
    setShowModal(false);
  };

  return (
    <>
      <Modal
        visible={showModal}
        onCancel={handleCancel}
        footer={null}
        width="40%"
        className="loan-config-modal"
      >
        <Box textAlign="center">
          <Heading as="h2" fontSize="sm" mb="4">
            Are you sure you want to delete this team member?
          </Heading>
          <Box d="block">
            <Button variant="danger" mr="4" onClick={handleDelete}>
              Delete
            </Button>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
