import { Box, Flex } from "@chakra-ui/react";
import { Button } from "antd";
import React from "react";
import ScanQr from "./ScanQr";
import AddManualQr from "./AddManualQr";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import { enable2FA } from "redux/actions/authActions";

type Props = {
  onNext: () => void;
  isForReset?: boolean;
};

const MfaDetails = ({ onNext, isForReset }: Props) => {
  const dispatch = useDispatch();
  const [step, setStep] = React.useState<"scan" | "manual">("scan");
  const userDetails = useSelector(
    (state: RootState) => state.authReducer.loginDataTwo
  );

  const fetch2FaData = React.useCallback(async () => {
    if (isForReset) return;

    if (userDetails)
      dispatch(enable2FA(userDetails?._id, userDetails?.accounts[0]));
  }, [dispatch, userDetails, isForReset]);

  React.useEffect(() => {
    fetch2FaData();
  }, [fetch2FaData]);

  const renderStep = () => {
    switch (step) {
      case "scan":
        return <ScanQr />;
      case "manual":
        return <AddManualQr />;
      default:
        return <ScanQr />;
    }
  };

  return (
    <Box>
      {renderStep()}
      <Flex gap={4} justifyContent="flex-end">
        <Box>
          {step === "scan" ? (
            <Button onClick={() => setStep("manual")}>
              Enter Code Manually
            </Button>
          ) : (
            <Button onClick={() => setStep("scan")}>Scan QR Code</Button>
          )}
        </Box>
        <Button onClick={onNext}>Continue</Button>
      </Flex>
    </Box>
  );
};

export default MfaDetails;
