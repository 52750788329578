import React from "react";
import {
  Avatar,
  Heading,
  Text,
  useBreakpointValue,
  Box,
  HStack,
  Flex,
} from "@chakra-ui/react";
import { EditIcon } from "components/common/CustomIcons";
import {
  AccountPreferenceForm,
  SecurityTab,
  SupportSettingsForm,
} from "components/forms";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import { fileStackKey } from "../../constants";
import {
  getUserSettings,
  updateUserSettings,
} from "redux/actions/settingsActions";
// @ts-ignore
import { client } from "filestack-react";
import { RootState } from "types";
import { isReadOnly } from "helpers/utilFunctions";

export default function GeneralTabPane() {
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const userSetting = setting?.settingData?.data;
  const dispatch = useDispatch();
  const size = useBreakpointValue({ base: "xl", lg: "2xl" });
  const xTag = localStorage.getItem("x_jwt");
  const readonly = isReadOnly();

  const pickerOptions = {
    fromSources: ["local_file_system", "googledrive", "googlephotos"],
    accept: ["image/*"],
    maxFiles: 1,
    onFileUploadFailed: () =>
      message.error("error uploading file, try a different file format"),
    onUploadDone: (res: any) => {
      dispatch(
        updateUserSettings(xTag, "updateLogo", {
          logo: res?.filesUploaded?.[0]?.url,
        })
      );
      dispatch(getUserSettings(xTag));
    },
  };

  const filestack = client.init(fileStackKey, pickerOptions);
  const openPicker = filestack.picker(pickerOptions);
  const onLogoChange = () => {
    openPicker.open();
  };
  return (
    <HStack
      spacing="0"
      w="100%"
      align="flex-start"
      justify="space-between"
      wrap="wrap"
    >
      <Box w={["100%", "100%", "25%"]} mb="3" className="general-profile">
        <Avatar size={size} src={userSetting?.logo} />
        <Box
          className="edit-cont"
          d={readonly ? "none" : "flex"}
          onClick={onLogoChange}
        >
          <EditIcon />
        </Box>
        <Heading
          as="h4"
          fontSize={{ base: "sm", md: "md" }}
          color="primaryBlue"
          my="3"
        >
          Your Logo
        </Heading>
        <Text fontSize="xx-small">Logo to be used across VeendHQ</Text>
      </Box>
      <Box ml="auto" w={["100%", "100%", "74%"]} className="general-settings">
        <Heading
          as="h2"
          fontSize={{ base: "16px", md: "20px", lg: "22px" }}
          lineHeight={{ base: "20px", md: "24px" }}
        >
          Support
        </Heading>
        <SupportSettingsForm />
      </Box>

      <Flex w="100%" justify="flex-end">
        <Box
          mt="4"
          ml="auto"
          w={["100%", "100%", "74%"]}
          className="general-settings"
        >
          <Heading
            as="h2"
            fontSize={{ base: "16px", md: "20px", lg: "22px" }}
            lineHeight={{ base: "20px", md: "24px" }}
          >
            Account Preferences
          </Heading>
          <AccountPreferenceForm />
        </Box>
      </Flex>

      <Flex w="100%" justify="flex-end">
        <Box
          mt="4"
          ml="auto"
          w={["100%", "100%", "74%"]}
          className="general-settings"
        >
          <Heading
            as="h2"
            fontSize={{ base: "16px", md: "20px", lg: "22px" }}
            lineHeight={{ base: "20px", md: "24px" }}
          >
            Security
          </Heading>
          <SecurityTab />
        </Box>
      </Flex>
    </HStack>
  );
}
