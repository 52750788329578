import React from "react";
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { Table } from "antd";
import moment from "moment";
import { formatNumberAmount } from "helpers/utilFunctions";
import { DateTimeFormat } from "../constants";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { DownloadOutlined } from "@ant-design/icons";
interface IRepaymentScheduleProps {
  record: any;
}

const columns = [
  {
    dataIndex: "period",
    key: "period",
    render: (text: string) => (
      <div>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Period
        </Text>
      </div>
    ),
  },
  {
    dataIndex: "principalDue",
    key: "principalDue",
    render: (text: string) => (
      <div>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Principal due
        </Text>
      </div>
    ),
  },
  {
    dataIndex: "principalPaid",
    key: "principalPaid",
    render: (text: string) => (
      <div>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Principal paid
        </Text>
      </div>
    ),
  },
  {
    dataIndex: "feeDue",
    key: "feeDue",
    render: (text: string) => (
      <div>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Fee due
        </Text>
      </div>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "feePaid",
    key: "feePaid",
    render: (text: string) => (
      <div>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Fee paid
        </Text>
      </div>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "interestDue",
    key: "interestDue",
    render: (text: string) => (
      <div>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Interest due
        </Text>
      </div>
    ),
  },
  {
    dataIndex: "interestPaid",
    key: "interestPaid",
    render: (text: string) => (
      <div>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Interest paid
        </Text>
      </div>
    ),
  },
  {
    dataIndex: "penaltyDue",
    key: "penaltyDue",
    render: (text: string) => (
      <div>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Penalty due
        </Text>
      </div>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "penaltyPaid",
    key: "penaltyPaid",
    render: (text: string) => (
      <div>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Penalty paid
        </Text>
      </div>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "repaymentDate",
    key: "repaymentDate",
    render: (text: string) => (
      <div>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Repayment date
        </Text>
      </div>
    ),
  },
  {
    dataIndex: "status",
    key: "status",
    render: (text: boolean) => (
      <div
        className="loan-status"
        style={
          text === false
            ? { backgroundColor: "var(--danger-600)" }
            : { backgroundColor: "var(--success-500)" }
        }
      >
        <Text fontSize={{ base: "xx-small", md: "xs" }} color="white">
          {text === false ? "unpaid" : "paid"}
        </Text>
      </div>
    ),
  },
];

const LoanRepayMentSchedule = ({ record }: IRepaymentScheduleProps) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const data: any[] | undefined = [];
  if (record) {
    for (let i = 1; i < record.length; i++) {
      const item = record[i];
      const key = i;
      const principalDue = formatNumberAmount(item?.principalDue);
      const principalPaid = formatNumberAmount(item?.principalPaid);
      const feeDue = formatNumberAmount(item?.feeChargesDue);
      const feePaid = formatNumberAmount(item?.feeChargesPaid);
      const interestDue = formatNumberAmount(item?.interestDue);
      const interestPaid = formatNumberAmount(item?.interestPaid);
      const penaltyDue = formatNumberAmount(item?.penaltyChargesDue);
      const penaltyPaid = formatNumberAmount(item?.penaltyChargesPaid);
      const repaymentDate = moment(item?.dueDate?.join("-")).format(
        DateTimeFormat
      );
      const status = item?.complete;
      const period = item?.period;
      data.push({
        principalDue,
        principalPaid,
        feeDue,
        feePaid,
        interestDue,
        interestPaid,
        penaltyDue,
        penaltyPaid,
        repaymentDate,
        status,
        period,
        key,
      });
    }
  }

  const exportToCSV = (csvData: any, fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <div className="loan-repayment-schedule-body">
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems="center"
          mb="3"
        >
          <Heading as="h3" fontSize={{ base: "sm", lg: "md" }}>
            Loan Repayment Schedule
          </Heading>
          <Button
            onClick={() => exportToCSV(data, "Repayment Schedule")}
            rightIcon={<DownloadOutlined />}
          >
            Download
          </Button>
        </Box>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 50,
            hideOnSinglePage: true,
            position: ["bottomLeft"],
          }}
        />
      </div>
    </>
  );
};

export default LoanRepayMentSchedule;
