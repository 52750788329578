import React from "react";
import { Layout } from "antd";
import { routes } from "routes";
import { IRoutes } from "types";
import { Route, Switch } from "react-router-dom";
import error404 from "views/error404";
import { Box } from "@chakra-ui/layout";

const { Content } = Layout;

export default function LandingPage() {
  const getRoutes = (routes: IRoutes[]) => {
    return routes.map((props, index) => {
      if (props.layout === "auth") {
        return (
          <Route
            exact={props.exact}
            path={props.path}
            component={props.component}
            key={index}
          />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <>
      <Box>
        {/* <Header className="landingPageHeader">
          <Box className="logo" />
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["1"]}>
            <Menu.Item key="1">Login</Menu.Item>
            <Menu.Item key="2">Sign Up</Menu.Item>
          </Menu>
        </Header> */}
        <Content style={{ backgroundColor: "white" }}>
          {
            <Switch>
              {getRoutes(routes)}
              <Route component={error404} />
            </Switch>
          }
        </Content>
      </Box>
    </>
  );
}
