import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { RootState } from "types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LendingLicenseUpload from "views/auth/LendingLicenseUpload";
import { showLendingLicenseModal } from "redux/actions/lendingLicenseActions";

type Props = {};

const LendingLicenceChecker = (props: Props) => {
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const license = useSelector(
    (state: RootState) => state.lendingLicenseReducer
  );

  const { settingData, isLoading } = setting;
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !isLoading &&
      !settingData.data.lendingLicenseUrl &&
      !!sessionStorage.getItem("license-reminder")
    ) {
      dispatch(showLendingLicenseModal(true));
    }
  }, [isLoading, settingData.data]);

  return <>{license.show && <LendingLicenseUpload state={license.show} />}</>;
};

export default LendingLicenceChecker;
