import React, { useEffect, useState } from "react";
import { Heading, Box, Button } from "@chakra-ui/react";
import { FeesTable } from "components/tables";
import { AddFeeModal } from "components/modals";
import { useDispatch, useSelector } from "react-redux";
import { getFees } from "redux/actions/settingsActions";
import { isReadOnly } from "helpers/utilFunctions";
import { RootState } from "types";
import { showLendingLicenseModal } from "redux/actions/lendingLicenseActions";

export default function FeesTabPane() {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const xJwt = localStorage.getItem("x_jwt");
  const readonly = isReadOnly();
  const settingsData = useSelector(
    (state: RootState) => state.settingsReducer.settingData
  );

  useEffect(() => {
    dispatch(getFees(xJwt));
  }, [dispatch, xJwt]);

  return (
    <>
      <Button
        d="block"
        mb="3"
        ml="auto"
        mr="1"
        onClick={() => {
          if (!settingsData.data?.lendingLicenseUrl) {
            return dispatch(showLendingLicenseModal(true));
          }
          setShowModal(true);
        }}
        disabled={readonly}
      >
        New Fee
      </Button>

      <Box className="loan-products-table-box">
        <Box className="settings-table-header">
          <Heading as="h4" fontSize={{ base: "xs", md: "sm" }}>
            Fees
          </Heading>
        </Box>
        <FeesTable />
      </Box>

      <AddFeeModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
