import React, { useState, useEffect } from "react";
import { Button, Heading, Box } from "@chakra-ui/react";
import { LoanOfferConfig } from "components/modals";
import { LoanOffersTable } from "components/tables";
import { useSelector, useDispatch } from "react-redux";
import { getLoanOffers, getLoanProducts } from "redux/actions/settingsActions";
import { Loader } from "components/common";
import { RootState } from "types";
import { isReadOnly } from "helpers/utilFunctions";
import { showLendingLicenseModal } from "redux/actions/lendingLicenseActions";

export default function LoanOfferTabPane() {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const isLoading = settingsState?.requestLoading;
  const xJwt = localStorage.getItem("x_jwt");
  const readonly = isReadOnly();

  useEffect(() => {
    dispatch(getLoanOffers(xJwt));
    dispatch(getLoanProducts(xJwt));
  }, [dispatch, xJwt]);

  return (
    <>
      <Button
        d="block"
        mb="3"
        ml="auto"
        mr="1"
        onClick={() => {
          if (!settingsState.settingData?.data?.lendingLicenseUrl) {
            return dispatch(showLendingLicenseModal(true));
          }
          setShowModal(true);
        }}
        disabled={readonly}
      >
        New Loan Offer
      </Button>
      <Box className="loan-products-table-box">
        <Box className="settings-table-header">
          <Heading as="h4" fontSize={{ base: "xs", md: "sm" }}>
            Loan Offers
          </Heading>
        </Box>
        {isLoading ? <Loader size="md" /> : <LoanOffersTable />}
      </Box>
      <LoanOfferConfig showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
