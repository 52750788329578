import React, { useEffect, useState } from "react";
import { Button, Link, Text, Box } from "@chakra-ui/react";
import moment from "moment";
import { LoanProfile, RepaymentModal } from "components/modals";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveLoan,
  getLoanRecord,
  getNextActiveLoansPage,
} from "redux/actions/loanActions";
import { Table } from "antd";
import { DateTimeFormat, disburseColor } from "../../constants";
import {
  WebIcon,
  UssdPhoneIcon,
  DotIcon,
  PrimeIcon,
} from "components/common/CustomIcons";
import { popLastId } from "helpers/utilFunctions";
import { Loader } from "components/common";
import { RootState } from "types";
import { defaultColumns, getDefaultColumnsData } from "helpers/loans";
import { capitalize } from "lodash";

const columns = [
  ...defaultColumns,
  {
    dataIndex: "expectedRepayment",
    key: "expectedRepayment",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Expected Repayment
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "totalRepayment",
    key: "totalRepayment",
    render: (text: string) => (
      <div>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Total Repayment
        </Text>
      </div>
    ),
  },
  {
    dataIndex: "repaymentDate",
    key: "repaymentDate",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Repayment Date
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "remitaMandateReference",
    key: "remitaMandateReference",
    render: (text: string) => (
      <Box onClick={e => e.stopPropagation()}>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Mandate
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "tenure",
    key: "tenure",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Tenure
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "bvn",
    key: "bvn",
    render: (text: string) => (
      <Box>
        <Text
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="bold"
          title={text}
        >
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          BVN
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "loanId",
    key: "loanId",
    render: (text: string) => (
      <Box onClick={e => e.stopPropagation()}>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Loan ID
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "loanChannel",
    key: "loanChannel",
    render: (text: string) => (
      <Box onClick={e => e.stopPropagation()}>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text.toLowerCase() === "onlending" ? "Prime" : capitalize(text)}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Channel
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "channel",
    key: "channel",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "disbursementStatus",
    key: "disbursementStatus",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small" }} fontWeight="bold">
          {text}
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "makeRepayment",
  },
];

export default function ActiveLoansTable() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [lastIds, setLastIds] = useState<(string | null)[]>([null]);
  const [showRepaymentModal, setShowRepaymentModal] = useState(false);
  const [loanRecord, setLoanRecord] = useState(undefined);
  const loanState = useSelector((state: RootState) => state.loanReducer);
  const activeLoanData = loanState?.activeLoanData;
  const loans = activeLoanData?.data;
  const loading = loanState?.requestLoading;
  const xJwt = localStorage.getItem("x_jwt");

  const openLoanProfile = () => {
    setShowModal(true);
  };

  const closeModalCallback = () => {
    dispatch(getActiveLoan(xJwt));
  };

  useEffect(() => {
    dispatch(getActiveLoan(xJwt));
  }, [dispatch, xJwt]);

  useEffect(() => {
    if (activeLoanData !== undefined && activeLoanData?.lastId !== undefined) {
      setLastIds([...lastIds, activeLoanData?.lastId]);
    }
  }, [activeLoanData]); //eslint-disable-line

  const repaymentAction = (record: any, e: any) => {
    e.stopPropagation();
    setLoanRecord(record);
    setShowRepaymentModal(true);
  };

  const loadNextPage = () => {
    dispatch(getNextActiveLoansPage(xJwt, activeLoanData?.lastId));
  };

  const loadPrevPage = () => {
    if (lastIds.length === 3) {
      dispatch(getActiveLoan(xJwt));
      setLastIds([null]);
    } else {
      dispatch(getNextActiveLoansPage(xJwt, lastIds[lastIds.length - 3]));
      popLastId(lastIds, setLastIds);
    }
  };

  const data: any[] | undefined = [];

  for (let i = 0; i < loans?.length; i++) {
    data?.push({
      ...getDefaultColumnsData(loans[i]),
      bvn: loans[i]?.user?.bvn,
      expectedRepayment: loans[i]?.totalExpectedRepayment,
      totalRepayment: loans[i]?.totalRepayment,
      repaymentDate: `${moment(loans[i]?.repaymentDate).format(
        DateTimeFormat
      )}`,
      remitaMandateReference: loans[i]?.remitaMandateReference
        ? loans[i]?.remitaMandateReference
        : "no mandate",
      loanChannel: loans[i]?.channel,
      channel:
        loans[i]?.channel === "MOBILE" || loans[i]?.channel === "USSD" ? (
          <UssdPhoneIcon title={loans[i]?.channel} />
        ) : loans[i]?.channel.toLowerCase() === "onlending" ||
          loans[i]?.channel.toLowerCase() === "prime" ? (
          <PrimeIcon title={loans[i]?.channel} />
        ) : (
          <WebIcon title={loans[i]?.channel} />
        ),
      loanId: loans[i]?.loanId,
      tenure: loans[i]?.tenure,
      isTopUp: loans[i]?.isTopup,
      disbursementStatus: (
        <DotIcon
          title={`Disburse status: ${loans[i]?.disbursementStatus}`}
          style={disburseColor(loans[i]?.disbursementStatus)}
          className="status-dot"
        />
      ),
      makeRepayment: (
        <Button
          variant="success"
          onClick={(e: any) => repaymentAction(loans[i], e)}
        >
          Make Repayment
        </Button>
      ),
    });
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 50,
            hideOnSinglePage: true,
            position: ["bottomLeft"],
          }}
          onRow={(record: any, rowIndex) => {
            return {
              onClick: e => {
                dispatch(getLoanRecord(record.key, xJwt, record.loanId));
                openLoanProfile();
              },
            };
          }}
          className="row-select"
        />
      )}
      {!activeLoanData?.isLastPage ? (
        <Box className="pagination-items">
          {lastIds.length <= 2 ? null : (
            <Link onClick={loadPrevPage} className="pagination-item-link prev">
              Previous page
            </Link>
          )}
          <Link onClick={loadNextPage} className="pagination-item-link next">
            Next page
          </Link>
        </Box>
      ) : null}
      <LoanProfile showModal={showModal} setShowModal={setShowModal} />
      <RepaymentModal
        showModal={showRepaymentModal}
        setShowModal={setShowRepaymentModal}
        loanRecord={loanRecord}
        afterClose={closeModalCallback}
      />
    </>
  );
}
