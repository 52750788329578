import React from "react";
import { Heading, Text, Box, HStack, Link, Image } from "@chakra-ui/react";
import veendLogo from "../../assets/veend-logo.png";
import "./styles/auth.css";
import { HubspotSignupForm } from "../../components/forms";
import { goToLoginPage } from "../../helpers/utilFunctions";

export default function LenderRegister(): JSX.Element {
  return (
    <>
      <Box>
        <HStack spacing="0">
          <Box w={["100%", "100%", "57%"]} className="signup-form">
            <Box py="4" textAlign="center">
              <Box textAlign="center" className="logo-img-container">
                <Link
                  href="https://veendhq.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src={veendLogo}
                    alt="veendHq"
                    className="login-logo-main"
                  />
                </Link>
              </Box>
              <Heading
                as="h1"
                mt="4"
                mb="2"
                fontSize={{ base: "md", md: "md", lg: "lg" }}
              >
                Sign up as a Lender on VeendHQ
              </Heading>
              <Box my="3" className="signup-form-group">
                <HubspotSignupForm />
              </Box>
              <Text
                fontSize={{ base: "xs", md: "xs", lg: "md" }}
                fontWeight="medium"
              >
                Already have an account?{" "}
                <Text
                  as="span"
                  color="primaryBlue"
                  fontWeight="semibold"
                  cursor="pointer"
                  onClick={goToLoginPage}
                >
                  Login
                </Text>
              </Text>
            </Box>
          </Box>
          <Box w="43%" maxH="unset" className="signup-img"></Box>
        </HStack>
      </Box>
    </>
  );
}
