import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getUserSettings } from "redux/actions/settingsActions";
import { RootState } from "types";
import MainLoader from "./MainLoader";

type Props = {
  children: React.ReactNode;
};

const VerificationGuide = (props: Props) => {
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const { settingData, isLoading } = setting;
  const history = useHistory();
  const currentUrl = useLocation();
  //
  const handleRedirect = () => {
    return history.push("/onboarding");
  };

  useEffect(() => {
    // not verified
    if (!settingData.data?.isKYCDone && !isLoading) {
      handleRedirect();
    }
  }, [currentUrl, isLoading]);

  const dispatch = useDispatch();

  const userSetting = setting?.settingData?.data;

  const xTag = localStorage.getItem("x_jwt");
  // const txJwt = localStorage.getItem("tx_jwt");

  useEffect(() => {
    dispatch(getUserSettings(xTag));
  }, [dispatch, xTag]);

  return (
    <>
      {isLoading ? <MainLoader /> : props.children}
    </>
  );
};

export default VerificationGuide;
