import { message } from "antd";
import axios from "axios";
import {
  COLENDING_TOGGLE_LOADING_FALSE,
  COLENDING_TOGGLE_LOADING_TRUE,
  CREATE_LOAN_OFFER_SUCCESS,
  CREATE_LOAN_PRODUCT_SUCCESS,
  CREATE_TEAM_MEMBER_SUCCESS,
  DELETE_FEE_SUCCESS,
  DELETE_LOAN_OFFER_SUCCESS,
  DELETE_LOAN_PRODUCT_SUCCESS,
  DELETE_TEAM_MEMBER_SUCCESS,
  ERROR_401_ACTION,
  GET_COLENDING_INTEREST_RATE_SUCCESS,
  GET_COLENDING_LEADER_SUCCESS,
  GET_FEES_FAILED,
  GET_FEES_SUCCESS,
  GET_LOAN_OFFERS_FAILED,
  GET_LOAN_OFFERS_SUCCESS,
  GET_LOAN_PRODUCTS_FAILED,
  GET_LOAN_PRODUCTS_SUCCESS,
  GET_TEAM_MEMBERS_FAILED,
  GET_TEAM_MEMBERS_SUCCESS,
  REQUEST_LOADING_FALSE,
  REQUEST_LOADING_TRUE,
  UPDATE_FEE_SUCCESS,
  UPDATE_LOAN_OFFER_SUCCESS,
  UPDATE_LOAN_PRODUCT_SUCCESS,
  UPDATE_TEAM_MEMBER_SUCCESS,
  UPDATE_USER_SETTINGS_ACTION_FAILURE,
  UPDATE_USER_SETTINGS_ACTION_SUCCESS,
  USER_SETTINGS_ACTION_FAILURE,
  USER_SETTINGS_ACTION_SUCCESS,
  VERIFY_ACCOUNT_LOADING_TRUE,
  VERIFY_ACCOUNT_NUMBER_FAILED,
  VERIFY_ACCOUNT_NUMBER_SUCCESS,
} from "./actionTypes";

const baseUrl = process.env.REACT_APP_BASE_URL;
const txUrl = process.env.REACT_APP_TXURL;

// user settings
export const getUserSettings =
  (xTag: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
        "x-no-cache": "yes",
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(`${baseUrl}/settings?x-tag=1`, config);
      localStorage.setItem("readonly", res?.headers?.["x-readonly"]);
      dispatch({
        type: USER_SETTINGS_ACTION_SUCCESS,
        payload: res?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: USER_SETTINGS_ACTION_FAILURE,
          });
        }
      }
      return error;
    }
  };

// update user settings
export const updateUserSettings =
  (xTag: any, tab: string, body: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xTag,
        "x-no-cache": "yes",
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.patch(
        `${baseUrl}/settings?x-tag=1&tab=${tab}`,
        body,
        config
      );
      dispatch({
        type: UPDATE_USER_SETTINGS_ACTION_SUCCESS,
        payload: res?.data,
      });
      message.success("settings saved, changes will take effect in 1 minute");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: UPDATE_USER_SETTINGS_ACTION_FAILURE,
          });
          const errorMessage =
            error?.response?.data?.data?.message ||
            error?.response?.data?.message;
          message.error(errorMessage);
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
        }
      }
      return error;
    }
  };

// validate an account number
export const verifyAccountNumber =
  (txToken: string | null, body: any, accountId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    const config: any = {
      headers: {
        Authorization: `Bearer ${txToken}`,
        "x-tag": "veend-setup",
      },
    };

    try {
      dispatch({
        type: VERIFY_ACCOUNT_LOADING_TRUE,
      });
      const res = await axios.post(
        `${txUrl}/v1/products/5a9297612032791140ff390f/validate?accountId=${accountId}`,
        body,
        config
      );
      dispatch({
        type: VERIFY_ACCOUNT_NUMBER_SUCCESS,
        payload: res?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch({
          type: VERIFY_ACCOUNT_NUMBER_FAILED,
        });
        message.error(error?.response?.data?.data?.msg);
      }
      return error;
    }
  };

// get loan offers
export const getLoanOffers =
  (xJwt: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(`${baseUrl}/loanoffers`, config);
      dispatch({
        type: GET_LOAN_OFFERS_SUCCESS,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: GET_LOAN_OFFERS_FAILED,
          });
        }
      }
      return error;
    }
  };

// create loan offer
export const createLoanOffer =
  (xJwt: any, body: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
        "x-tag": "veend-setup",
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.post(`${baseUrl}/loanoffers`, body, config);
      dispatch({
        type: CREATE_LOAN_OFFER_SUCCESS,
        payload: res?.data?.data,
      });
      message.success(
        "Loan offer created, changes will take effect in 1 minute"
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// get loan products
export const getLoanProducts =
  (xJwt: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(`${baseUrl}/loanproducts`, config);
      dispatch({
        type: GET_LOAN_PRODUCTS_SUCCESS,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: GET_LOAN_PRODUCTS_FAILED,
          });
        }
      }
      return error;
    }
  };

// create loan product
export const createLoanProduct =
  (xJwt: any, body: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
        "x-tag": "veend-setup",
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.post(`${baseUrl}/loanproducts`, body, config);
      dispatch({
        type: CREATE_LOAN_PRODUCT_SUCCESS,
        payload: res?.data?.data,
      });
      message.success(
        "Loan product created, changes will take effect in 1 minute"
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// patch loan product
export const updateLoanProduct =
  (xJwt: any, productId: any, updateBody: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
        "x-tag": "veend-setup",
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.patch(
        `${baseUrl}/loanproducts/${productId}`,
        updateBody,
        config
      );
      dispatch({
        type: UPDATE_LOAN_PRODUCT_SUCCESS,
        payload: res?.data?.data,
      });
      message.success(
        "Loan product updated, changes will take effect in 1 minute"
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// delete loan product
export const deleteLoanProduct =
  (xJwt: any, productId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
        "x-tag": "veend-setup",
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.delete(
        `${baseUrl}/loanproducts/${productId}`,
        config
      );
      dispatch({
        type: DELETE_LOAN_PRODUCT_SUCCESS,
        payload: res?.data?.data,
      });
      message.success(
        "Loan product deleted, changes will take effect in 1 minute"
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// patch loan offer
export const editLoanOffer =
  (xJwt: any, body: any, offerId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
        "x-tag": "veend-setup",
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.patch(
        `${baseUrl}/loanoffers/${offerId}`,
        body,
        config
      );
      dispatch({
        type: UPDATE_LOAN_OFFER_SUCCESS,
        payload: res?.data?.data,
      });
      message.success(
        "Loan offer updated, changes will take effect in 1 minute"
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        }
        dispatch({
          type: REQUEST_LOADING_FALSE,
        });
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// delete loan offer
export const deleteLoanOffer =
  (xJwt: any, offerId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
        "x-tag": "veend-setup",
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.delete(
        `${baseUrl}/loanoffers/${offerId}`,
        config
      );
      dispatch({
        type: DELETE_LOAN_OFFER_SUCCESS,
        payload: res?.data?.data,
      });
      message.success(
        "Loan offer deleted, changes will take effect in 1 minute"
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        }
        dispatch({
          type: REQUEST_LOADING_FALSE,
        });
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// get team members
export const getTeamMembers =
  (xJwt: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(`${baseUrl}/teams`, config);
      dispatch({
        type: GET_TEAM_MEMBERS_SUCCESS,
        payload: res?.data?.data?.members,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: GET_TEAM_MEMBERS_FAILED,
          });
        }
      }
      return error;
    }
  };

// create team member
export const createTeamMember =
  (xJwt: any, body: any, _dispatch: (e: any) => void) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
        "x-tag": "veend-setup",
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.post(`${baseUrl}/teams`, body, config);
      dispatch({
        type: CREATE_TEAM_MEMBER_SUCCESS,
        payload: res?.data?.data,
      });
      _dispatch(getTeamMembers(xJwt));
      message.success(
        "Team member created, an email has been sent to create a password"
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// update team member
export const updateTeamMember =
  (xJwt: any, body: any, memberId: string, _dispatch: (e: any) => void) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
        "x-tag": "veend-setup",
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.patch(
        `${baseUrl}/teams/access/${memberId}`,
        body,
        config
      );
      dispatch({
        type: UPDATE_TEAM_MEMBER_SUCCESS,
        payload: res?.data?.data,
      });
      _dispatch(getTeamMembers(xJwt));
      message.success(
        "Team member updated, changes will take effect in 1 minute"
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// delete team member
export const deleteTeamMember =
  (xJwt: any, email: any, accountID: any, _dispatch: (e: any) => void) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
        "x-tag": "veend-setup",
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.patch(
        `${baseUrl}/teams/${accountID}`,
        { email },
        config
      );
      dispatch({
        type: DELETE_TEAM_MEMBER_SUCCESS,
        payload: res?.data?.data,
      });
      _dispatch(getTeamMembers(xJwt));
      message.success(
        "Team member deleted, changes will take effect in 1 minute"
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        }
        dispatch({
          type: REQUEST_LOADING_FALSE,
        });
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// get fees
export const getFees =
  (xJwt: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(`${baseUrl}/lendlotcharges`, config);
      dispatch({
        type: GET_FEES_SUCCESS,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: GET_FEES_FAILED,
          });
        }
      }
      return error;
    }
  };

// create fee
export const createFee =
  (xJwt: any, body: any, _dispatch: (e: any) => void) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
        "x-tag": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.post(`${baseUrl}/lendlotcharges`, body, config);
      dispatch({
        type: CREATE_TEAM_MEMBER_SUCCESS,
        payload: res?.data?.data,
      });
      _dispatch(getFees(xJwt));
      message.success("Fee created successfully");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// update fee
export const updateFee =
  (xJwt: any, body: any, feeId: string, _dispatch: (e: any) => void) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
        "x-tag": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.patch(
        `${baseUrl}/lendlotcharges/${feeId}`,
        body,
        config
      );
      dispatch({
        type: UPDATE_FEE_SUCCESS,
        payload: res?.data?.data,
      });
      _dispatch(getFees(xJwt));
      message.success("Fee updated, changes will take effect in 1 minute");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// delete fee
export const deleteFee =
  (xJwt: any, feeID: any, _dispatch: (e: any) => void) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.delete(
        `${baseUrl}/lendlotcharges/${feeID}`,
        config
      );
      dispatch({
        type: DELETE_FEE_SUCCESS,
        payload: res?.data?.data,
      });
      _dispatch(getFees(xJwt));
      message.success("Fee deleted, changes will take effect in 1 minute");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        }
        dispatch({
          type: REQUEST_LOADING_FALSE,
        });
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// enable / disable co-lending
export const toggleColending =
  (xJwt: string | null, bool: boolean) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
        "x-tag": "veend-setup",
      },
    };
    let body = {
      colending: !bool,
    };
    try {
      dispatch({
        type: COLENDING_TOGGLE_LOADING_TRUE,
      });
      await axios.patch(`${baseUrl}/settings?tab=CoLending`, body, config);
      dispatch({
        type: COLENDING_TOGGLE_LOADING_FALSE,
      });
      message.success(
        `Origiate AI ${
          !bool ? "enabled" : "disabled"
        } successfully...changes will take effect in a few minutes`
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: COLENDING_TOGGLE_LOADING_FALSE,
            payload: error?.response,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// get current co-lending interest rate
export const getCoLendingInterestRate =
  (xJwt: string | null) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      const res = await axios.get(`${baseUrl}/colendinginterestrate`, config);
      dispatch({
        type: GET_COLENDING_INTEREST_RATE_SUCCESS,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
            payload: error?.response,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// update DTI
export const updateDTI =
  (xJwt: string | null, dti: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
        "x-tag": "veend-setup",
      },
    };
    let body = {
      dti: parseFloat(dti),
    };
    try {
      dispatch({
        type: REQUEST_LOADING_TRUE,
      });
      const res = await axios.patch(
        `${baseUrl}/settings?tab=dti`,
        body,
        config
      );
      dispatch({
        type: REQUEST_LOADING_FALSE,
        payload: res?.data?.data,
      });
      message.success(
        `DTI updated successfully...changes will take effect in a few minutes`
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
            payload: error?.response,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// get current co-lending lead
export const getLenderAtTopOfQueue =
  () => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      const res = await axios.get(
        `https://api.veendhq.com/organization?client=veendhqautolending`
      );
      dispatch({
        type: GET_COLENDING_LEADER_SUCCESS,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: REQUEST_LOADING_FALSE,
            payload: error?.response,
          });
        }
      }
      return error;
    }
  };
//
