import React from "react";
import Ellipses from "../../assets/elipses-trans.png";
import { Box, Heading, Image, Text } from "@chakra-ui/react";
import { capitalizeString } from "helpers/utilFunctions";
import { RewardIcon } from "components/common/CustomIcons";

interface BalanceCardProps {
  balance: string | number;
  account: {
    number: number;
    bank: string;
  };
  bgColor: string;
  children: React.ReactNode;
}

const BalanceCard = ({
  balance,
  account,
  children,
  bgColor,
}: BalanceCardProps) => {
  return (
    <Box w={["100%", "100%", "56%"]} alignSelf="stretch">
      <Box className="balance-box" bg={bgColor}>
        <Image src={Ellipses} alt="ellipses" className="elipses" />
        <Box className="balance-amount">
          <Heading
            as="h3"
            mb="3"
            fontSize={{ base: "x-small", md: "sm" }}
            color="white"
          >
            Balance
          </Heading>

          <Heading
            as="h1"
            mb="2"
            color="white"
            className="balance-amount"
            fontSize={{ base: "xx-large", md: "4xl", lg: "5xl" }}
          >
            ₦{balance}
          </Heading>

          <Text color="white" mb="4" fontSize={{ base: "xx-small", md: "xs" }}>
            Funding Account Number:{" "}
            <Text as="span" fontWeight="bold">
              {account?.number} {capitalizeString(account?.bank)} Bank
            </Text>
          </Text>
        </Box>
        <Box d="none" mb="4">
          <RewardIcon
            style={{ width: ".85rem", height: ".85rem", color: "#FFF" }}
          />
          <Text ml="2" color="white" fontSize="xs">
            Amount in reward Pool: <Text as="span">₦323.45</Text>
          </Text>
        </Box>

        {children}
      </Box>
    </Box>
  );
};

export default BalanceCard;
