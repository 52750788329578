import React from "react";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import veendLogo from "../../assets/veend-logo.png";
import { Heading, Button, Box, Link, Image } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { InputField, ResultStatus } from "components/common";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "redux/actions/authActions";
import { goToLoginPage } from "../../helpers/utilFunctions";
import { RootState } from "types";

interface Values {
  password: string;
  confirmPassword?: string;
}
const ResetPassword = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const resetState = useSelector((state: RootState) => state?.authReducer);
  const loadingState = resetState?.isLoading;
  const resetStateSuccess = resetState?.resetPasswordSuccess;
  const resetStateFailed = resetState?.resetPasswordFailed;

  const setNewPassword = (values: Values) => {
    let data = {
      resetToken: location.pathname.split("/")[2],
      identifier: location.pathname.split("/")[3],
      password: values.password,
    };
    return dispatch(resetPassword(data));
  };

  const renderResult = () => {
    if (resetStateSuccess === true) {
      return (
        <ResultStatus
          status="success"
          title="Password reset successful"
          subTitle=" "
          btnText="Go to Login"
          redirect={goToLoginPage}
        />
      );
    } else if (resetStateFailed === true) {
      return (
        <ResultStatus
          status="error"
          title="Password reset failed"
          subTitle="An unexpected error occured"
          btnText="Try Again"
          redirect={() => window.location.reload()}
        />
      );
    }
  };

  return (
    <>
      <Box d="grid" h="100vh" placeItems="center" px="4">
        <Box textAlign="center" w={["100%", "100%", "320px"]}>
          <Box textAlign="center" className="logo-img-container">
            <Link href="https://veendhq.com" target="_blank" rel="noreferrer">
              <Image
                src={veendLogo}
                alt="veendHq"
                className="login-logo-main"
              />
            </Link>
          </Box>
          <Heading as="h3" mt="4" mb="2" fontSize={{ base: "x-large" }}>
            Set new password
          </Heading>
          <Box>
            <Formik
              initialValues={{ password: "", confirmPassword: "" }}
              onSubmit={async (values: Values) => {
                setNewPassword(values);
              }}
              validationSchema={yup.object().shape({
                password: yup
                  .string()
                  .required("Please provide your new password"),
                confirmPassword: yup
                  .string()
                  .required("Please confirm your new password")
                  .oneOf([yup.ref("password"), null], "Passwords must match"),
              })}
            >
              {({ values, handleChange, handleBlur, errors }) => (
                <Form>
                  <InputField
                    inputType="password"
                    name="password"
                    hasLabel
                    label="New Password"
                    placeholder="Enter password"
                    value={values.password}
                    onBlur={() => handleBlur}
                    onChange={handleChange}
                    error={errors.password}
                  />
                  <InputField
                    inputType="password"
                    name="confirmPassword"
                    hasLabel
                    label="Confirm New Password"
                    placeholder="Enter confirm password"
                    value={values.confirmPassword}
                    onBlur={() => handleBlur}
                    onChange={handleChange}
                    error={errors.confirmPassword}
                  />
                  <Button
                    type="submit"
                    variant="primary"
                    size="md"
                    mt="1"
                    isLoading={loadingState}
                  >
                    Reset Password
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
      {renderResult()}
    </>
  );
};

export default ResetPassword;
