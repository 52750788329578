import React from "react";
import { Modal, Button } from "antd";
import { Heading, Box, Flex } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { TextareaInput } from "components/common";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  closeAddManuallyModal,
  uploadPhoneNumbers,
} from "redux/actions/smsAutomationActions";
import { RootState } from "types";

export default function AddRecipientsManualModal() {
  const dispatch = useDispatch();
  const xJwt = localStorage.getItem("x_jwt");

  const campaignsState = useSelector(
    (state: RootState) => state.smsAutomationReducer
  );
  const isLoading = campaignsState?.requestLoading;
  const showModal = campaignsState?.showAddRecipientsManualModal;

  function handleCancel() {
    // close the current modal
    dispatch(closeAddManuallyModal());
  }

  function handleSubmit(values: any) {
    const numbersFromInput: string = values.phoneNumbers;

    var phoneNumbers = numbersFromInput
      ?.toString()
      .replace(/(?:\r\n|\r|\n)/g, ",")
      .split(",")
      .filter(n => n) // Remove empty elements
      .map(n => n.trim()) // Trim off the whitespaces
      .filter((item, position, self) => self.indexOf(item) === position); // Remove duplicated contacts

    dispatch(uploadPhoneNumbers(xJwt, { phoneNumbers }, dispatch));
  }

  return (
    <Modal
      visible={showModal}
      onCancel={handleCancel}
      footer={null}
      width="600px"
    >
      <Box>
        <Heading as="h2" mb="8" fontSize={{ base: "md", md: "lg", lg: "xl" }}>
          Add recepients manually
        </Heading>
      </Box>

      <Formik
        initialValues={{ phoneNumbers: "" }}
        validationSchema={yup.object().shape({
          phoneNumbers: yup
            .string()
            .required("Insert at least a phone number")
            .trim(),
        })}
        onSubmit={values => handleSubmit(values)}
      >
        {({ values, handleChange, handleBlur, errors }) => (
          <Form>
            <TextareaInput
              name="phoneNumbers"
              placeholder="Insert phone numbers separated with comma"
              value={values.phoneNumbers}
              onBlur={() => handleBlur}
              onChange={handleChange}
              error={errors.phoneNumbers}
              rows={8}
            />

            <Flex gap="6" justify="flex-end" mt="6">
              <Button
                className="outline"
                size="large"
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Add recepients
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
