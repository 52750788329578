import React from "react";
import { Link } from "react-router-dom";
import { Box, Heading } from "@chakra-ui/react";
import { ArrowBackBGIcon } from "./CustomIcons";

interface SmsAutomationHeaderProps {
  title: string;
  path?: string;
  hideBackBtn?: boolean;
}

const SmsAutomationHeader = ({
  title,
  path = "/sms-automation",
  hideBackBtn = false,
}: SmsAutomationHeaderProps) => {
  return (
    <>
      <Box mb="6">
        {hideBackBtn === false && (
          <Link to={path} className="icon-btn" style={{ paddingLeft: "0px" }}>
            <ArrowBackBGIcon style={{ color: "#040320" }} />
          </Link>
        )}

        <Heading
          as="h1"
          mt="4"
          fontSize={{ base: "md", md: "lg", lg: "2xl" }}
          color="#333344"
        >
          {title}
        </Heading>
      </Box>
    </>
  );
};

export default SmsAutomationHeader;
