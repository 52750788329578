import * as yup from "yup";
import { Form, Formik } from "formik";
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import { LockIcon } from "components/common/CustomIcons";
import { useDispatch, useSelector } from "react-redux";
import { loginUser2FA } from "redux/actions/authActions";
import { RootState } from "types";

type Props = {
  onNext: () => void;
};

const ConfirmPassword = ({ onNext }: Props) => {
  const dispatch = useDispatch();
  const email = localStorage.getItem("email");
  const authState = useSelector((state: RootState) => state.authReducer);

  const handleSubmit = async (password: string) => {
    dispatch(
      loginUser2FA(email, password, () => {
        onNext();
      })
    );
  };

  return (
    <Formik
      initialValues={{ password: "" }}
      onSubmit={values => handleSubmit(values.password)}
      validationSchema={yup.object().shape({
        password: yup
          .string()
          .required("Please provide your password to continue"),
      })}
    >
      {({ values, handleChange, errors }) => (
        <Form>
          <Flex
            flexDir="column"
            alignItems="center"
            px={{ base: "0", md: "20px", lg: "40px" }}
          >
            <LockIcon boxSize={"80px"} />
            <Text
              mb="16px"
              mt="38px"
              fontWeight="600"
              textAlign="center"
              color="neutral.500"
              fontSize={["20px", "20px", "24px", "28px"]}
            >
              Confirm your password
            </Text>
            <Text type="02" mb="32px" textAlign="center">
              This is to prevent fraud and ensure it is the rightful owner of
              this account making this request.
            </Text>
            <Box w="100%">
              <Input
                id="password"
                type="password"
                fontSize="14px"
                value={values.password}
                onChange={handleChange}
                error={errors.password}
                placeholder="Enter password"
              />
            </Box>
          </Flex>

          <Box h="50px" />

          <Flex
            mb="40px"
            alignItems={"center"}
            justifyContent={{ md: "center" }}
            flexDir={["column-reverse", null, "row"]}
            px={{ base: "0", md: "20px", lg: "40px" }}
          >
            <Button
              type="submit"
              bgColor={"#060089"}
              disabled={!values.password}
              isLoading={authState.isLoading2FALogin}
            >
              Continue
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default ConfirmPassword;
