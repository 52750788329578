import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { IEligibilityStatus, RootState } from "../../types";

export default function DownloadEligibilities({ tab }: IEligibilityStatus) {
  const userData = useSelector(
    (state: RootState) => state.authReducer.loginDataTwo
  );
  const eligibilityState = useSelector(
    (state: RootState) => state.toolsReducer
  );
  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const info = settingsState?.settingData?.data?.info;
  const eligibilityData = eligibilityState?.eligibilityData?.data;
  const isLoading = eligibilityState?.requestLoading;

  const download_list = (tab: IEligibilityStatus["tab"]) => {
    let tabList = {
      all: eligibilityData,
      pending: eligibilityData,
      processing: eligibilityData,
      completed: eligibilityData,
      failed: eligibilityData,
    };
    return tabList[tab]?.map((eligibility: any) => ({
      phoneNumber: eligibility?.phoneNumber,
      fullName: `${eligibility?.user?.firstName} ${eligibility?.user?.lastName}`,
      employer: eligibility?.user?.employer,
      salaryAmount: eligibility?.user?.salaryAmount,
      eligibleAmount: eligibility?.eligibility?.amount,
      createdAt: eligibility?.createdAt,
      status: eligibility?.status,
      updatedAt: eligibility?.updatedAt,
    }));
  };

  const isEmpty = _.isEmpty(download_list(tab));

  return (
    <Button
      variant="action"
      rightIcon={<DownloadOutlined />}
      isDisabled={isEmpty || isLoading}
      className="download-loans-btn"
    >
      {isEmpty ? (
        "Download"
      ) : (
        <CSVLink
          data={download_list(tab)}
          filename={`${
            info?.businessName || userData?.businessName || ""
          }_VeendHQAutoLending.${tab}-eligibilities_${moment().format(
            "YYYY/MM/DD/HH/mm"
          )}.csv`}
          target="_blank"
          className="no-hover"
        >
          Download
        </CSVLink>
      )}
    </Button>
  );
}
