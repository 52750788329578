import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { searchBorrowers } from "redux/actions/borrowerActions";
import { Input } from "antd";
import { Box } from "@chakra-ui/layout";

const { Search } = Input;

interface ISearchValues {
  searchQuery: string;
}

interface ISearchBorrowersInputProps {
  searchField: string;
  isDisabled?: boolean;
}

export default function SearchBorrowersInput({
  searchField,
  isDisabled,
}: ISearchBorrowersInputProps) {
  const dispatch = useDispatch();
  const xJwt = localStorage.getItem("x_jwt");

  return (
    <Formik
      initialValues={{
        searchQuery: "",
      }}
      validationSchema={yup.object().shape({
        searchQuery: yup.string().required("Please fill out this field"),
      })}
      onSubmit={(values: ISearchValues) => {
        dispatch(searchBorrowers(xJwt, searchField, values.searchQuery));
      }}
    >
      {({ values, handleChange, submitForm }) => (
        <Box as={Form} textAlign="center">
          <Search
            placeholder={`Search ${
              searchField === "phoneNumber" ? "phone number" : "employer"
            }`}
            onChange={handleChange}
            value={values.searchQuery}
            name="searchQuery"
            required
            onSearch={submitForm}
            enterButton
            className="custom-search-input"
            disabled={isDisabled}
          />
        </Box>
      )}
    </Formik>
  );
}
