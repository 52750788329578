import React from "react";
import "antd/dist/antd.css";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { AppTheme } from "./theme";
import PageLayout from "components/layouts/PageLayout";

function App() {
  return (
    <ChakraProvider theme={AppTheme}>
      {/* @ts-ignore */}
      <Router>
        <Switch>
          <Route path="/" component={PageLayout} />
        </Switch>
      </Router>
    </ChakraProvider>
  );
}

export default App;
