import React from "react";
import * as yup from "yup";
import { Modal } from "antd";
import { Heading, Button, Box, HStack } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { InputField, SelectInput } from "components/common";
import { useDispatch } from "react-redux";
import { createFee, updateFee } from "redux/actions/settingsActions";
import { Fee, IOptions } from "types";

interface IAddFeeModalProps {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
  fee?: Fee;
}

interface FeeFormValues {
  name: string;
  amount: number;
  chargeTimeType: string | IOptions;
  chargeCalculationType: string | IOptions;
}

export default function AddFeeModal({
  showModal,
  setShowModal,
  fee,
}: IAddFeeModalProps) {
  const dispatch = useDispatch();
  const xJwt = localStorage.getItem("x_jwt");

  const handleOk = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const getOption = (value: string): IOptions => {
    return { value, label: value };
  };

  const getOptions = (values: string[]): IOptions[] => {
    return values.map(getOption);
  };

  return (
    <>
      <Modal
        visible={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width="80%"
        className="loan-config-modal"
      >
        <Box textAlign="center" key={fee?.name}>
          <Heading as="h2" fontSize="md" mb="3">
            {`${fee ? "Edit" : "Create"} Fee`}
          </Heading>
          <Formik
            initialValues={{
              name: fee ? fee.name : "",
              amount: fee ? fee.amount : 0,
              chargeTimeType: fee ? fee.chargeTimeType : "",
              chargeCalculationType: fee ? fee.chargeCalculationType : "",
            }}
            onSubmit={(values: FeeFormValues, { resetForm }) => {
              const { chargeTimeType, chargeCalculationType } = values;
              const body = {
                ...values,
                chargeTimeType: (chargeTimeType as IOptions).value,
                chargeCalculationType: (chargeCalculationType as IOptions)
                  .value,
              };

              if (fee) {
                dispatch(updateFee(xJwt, body, fee._id, dispatch));
              } else {
                dispatch(createFee(xJwt, body, dispatch));
              }

              resetForm();
              handleCancel();
            }}
            validationSchema={yup.object().shape({
              name: yup.string().required("Please provide name of fee"),
              amount: yup
                .number()
                .required("Please provide fee amount")
                .typeError("Amount must be a number"),
              chargeTimeType: yup
                .mixed()
                .required("Please provide charge time"),
              chargeCalculationType: yup
                .mixed()
                .required("Please provide charge calculation"),
            })}
          >
            {({ values, handleChange, handleBlur, setFieldValue, errors }) => (
              <Form>
                <HStack justify="space-between">
                  <Box className="half-form">
                    <InputField
                      inputType="text"
                      name="name"
                      hasLabel
                      label="Name"
                      placeholder="Enter fee name"
                      value={values.name}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.name}
                    />
                  </Box>
                  <Box className="half-form">
                    <InputField
                      inputType="text"
                      name="amount"
                      hasLabel
                      label="Amount"
                      placeholder="Enter fee amount"
                      value={values.amount}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.amount}
                    />
                  </Box>
                </HStack>
                <HStack justify="space-between">
                  <Box className="half-form">
                    <SelectInput
                      id="chargeTimeType"
                      hasLabel
                      label="Charge Time"
                      placeholder="Select charge time type"
                      value={
                        typeof values.chargeTimeType === "string" &&
                        values.chargeTimeType
                          ? getOption(values.chargeTimeType)
                          : values.chargeTimeType
                      }
                      options={getOptions(["Disbursement", "Installment Fee"])}
                      onChange={setFieldValue}
                      error={errors.chargeTimeType}
                    />
                  </Box>
                  <Box className="half-form">
                    <SelectInput
                      hasLabel
                      id="chargeCalculationType"
                      label="Charge Calculation"
                      placeholder="Select charge calculation type"
                      value={
                        typeof values.chargeCalculationType === "string" &&
                        values.chargeCalculationType
                          ? getOption(values.chargeCalculationType)
                          : values.chargeCalculationType
                      }
                      onChange={setFieldValue}
                      options={getOptions(["flat", "percentage"])}
                      error={errors.chargeCalculationType}
                    />
                  </Box>
                </HStack>

                <Button type="submit" mt="3">
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
}
