import React from "react";
import { Button, Heading, Text, Box } from "@chakra-ui/react";

export default function error404() {
  const goToHome = () => {
    window.location.href = "/dashboard";
  };
  return (
    <Box className="error-404">
      <Heading
        as="h1"
        color="primaryBlue"
        fontSize={{ base: "5xl", md: "7xl", lg: "9xl" }}
      >
        404
      </Heading>
      <Text fontSize={{ base: "xs", md: "md", lg: "lg" }}>
        An unexpected error occured{" "}
      </Text>
      <Button onClick={goToHome}>Back to home</Button>
    </Box>
  );
}
