import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Box } from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import moment from "moment";
import { ILoanTab, RootState } from "../../types";

interface IDownloadLoansProps {
  tab: ILoanTab["loanTab"];
}

export default function DownloadLoans({ tab }: IDownloadLoansProps) {
  const userData = useSelector(
    (state: RootState) => state.authReducer.loginDataTwo
  );

  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const loansState = useSelector((state: RootState) => state.loanReducer);
  const info = settingsState?.settingData?.data?.info;
  const list = loansState?.loansDownloadList;
  const approvedLoansDownloadList = loansState?.approvedLoansDownloadList;
  const processingLoansDownloadList = loansState?.processingLoansDownloadList;
  const activeLoansDownloadList = loansState?.activeLoansDownloadList;
  const partiallyPaidLoansDownloadList =
    loansState?.partiallyPaidLoansDownloadList;
  const primeLoansDownloadList = loansState?.primeLoansDownloadList;
  const paidLoansDownloadList = loansState?.paidLoansDownloadList;
  const overdueLoansDownloadList = loansState?.overdueLoansDownloadList;
  const topupLoansDownloadList = loansState?.topupLoansDownloadList;
  const isLoading = loansState?.requestLoading;

  const download_list = (tab: ILoanTab["loanTab"]) => {
    let tabList = {
      all: list,
      approved: approvedLoansDownloadList,
      processing: processingLoansDownloadList,
      active: activeLoansDownloadList,
      paid: paidLoansDownloadList,
      overdue: overdueLoansDownloadList,
      topup: topupLoansDownloadList,
      partial: partiallyPaidLoansDownloadList,
      prime: primeLoansDownloadList,
    };
    if (tab === "active" || tab === "overdue" || "approved") {
      return tabList[tab]?.map(loan => ({
        phoneNumber: loan?.user?.phoneNumber,
        principalAmount: loan?.amount,
        fee: loan?.fee,
        interestAmount: loan?.interestAmount,
        interestRate: loan?.interestRate,
        createdAt: loan?.createdAt,
        loanId: loan?.loanId,
        tenure: loan?.tenure,
        accountNumber: loan?.user?.accountNumber,
        bankCode: loan?.channel === "prime" ? "0" + "35" : "",
        bankName: loan?.channel === "prime" ? "Wema Bank" : "",
        disbursementStatus: loan?.disbursementStatus,
        disburseDate: loan?.disburseDate,
        status: loan?.status,
        remitaMandateReference: loan?.remitaMandateReference,
        isTopup: loan?.isTopup,
        repaymentAmount: loan?.repaymentAmount,
        totalExpectedRepayment: loan?.totalExpectedRepayment,
        repaymentDate: loan?.repaymentDate,
        totalOutstanding: loan?.totalOutstanding,
        totalRepayment: loan?.totalRepayment,
        lastInstallmentDate: loan?.lastInstallmentDate,
        fullName: `${loan?.user?.firstName} ${loan?.user?.lastName}`,
        email: loan?.user?.email,
        bvn: loan?.user?.bvn,
        employer: loan?.user?.employer,
        salaryAmount: loan?.user?.salaryAmount,
        preApprovedAmount: loan?.user?.preApprovedAmount,
        channel: loan?.channel,
      }));
    } else {
      return tabList[tab]?.map(loan => ({
        principalAmount: loan?.amount,
        fee: loan?.fee,
        interestAmount: loan?.interestAmount,
        interestRate: loan?.interestRate,
        createdAt: loan?.createdAt,
        loanId: loan?.loanId,
        tenure: loan?.tenure,
        accountNumber: loan?.user?.accountNumber,
        bankCode: loan?.channel === "prime" ? "035" : "",
        bankName: loan?.channel === "prime" ? "Wema Bank" : "",
        disbursementStatus: loan?.disbursementStatus,
        disburseDate: loan?.disburseDate,
        status: loan?.status,
        remitaMandateReference: loan?.remitaMandateReference,
        isTopup: loan?.isTopup,
        repaymentAmount: loan?.repaymentAmount,
        lastInstallmentDate: loan?.lastInstallmentDate,
        totalExpectedRepayment: loan?.totalExpectedRepayment,
        repaymentDate: loan?.repaymentDate,
        totalOutstanding: loan?.totalOutstanding,
        totalRepayment: loan?.totalRepayment,
        fullName: `${loan?.user?.firstName} ${loan?.user?.lastName}`,
        email: loan?.user?.email,
        employer: loan?.user?.employer,
        salaryAmount: loan?.user?.salaryAmount,
        preApprovedAmount: loan?.user?.preApprovedAmount,
        channel: loan?.channel,
      }));
    }
  };
  const isListEmpty = isEmpty(download_list(tab));

  return (
    <Box d="flex" flexDir="row-reverse" py="3">
      <Button
        variant="action"
        rightIcon={<DownloadOutlined />}
        isDisabled={isListEmpty || isLoading}
        className="download-loans-btn"
      >
        {isListEmpty ? (
          "Download"
        ) : (
          <CSVLink
            data={download_list(tab)}
            filename={`${
              info?.businessName || userData?.businessName || ""
            }_VeendHQAutoLending.${tab}-loans_${moment().format(
              "YYYY/MM/DD/HH/mm"
            )}.csv`}
            target="_blank"
            className="no-hover"
          >
            Download
          </CSVLink>
        )}
      </Button>
    </Box>
  );
}
