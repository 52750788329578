import React, { ReactNode } from "react";
import { Input } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import "./styles/InputFieldStyles.css";
import { Box, Text } from "@chakra-ui/layout";

interface InputFieldProps {
  name: string;
  onChange: (e: any) => void;
  onBlur?: () => void;
  placeholder: string;
  value?: string | number;
  error: any;
  inputType: string;
  hasLabel?: boolean;
  label?: string;
  isDisabled?: boolean;
  defaultValue?: string;
  prefix?: any;
  required?: boolean;
  readOnly?: boolean;
  desc?: ReactNode;
}

const InputField = ({
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  inputType,
  hasLabel,
  label,
  isDisabled,
  defaultValue,
  prefix,
  required,
  desc,
  readOnly,
}: InputFieldProps) => {
  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  return (
    <Box my="4" className={wrapperClass}>
      {hasLabel && (
        <Box
          d="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
          mb="1"
          className="label-div"
        >
          <Text className="form-label">{label}</Text>
        </Box>
      )}
      {inputType === "password" ? (
        <Box
          as={Input.Password}
          name={name}
          className="custom-input"
          placeholder={placeholder}
          my="2"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isDisabled}
          prefix={prefix}
          required={required}
        />
      ) : (
        <Box
          as={Input}
          type={inputType}
          name={name}
          my="2"
          className="custom-input"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isDisabled}
          defaultValue={defaultValue}
          prefix={prefix}
          required={required}
          readOnly={readOnly}
        />
      )}
      {error && (
        <Box className="error-div">
          <ExclamationCircleFilled className="error-icon" />
          <Text className="error-text">{error}</Text>
        </Box>
      )}
      {desc && (
        <Box className="input-desc">
          <Box className="input-desc-text">{desc}</Box>
        </Box>
      )}
    </Box>
  );
};

export default InputField;
