import { message } from "antd";
import jwtDecode from "jwt-decode";
import axios from "axios";
import {
  AUTH_LOADING_FALSE,
  AUTH_LOADING_TRUE,
  ENABLE_2FA_ACTION_SUCCESS,
  ERROR_401_ACTION,
  GET_USER_PROFILE_ACTION_SUCCESS,
  LOGIN_USER_2FA_ACTION_SUCCESS,
  LOGIN_USER_2FA_LOADING_FALSE,
  LOGIN_USER_2FA_LOADING_TRUE,
  LOGIN_USER_ACTION_FAILURE,
  LOGIN_USER_ACTION_SUCCESS,
  RESET_PASSWORD_ACTION_FAILURE,
  RESET_PASSWORD_ACTION_SUCCESS,
  RESET_VERIFY_EMAIL_STATE,
  SIGNUP_LENDER_ACTION_FAILURE,
  SIGNUP_LENDER_ACTION_SUCCESS,
  TWO_FA_NEEDED_FOR_LOGIN,
  VERIFICATION_ONBOARDING_FAILED,
  VERIFICATION_ONBOARDING_SUCCESS,
  VERIFY_EMAIL_ACTION_FAILURE,
  VERIFY_EMAIL_ACTION_SUCCESS,
} from "./actionTypes";
import { IDecodedTJWTToken } from "types";

const baseUrl = process.env.REACT_APP_BASE_URL;
const resetbaseUrl = process.env.REACT_APP_TXURL;

export const getUserProfile =
  () => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      const token = localStorage.getItem("tx_jwt");
      const decodedToken: IDecodedTJWTToken = jwtDecode(token!);
      const res = await axios.get(
        `${resetbaseUrl}/v1/users/${decodedToken.sub}?accountId=${decodedToken.account._id}`,
        {
          headers: {
            "x-tag": "vend-setup",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: GET_USER_PROFILE_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { response } = error;
        message.error(response?.data.message || response?.data?.data?.message);
      }
    }
  };

export const verify2FACode = async (
  userId: string,
  accountId: string,
  verificationCode: string,
  onSuccess: () => void
) => {
  try {
    const token = localStorage.getItem("tx_jwt");
    const res = await axios.get(
      `${resetbaseUrl}/v1/users/${userId}/2fa/${verificationCode}?accountId=${accountId}`,
      {
        headers: {
          "x-tag": "vend-setup",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    res?.data.data?.isValid && onSuccess();
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      message.error(response?.data.message || response?.data?.data?.message);
    }
  }
};

export const loginUser2FA =
  (email: string | null, password: string, onNext?: () => void) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({ type: LOGIN_USER_2FA_LOADING_TRUE });
      const res = await axios.post(`${resetbaseUrl}/login?x-tag=vend-setup`, {
        email,
        password,
      });
      dispatch({
        type: LOGIN_USER_2FA_ACTION_SUCCESS,
        payload: res?.data?.data?.user,
      });
      dispatch({ type: LOGIN_USER_2FA_LOADING_FALSE });
      onNext?.();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch({ type: LOGIN_USER_2FA_LOADING_FALSE });
        const { response } = error;
        message.error(response?.data.message || response?.data?.data?.message);
      }

      dispatch({ type: LOGIN_USER_2FA_LOADING_FALSE });
    }
  };

export const enable2FA =
  (userId: string, accountId: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    const token = localStorage.getItem("tx_jwt");

    try {
      dispatch({ type: AUTH_LOADING_TRUE });
      const res = await axios.get(
        `${resetbaseUrl}/v1/users/${userId}/2fa/enable?accountId=${accountId}`,
        {
          headers: {
            "x-tag": "vend-setup",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: ENABLE_2FA_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
      dispatch({ type: AUTH_LOADING_FALSE });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch({ type: AUTH_LOADING_FALSE });
        const { response } = error;
        message.error(response?.data.message || response?.data?.data?.message);
      }

      dispatch({ type: AUTH_LOADING_FALSE });
    }
  };

export const disable2FA =
  (userId: string, accountId: string, onNext?: () => void) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    const token = localStorage.getItem("tx_jwt");

    try {
      dispatch({ type: AUTH_LOADING_TRUE });

      await axios.get(
        `${resetbaseUrl}/v1/users/${userId}/2fa/disable?accountId=${accountId}`,
        {
          headers: {
            "x-tag": "vend-setup",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: AUTH_LOADING_FALSE });
      onNext?.();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch({ type: AUTH_LOADING_FALSE });
        const { response } = error;
        message.error(response?.data.message || response?.data?.data?.message);
      }

      dispatch({ type: AUTH_LOADING_FALSE });
    }
  };

export const recoverTwoFA =
  (email: string, password: string, token: string, onSuccess?: () => void) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({ type: AUTH_LOADING_TRUE });
      const res = await axios.post(
        `${resetbaseUrl}/v1/recover2fa`,
        {
          email,
          password,
          token,
        },
        {
          headers: {
            "x-tag": "vend-setup",
          },
        }
      );

      dispatch({
        type: ENABLE_2FA_ACTION_SUCCESS,
        payload: res?.data?.data,
      });
      dispatch({ type: AUTH_LOADING_FALSE });
      onSuccess?.();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch({ type: AUTH_LOADING_FALSE });
        const { response } = error;
        message.error(response?.data.message || response?.data?.data?.message);
      }

      dispatch({ type: AUTH_LOADING_FALSE });
    }
  };

// login user
export const loginUser =
  (email: string | null, password: string, token?: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: AUTH_LOADING_TRUE,
      });
      const res = await axios.post(
        `${baseUrl}/login?x-tag=vend-setup`,
        token
          ? {
              email,
              password,
              token,
            }
          : {
              email,
              password,
            }
      );
      dispatch({
        type: LOGIN_USER_ACTION_SUCCESS,
        payload: res?.data,
      });
      localStorage.setItem("x_jwt", res?.data?.data.x_jwt);
      localStorage.setItem("tx_jwt", res?.data?.data.tx_jwt);
      sessionStorage.setItem("license-reminder", "true");
      window.location.href = "/dashboard";
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch({
          type: LOGIN_USER_ACTION_FAILURE,
        });
        const { response } = error;
        message.error(response?.data.message || response?.data?.data?.message);
        if (
          response?.data?.message.includes(
            "Enter your 2FA code from your authentication app."
          )
        ) {
          dispatch({
            type: TWO_FA_NEEDED_FOR_LOGIN,
            payload: true,
          });
        }
        if (
          response?.data.message.includes("An OTP has been sent to your email.")
        ) {
          window.location.href = `/verify-email-otp?email=${email}`;
        }
        //
        dispatch({
          type: AUTH_LOADING_FALSE,
        });
      }
      return error;
    }
  };

export const loginUserFor2FA =
  (email: string | null, password: string, token: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: AUTH_LOADING_TRUE,
      });
      const res = await axios.post(`${resetbaseUrl}/login?x-tag=vend-setup`, {
        email,
        password,
        token,
      });
      dispatch({
        type: LOGIN_USER_ACTION_SUCCESS,
        payload: res?.data,
      });
      localStorage.setItem("x_jwt", res?.data?.data?.lenderJWTToken);
      localStorage.setItem("tx_jwt", res?.data?.data?.jwt);
      window.location.href = "/dashboard";
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch({
          type: LOGIN_USER_ACTION_FAILURE,
        });
        const { response } = error;
        message.error(response?.data.message || response?.data?.data?.message);
        if (
          response?.data?.message.includes(
            "Enter your 2FA code from your authentication app."
          )
        ) {
          dispatch({
            type: TWO_FA_NEEDED_FOR_LOGIN,
            payload: true,
          });
        }
        if (
          response?.data.message.includes("An OTP has been sent to your email.")
        ) {
          window.location.href = `/verify-email-otp?email=${email}`;
        }
        //
        dispatch({
          type: AUTH_LOADING_FALSE,
        });
      }
      return error;
    }
  };

// signup lender
export const lenderSignup =
  (lenderData: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    const config: any = {
      headers: {
        "x-tag": `veend-setup`,
      },
    };
    try {
      dispatch({
        type: AUTH_LOADING_TRUE,
      });
      const res = await axios.post(`${baseUrl}/signup`, lenderData, config);
      dispatch({
        type: SIGNUP_LENDER_ACTION_SUCCESS,
        payload: res?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { response } = error;

        if (
          response?.data.message.includes("An OTP has been sent to your email.")
        ) {
          message.success(
            response?.data.message || response?.data?.data?.message
          );
          return (window.location.href = `/verify-email-otp?email=${lenderData?.email}`);
        }

        message.error(response?.data.message || response?.data?.data?.message);
        dispatch({
          type: SIGNUP_LENDER_ACTION_FAILURE,
          payload: response?.data.message || response?.data?.data?.message,
        });
        dispatch({
          type: AUTH_LOADING_FALSE,
        });
      }
      return error;
    }
  };

export const saveToSheet = (signupData: string) => {
  try {
    axios.post(`https://api.apispreadsheets.com/data/18162/`, signupData);
  } catch (error) {
    return error;
  }
};

//send reset password link
export const forgotPasswordRequest =
  (userData: any) =>
  async (dispatch: (arg0: { type?: string; payload?: any }) => void) => {
    const config: any = {
      headers: {
        "x-tag": "veend-forgot-password",
      },
    };
    try {
      await axios.post(`${resetbaseUrl}/forgotPassword`, userData, config);
      message.success("Reset link sent to mail");
    } catch (error) {
      message.error("Invalid email");
      return error;
    }
  };

// reset password
export const resetPassword =
  (userData: any) =>
  async (dispatch: (arg0: { type?: string; payload?: any }) => void) => {
    dispatch({
      type: AUTH_LOADING_TRUE,
    });
    const config: any = {
      headers: {
        "x-tag": "veend-reset-password",
      },
    };
    try {
      await axios.post(`${resetbaseUrl}/setPassword`, userData, config);
      dispatch({
        type: RESET_PASSWORD_ACTION_SUCCESS,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch({
          type: RESET_PASSWORD_ACTION_FAILURE,
        });
        dispatch({
          type: AUTH_LOADING_FALSE,
        });
      }
      return error;
    }
  };

//verify email
export const verifyEmail =
  (userData: any) =>
  async (dispatch: (arg: { type?: string; payload?: any }) => void) => {
    dispatch({
      type: RESET_VERIFY_EMAIL_STATE,
    });
    const config: any = {
      headers: {
        "x-tag": "veend-verify-email",
      },
    };
    try {
      await axios.post(`${resetbaseUrl}/v1/verify`, userData, config);
      message.success("Email Verified Successfully");
      dispatch({
        type: VERIFY_EMAIL_ACTION_SUCCESS,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch({
          type: VERIFY_EMAIL_ACTION_FAILURE,
        });
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };

// dojah success

export const saveDojahDetails =
  (userData: any) =>
  async (dispatch: (arg: { type?: string; payload?: any }) => void) => {
    const xjwt = localStorage.getItem("x_jwt");
    const config: any = {
      headers: {
        "x-tag": "veend",
        "x-jwt": xjwt,
      },
    };
    try {
      const response = await axios.post(`${baseUrl}/kyc`, userData, config);
      dispatch({
        type: VERIFICATION_ONBOARDING_SUCCESS,
        payload: response.data,
      });
      message.success("Verification complete");
      window.location.href = "/dashboard";
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: VERIFICATION_ONBOARDING_FAILED,
          });
        }
        message.error(error?.response?.data?.message);
      }
      return error;
    }
  };
