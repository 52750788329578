import React from "react";
import { Modal } from "antd";
import { Heading, Text, Button, Box, HStack } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { InputField } from "components/common";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { createBankAccount } from "redux/actions/generalActions";
import validationSchema from "constants/validationSchema";
import { RootState } from "types";

interface ICreateAccount {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
  userId: string;
  accountId: string;
  info: any;
  afterClose: () => void;
}
interface IAccountForm {
  BVN: string;
  address: string;
  addressState: string;
  rcNumber: string;
}
const CreateAccountModal = ({
  setShowModal,
  showModal,
  userId,
  accountId,
  info,
  afterClose,
}: ICreateAccount) => {
  const dispatch = useDispatch();

  const txJwt = localStorage.getItem("tx_jwt");
  const userData = useSelector(
    (state: RootState) => state.authReducer.loginDataTwo
  );

  const handleCancel = () => {
    setShowModal(false);
  };
  const handleOk = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal
        visible={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width="80%"
        afterClose={afterClose}
      >
        <Heading as="h1" mb="5" fontSize="md" textAlign="center">
          Create Dedicated Account
        </Heading>

        <Formik
          initialValues={{
            BVN: "",
            address: "",
            addressState: "",
            rcNumber: "",
          }}
          onSubmit={async (values: IAccountForm) => {
            let createAccountBody = {
              firstName: info?.firstName || userData?.firstName || "",
              lastName: info?.lastName || userData?.lastName || "",
              BVN: values.BVN,
              address: values.address,
              addressState: values.addressState,
              businessName: info?.businessName || userData?.businessName || "",
              rcNumber: values.rcNumber,
            };
            dispatch(
              createBankAccount(txJwt, userId, accountId, createAccountBody)
            );
            setShowModal(false);
          }}
          validationSchema={yup
            .object()
            .shape(validationSchema.createFundingAccount)}
        >
          {({ values, handleChange, handleBlur, errors }) => (
            <Form>
              <HStack justify="space-between">
                <Box className="half-form">
                  <Text fontSize={{ base: "xs", md: "sm" }}>
                    Fullname:{" "}
                    <Text as="span" fontWeight="semibold" fontStyle="italic">
                      {`${info?.firstName || userData?.firstName || ""} ${
                        info?.lastName || userData?.lastName || ""
                      }`}
                    </Text>
                  </Text>
                </Box>
                <Box className="half-form">
                  <Text fontSize={{ base: "xs", md: "sm" }}>
                    Business name:{" "}
                    <Text as="span" fontWeight="semibold" fontStyle="italic">
                      {`${info?.businessName || userData?.businessName || ""}`}
                    </Text>
                  </Text>
                </Box>
              </HStack>
              <HStack justify="space-between">
                <Box className="half-form">
                  <InputField
                    inputType="text"
                    name="BVN"
                    hasLabel
                    label="BVN"
                    placeholder="Enter BVN"
                    value={values.BVN}
                    onBlur={() => handleBlur}
                    onChange={handleChange}
                    error={errors.BVN}
                  />
                </Box>
                <Box className="half-form">
                  <InputField
                    inputType="text"
                    name="rcNumber"
                    hasLabel
                    label="RC NUMBER"
                    placeholder="Enter RC NUMBER"
                    value={values.rcNumber}
                    onBlur={() => handleBlur}
                    onChange={handleChange}
                    error={errors.rcNumber}
                  />
                </Box>
              </HStack>

              <HStack justify="space-between">
                <Box className="half-form">
                  <InputField
                    inputType="text"
                    name="address"
                    hasLabel
                    label="Address"
                    placeholder="Enter Address"
                    value={values.address}
                    onBlur={() => handleBlur}
                    onChange={handleChange}
                    error={errors.address}
                  />
                </Box>
                <Box className="half-form">
                  <InputField
                    inputType="text"
                    name="addressState"
                    hasLabel
                    label="Address State"
                    placeholder="Enter Address state"
                    value={values.addressState}
                    onBlur={() => handleBlur}
                    onChange={handleChange}
                    error={errors.addressState}
                  />
                </Box>
              </HStack>

              <Box style={{ width: "100%", textAlign: "center" }}>
                <Button type="submit" variant="primary" mt="2" size="md">
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default CreateAccountModal;
