import React from "react";
import veendLogo from "../../assets/veend-logo.png";
import { useHistory } from "react-router-dom";
import { Heading, Text, Box, Link, Image } from "@chakra-ui/react";

interface FooterLink {
  title: string;
  to: string;
}

interface AuthFormWrapperProps {
  header: string;
  description: React.ReactNode;
  children: React.ReactNode;
  footerLinks?: FooterLink[];
}

const AuthFormWrapper = ({
  header,
  description,
  children,
  footerLinks,
}: AuthFormWrapperProps) => {
  const history = useHistory();

  return (
    <>
      <Box className="logo-img-container" textAlign="center">
        <Link href="https://veendhq.com" target="_blank" rel="noreferrer">
          <Image src={veendLogo} alt="veendHq" className="login-logo-main" />
        </Link>
      </Box>
      <Heading
        as="h1"
        mt="6"
        mb="2"
        fontSize={{
          base: "lg",
          md: "x-large",
          lg: footerLinks ? "x-large" : "xx-large",
        }}
      >
        {header}
      </Heading>
      {description}
      <Box mt="3" mx="auto" textAlign="center" className="login-form-group">
        {children}
      </Box>

      {footerLinks
        ? footerLinks.map(({ to, title }, index) => (
            <Text
              fontSize={{ base: "xx-small", md: "xs" }}
              color="primaryBlue"
              fontWeight="semibold"
              cursor="pointer"
              mt="4"
              onClick={() => history.push(to)}
              key={index}
            >
              {title}
            </Text>
          ))
        : null}
    </>
  );
};

export default AuthFormWrapper;
