import React from "react";
import { Button, Heading, Text } from "@chakra-ui/react";

export default function ConsumerRegister() {
  const goToHome = () => {
    window.location.href = "/";
  };
  return (
    <div className="error-404">
      <Heading
        as="h1"
        color="primaryBlue"
        fontSize={{ base: "5xl", md: "7xl", lg: "9xl" }}
      >
        404
      </Heading>
      <Text fontSize={{ base: "xs", md: "md", lg: "lg" }}>
        This page is still under construction
      </Text>
      <Button onClick={goToHome}>Go Back</Button>
    </div>
  );
}
