import React from "react";
import { Modal, Button } from "antd";
import { Heading, Box, Flex, Text } from "@chakra-ui/react";
import {
  CalenderIcon,
  ClockIcon,
  GroupIcon,
  MoneyIcon,
} from "components/common/CustomIcons";
import { useFormikContext } from "formik";
import { CampaignFields } from "views/main/smsAutomation/CreateNewCampaign";

interface ILaunchCampaignModalProps {
  isFormForEdit?: boolean;
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
  launchCampaign: (values: object) => void;
}

export default function LaunchCampaignModal({
  isFormForEdit = false,
  showModal,
  setShowModal,
  launchCampaign,
}: ILaunchCampaignModalProps) {
  const { values } = useFormikContext<CampaignFields>();

  function handleCancel() {
    setShowModal(false);
  }

  return (
    <Modal
      visible={showModal}
      onCancel={handleCancel}
      footer={null}
      width="520px"
    >
      <Box>
        <Heading as="h2" mb="8" fontSize={{ base: "md", md: "lg", lg: "xl" }}>
          {isFormForEdit === false
            ? "Launch campaign"
            : "Save and Launch campaign"}
        </Heading>
      </Box>

      <Flex direction="column" gap="5" pb="16">
        <Flex gap="4" align="center">
          <GroupIcon style={{ width: "100%", maxWidth: "20px" }} />
          <Text color="#A0A0AA">
            Sending message to {values?.amount} recepient
            {values?.amount > 1 ? "s" : ""}
          </Text>
        </Flex>

        <Flex gap="4" align="center">
          <CalenderIcon style={{ width: "100%", maxWidth: "20px" }} />
          <Text color="#A0A0AA">
            {values?.isContinous
              ? "Campaign is continuous"
              : `End date is ${values?.endDate}`}
          </Text>
        </Flex>

        <Flex gap="4" align="center">
          <ClockIcon style={{ width: "100%", maxWidth: "20px" }} />
          <Text color="#A0A0AA">Sends every 6am</Text>
        </Flex>

        {!isFormForEdit && (
          <Flex gap="4" align="center">
            <MoneyIcon style={{ width: "100%", maxWidth: "20px" }} />
            <Text color="#A0A0AA">
              {values?.isContinous
                ? `Estimated cost per day is ₦${values.estimatedCostPerDay}`
                : `Estimated cost per campaign is ₦${values.estimatedCostPerCampaign}`}
            </Text>
          </Flex>
        )}
      </Flex>

      <Flex gap="6" justify="flex-end" mt="6" wrap="wrap">
        <Button className="outline" size="large" onClick={() => handleCancel()}>
          Cancel
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={() => launchCampaign(values)}
        >
          Launch campaign
        </Button>
      </Flex>
    </Modal>
  );
}
