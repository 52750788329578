import React from "react";
import { HStack, Text } from "@chakra-ui/react";

interface BorrowerInfoItemProps {
  label: string;
  value?: string | number;
}

const BorrowerInfoItem = ({ label, value }: BorrowerInfoItemProps) => {
  return (
    <HStack className="borrower-info-row">
      <Text fontWeight="semibold" fontSize={{ base: "xs", md: "sm" }}>
        {label}:
      </Text>
      <Text align="right" fontSize={{ base: "xs", md: "sm" }}>
        {value || "N/A"}
      </Text>
    </HStack>
  );
};

export default BorrowerInfoItem;
