import React, { useState, useEffect } from "react";
import { Modal, Upload } from "antd";
import { PaperClipOutlined, UploadOutlined } from "@ant-design/icons";
import { Heading, Button, Text, Box, Stack } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { uploadEligibility } from "redux/actions/toolsActions";
import { TextareaInput } from "../common";
import { commaSeparatedPhoneRegExp } from "../../constants";
import { convertCsvFile } from "helpers/utilFunctions";
import { Loader } from "components/common";
import { RootState } from "types";

interface IGetEligibilityModalProps {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
}

interface GetEligibilitysFormValues {
  phoneNumbers: string;
}

export default function GetEligibilityModal({
  showModal,
  setShowModal,
}: IGetEligibilityModalProps) {
  const [numbers, setNumbers] = useState<any[]>([]);
  const dispatch = useDispatch();
  const eligibilityState = useSelector(
    (state: RootState) => state.toolsReducer
  );
  // const eligibilityData = eligibilityState?.eligibilityData;
  // const eligibleUserData = eligibilityState?.eligibleUserData;
  const isLoading = eligibilityState?.requestLoading;
  const xJwt = localStorage.getItem("x_jwt");

  const Example = "https://cdn.filestackcontent.com/9356etvzThefqhelnWn0";

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleClose = () => {};

  const handleChange = (e: any) => {
    const files = e.file;
    convertCsvFile(files, setNumbers);
    return handleCancel();
  };

  /* list of supported file types */
  const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "csv"]
    .map(function (x) {
      return "." + x;
    })
    .join(",");

  useEffect(() => {
    if (numbers?.length > 0) {
      dispatch(uploadEligibility(xJwt, numbers));
      setNumbers([]);
    }
  }, [dispatch, numbers, xJwt]);

  return (
    <>
      <Modal
        visible={showModal}
        onCancel={handleCancel}
        footer={null}
        width="60%"
        className="eligibility-modal"
        afterClose={handleClose}
      >
        <Heading as="h2" mb="2" fontSize={{ base: "sm", md: "md", lg: "lg" }}>
          Upload phone numbers
        </Heading>
        <Formik
          initialValues={{
            phoneNumbers: "",
          }}
          onSubmit={(values: GetEligibilitysFormValues, { resetForm }) => {
            let numbersArr = values.phoneNumbers.split(",");
            dispatch(uploadEligibility(xJwt, numbersArr));
            resetForm();
            handleCancel();
          }}
          validationSchema={yup.object().shape({
            phoneNumbers: yup
              .string()
              .matches(commaSeparatedPhoneRegExp, "invalid format")
              .required(
                "Please enter phone number(s) you want to process eligibilities for"
              ),
          })}
        >
          {({ values, handleChange, handleBlur, errors }) => (
            <Box as={Form} textAlign="center" mt="3" mx="auto" w="75%">
              <TextareaInput
                name="phoneNumbers"
                hasLabel
                label="Customer salary number(s)"
                placeholder="e.g 08021122334,09021122334,07021122334"
                value={values.phoneNumbers}
                onBlur={() => handleBlur}
                onChange={handleChange}
                error={errors.phoneNumbers}
                required
                rows={3}
                desc="please note that this request will be charged from your wallet"
              />

              <Button type="submit" mt={4} leftIcon={<UploadOutlined />}>
                Upload
              </Button>
            </Box>
          )}
        </Formik>

        {isLoading && (
          <Box my="3">
            <Loader />
          </Box>
        )}

        <Stack justify="center" py="3">
          <Text fontSize="lg" fontWeight="bold">
            OR
          </Text>
        </Stack>
        {/* {isEmpty(eligibilityData) ? null : (
          <div className="eligibility-response">
            <Text fontSize={{ base: "xs", md: "sm" }}>
              Status:
              <Text as="span" fontWeight="medium">
                {eligibilityData?.status}
              </Text>
            </Text>
            <Text fontSize={{ base: "xs", md: "sm" }}>
              Eligible amount:{"   "}
              <Text as="span" fontWeight="medium">
                {formatNumberAmount(eligibilityData?.amount)}
              </Text>
            </Text>
            <Text fontSize={{ base: "xs", md: "sm" }}>
              Tenure:
              <Text as="span" fontWeight="medium">
                {eligibilityData?.tenure} {eligibilityData?.tenureType}
              </Text>
            </Text>
            <Text fontSize={{ base: "xs", md: "sm" }}>
              Total expected repayment:{" "}
              <Text as="span" fontWeight="medium">
                {formatNumberAmount(eligibilityData?.totalExpectedRepayment)}
              </Text>
            </Text>
            {eligibilityData?.expectedRepaymentDate && (
              <Text fontSize={{ base: "xs", md: "sm" }}>
                Expected repayment date:
                <Text as="span" fontWeight="medium">
                  {moment(eligibilityData?.expectedRepaymentDate).format(
                    dateInputFormat
                  )}
                </Text>
              </Text>
            )}

            <Text fontSize={{ base: "xs", md: "sm" }}>
              Customer name:
              <Text as="span" fontWeight="medium">
                {`${eligibleUserData?.[0]?.firstName} ${eligibleUserData?.[0]?.lastName}`}
              </Text>
            </Text>
            <Text fontSize={{ base: "xs", md: "sm" }}>
              Customer phone number:
              <Text as="span" fontWeight="medium">
                {eligibleUserData?.[0]?.phoneNumber}
              </Text>
            </Text>
            {eligibleUserData?.[0]?.email && (
              <Text fontSize={{ base: "xs", md: "sm" }}>
                Customer email:
                <Text as="span" fontWeight="medium">
                  {eligibleUserData?.[0]?.email}
                </Text>
              </Text>
            )}
            {eligibleUserData?.[0]?.employer && (
              <Text fontSize={{ base: "xs", md: "sm" }}>
                Employer:
                <Text as="span" fontWeight="medium">
                  {eligibleUserData?.[0]?.employer}
                </Text>
              </Text>
            )}
            {eligibleUserData?.[0]?.salaryAmount && (
              <Text fontSize={{ base: "xs", md: "sm" }}>
                Salary amount:
                <Text as="span" fontWeight="medium">
                  {formatNumberAmount(eligibleUserData?.[0]?.salaryAmount)}
                </Text>
              </Text>
            )}
            {eligibleUserData?.[0]?.salaryPaymentDate && (
              <Text fontSize={{ base: "xs", md: "sm" }}>
                Salary payment date:
                <Text as="span" fontWeight="medium">
                  {moment(eligibleUserData?.[0]?.salaryPaymentDate).format(
                    dateInputFormat
                  )}
                </Text>
              </Text>
            )}
            {eligibilityData?.log && (
              <div
                style={{
                  textAlign: "left",
                  overflowX: "auto",
                  marginTop: "0.5rem",
                }}
              >
                <Text fontSize={{ base: "xs", md: "sm" }}>log:</Text>
                <JSONViewer json={eligibilityData?.log} />
              </div>
            )}
          </div>
        )} */}
        <Upload
          onChange={handleChange}
          accept={SheetJSFT}
          beforeUpload={() => false}
          onRemove={() => false}
          maxCount={1}
          showUploadList={false}
        >
          <Text
            fontSize={{ base: "sm", md: "md", lg: "lg" }}
            my="3"
            className="more-eligibility-toggle"
          >
            <Box as={PaperClipOutlined} mr="1" />
            Upload a csv or excel file of phone numbers
          </Text>
        </Upload>
        <Text
          fontSize={{ base: "xx-small", md: "xs", lg: "sm" }}
          fontWeight="semibold"
          my="3"
        >
          Accepted format:{" "}
        </Text>
        <img
          src={Example}
          alt="csv-example-format"
          className="csv-example-format"
        />
      </Modal>
    </>
  );
}
