import React, { useState } from "react";
import { Text, Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import moment from "moment";
import { EditIcon } from "components/common/CustomIcons";
import { AddFeeModal, ConfirmDeleteFee } from "components/modals";
import { DateTimeFormat } from "../../constants";
import { Table } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { Loader } from "components/common";
import { Fee, RootState } from "types";
import { isReadOnly } from "helpers/utilFunctions";

const columns = [
  {
    dataIndex: "name",
    key: "name",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Name
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "amount",
    key: "amount",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Amount
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "chargeType",
    key: "chargeType",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Charge type
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "createdOn",
    key: "createdOn",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Created On
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "editAction",
  },
  {
    dataIndex: "deleteAction",
  },
];
export default function FeesTable() {
  const [showModal, setShowModal] = useState(false);
  const [fee, setFee] = useState({} as Fee);
  const [deleteRecord, setDeleteRecord] = useState({} as Fee);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const readonly = isReadOnly();

  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const requestLoading = settingsState?.requestLoading;
  const fees = settingsState?.fees;

  const onEdit = (record: any, e: any) => {
    e.stopPropagation();
    setFee(record);
    setShowModal(true);
  };

  const onDelete = (record: any, e: any) => {
    e.stopPropagation();
    setDeleteRecord(record);
    setShowConfirmationModal(true);
  };

  //map through user setting and add to data
  const data: any[] | undefined = [];
  for (let i = 0; i < fees?.length; i++) {
    data.push({
      key: fees[i]?._id,
      name: fees[i]?.name,
      amount: fees[i]?.amount,
      chargeType: fees[i]?.chargeCalculationType,
      createdOn: moment(fees[i]?.createdAt).format(DateTimeFormat),
      editAction: (
        <Box
          className="edit-cont"
          d={readonly ? "none" : "flex"}
          onClick={e => onEdit(fees[i], e)}
        >
          <EditIcon />
        </Box>
      ),
      deleteAction: (
        <Box
          className="delete-cont"
          d={readonly ? "none" : "flex"}
          onClick={e => onDelete(fees[i], e)}
        >
          <DeleteFilled
            style={{ color: "var(--danger-500)", fontSize: "1.1rem" }}
          />
        </Box>
      ),
    });
  }
  return (
    <>
      {requestLoading ? (
        <Loader size="md" />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
        />
      )}

      <AddFeeModal
        showModal={showModal}
        setShowModal={setShowModal}
        fee={fee}
      />

      <ConfirmDeleteFee
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
        fee={deleteRecord}
      />
    </>
  );
}
