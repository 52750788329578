import React from "react";
import { Table } from "antd";
import { Box, Text } from "@chakra-ui/react";

const columns = [
  {
    title: "Campaign",
    dataIndex: "title",
    key: "title",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
      </Box>
    ),
  },
  {
    title: "Pending",
    dataIndex: "pending",
    key: "pending",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Pending
        </Text>
      </Box>
    ),
  },
  {
    title: "Sent",
    dataIndex: "sent",
    key: "sent",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Sent
        </Text>
      </Box>
    ),
  },
  {
    title: "Delivered",
    dataIndex: "delivered",
    key: "delivered",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Delivered
        </Text>
      </Box>
    ),
  },
  {
    title: "DND",
    dataIndex: "dnd",
    key: "dnd",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          DND
        </Text>
      </Box>
    ),
  },
  {
    title: "Clicked",
    dataIndex: "clicked",
    key: "clicked",
    render: (text: string) => (
      <Box>
        <Text
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="bold"
          color="#00A341"
        >
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Clicked
        </Text>
      </Box>
    ),
  },
];

const data = [
  {
    key: 1,
    title: "Campaign",
    pending: "0",
    sent: "2000",
    delivered: "1800",
    dnd: "100",
    clicked: "1000 (50%)",
  },
];

export default function AnalyticsTable() {
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
    />
  );
}
