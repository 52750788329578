import React, { useRef, useState } from "react";
import { Button, Table, Dropdown, Menu } from "antd";
import { MenuIcon, PlusIcon } from "../common/CustomIcons";
import {
  Button as ButtonChakra,
  Flex,
  Box,
  Text,
  Stack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { UploadContactsButton } from "components/common";
import moment from "moment";
import { DateTimeFormat } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCampaign,
  updateCampaignStatus,
} from "redux/actions/smsAutomationActions";
import { RootState } from "types";
import { filterUsernameOut } from "helpers/utilFunctions";

interface IProps {
  campaigns: campaign[];
}

type campaign = {
  _id: string;
  name: string;
  recepient?: number;
  enabled: boolean;
  createdAt: string;
  arguments: any;
};

const columns = [
  {
    dataIndex: "name",
    key: "name",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Name
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "recepient",
    key: "recepient",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Recepient
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "enabled",
    key: "enabled",
    render: (state: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {state ? "Active" : "Paused"}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Status
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text: string) => (
      <Flex gap="2" justifyContent="space-between" alignItems="center">
        <Box>
          <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
            {text}
          </Text>
          <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
            Date created
          </Text>
        </Box>
      </Flex>
    ),
  },
  {
    dataIndex: "menu",
    key: "menu",
  },
];

export default function CampaignsTable({ campaigns }: IProps) {
  const [activeStatus, setActiveStatus] = useState<"All" | "Active" | "Paused">(
    "All"
  );

  const userData = useSelector(
    (state: RootState) => state.authReducer.loginDataTwo
  );

  const dispatch = useDispatch();
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const userSetting = setting?.settingData?.data;
  const xJwt = localStorage.getItem("x_jwt");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<any>();

  const data: any[] | undefined = [];

  for (let i = 0; i < campaigns?.length; i++) {
    const campaign = campaigns[i];

    // Use this to filter the campaigns
    if (
      (activeStatus === "Active" && campaign?.enabled === false) ||
      (activeStatus === "Paused" && campaign?.enabled === true)
    ) {
      continue;
    }

    data?.push({
      key: campaign?._id,
      name: filterUsernameOut(
        campaign?.name,
        userSetting?.info?.businessName || userData?.businessName
      ),
      recepient: campaign?.arguments?.campaign?.amount,
      enabled: campaign?.enabled,
      createdAt: moment(campaign?.createdAt).format(DateTimeFormat),
      menu: getMenu(campaign?._id, campaign?.enabled),
    });
  }

  function getMenu(campaignID: string, isCampaignActive: boolean) {
    return (
      <Flex justifyContent="flex-end" alignItems="center">
        <Dropdown
          className="icon-btn"
          placement="bottomRight"
          trigger={["click"]}
          overlay={
            <Menu>
              {/* {isCampaignActive && (
                <Menu.Item key={1}>
                  <Link to={`/sms-automation/analytics?id=${campaignID}`}>
                    Analytics
                  </Link>
                </Menu.Item>
              )} */}

              <Menu.Item key={2}>
                <Link to={`/sms-automation/edit?id=${campaignID}`}>Edit</Link>
              </Menu.Item>

              {isCampaignActive ? (
                <Menu.Item
                  key={3}
                  onClick={() => updateCampaign(campaignID, "pause")}
                >
                  Pause
                </Menu.Item>
              ) : (
                <Menu.Item
                  key={4}
                  onClick={() => updateCampaign(campaignID, "resume")}
                >
                  Resume
                </Menu.Item>
              )}

              <Menu.Item key={5} onClick={() => onOpen()}>
                <span style={{ color: "red" }}>Remove</span>
              </Menu.Item>

              <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      Delete Campaign
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      Are you sure you want to delete this campaign? You can't
                      undo this action afterwards.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <HStack>
                        <ButtonChakra
                          ref={cancelRef}
                          onClick={onClose}
                          variant="outline"
                          color="#060089"
                          borderColor="#060089"
                        >
                          Cancel
                        </ButtonChakra>
                        <ButtonChakra
                          onClick={() => removeCampaign(campaignID)}
                          backgroundColor="#ff4d4f"
                          _hover={{
                            backgroundColor: "#C13B3D",
                            borderColor: "#ff4d4f",
                            color: "white",
                            shadow: "none",
                          }}
                        >
                          Delete
                        </ButtonChakra>
                      </HStack>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            </Menu>
          }
        >
          <MenuIcon style={{ cursor: "pointer" }} />
        </Dropdown>
      </Flex>
    );
  }

  function updateCampaign(campaignID: string, status: "pause" | "resume") {
    const body = {
      enabled: status === "pause" ? false : true,
    };

    dispatch(updateCampaignStatus(xJwt, campaignID, body, dispatch));
  }

  function removeCampaign(campaignID: string) {
    dispatch(deleteCampaign(xJwt, campaignID, dispatch));
  }

  return (
    <>
      <Stack spacing={8} className="sms-box">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap="4"
          wrap={{ base: "wrap", lg: "nowrap" }}
        >
          {/* <Flex gap="4" alignItems="center">
            <InputGroup size="md">
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray.300" />}
              />
              <Input type="search" placeholder="Search" />
            </InputGroup>

            <Button className="outline" icon={<FilterIcon />} size="large">
              Filter
            </Button>
          </Flex> */}

          <Flex gap="2" wrap="wrap" className="filter-campaign">
            <Button
              type={`${activeStatus === "All" ? "default" : "text"}`}
              onClick={() => setActiveStatus("All")}
            >
              All
            </Button>
            <Button
              type={`${activeStatus === "Active" ? "default" : "text"}`}
              onClick={() => setActiveStatus("Active")}
            >
              Active
            </Button>
            <Button
              type={`${activeStatus === "Paused" ? "default" : "text"}`}
              onClick={() => setActiveStatus("Paused")}
            >
              Paused
            </Button>
          </Flex>

          <Flex gap="4" align="center" wrap={{ base: "wrap", md: "nowrap" }}>
            <UploadContactsButton />

            <Link to="/sms-automation/new-campaign">
              <Button type="primary" size="large" icon={<PlusIcon />}>
                Create New Campaign
              </Button>
            </Link>
          </Flex>
        </Flex>

        <Table
          columns={columns}
          dataSource={data}
          pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
        />
      </Stack>
    </>
  );
}
