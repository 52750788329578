import React from "react";
import { Modal, Button } from "antd";
import { Heading, Text, Box, Flex, Stack } from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import {
  closeStagedPhoneNumbersModal,
  uploadPhoneNumbers,
} from "redux/actions/smsAutomationActions";
import { RootState } from "types";

export default function StagedPhoneNumbersModal() {
  const dispatch = useDispatch();
  const campaignsState = useSelector(
    (state: RootState) => state.smsAutomationReducer
  );
  const showModal = campaignsState?.showStagedPhoneNumbersModal;
  const stagedNumbers = campaignsState?.stagedNumbers;
  const xJwt = localStorage.getItem("x_jwt");

  function uploadNumbers() {
    dispatch(
      uploadPhoneNumbers(xJwt, { phoneNumbers: stagedNumbers }, dispatch)
    );
  }

  return (
    <Modal
      visible={showModal}
      onCancel={() => dispatch(closeStagedPhoneNumbersModal())}
      footer={null}
      width="500px"
      className="staged-phone-numbers-modal"
    >
      <Box textAlign="center">
        <Heading as="h2" mb="8" fontSize={{ base: "md", md: "lg", lg: "xl" }}>
          Import phone numbers
        </Heading>
      </Box>

      <Text align="right" mb="2" color="#656566" fontWeight="500">
        {stagedNumbers.length} phone number{stagedNumbers.length > 1 ? "s" : ""}
      </Text>

      <Stack
        spacing="6"
        p="4"
        border="1px dashed #DCDBEA"
        borderRadius="8px"
        overflow="auto"
      >
        <Heading as="h5" fontSize="md">
          Phone numbers
        </Heading>

        <Stack spacing="4">
          {stagedNumbers?.map((pNum, key) => (
            <Text color="#A0A0AA" key={key}>
              {pNum}
            </Text>
          ))}
        </Stack>
      </Stack>

      <Flex gap="6" justify="flex-end" mt="6" wrap="wrap">
        <Button
          className="outline"
          size="large"
          onClick={() => dispatch(closeStagedPhoneNumbersModal())}
        >
          Cancel
        </Button>
        <Button
          htmlType="submit"
          size="large"
          type="primary"
          onClick={() => uploadNumbers()}
        >
          Add recepients
        </Button>
      </Flex>
    </Modal>
  );
}
