import { Box, Flex, Text } from "@chakra-ui/react";
import { Select } from "antd";
import { Form, Formik } from "formik";
import { Line } from "react-chartjs-2";
const { Option } = Select;

const options = {
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
  },
};

const data = {
  labels: [
    "12:00am",
    "4:00am",
    "8:00am",
    "12:00pm",
    "4:00pm",
    "8:00pm",
    "11:59pm",
  ],
  datasets: [
    {
      label: "Sent",
      data: [392, 667, 456, 323, 720, 543, 670],
      borderColor: "#4E0CC9",
      backgroundColor: "#4E0CC9",
    },
    {
      label: "Delivered",
      data: [244, 437, 300, 73, 206, 423, 370],
      borderColor: "#F4C161",
      backgroundColor: "#F4C161",
    },
    {
      label: "Clicked",
      data: [692, 207, 562, 716, 500, 243, 70],
      borderColor: "#12A894",
      backgroundColor: "#12A894",
    },
  ],
};

function AnalyticsLineChart() {
  return (
    <Box className="sms-box" mb={8}>
      <Flex align="center" justify="space-between" gap="4" mb="8">
        <Flex gap="4">
          <Flex align="center" gap="1">
            <Box w="3" h="3" borderRadius="50%" bg="#4E0CC9" />
            <Text fontWeight="500" color="#656566">
              Sent
            </Text>
          </Flex>
          <Flex align="center" gap="1">
            <Box w="3" h="3" borderRadius="50%" bg="#F4C161" />
            <Text fontWeight="500" color="#656566">
              Delivered
            </Text>
          </Flex>
          <Flex align="center" gap="1">
            <Box w="3" h="3" borderRadius="50%" bg="#12A894" />
            <Text fontWeight="500" color="#656566">
              Clicked
            </Text>
          </Flex>
        </Flex>

        <Formik
          initialValues={{ filter: null }}
          onSubmit={values => console.log(values)}
        >
          {({ handleChange, errors, values }) => (
            <Form>
              <Select
                style={{
                  width: 110,
                }}
                size="small"
                onChange={handleChange}
                defaultValue="today"
              >
                <Option value="today">Today</Option>
                <Option value="yesterday">Yesterday</Option>
                <Option value="lastweek">Last week</Option>
                <Option value="custom">Custom</Option>
              </Select>
            </Form>
          )}
        </Formik>
      </Flex>

      <Line options={options} data={data} />
    </Box>
  );
}

export default AnalyticsLineChart;
