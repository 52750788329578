import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

interface ICampaignStatsCardProps {
  value: string | number;
  description: string;
  Icon: any;
  filter?: any;
  viewBtn?: React.ReactNode;
}

export default function CampaignStatsCard({
  value,
  description,
  Icon,
  filter,
  viewBtn,
}: ICampaignStatsCardProps) {
  return (
    <Box className="uploaded-contacts-card" w="full">
      <Flex gap={2} justifyContent="space-between" alignItems="flex-start">
        <Icon className="contacts-icon" />

        {filter}
      </Flex>

      <Flex gap={6} align="flex-end">
        <Box>
          <Heading as="h6" fontSize={{ base: "lg", md: "2xl" }}>
            {value}
          </Heading>

          <Text>{description}</Text>
        </Box>

        {viewBtn}
      </Flex>
    </Box>
  );
}
