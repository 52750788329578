import { Box } from "@chakra-ui/layout";
import React, { useEffect } from "react";

const HubspotSignupForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js-eu1.hsforms.net/forms/shell.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          region: "eu1",
          portalId: "24949572",
          formId: "d8521603-9919-4e31-ba4b-280807808ce5",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return <Box id="hubspotForm" px="5"></Box>;
};

export default HubspotSignupForm;
