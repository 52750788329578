import { Col, Row } from "antd";
import { InputField, SMSInputField } from "components/common";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { CampaignFields } from "views/main/smsAutomation/CreateNewCampaign";
import CampaignScheduleForm from "./CampaignScheduleForm";
import { COST_PER_SMS } from "../../constants";
import { getUserSettings } from "redux/actions/settingsActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import { filterUsernameOut } from "helpers/utilFunctions";

interface IProps {
  isFormForEdit?: boolean;
}

export default function CampaignReviewForm({ isFormForEdit = false }: IProps) {
  const { values, setFieldValue, handleBlur, handleChange, errors } =
    useFormikContext<CampaignFields>();

  const xJwt = localStorage.getItem("x_jwt");
  const dispatch = useDispatch();
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const userSetting = setting?.settingData?.data;

  const userData = useSelector(
    (state: RootState) => state.authReducer.loginDataTwo
  );

  function countAllSelectedDays(
    startDate: Date,
    endDate: Date,
    selectedDays: string[]
  ) {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const daysInTheRange = [];
    let selectedDaysAllTogether = 0;

    // Get all the days within the range
    for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
      daysInTheRange.push(days[i.getDay()]);
    }

    // Count the number of occurrence of a selected day in the daysInTheRange
    function countOccurrences(arr: string[], val: string) {
      return arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
    }

    for (let i = 0; i < selectedDays.length; i++) {
      const selectedDay = selectedDays[i];
      const selectedDayOccurrence = countOccurrences(
        daysInTheRange,
        selectedDay
      );

      selectedDaysAllTogether += selectedDayOccurrence;
    }

    return selectedDaysAllTogether;
  }

  useEffect(() => {
    if (values.isContinous) {
      setFieldValue("estimatedCostPerDay", values.amount * COST_PER_SMS);
    } else {
      if (values.endDate) {
        const selectedDaysCount = countAllSelectedDays(
          new Date(values.startDate),
          new Date(values.endDate),
          values.selectedDays
        );

        setFieldValue(
          "estimatedCostPerCampaign",
          selectedDaysCount * values.amount * COST_PER_SMS
        );
      }
    }
  }, [
    values.amount,
    values.frequency,
    values.selectedDays,
    values.startDate,
    values.endDate,
    values.isContinous,
    setFieldValue,
  ]);

  useEffect(() => {
    dispatch(getUserSettings(xJwt));
  }, [dispatch, xJwt]);

  return (
    <>
      <Row gutter={32}>
        <Col xs={24} sm={12}>
          <InputField
            inputType="text"
            name="name"
            hasLabel
            label="Campaign name"
            placeholder="July Marketing"
            value={filterUsernameOut(
              values.name,
              userSetting?.info?.businessName || userData?.businessName
            )}
            onBlur={() => handleBlur}
            onChange={handleChange}
            error={errors.name}
          />
        </Col>

        <Col xs={24} sm={12}>
          <InputField
            inputType="number"
            name="amount"
            hasLabel
            label="Number of messages at a time"
            placeholder="500"
            value={values.amount}
            onBlur={() => handleBlur}
            onChange={handleChange}
            error={errors.amount}
          />
        </Col>
      </Row>

      <CampaignScheduleForm isFormForEdit={isFormForEdit} />

      {isFormForEdit === false &&
        (values?.isContinous ? (
          <InputField
            inputType="number"
            name="estimatedCostPerDay"
            hasLabel
            label="Estimated cost per day"
            placeholder="₦500000"
            value={values.estimatedCostPerDay}
            onBlur={() => handleBlur}
            onChange={handleChange}
            error={errors.estimatedCostPerDay}
            readOnly={true}
          />
        ) : (
          <InputField
            inputType="number"
            name="estimatedCostPerCampaign"
            hasLabel
            label="Estimated cost per campaign"
            placeholder="₦500000"
            value={values.estimatedCostPerCampaign}
            onBlur={() => handleBlur}
            onChange={handleChange}
            error={errors.estimatedCostPerCampaign}
            readOnly={true}
          />
        ))}

      <SMSInputField isFormForEdit={isFormForEdit} />
    </>
  );
}
