import { Fee, LoanOffer, TeamMember } from "types";
import { Account, LoanProduct, SettingsResponse } from "types/responses";
import {
  UPDATE_USER_SETTINGS_ACTION_FAILURE,
  UPDATE_USER_SETTINGS_ACTION_SUCCESS,
  USER_SETTINGS_ACTION_FAILURE,
  USER_SETTINGS_ACTION_SUCCESS,
  VERIFY_ACCOUNT_NUMBER_SUCCESS,
  VERIFY_ACCOUNT_NUMBER_FAILED,
  REQUEST_LOADING_TRUE,
  REQUEST_LOADING_FALSE,
  GET_LOAN_OFFERS_SUCCESS,
  GET_LOAN_OFFERS_FAILED,
  CREATE_LOAN_OFFER_SUCCESS,
  UPDATE_LOAN_PRODUCT_SUCCESS,
  UPDATE_LOAN_OFFER_SUCCESS,
  DELETE_LOAN_OFFER_SUCCESS,
  GET_COLENDING_INTEREST_RATE_SUCCESS,
  COLENDING_TOGGLE_LOADING_TRUE,
  COLENDING_TOGGLE_LOADING_FALSE,
  GET_COLENDING_LEADER_SUCCESS,
  VERIFY_ACCOUNT_LOADING_TRUE,
  GET_TEAM_MEMBERS_SUCCESS,
  GET_TEAM_MEMBERS_FAILED,
  CREATE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_SUCCESS,
  CREATE_LOAN_PRODUCT_SUCCESS,
  DELETE_LOAN_PRODUCT_SUCCESS,
  UPDATE_TEAM_MEMBER_SUCCESS,
  GET_FEES_FAILED,
  CREATE_FEE_SUCCESS,
  UPDATE_FEE_SUCCESS,
  DELETE_FEE_SUCCESS,
  GET_FEES_SUCCESS,
  GET_LOAN_PRODUCTS_FAILED,
  GET_LOAN_PRODUCTS_SUCCESS,
} from "../actions/actionTypes";

interface SettingsAccount extends Account {
  data: {
    account_name: string;
  };
}

export interface SettingsState {
  isLoading: boolean;
  settingData: SettingsResponse;
  updateUserSettingsData: SettingsResponse;
  accountName: SettingsAccount;
  requestLoading: boolean;
  loanOffers: LoanOffer[];
  loanProducts: LoanProduct[];
  teamMembers: TeamMember[];
  fees: Fee[];
  colendingInterestRate: { interestRate: number };
  colendingLoading: boolean;
  colendingLeader: { client: string };
  verifyLoading: boolean;
}

const initialState: SettingsState = {
  isLoading: true,
  settingData: {} as SettingsResponse,
  updateUserSettingsData: {} as SettingsResponse,
  accountName: {} as SettingsAccount,
  requestLoading: false,
  loanOffers: [],
  loanProducts: [],
  teamMembers: [],
  fees: [],
  colendingInterestRate: {} as { interestRate: number },
  colendingLoading: false,
  colendingLeader: {} as { client: string },
  verifyLoading: false,
};

function settingsReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case USER_SETTINGS_ACTION_SUCCESS:
      return {
        ...state,
        settingData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case USER_SETTINGS_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestLoading: false,
      };
    case UPDATE_USER_SETTINGS_ACTION_SUCCESS:
      return {
        ...state,
        updateUserSettingsData: payload,
        isLoading: false,
        requestLoading: false,
        accountName: {},
      };
    case UPDATE_USER_SETTINGS_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        verifyLoading: false,
      };
    case VERIFY_ACCOUNT_NUMBER_SUCCESS:
      return {
        ...state,
        accountName: payload,
        isLoading: false,
        verifyLoading: false,
      };
    case VERIFY_ACCOUNT_NUMBER_FAILED:
      return {
        ...state,
        accountName: {},
        isLoading: false,
        requestLoading: false,
      };
    case GET_LOAN_OFFERS_SUCCESS:
      return {
        ...state,
        loanOffers: payload,
        requestLoading: false,
      };
    case GET_LOAN_PRODUCTS_SUCCESS:
      return {
        ...state,
        loanProducts: payload,
        requestLoading: false,
      };
    case GET_LOAN_OFFERS_FAILED:
    case GET_LOAN_PRODUCTS_FAILED:
    case CREATE_LOAN_OFFER_SUCCESS:
    case UPDATE_LOAN_OFFER_SUCCESS:
    case DELETE_LOAN_OFFER_SUCCESS:
    case REQUEST_LOADING_FALSE:
    case CREATE_LOAN_PRODUCT_SUCCESS:
    case UPDATE_LOAN_PRODUCT_SUCCESS:
    case DELETE_LOAN_PRODUCT_SUCCESS:
    case GET_TEAM_MEMBERS_FAILED:
    case CREATE_TEAM_MEMBER_SUCCESS:
    case UPDATE_TEAM_MEMBER_SUCCESS:
    case DELETE_TEAM_MEMBER_SUCCESS:
    case GET_FEES_FAILED:
    case CREATE_FEE_SUCCESS:
    case UPDATE_FEE_SUCCESS:
    case DELETE_FEE_SUCCESS:
      return {
        ...state,
        requestLoading: false,
      };
    case GET_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        teamMembers: payload,
        requestLoading: false,
      };
    case GET_FEES_SUCCESS:
      return {
        ...state,
        fees: payload,
        requestLoading: false,
      };
    case REQUEST_LOADING_TRUE:
      return {
        ...state,
        requestLoading: true,
      };
    case VERIFY_ACCOUNT_LOADING_TRUE:
      return {
        ...state,
        verifyLoading: true,
      };
    case COLENDING_TOGGLE_LOADING_TRUE:
      return {
        ...state,
        colendingLoading: true,
      };
    case COLENDING_TOGGLE_LOADING_FALSE:
      return {
        ...state,
        colendingLoading: false,
      };
    case GET_COLENDING_INTEREST_RATE_SUCCESS:
      return {
        ...state,
        colendingInterestRate: payload,
        requestLoading: false,
      };
    case GET_COLENDING_LEADER_SUCCESS:
      return {
        ...state,
        colendingLeader: payload,
        requestLoading: false,
      };
    default:
      return state;
  }
}

export default settingsReducer;
