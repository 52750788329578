import { Box } from "@chakra-ui/react";
import AnalyticsLineChart from "components/charts/AnalyticsLineChart";
import { SmsAutomationHeader } from "components/common";
import { AnalyticsTable } from "components/tables";

export default function Analytics() {
  return (
    <>
      <SmsAutomationHeader title="Analytics" />

      <AnalyticsLineChart />

      <Box className="sms-box">
        <AnalyticsTable />
      </Box>
    </>
  );
}
