import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { Stat } from "types";

const LoanProfileStat = ({ label, value }: Stat) => {
  return (
    <Box className="loan-profile-top-item">
      <Text fontSize={{ base: "x-small", md: "sm" }} fontWeight="semibold">
        {label}
      </Text>
      <Heading
        as="h2"
        fontSize={{ base: "lg", md: "xl", lg: "xx-large" }}
        color="#fff"
        mt="2"
      >
        {value}
      </Heading>
    </Box>
  );
};

export default LoanProfileStat;
