import React from "react";
import moment from "moment";
import Loader from "./common/Loader";
import { Avatar, Heading, Text, Button, Box, HStack } from "@chakra-ui/react";
import { CloudUploadOutlined } from "@ant-design/icons";
import { formatNumberAmount } from "helpers/utilFunctions";
import { DateTimeFormat } from "../constants";
import { useSelector } from "react-redux";
import { BorrowerInfoItem } from "./common";
import { RootState } from "types";
import { User } from "types/responses";

interface BorrowerProfileProps {
  borrower: User;
}

export default function BorrowerProfile({ borrower }: BorrowerProfileProps) {
  // get loan id from loanRecord
  const borrowerState = useSelector(
    (state: RootState) => state.borrowerReducer
  );
  const isLoading = borrowerState?.borrowerProfileLoading;
  const firstName = borrower !== undefined ? borrower?.firstName : "N/A";
  const lastName = borrower !== undefined ? borrower?.lastName : "N/A";
  const email = borrower !== undefined ? borrower?.email : "N/A";
  const phone = borrower !== undefined ? borrower?.phoneNumber : "N/A";
  const address = borrower !== undefined ? borrower?.address : "N/A";
  const bvn = borrower !== undefined ? borrower?.bvn : "N/A";
  const nextOfKinName =
    borrower !== undefined ? borrower?.nextOfKin?.name : "N/A";
  const nextOfKinAddress =
    borrower !== undefined ? borrower?.nextOfKin?.address : "N/A";
  const nextOfKinPhoneNumber =
    borrower !== undefined ? borrower?.nextOfKin?.phoneNumber : "N/A";
  const employerName = borrower !== undefined ? borrower?.employer : "N/A";
  const employerSalary =
    borrower !== undefined ? formatNumberAmount(borrower?.salaryAmount) : "N/A";
  const employerSalaryPaymentDate =
    borrower !== undefined
      ? moment(borrower?.salaryPaymentDate).format(DateTimeFormat)
      : "N/A";
  const preApprovedAmount = borrower?.preApprovedAmount
    ? formatNumberAmount(borrower?.preApprovedAmount)
    : "0.00";

  return (
    <>
      {isLoading && <Loader />}
      <HStack
        spacing="0"
        justify="space-between"
        align="flex-start"
        wrap="wrap"
        className="borrower-details-box"
      >
        <Box w={["100%", "100%", "100%", "25%"]}>
          {/* borrower profile box */}
          <Box className="borrower-profile-box">
            <Avatar mb="3" size="lg" />
            <Text fontSize="xs" mb="3">
              {email}
            </Text>
            <Text fontSize="xx-small" mb="3">
              0 card(s) added for repayment
            </Text>
            <Button variant="danger" disabled>
              Block
            </Button>
          </Box>
          {/* Pre approval box */}
          <Box className="pre-approval-box">
            <Heading as="h3" fontSize="sm" mb="3">
              Loan Pre Approval
            </Heading>
            <Text fontSize="xs" fontWeight="semibold" mb="3">
              Pre-approved Amount: <span>₦{`${preApprovedAmount}`}</span>
            </Text>
            {/* <Text fontSize="xs" fontWeight="semibold">
              Tenure: <span>Months</span>
            </Text> */}
          </Box>
        </Box>
        <Box w={["100%", "100%", "100%", "73%"]}>
          <Box className="borrower-info">
            <Heading as="h3" fontSize={{ base: "xs", md: "sm" }} mb="3">
              Basic Information
            </Heading>
            <BorrowerInfoItem label="First Name" value={firstName} />
            <BorrowerInfoItem label="Last Name" value={lastName} />
            <BorrowerInfoItem label="Mobile Number" value={phone} />
            <BorrowerInfoItem label="Alternate Mobile Number" value="" />
            <BorrowerInfoItem label="Address" value={address} />
            <BorrowerInfoItem label="BVN" value={bvn} />

            {/* Next of Kin info */}
            <Heading as="h3" my="3" fontSize={{ base: "xs", md: "sm" }}>
              Next of Kin Information
            </Heading>
            <BorrowerInfoItem label="Name" value={nextOfKinName} />
            <BorrowerInfoItem label="Address" value={nextOfKinAddress} />
            <BorrowerInfoItem
              label="Mobile Number"
              value={nextOfKinPhoneNumber}
            />

            {/* Employer Info */}
            <Heading as="h3" my="3" fontSize={{ base: "xs", md: "sm" }}>
              Employer Information
            </Heading>
            <BorrowerInfoItem label="Employer Name" value={employerName} />
            <BorrowerInfoItem label="Employer Category" value="" />
            <BorrowerInfoItem label="Employer Address" value="" />
            <BorrowerInfoItem label="Employer Mobile Number" value="" />
            <BorrowerInfoItem
              label="Salary Payment Date"
              value={employerSalaryPaymentDate}
            />
            <BorrowerInfoItem label="Salary Amount" value={employerSalary} />
          </Box>
          {/* Borrower government issued ID doc */}
          <Box my="4" d="none" className="borrower-id-docs">
            <Heading as="h4" mb="4" fontSize={{ base: "xs", md: "sm" }}>
              Government Issued Identification Document
            </Heading>
            <Text fontSize="xx-small">Driver&apos;s License</Text>
            <Box className="id-doc upload-doc">
              <CloudUploadOutlined className="cloud" />
            </Box>
            <Text fontSize="xx-small">Intenational Passport</Text>
            <Box className="id-doc upload-doc">
              <CloudUploadOutlined className="cloud" />
            </Box>
          </Box>
          {/* Borrower Banks */}
          <Box my="4" d="none" className="borrower-banks">
            <Heading as="h4" fontSize={{ base: "xs", md: "sm" }}>
              Banks
            </Heading>
            <Box className="bank-box upload-doc">
              <CloudUploadOutlined className="cloud" />
            </Box>
          </Box>
          {/* Borrower Cards */}
          <Box d="none" className="borrower-cards">
            <Heading as="h4" fontSize={{ base: "xs", md: "sm" }}>
              Cards
            </Heading>
            <Box className="bank-box upload-doc">
              <CloudUploadOutlined className="cloud" />
            </Box>
          </Box>
        </Box>
      </HStack>
    </>
  );
}
