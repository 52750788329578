import React, { useState } from "react";
import { Text, Box } from "@chakra-ui/react";
import moment from "moment";
import { formatNumberAmount } from "helpers/utilFunctions";
import { Table } from "antd";
import { useDispatch } from "react-redux";
import { getLoanRecord } from "redux/actions/loanActions";
import { LoanProfile } from "components/modals";
import { DateTimeFormat, disburseColor } from "../../constants";
import { UssdPhoneIcon, WebIcon, DotIcon } from "components/common/CustomIcons";

interface BorrowerProfileProps {
  borrowerLoan?: any;
}
const columns = [
  {
    dataIndex: "submitted",
    key: "submitted",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xs" }} fontWeight="normal">
          Submitted
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "principal",
    key: "principal",
    render: (text: string, loans: any) => (
      <Box>
        <Text
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="bold"
          color={loans?.isTopUp === true ? "#060088" : "#000"}
          title={loans?.isTopUp === true ? "Top Up" : "New"}
        >
          {text}
        </Text>
        <Text fontSize={{ base: "xs" }} fontWeight="normal">
          Principal
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "interest",
    key: "interest",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Interest
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "fee",
    key: "fee",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Fee
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "expectedRepayment",
    key: "expectedRepayment",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xs" }} fontWeight="normal">
          Expected Repayment
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "repaymentDate",
    key: "repaymentDate",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Repayment Date
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "remitaMandateReference",
    key: "remitaMandateReference",
    render: (text: string) => (
      <Box onClick={e => e.stopPropagation()}>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Mandate
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "tenure",
    key: "key",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Tenure
        </Text>
      </Box>
    ),
  },

  {
    dataIndex: "loanId",
    key: "loanId",
    render: (text: string) => (
      <Box onClick={e => e.stopPropagation()}>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Loan ID
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "channel",
    key: "channel",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small" }} fontWeight="bold">
          {text}
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "disbursementStatus",
    key: "disbursementStatus",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small" }} fontWeight="bold">
          {text}
        </Text>
      </Box>
    ),
  },
];

export default function BorrowerLoansTable({
  borrowerLoan,
}: BorrowerProfileProps) {
  const dispatch = useDispatch();
  const xTag = localStorage.getItem("x_jwt");
  const [showModal, setShowModal] = useState(false);

  const openLoanProfile = () => {
    setShowModal(true);
  };

  const data: any[] | undefined = borrowerLoan?.map((loan: any) => ({
    key: loan?._id,
    submitted: `${moment(loan?.createdAt).format(DateTimeFormat)}`,
    principal: formatNumberAmount(loan?.amount),
    fee: loan?.fee,
    interest: loan?.interestAmount,
    expectedRepayment: formatNumberAmount(loan?.totalExpectedRepayment),
    repaymentDate: `${moment(loan?.repaymentDate).format(DateTimeFormat)}`,
    remitaMandateReference: loan?.remitaMandateReference
      ? loan?.remitaMandateReference
      : "no mandate",
    channel:
      loan?.channel === "WEB" ? (
        <WebIcon title={loan?.channel} />
      ) : (
        <UssdPhoneIcon title={loan?.channel} />
      ),
    loanId: loan?.loanId,
    tenure: loan?.tenure,
    isTopUp: loan?.isTopup,
    disbursementStatus: (
      <DotIcon
        title={`Disburse status: ${loan?.disbursementStatus}`}
        style={disburseColor(loan?.disbursementStatus)}
        className="status-dot"
      />
    ),
  }));
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
        onRow={(record: any, rowIndex) => {
          return {
            onClick: e => {
              dispatch(getLoanRecord(record.key, xTag, record.loanId));
              openLoanProfile();
            },
          };
        }}
        className="row-select"
      />
      <LoanProfile showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
