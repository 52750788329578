import { combineReducers } from "redux";
import dashboardReducer from "./dashboardReducer";
import loanReducer from "./loanReducer";
import borrowerReducer from "./borrowerReducer";
import authReducer from "./authReducer";
import settingsReducer from "./settingsReducer";
import generalReducer from "./generalReducer";
import transactionReducer from "./transactionReducer";
import toolsReducer from "./toolsReducer";
import repaymentReducer from "./repaymentReducer";
import smsAutomationReducer from "./smsAutomationReducer";
import lendingLicenseReducer from "./lendingLicenseReducer";

export default combineReducers({
  dashboardReducer,
  loanReducer,
  borrowerReducer,
  authReducer,
  repaymentReducer,
  settingsReducer,
  generalReducer,
  transactionReducer,
  toolsReducer,
  smsAutomationReducer,
  lendingLicenseReducer,
});
