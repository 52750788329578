import { useState } from "react";
import { Text, Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { DeleteFilled } from "@ant-design/icons";
import { AddTeamMemberModal, ConfirmDeleteTeamMember } from "components/modals";
import { Table } from "antd";
import { RootState } from "types";
import { EditIcon } from "components/common/CustomIcons";
import { isReadOnly } from "helpers/utilFunctions";

const columns = [
  {
    dataIndex: "email",
    key: "email",
    render: (email: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {email}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Email Address
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "access",
    key: "access",
    render: (access: Boolean) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {access ? "Read Only" : "Read and Write"}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Access
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "editAction",
    key: "editAction",
  },
  {
    dataIndex: "deleteAction",
    key: "deleteAction",
  },
];
export default function TeamMembersTable() {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [teamMember, setTeamMember] = useState();
  const [deleteRecord, setDeleteRecord] = useState({
    email: undefined,
    giroAccountID: undefined,
  });
  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const teamMembers = settingsState?.teamMembers;
  const readonly = isReadOnly();

  const onEdit = (record: any, e: any) => {
    e.stopPropagation();
    setTeamMember(record);
    setShowModal(true);
  };

  const onDelete = (record: any, e: any) => {
    e.stopPropagation();
    setDeleteRecord({
      email: record?.email,
      giroAccountID: record?.giroAccountID,
    });
    setShowConfirmationModal(true);
  };

  const data: any[] | undefined = [];
  for (let i = 0; i < teamMembers?.length; i++) {
    data.push({
      email: teamMembers[i]?.email,
      access: teamMembers[i]?.readonly,
      editAction: (
        <Box
          className="edit-cont"
          display={readonly ? "none" : "flex"}
          onClick={e => onEdit(teamMembers[i], e)}
        >
          <EditIcon />
        </Box>
      ),
      deleteAction: (
        <Box
          className="delete-cont"
          display={readonly ? "none" : "flex"}
          onClick={e => onDelete(teamMembers[i], e)}
        >
          <DeleteFilled
            style={{ color: "var(--danger-500)", fontSize: "1.1rem" }}
          />
        </Box>
      ),
    });
  }
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
      />
      <AddTeamMemberModal
        showModal={showModal}
        setShowModal={setShowModal}
        teamMember={teamMember}
      />
      <ConfirmDeleteTeamMember
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
        record={deleteRecord}
      />
    </>
  );
}
