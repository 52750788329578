//@ts-nocheck
import React, { useEffect } from "react";
import {
  Heading,
  Button,
  Box,
  Link,
  Image,
  Text,
  Container,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputField } from "components/common";
import veendLogo from "../../assets/veend-logo.png";
import { useSelector, useDispatch } from "react-redux";
import { lenderSignup } from "redux/actions/authActions";
import validationSchema from "constants/validationSchema";
import { RootState } from "types";
import { Checkbox, message } from "antd";
import InputPhoneField from "components/common/InputPhoneField";
import "./styles/auth.css";
import { useHistory } from "react-router-dom";

interface SignupValues {
  firstName: string;
  lastName: string;
  businessName: string;
  email: string;
  phoneNumber: string;
  password: string;
  agree: boolean;
}

export default function NewSignUp(): JSX.Element {
  const signupState = useSelector((state: RootState) => state.authReducer);
  const signupSuccess = signupState?.signupSuccess;
  const signup = signupState?.signup;
  const emailExists = signup?.message?.includes("This email already exist.")
    ? true
    : false;
  const isLoading = signupState?.isLoading;
  const dispatch = useDispatch();
  const history = useHistory();
  const { values, errors, touched, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      businessName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      agree: false,
      subscribe: true,
    },
    onSubmit: async (values: SignupValues) => {
      postSignup(values);
    },
    validationSchema: yup.object().shape(validationSchema.lenderSignup),
  });


  // post to server
  const postSignup = (values: SignupValues) => {
    let signupData = {
      firstName: values.firstName,
      lastName: values.lastName,
      businessName: values.businessName,
      email: values.email,
      phonenumber: values.phoneNumber,
      password: values.password,
    };
    return dispatch(lenderSignup(signupData));
  };

  const goToLoginPage = () => {
    history.push("/login");
  };

  const goToPolicy = () => {
    history.push("https://veendhq.com/privacy-policy");
  };

  const goToTerms = () => {
    history.push("https://veendhq.com/terms-and-conditions");
  };

  const handleRedirect = () => {
    history.push(`/verify-email-otp?email=${values.email}`, {
      onboarding: true,
    });
    return <></>;
  };
  useEffect(() => {
    if (emailExists) {
      message.error(signup?.message || "This email already exist.");
    }
  }, [emailExists, isLoading]);

  return (
    <>
      {signupSuccess ? (
        <>{handleRedirect()}</>
      ) : (
        <Container maxW={"container.xl"}>
          <Box
            py={8}
            display={"flex"}
            flexDirection="column"
            alignItems={"center"}
            minH={"100vh"}
          >
            <Box display={"flex"} justifyContent="flex-start" w={"100%"}>
              <Link href="https://veendhq.com" target="_blank" rel="noreferrer">
                <Image
                  src={veendLogo}
                  alt="veendHq"
                  className="login-logo-main"
                  width={{ base: 100, md: 150 }}
                />
              </Link>
            </Box>
            <Box
              w={["100%", "100%", "500px"]}
              className="signup-form"
              display={"flex"}
              flexDirection="column"
              justifyContent={"center"}
              alignItems="center"
            >
              <Box py="4">
                <Heading
                  as="h1"
                  mt="4"
                  mb="2"
                  fontSize={{ base: "md", md: "md", lg: "26px" }}
                >
                  Let’s get you started
                </Heading>
                <Box my="3">
                  <form onSubmit={handleSubmit}>
                    <Box
                      columnGap={4}
                      flexDirection={{ base: "column", md: "row" }}
                      py={0}
                      display={{ base: "block", md: "flex" }}
                    >
                      <Box flex={1}>
                        <InputField
                          inputType="text"
                          name="firstName"
                          hasLabel
                          label="First Name"
                          placeholder="Enter First Name"
                          value={values.firstName}
                          onBlur={() => handleBlur}
                          onChange={handleChange}
                          error={errors.firstName}
                          required
                        />
                      </Box>
                      <Box flex={1}>
                        <InputField
                          inputType="text"
                          name="lastName"
                          hasLabel
                          label="Last Name"
                          placeholder="Enter Last Name"
                          value={values.lastName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={errors.lastName}
                          required
                        />
                      </Box>
                    </Box>
                    <Box w={"100%"}>
                      <InputPhoneField
                        inputType="text"
                        name="phoneNumber"
                        hasLabel
                        label="Phone Number"
                        placeholder="Enter Phone Number"
                        value={values.phoneNumber}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.phoneNumber && errors.phoneNumber}
                        required
                      />
                    </Box>

                    <Box>
                      <InputField
                        inputType="email"
                        name="email"
                        hasLabel
                        label="Email Address"
                        placeholder="Enter Email Address"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.email && errors.email}
                        required
                      />
                    </Box>
                    <Box>
                      <InputField
                        inputType="password"
                        name="password"
                        hasLabel
                        label="Password"
                        placeholder="Enter Password"
                        value={values.password}
                        onBlur={() => handleBlur}
                        onChange={handleChange}
                        error={touched.password && errors.password}
                        required
                      />
                        <InputField
                        inputType="password"
                        name="confirmPassword"
                        hasLabel
                        label="Confirm Password"
                        placeholder="Re-enter Password"
                        value={values.confirmPassword}
                        onBlur={() => handleBlur}
                        onChange={handleChange}
                        error={ touched.confirmPassword && errors.confirmPassword}
                        required
                      />
                    </Box>
                    <Box my="2">
                      <Checkbox
                        style={{ fontSize: "small" }}
                        onChange={handleChange}
                        name="agree"
                        value={values.agree}
                      >
                        By checking this box, you agree to our{" "}
                        <Text
                          color="primaryBlue"
                          as="span"
                          fontWeight={600}
                          ml="2"
                          cursor={"pointer"}
                          onClick={goToTerms}
                        >
                          Terms of service
                        </Text>{" "}
                        and
                        <Text
                          color="primaryBlue"
                          as="span"
                          fontWeight={600}
                          ml="2"
                          cursor={"pointer"}
                          onClick={goToPolicy}
                        >
                          Privacy Policy
                        </Text>
                      </Checkbox>
                    </Box>
                    <Box>
                      <Checkbox
                        style={{ fontSize: "small" }}
                        name="subscribe"
                        onChange={handleChange}
                        value={values.subscribe}
                        checked={values.subscribe}
                      >
                        Subscribe to our newsletter to get exclusive news and
                        updates on our product
                      </Checkbox>
                    </Box>
                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      mt="10"
                      isLoading={isLoading}
                      isFullWidth
                      disabled={!values.agree}
                    >
                      Create an account
                    </Button>
                  </form>
                  <Box mt="10">
                    <Text>
                      Already have an account with us?
                      <Text
                        color="primaryBlue"
                        fontSize={{
                          base: "xs",
                          lg: "sm",
                          xl: "md",
                        }}
                        as="span"
                        fontWeight={600}
                        ml="2"
                        cursor={"pointer"}
                        onClick={goToLoginPage}
                      >
                        Login here
                      </Text>
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
}
