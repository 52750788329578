import {
  Button as ChakraButton,
  ButtonProps,
  forwardRef,
} from "@chakra-ui/react";

const VeendButton = forwardRef(({ children, ...rest }: ButtonProps, ref) => {
  const styles: ButtonProps = {
    p: "12px 18px",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "17px",
    borderRadius: "4px",
  };

  return (
    <ChakraButton size="lg" ref={ref} {...styles} {...rest}>
      {children}
    </ChakraButton>
  );
});

export default VeendButton;
