import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { Box, Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { MainLoader, SmsAutomationHeader } from "components/common";
import { EditPhoneNumbersModal } from "components/modals";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignsSummary,
  getUploadedContacts,
} from "redux/actions/smsAutomationActions";
import { RootState } from "types";
import { UPLOADED_CONTACTS_LIMIT_PER_PAGE } from "constants/index";

function UploadedContacts() {
  const [showEditModal, setShowEditModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const [goTo, setGoTo] = useState<"" | "Next" | "Previous">("");

  const dispatch = useDispatch();
  const campaignsState = useSelector(
    (state: RootState) => state.smsAutomationReducer
  );

  const uploadedContacts = campaignsState?.uploadedContacts;
  const isPageLoading = campaignsState?.isLoading;
  const isRequestLoading = campaignsState?.requestLoading;

  const campaignsSummary = campaignsState?.campaignsSummary;
  const totalUploadedContacts = campaignsSummary.uploads;

  const expectedNoOfPages = Math.ceil(
    totalUploadedContacts / UPLOADED_CONTACTS_LIMIT_PER_PAGE
  );

  const xJwt = localStorage.getItem("x_jwt");

  useEffect(() => {
    dispatch(getUploadedContacts(xJwt, pageNumber));
    dispatch(getCampaignsSummary(xJwt));
  }, [dispatch, pageNumber, xJwt]);

  return (
    <>
      {isPageLoading && uploadedContacts.length === 0 && <MainLoader />}

      <Box>
        <SmsAutomationHeader title="Uploaded contacts" />

        <Flex gap={10} flexDir="column" className="sms-box">
          <Flex
            align="center"
            justify="space-between"
            gap={2}
            wrap={{ base: "wrap", md: "nowrap" }}
          >
            <Heading
              as="h3"
              fontSize={{ base: "md", md: "lg", lg: "lg" }}
              color="#333344"
            >
              All Phone numbers
            </Heading>
            <Button
              type="primary"
              onClick={() => setShowEditModal(true)}
              disabled={uploadedContacts.length === 0}
            >
              Edit phone numbers
            </Button>
          </Flex>

          {uploadedContacts.length === 0 ? (
            <Text textAlign={"center"} fontSize="xl">
              You have not uploaded any contact
            </Text>
          ) : (
            <SimpleGrid columns={{ base: 2, md: 5 }} spacingX={4} spacingY={8}>
              {uploadedContacts.map(item => (
                <Text key={item._id} textAlign="center" color="#A0A0AA">
                  {item.number}
                </Text>
              ))}
            </SimpleGrid>
          )}

          <Flex align="center" justify="flex-end" gap={2}>
            <Button
              type="ghost"
              disabled={pageNumber === 1}
              loading={isRequestLoading && goTo === "Previous"}
              onClick={() => {
                setGoTo("Previous");
                setPageNumber(prev => prev - 1);
              }}
            >
              Previous
            </Button>
            <Button
              type="primary"
              disabled={pageNumber >= expectedNoOfPages}
              loading={isRequestLoading && goTo === "Next"}
              onClick={() => {
                setGoTo("Next");
                setPageNumber(prev => prev + 1);
              }}
            >
              Next
            </Button>
          </Flex>
        </Flex>

        <EditPhoneNumbersModal
          pageNumber={pageNumber}
          uploadedContacts={uploadedContacts}
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      </Box>
    </>
  );
}

export default UploadedContacts;
