import React from "react";
import { Button, Box, HStack } from "@chakra-ui/react";
import { FromDateInput, ToDateInput } from "components/common";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import moment, { Moment } from "moment";
import { useDispatch } from "react-redux";
import { filterLoansByDateRange } from "redux/actions/loanActions";
import { dateInputFormat } from "../../constants";
import { ILoanTab } from "../../types";
interface IDateRangeFormValues {
  fromDate: moment.Moment | string;
  toDate: moment.Moment | string;
}

interface IDateRangeWrapperProps {
  loanTab?: ILoanTab["loanTab"];
  filterAction?: (
    xJwt: string | null,
    from: string | Moment,
    to: string | Moment
  ) => void;
}

export default function DateRangeWrapper({
  loanTab,
  filterAction,
}: IDateRangeWrapperProps) {
  const dispatch = useDispatch();
  const xJwt = localStorage.getItem("x_jwt");
  return (
    <Box className="date-range">
      <Formik
        initialValues={{
          fromDate: moment().subtract(1, "days").format(dateInputFormat),
          toDate: moment().format(dateInputFormat),
        }}
        onSubmit={(values: IDateRangeFormValues) => {
          let toDatePlusOne = moment(values.toDate)
            .add(1, "days")
            .format(dateInputFormat);

          dispatch(
            filterAction
              ? filterAction(xJwt, values.fromDate, toDatePlusOne)
              : filterLoansByDateRange(
                  xJwt,
                  loanTab as ILoanTab["loanTab"],
                  values.fromDate,
                  toDatePlusOne
                )
          );
        }}
        validationSchema={yup.object().shape({
          fromDate: yup.date().required("Please choose a date").nullable(),
          toDate: yup.date().required("Please choose a date").nullable(),
        })}
      >
        {({ values, errors }) => (
          <Box as={Form} textAlign="center">
            <HStack justify="center">
              <Box className="half-form">
                <Field
                  name="fromDate"
                  type="date"
                  component={FromDateInput}
                  hasLabel
                  label="From Date"
                  value={values.fromDate}
                  format={dateInputFormat}
                  error={errors.fromDate}
                />
              </Box>
              <Box className="half-form">
                <Field
                  name="toDate"
                  type="date"
                  component={ToDateInput}
                  hasLabel
                  label="To Date"
                  value={values.toDate}
                  format={dateInputFormat}
                  error={errors.toDate}
                />
              </Box>
            </HStack>

            <Button type="submit" mt="2">
              Search
            </Button>
          </Box>
        )}
      </Formik>
    </Box>
  );
}
