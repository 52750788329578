import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { getDisburseChart } from "redux/actions/dashboardAction";
import { getChartDataset } from "helpers/utilFunctions";
import { RootState } from "types";

export default function DisburseBarChart() {
  const expectedChart = useSelector(
    (state: RootState) => state.dashboardReducer
  );
  const chart = expectedChart?.disburseData?.data;
  const dispatch = useDispatch();
  const xTag = localStorage.getItem("x_jwt");
  useEffect(() => {
    dispatch(getDisburseChart(xTag, 3));
  }, [dispatch, xTag]);
  const chartLabels = chart?.labels;
  const dataLabel = chart?.datasets?.[0]?.label;
  const datasets = chart?.datasets;

  let data = {
    // x-axis labels
    labels: chartLabels?.length ? [...chartLabels] : [],
    // y-axis datasets
    datasets: [
      {
        label: dataLabel,
        data: getChartDataset(datasets, "Total Disburse", "totalDisburse"),
        backgroundColor: "#060088",
        hoverOffset: 3,
      },
    ],
  };
  let options = {
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          padding: 20,
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1000,
          font: {
            weight: "bold",
          },
        },
        labels: {
          font: {
            size: 18,
          },
        },
      },
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            weight: "bold",
          },
        },
      },
    },
  };

  return <Bar data={data} width={400} height={100} options={options} />;
}
