import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {
  searchAllLoans,
  searchAllLoansWithPhonenumber,
  searchLoans,
  searchLoansWithPhonenumber,
  searchPartialLoans,
  searchPartialLoansWithPhonenumber,
  searchProcessingDisbursementLoans,
  searchProcessingDisbursementLoansWithPhonenumber,
  searchTopupLoans,
  searchTopupLoansWithPhonenumber,
} from "redux/actions/loanActions";
import { Input } from "antd";
import { Box } from "@chakra-ui/layout";
import { ILoanTab } from "types";

const { Search } = Input;

interface ISearchValues {
  searchQuery: string;
}

interface ISearchLoansInputProps {
  loanStatus: "pending" | "approved" | "active" | "closed";
  processStatus: "pending" | "approved" | "disbursed";
  disbursementStatus: "processing" | "completed" | "failed";
  searchField: string;
  totalOutstanding?: number;
  isDisabled?: boolean;
  allLoans?: boolean;
  processingLoans?: boolean;
  loanTab?: ILoanTab["loanTab"];
}

export default function SearchLoansInput({
  loanStatus,
  processStatus,
  disbursementStatus,
  searchField,
  totalOutstanding,
  isDisabled,
  allLoans,
  processingLoans,
  loanTab,
}: ISearchLoansInputProps) {
  const dispatch = useDispatch();
  const xJwt = localStorage.getItem("x_jwt");

  const isTopup = loanTab === "topup";
  const isPartial = loanTab === "partial";

  return (
    <Formik
      initialValues={{
        searchQuery: "",
      }}
      validationSchema={yup.object().shape({
        searchQuery: yup.string().required("Please fill out this field"),
      })}
      onSubmit={(values: ISearchValues) => {
        if (searchField === "phoneNumber") {
          if (allLoans) {
            dispatch(searchAllLoansWithPhonenumber(xJwt, values.searchQuery));
          } else if (processingLoans) {
            dispatch(
              searchProcessingDisbursementLoansWithPhonenumber(
                xJwt,
                values.searchQuery
              )
            );
          } else if (isTopup) {
            dispatch(searchTopupLoansWithPhonenumber(xJwt, values.searchQuery));
          } else if (isPartial) {
            dispatch(
              searchPartialLoansWithPhonenumber(xJwt, values.searchQuery)
            );
          } else {
            dispatch(
              searchLoansWithPhonenumber(
                xJwt,
                loanStatus,
                processStatus,
                disbursementStatus,
                values.searchQuery,
                totalOutstanding
              )
            );
          }
        } else {
          if (allLoans) {
            dispatch(searchAllLoans(xJwt, searchField, values.searchQuery));
          } else if (processingLoans) {
            dispatch(
              searchProcessingDisbursementLoans(
                xJwt,
                searchField,
                values.searchQuery
              )
            );
          } else if (isTopup) {
            dispatch(searchTopupLoans(xJwt, searchField, values.searchQuery));
          } else if (isPartial) {
            dispatch(searchPartialLoans(xJwt, searchField, values.searchQuery));
          } else {
            dispatch(
              searchLoans(
                xJwt,
                loanStatus,
                processStatus,
                disbursementStatus,
                searchField,
                values.searchQuery,
                totalOutstanding
              )
            );
          }
        }
      }}
    >
      {({ values, handleChange, submitForm, errors }) => (
        <Box as={Form} textAlign="center">
          <Search
            placeholder="Search loans"
            onChange={handleChange}
            value={values.searchQuery}
            name="searchQuery"
            required
            onSearch={submitForm}
            enterButton
            className="custom-search-input"
            disabled={isDisabled}
          />
        </Box>
      )}
    </Formik>
  );
}
