import React from "react";
import { Box, Button, Flex, IconButton, Text } from "@chakra-ui/react";
import { MfaSuccessIcon } from "components/common/CustomIcons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import Copyable from "components/common/Copyable";
import { useHistory } from "react-router-dom";
import { TWO_FA_NEEDED_FOR_LOGIN } from "redux/actions/actionTypes";

type Props = {
  onNext: () => void;
  isForRecovery?: boolean;
};

const MfaSuccess = ({ onNext, isForRecovery }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const recoveryCode = useSelector(
    (state: RootState) => state.authReducer?.twoFAInfo?.recoveryCode
  );

  const onContinue = () => {
    onNext();
    isForRecovery &&
      dispatch({ type: TWO_FA_NEEDED_FOR_LOGIN, payload: false });
    isForRecovery && history.push("/login");
  };

  return (
    <Box>
      <Flex
        flexDir="column"
        alignItems="center"
        px={{ base: "0", md: "20px", lg: "40px" }}
      >
        <MfaSuccessIcon boxSize={"120px"} />
        <Text
          mb="16px"
          mt="38px"
          fontWeight="600"
          textAlign="center"
          color="neutral.500"
          fontSize={["20px", "20px", "24px", "28px"]}
        >
          Now, you are all set!
        </Text>
        {recoveryCode ? (
          <>
            <Text mb="32px" textAlign="center">
              This is your one-time recovery code. Please keep it safe. You will
              need it to reset your 2FA code if you lose access to your
              authenticator application.
            </Text>
            <Flex
              gap={2}
              w="100%"
              p={"10px"}
              bg="neutral.50"
              width={"100%"}
              borderRadius="4px"
              alignItems={"center"}
              justifyContent="space-between"
              border="1px solid #E2E8F0"
            >
              <Text
                fontSize="16px"
                fontWeight="600"
                textAlign="center"
                color={"primary.500"}
              >
                {recoveryCode}
              </Text>
              <IconButton
                aria-label=""
                as={Copyable}
                width="24px"
                height="24px"
                bgColor="transparent"
                text={recoveryCode}
                _hover={{ bgColor: "transparent" }}
              />
            </Flex>
          </>
        ) : (
          <Text type="02" mb="32px" textAlign="center">
            Anytime you are asked for a 2FA code, simply copy the code generated
            in your Google Authenticator.
          </Text>
        )}
      </Flex>
      <Box h="58px" />
      <Flex
        mb="40px"
        alignItems={"center"}
        justifyContent={{ md: "flex-end" }}
        flexDir={["column-reverse", null, "row"]}
        px={{ base: "0", md: "20px", lg: "40px" }}
      >
        <Button variant="solid" onClick={onContinue}>
          Okay, thanks
        </Button>
      </Flex>
    </Box>
  );
};

export default MfaSuccess;
