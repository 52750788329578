import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSmsTransactions,
  getMoreSmsTransactions,
} from "redux/actions/transActions";
import moment from "moment";
import { Box, Text, Link } from "@chakra-ui/react";
import { popLastId } from "helpers/utilFunctions";
import { DateTimeFormat } from "../../constants";
import { Table } from "antd";
import { Loader } from "components/common";
import { RootState } from "types";

const columns = [
  {
    dataIndex: "transactionAmount",
    key: "transactionAmount",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Amount
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "transactionDate",
    key: "transactionDate",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Date
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    ellipsis: true,
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Phone number
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "message",
    key: "message",
    ellipsis: true,
    render: (text: string) => (
      <Box>
        <Text
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="bold"
          title={text}
        >
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Message
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "status",
    key: "status",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Status
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
];

export default function SmsTransactionsTable() {
  const dispatch = useDispatch();
  const transactionState = useSelector(
    (state: RootState) => state.transactionReducer
  );
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const userSetting = setting?.settingData?.data;
  const accountId = userSetting?.apiCredentials?.id;
  //61512e41ac43b4002907ca32
  const transactions = transactionState?.smsTransactionsData?.data;
  const loading = transactionState?.requestLoading;
  const [lastIds, setLastIds] = useState<(string | null)[]>([null]);
  const xJwt = localStorage.getItem("tx_jwt");

  useEffect(() => {
    if (accountId) {
      dispatch(getSmsTransactions(xJwt, accountId));
    }
  }, [dispatch, accountId, xJwt]);

  useEffect(() => {
    if (
      transactionState?.smsTransactionsData !== undefined &&
      transactionState?.smsTransactionsData?.lastId !== undefined
    ) {
      setLastIds([...lastIds, transactionState?.smsTransactionsData?.lastId]);
    }
  }, [transactionState?.smsTransactionsData]); //eslint-disable-line

  const loadNextPage = () => {
    dispatch(
      getMoreSmsTransactions(
        xJwt,
        accountId,
        transactionState?.smsTransactionsData?.lastId
      )
    );
  };

  const loadPrevPage = () => {
    if (lastIds.length === 3) {
      dispatch(getSmsTransactions(xJwt, accountId));
      setLastIds([null]);
    } else {
      dispatch(
        getMoreSmsTransactions(xJwt, accountId, lastIds[lastIds.length - 3])
      );
      popLastId(lastIds, setLastIds);
    }
  };

  const data: any[] | undefined = [];
  if (transactions) {
    const nonPendingTransactions = transactions.filter(
      (transaction: any) => transaction?.frontendStatus === "successful"
    );
    for (let i = 0; i < nonPendingTransactions.length; i++) {
      const transaction = nonPendingTransactions[i];
      const key = transaction?._id;
      const transactionAmount = transaction?.totalCost;
      const transactionDate = moment(transaction?.createdAt).format(
        DateTimeFormat
      );
      const phoneNumber = transaction?.productsDetails?.to;
      const message = transaction?.productsDetails?.message;
      const status = transaction?.frontendStatus;
      data.push({
        key,
        transactionAmount,
        transactionDate,
        phoneNumber,
        message,
        status,
      });
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 50,
            hideOnSinglePage: true,
            position: ["bottomLeft"],
          }}
        />
      )}
      <Box className="pagination-items">
        {lastIds.length <= 2 ? null : (
          <Link onClick={loadPrevPage} className="pagination-item-link prev">
            Previous page
          </Link>
        )}
        {!transactionState?.smsTransactionsData?.isLastPage ? (
          <Link onClick={loadNextPage} className="pagination-item-link next">
            Next page
          </Link>
        ) : null}
      </Box>
    </>
  );
}
