import {
  EmptyCampaign,
  SmsAutomationHeader,
  CapmaignStats,
  MainLoader,
} from "../../../components/common";
import { CampaignsTable } from "components/tables";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCampaigns } from "redux/actions/smsAutomationActions";
import { RootState } from "types";
import { getUserSettings } from "redux/actions/settingsActions";

export default function Campaigns() {
  const dispatch = useDispatch();
  const campaignsState = useSelector(
    (state: RootState) => state.smsAutomationReducer
  );
  const campaigns = campaignsState?.campaigns;
  const isLoading = campaignsState?.isLoading;

  const xJwt = localStorage.getItem("x_jwt");

  useEffect(() => {
    dispatch(getUserSettings(xJwt));
    dispatch(getCampaigns(xJwt));
  }, [dispatch, xJwt]);

  return (
    <>
      {isLoading && campaigns.length === 0 && <MainLoader />}

      <SmsAutomationHeader title="Campaigns" hideBackBtn={true} />

      <CapmaignStats />

      {campaigns.length === 0 ? (
        <EmptyCampaign />
      ) : (
        <CampaignsTable campaigns={campaigns} />
      )}
    </>
  );
}
