import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
  SettingFilled,
} from "@ant-design/icons";
import {
  DashboardIcon,
  BorrowersIcon,
  LoanIcon,
  BalanceIcon,
  NotificationIcon,
  LogoutIcon,
  ToolsIcon,
  RepaymentsIcon,
  VerifiedIcon,
  NotVerifiedIcon,
} from "components/common/CustomIcons";
import veendLogo from "../../assets/veend-logo.png";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import { routes } from "routes";
import { IRoutes, RootState } from "types";
import {
  Avatar,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  Box,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getUserSettings } from "redux/actions/settingsActions";
// import { getBanksList } from "redux/actions/dashboardAction";
import headwayWidget from "helpers/headwayWidget";
import useWindowDimensions from "helpers/useWIndowDimensions";
import SlaGuide from "components/common/SlaGuide";
import VerificationGuide from "components/common/VerificationGuide";
import LendingLicenceChecker from "components/common/LendingLicenceChecker";
import { showLendingLicenseModal } from "redux/actions/lendingLicenseActions";
import { getUserProfile } from "redux/actions/authActions";

const { Header, Sider, Content } = Layout;

export default function LoggedInPage() {
  const { width } = useWindowDimensions();
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const userSetting = setting?.settingData?.data;
  // const accountID = userSetting?.apiCredentials?.id;
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const currentUrl = useLocation();
  const xTag = localStorage.getItem("x_jwt");
  // const txJwt = localStorage.getItem("tx_jwt");

  useEffect(() => {
    headwayWidget();
    dispatch(getUserSettings(xTag));
    dispatch(getUserProfile());
  }, [dispatch, xTag]);

  useEffect(() => {
    if (width) {
      setCollapsed(width < 769 ? true : false);
    }
  }, [width]);

  const addActiveClass = (path: string) => {
    if (currentUrl.pathname.includes(path)) {
      return "ant-menu-item-selected";
    } else {
      return "";
    }
  };

  const size = useBreakpointValue({ base: "sm", md: "sm", lg: "md" });

  // clear x-tag from localstorage and redirect to login page
  const logout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  const getRoutes = (routes: IRoutes[]) => {
    return routes.map((props, index) => {
      if (props.layout === "main") {
        return (
          <Route
            path={props.path}
            component={props.component}
            key={index}
            exact={props.exact}
          />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <>
      <VerificationGuide>
        <SlaGuide>
          <LendingLicenceChecker />
          <Sider
            trigger={null}
            collapsible
            collapsedWidth="50px"
            width="168px"
            collapsed={collapsed}
            className="side-nav"
          >
            <Box className="loggedInLogo" mb={20}>
              <Image
                src={veendLogo}
                alt="brand-logo"
                className="dashboard-logo"
              />
            </Box>
            <Box className="profile-pic-container">
              <Avatar size={size} src={userSetting?.logo} />
              {/* <Link to={"/settings"}>
            <Text
              fontSize="xs"
              color="primaryBlue"
              mt=3
              fontWeight="medium"
            >
              View Profile
            </Text>
          </Link> */}
            </Box>
            <Box mt="4">
              <Menu theme="light" mode="inline" className="sider-menu">
                <Menu.Item
                  key="1"
                  icon={<DashboardIcon />}
                  className={addActiveClass("/dashboard")}
                >
                  <Link to={"/dashboard"}>Dashboard</Link>
                </Menu.Item>
                <Menu.Item
                  key="2"
                  icon={<LoanIcon />}
                  className={addActiveClass("/loans")}
                >
                  <Link to={"/loans"}>Loans</Link>
                </Menu.Item>
                <Menu.Item
                  key="3"
                  icon={<BorrowersIcon />}
                  className={addActiveClass("/borrowers")}
                >
                  <Link to={"/borrowers"}>Borrowers</Link>
                </Menu.Item>
                <Menu.Item
                  key="4"
                  icon={<RepaymentsIcon />}
                  className={addActiveClass("/repayments")}
                >
                  <Link to={"/repayments"}>Repayments</Link>
                </Menu.Item>
                <Menu.Item
                  key="5"
                  icon={<BalanceIcon />}
                  className={addActiveClass("/balance")}
                >
                  <Link to={"/balance"}>Balance</Link>
                </Menu.Item>
                <Menu.Item
                  key="6"
                  icon={<ToolsIcon />}
                  className={addActiveClass("/tools")}
                >
                  <Link to={"/tools"}>Tools</Link>
                </Menu.Item>
                <Menu.Item
                  key="7"
                  icon={<SettingFilled style={{ fontSize: "20px" }} />}
                  className={`settings-menu-item ${addActiveClass(
                    "/settings"
                  )}`}
                >
                  <Link to={"/settings"}>Settings</Link>
                </Menu.Item>
              </Menu>
            </Box>
          </Sider>
          <Layout className="site-layout">
            <Box
              pos={"fixed"}
              top={0}
              zIndex={1000}
              left={0}
              w={"100%"}
              display="none"
            ></Box>
            <Header
              className="dashboard-header"
              style={{ flexDirection: "column" }}
            >
              <Box display="flex">
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  { className: "trigger", onClick: toggle }
                )}
                <InputGroup d="none" className="search-bar">
                  <InputLeftElement
                    pointerEvents="none"
                    children={<SearchOutlined />}
                    style={{ height: "32px", color: "#060088" }}
                  />
                  <Input placeholder="Search anything" variant="search" />
                </InputGroup>

                <Box className="right-nav">
                  <Box className="nav-notification">
                    <NotificationIcon className="headway-trigger blue-icon" />
                    <Text
                      fontSize={{ base: "xx-small", md: "xs" }}
                      fontWeight="semibold"
                      color="primaryBlue"
                      ml="2"
                      className="headway-trigger"
                    >
                      Product Updates
                    </Text>
                  </Box>
                  <Box className="nav-logout" onClick={logout}>
                    <LogoutIcon className="blue-icon" />
                    <Text
                      fontSize={{ base: "xx-small", md: "xs" }}
                      fontWeight="semibold"
                      color="primaryBlue"
                      ml="2"
                    >
                      Logout
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Box
                bg="#FFE0E0"
                display={!userSetting?.lendingLicenseUrl ? "flex" : "none"}
                justifyContent={{ base: "flex-start", md: "center" }}
                gap="8px"
                cursor="pointer"
                onClick={() => {
                  sessionStorage.setItem("license-reminder", "true");
                  dispatch(showLendingLicenseModal(true));
                }}
                flexWrap="wrap"
                lineHeight={"normal"}
                p={[2, 4]}
              >
                <NotVerifiedIcon boxSize={"32px"} />
                <Text color="#FF3F3F" fontWeight={500}>
                  Upload your operating licence to complete your verification
                  process
                </Text>
              </Box>
            </Header>
            <Content
              style={{
                backgroundColor: "white",
                paddingTop: !userSetting?.lendingLicenseUrl ? "80px" : "20px",
              }}
            >
              {<Switch>{getRoutes(routes)}</Switch>}
            </Content>
          </Layout>
        </SlaGuide>
      </VerificationGuide>
    </>
  );
}
