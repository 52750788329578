import React from "react";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { Button, Heading, Text, Box } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { InputField } from "components/common";
import { sendMoney } from "redux/actions/generalActions";
import moment from "moment";
import { formatNumberAmount } from "helpers/utilFunctions";
import { useHistory } from "react-router-dom";

interface IWithdrawFundsModalProps {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
  balance: number;
  accountId: string | number;
  bankCode: string;
  bankName: string;
  accountNumber: string;
  nameOnAccount: string;
  afterClose: () => void;
}

interface IWithdrawalAmountValues {
  withdrawAmount: string;
  narration: string;
}

export default function WithdrawFundsModal({
  showModal,
  setShowModal,
  balance,
  accountId,
  bankCode,
  accountNumber,
  bankName,
  nameOnAccount,
  afterClose,
}: IWithdrawFundsModalProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleCancel = () => {
    setShowModal(false);
  };
  const txJwt = localStorage.getItem("tx_jwt");

  const nowToMinute = () => {
    let now = moment().format("YYYY/MM/DD/HH/mm");
    return now;
  };

  return (
    <Modal
      visible={showModal}
      onCancel={handleCancel}
      footer={null}
      width="45%"
      className="loan-config-modal"
      afterClose={afterClose}
    >
      <Box textAlign="center">
        <Heading as="h2" mb="3" fontSize={{ base: "xs", md: "sm", lg: "md" }}>
          Withdraw Funds
        </Heading>
        <Text fontSize={{ base: "xs", md: "sm" }}>
          Current balance:{" "}
          <Text
            as="span"
            fontWeight="semibold"
            fontStyle="italic"
          >{`₦ ${formatNumberAmount(balance)}`}</Text>
        </Text>
        {/* <Text fontSize={{ base: "xs", md: "sm" }}>
            withdrawal account:{" "}
            <Text
              as="span"
              fontWeight="semibold"
              fontStyle="italic"
            >{`${accountNumber}`}</Text>
          </Text>
          <Text fontSize={{ base: "xs", md: "sm" }}>
            bank:{" "}
            <Text
              as="span"
              fontWeight="semibold"
              fontStyle="italic"
            >{`${bankName}`}</Text>
          </Text>
          <Text fontSize={{ base: "xs", md: "sm" }}>
            name on account:{" "}
            <Text
              as="span"
              fontWeight="semibold"
              fontStyle="italic"
            >{`${nameOnAccount}`}</Text>
          </Text> */}
      </Box>
      <Formik
        initialValues={{
          withdrawAmount: "",
          narration: "",
        }}
        onSubmit={(values: IWithdrawalAmountValues) => {
          let withdrawBody = {
            purchaseReference: `VeendHQ-withdrawal/${
              values.withdrawAmount
            }/${bankCode}/${accountNumber}/NGN/${nowToMinute()}`,
            amount: values.withdrawAmount,
            bankcode: bankCode,
            accountNumber: accountNumber,
            currency: "NGN",
            senderName: "VeendHQ",
            narration: values.narration,
          };
          dispatch(sendMoney(txJwt, withdrawBody, accountId));
          setShowModal(false);
        }}
        validationSchema={yup.object().shape({
          withdrawAmount: yup
            .number()
            .max(balance, "insufficient balance")
            .required("Please enter amount to withdraw"),
          narration: yup.string().required("Please provide a narration"),
        })}
      >
        {({ values, handleChange, handleBlur, errors }) => (
          <Box as={Form} textAlign="center">
            <InputField
              inputType="text"
              name="withdrawAmount"
              placeholder="Amount to withdraw"
              value={values.withdrawAmount}
              onBlur={() => handleBlur}
              onChange={handleChange}
              error={errors.withdrawAmount}
              required
            />
            <InputField
              inputType="text"
              name="narration"
              placeholder="Short Narration"
              value={values.narration}
              onBlur={() => handleBlur}
              onChange={handleChange}
              error={errors.narration}
              required
            />

            <Button
              type="submit"
              d="block"
              mt="3"
              mx="auto"
              disabled={accountNumber === undefined ? true : false}
            >
              Withdraw Funds
            </Button>
            {accountNumber === undefined ? (
              <>
                <Text
                  fontSize={{ base: "xx-small", md: "xs" }}
                  fontStyle="italic"
                  fontWeight="medium"
                  my="2"
                >
                  You have not set up a withdrawal account
                </Text>
                <Button
                  d="block"
                  mx="auto"
                  variant="secondary"
                  onClick={() => history.push("/settings", { tab: "5" })}
                >
                  Add Account details
                </Button>
              </>
            ) : null}
          </Box>
        )}
      </Formik>
    </Modal>
  );
}
