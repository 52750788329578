import React from "react";
import { Text } from "@chakra-ui/react";
import { Step } from "react-joyride";

export const dashboardPageSteps: Step[] = [
  {
    content: <Text>Welcome to your VeendHQ Dashboard</Text>,
    placement: "center",
    target: "body",
  },
  {
    content: <Text>Navigate your lending dashboard via this menu.</Text>,
    spotlightPadding: 10,
    placement: "right",
    target: ".side-nav",
  },
  {
    content: <Text>Create your funding account</Text>,
    spotlightPadding: 10,
    placement: "top",
    target: ".create-account-btn",
  },
  {
    content: (
      <Text>
        View your available balance and fund your account via the funding
        account number created.
      </Text>
    ),
    spotlightPadding: 10,
    placement: "top",
    target: ".balance-amount",
  },
  {
    content: (
      <Text>
        Toggle Originate AI. On enabling Originate AI, VeendHQ will recommend
        the interest rate you need to charge customers to route loan requests to
        you. VeendHQ will use the loan offer with interest rate nearest to the
        recommended rate on your account to route loan requests.
      </Text>
    ),
    spotlightPadding: 10,
    placement: "top",
    target: ".colending-toggle",
  },
  {
    content: (
      <Text>
        Copy this link and send to your customers to apply for loans instantly
        via WEB.
      </Text>
    ),
    spotlightPadding: 10,
    placement: "top",
    target: ".loan-request-link",
    spotlightClicks: true,
  },
  {
    content: (
      <Text>Your customers can also apply for instant loans via USSD.</Text>
    ),
    spotlightPadding: 10,
    placement: "top",
    target: ".ussd-request-link",
    spotlightClicks: true,
  },
  {
    content: <Text>This shows a summary of your loan activities.</Text>,
    spotlightPadding: 10,
    placement: "top",
    target: ".loan-activities",
  },
  {
    content: (
      <Text>
        Your default loan interest rate is shown here, you can change this by
        editing "Loan Product" in Settings page.
      </Text>
    ),
    spotlightPadding: 10,
    placement: "top",
    target: ".loan-interest-rate",
  },
  {
    content: (
      <Text>
        You will be notified when new features and updates are added to VeendHQ.
      </Text>
    ),
    spotlightPadding: 10,
    placement: "top",
    target: ".nav-notification",
  },
  {
    content: <Text>You can logout of the dashboard here.</Text>,
    spotlightPadding: 10,
    placement: "top",
    target: ".nav-logout",
  },
  {
    content: <Text>Click here to open this walkthrough again later.</Text>,
    spotlightPadding: 10,
    placement: "top",
    target: ".onboarding-toggler",
  },
  {
    content: (
      <Text>
        Setup other configuration like Loan Product, Loan Offers & Withdrawal
        Account on the Settings page.
      </Text>
    ),
    spotlightPadding: 10,
    placement: "right",
    target: ".settings-menu-item",
    disableOverlay: true,
  },
];

export const loansPageSteps: Step[] = [
  {
    content: <Text>This is where you manage all loans on your account.</Text>,
    placement: "center",
    target: "body",
  },
  {
    content: (
      <Text>
        This section shows you an overview of how your loan portfolio is
        looking.
      </Text>
    ),
    spotlightPadding: 10,
    placement: "bottom",
    target: ".loan-card-container",
  },
  {
    content: (
      <Text>
        This section shows tables of your loans by category. Clicking on a row
        will show more details about the loan.
      </Text>
    ),
    spotlightPadding: 10,
    placement: "top",
    target: ".loans-tables-container",
  },
  {
    content: (
      <Text>
        This tab shows a table of all loans attempted on your service.
      </Text>
    ),
    spotlightPadding: 10,
    spotlightClicks: true,
    placement: "top",
    target: ".all-loans-tabkey",
  },
  {
    content: (
      <Text>
        This tab shows a table of loans approved, but not disbursed. You can
        manually trigger a disbursement on this table.
      </Text>
    ),
    spotlightPadding: 10,
    spotlightClicks: true,
    placement: "top",
    target: ".approved-loans-tabkey",
  },
  {
    content: (
      <Text>
        This tab shows a table of loans approved and disbursement process
        started.
      </Text>
    ),
    spotlightPadding: 10,
    spotlightClicks: true,
    placement: "top",
    target: ".processing-loans-tabkey",
  },
  {
    content: (
      <Text>
        This tab shows a table of loans approved and disbursement process
        completed. You can manually log a repayment on this table.
      </Text>
    ),
    spotlightPadding: 10,
    spotlightClicks: true,
    placement: "top",
    target: ".active-loans-tabkey",
  },
  {
    content: <Text>This tab shows a table of topup loans.</Text>,
    spotlightPadding: 10,
    spotlightClicks: true,
    placement: "top",
    target: ".topup-loans-tabkey",
  },
  {
    content: <Text>This tab shows a table of partially repaid loans.</Text>,
    spotlightPadding: 10,
    spotlightClicks: true,
    placement: "top",
    target: ".partiallyPaid-loans-tabkey",
  },
  {
    content: <Text>This tab shows a table of loans repaid.</Text>,
    spotlightPadding: 10,
    spotlightClicks: true,
    placement: "top",
    target: ".paid-loans-tabkey",
  },
  {
    content: <Text>This tab shows a table of loans in arrears.</Text>,
    spotlightPadding: 10,
    spotlightClicks: true,
    placement: "top",
    target: ".overdue-loans-tabkey",
  },
  {
    content: (
      <Text>
        You can search your loans by phone number, loan ID and Remita mandate
      </Text>
    ),
    spotlightPadding: 10,
    placement: "top",
    target: ".search-loans-container",
  },
  {
    content: (
      <Text>
        You can filter each loans category by a date range and also download the
        results as CSV file (up to 10,000 records)
      </Text>
    ),
    spotlightPadding: 10,
    placement: "top",
    target: ".table-btns",
  },
  {
    content: (
      <Text>
        You can download a CSV file of the data displayed on the table (up to
        10,000 records)
      </Text>
    ),
    spotlightPadding: 10,
    placement: "top",
    target: ".download-loans-btn",
  },
];

export const settingsPageSteps: Step[] = [
  {
    content: (
      <Text>
        This is where you make important decisions on your Autolending account.
      </Text>
    ),
    placement: "center",
    target: "body",
  },
  {
    content: <Text>Update logo and support contact details on this tab.</Text>,
    placement: "top",
    spotlightPadding: 10,
    spotlightClicks: true,
    target: ".general-tabkey",
  },
  {
    content: <Text>Edit loan product on this tab.</Text>,
    spotlightPadding: 10,
    spotlightClicks: true,
    placement: "top",
    target: ".loanProduct-tabkey",
  },
  {
    content: <Text>Create, edit or delete loan offers on this tab.</Text>,
    spotlightPadding: 10,
    spotlightClicks: true,
    placement: "top",
    target: ".loanOffer-tabkey",
  },
  {
    content: <Text>Add withdrawal account on this tab.</Text>,
    spotlightPadding: 10,
    spotlightClicks: true,
    placement: "top",
    target: ".accountDetails-tabkey",
  },
  {
    content: <Text>Configure team members access on this tab.</Text>,
    spotlightPadding: 10,
    spotlightClicks: true,
    placement: "top",
    target: ".teamMember-tabkey",
  },
];

export const borrowersPageSteps: Step[] = [
  {
    content: (
      <Text>
        The table below shows a list of your borrowers as soon as they attempt a
        loan application. Clicking on a row will show more details about the
        borrower.
      </Text>
    ),
    spotlightPadding: 10,
    placement: "top",
    target: ".borrowers-table",
  },
  {
    content: <Text>You can search your borrowers by phone number.</Text>,
    spotlightPadding: 10,
    placement: "top",
    target: ".search-borrowers-container",
  },
  {
    content: (
      <Text>You can search your borrowers by setting a date range.</Text>
    ),
    spotlightPadding: 10,
    placement: "top",
    target: ".table-btns",
  },
  {
    content: (
      <Text>
        You can download a CSV file of the data displayed on the table (up to
        10,000 records).
      </Text>
    ),
    spotlightPadding: 10,
    placement: "top",
    target: ".download-borrowers-btn",
  },
];

export const balancePageSteps: Step[] = [
  {
    content: (
      <Text>
        A view of your available balance and funding account number created.
      </Text>
    ),
    spotlightPadding: 10,
    placement: "top",
    target: ".balance-amount",
  },
  {
    content: (
      <Text>
        Withdraw funds from your funding wallet to your bank account which can
        be setup in settings under "Account Details" tab.
      </Text>
    ),
    spotlightPadding: 10,
    spotlightClicks: true,
    placement: "top",
    target: ".withdraw-funds-btn",
  },
  {
    content: <Text>This section shows your transactions by category.</Text>,
    spotlightPadding: 10,
    placement: "top",
    target: ".transactions-tabs",
  },
  {
    content: (
      <Text>
        This table shows all disbursements made from your wallet balance.
      </Text>
    ),
    spotlightClicks: true,
    placement: "top",
    target: ".disbursements-tabkey",
  },
  {
    content: (
      <Text>This table shows all REMITA charges on your wallet balance.</Text>
    ),
    spotlightClicks: true,
    placement: "top",
    target: ".remita-tabkey",
  },
  {
    content: (
      <Text>This table shows all USSD charges on your wallet balance.</Text>
    ),
    spotlightClicks: true,
    placement: "top",
    target: ".ussd-tabkey",
  },
  {
    content: (
      <Text>This table shows all SMS charges on your wallet balance.</Text>
    ),
    spotlightClicks: true,
    placement: "top",
    target: ".sms-tabkey",
  },
  {
    content: (
      <Text>
        This table shows all withdrawals made from your wallet balance.
      </Text>
    ),
    spotlightClicks: true,
    placement: "top",
    target: ".withdrawals-tabkey",
  },
  {
    content: (
      <Text>
        This table shows all transactions made from your wallet balance.
      </Text>
    ),
    spotlightClicks: true,
    placement: "top",
    target: ".statement-tabkey",
  },
  {
    content: (
      <Text>You can search each table by searching via date range.</Text>
    ),
    spotlightPadding: 10,
    placement: "top",
    target: ".table-btns",
  },
  {
    content: (
      <Text>
        You can download a CSV file of the data displayed on the table (up to
        10,000 records).
      </Text>
    ),
    spotlightPadding: 10,
    placement: "top",
    target: ".transactions-download-btn",
  },
];

export const BulkEligibilityTourSteps: Step[] = [
  {
    content: (
      <Text>
        This tool helps you process eligibilities by uploading phone numbers
      </Text>
    ),
    placement: "center",
    target: "body",
  },
  {
    content: (
      <Text>
        Click here to upload comma-separated phonenumbers or a CSV file of
        phonenumbers following the accepted formatting.
      </Text>
    ),
    spotlightPadding: 10,
    placement: "auto",
    target: ".upload-numbers-btn",
  },
  {
    content: (
      <Text>
        The uploaded phone numbers are added to a queue to be processed, all
        phone numbers added start with a pending status and can be tracked in
        the table below
      </Text>
    ),
    spotlightPadding: 10,
    placement: "bottom",
    target: ".bulk-eligibility-table",
  },
  {
    content: (
      <Text>
        Switch between each status using these buttons. "Pending" and
        "Processing" shows numbers that are queued for processing, "Eligible
        users" shows eligible numbers and "Non-Eligible users" shows numbers
        that are not eligible.
      </Text>
    ),
    spotlightPadding: 10,
    placement: "auto",
    target: ".eligibilities-radio",
  },
  {
    content: (
      <Text>
        Click here to refresh the table in view. Please refresh every 5 minutes
        for uploads less than 1000 and every 15 minutes for uploads more than
        1000.
      </Text>
    ),
    spotlightPadding: 10,
    placement: "auto",
    target: ".refresh-eligibilities",
  },
  {
    content: <Text>Click here to download the records in view.</Text>,
    spotlightPadding: 10,
    placement: "auto",
    target: ".download-loans-btn",
  },
];

export const EligibleUsersTourSteps: Step[] = [
  {
    content: (
      <Text>
        This tool shows you all eligible users you have on your borrower
        database
      </Text>
    ),
    placement: "center",
    target: "body",
  },
  {
    content: <Text>Switch between each status using these buttons.</Text>,
    spotlightPadding: 10,
    placement: "auto",
    target: ".eligibilities-radio",
  },
  {
    content: <Text>This shows all eligible users in your database.</Text>,
    spotlightPadding: 10,
    placement: "auto",
    target: ".all-eligible",
  },
  {
    content: (
      <Text>
        "No current active loans" shows eligible users that have taken a loan
        before but do not currently have an active loan.
      </Text>
    ),
    spotlightPadding: 10,
    placement: "auto",
    target: ".noCurrentActive-eligible",
  },
  {
    content: (
      <Text>
        "No active loans" shows eligible users without active loans regardless
        of whether they've taken a loan before or not.
      </Text>
    ),
    spotlightPadding: 10,
    placement: "auto",
    target: ".noActive-eligible",
  },
  {
    content: <Text>This shows users eligible to take top up loans.</Text>,
    spotlightPadding: 10,
    placement: "auto",
    target: ".topup-eligible",
  },
  {
    content: <Text>Click here to download the records in view.</Text>,
    spotlightPadding: 10,
    placement: "auto",
    target: ".download-btn",
  },
];

export const PayingEmployersTourSteps: Step[] = [
  {
    content: (
      <Text>
        This tool shows you all employers we serve and their most recent payday.
      </Text>
    ),
    placement: "center",
    target: "body",
  },
];
