import CryptoJS from "crypto-js";

export const encrypt = (key: string, value: string) => {
  return CryptoJS.AES.encrypt(value, key).toString();
};

export const decrypt = (encrypted: string, key: string) => {
  return CryptoJS.AES.decrypt(encrypted, key);
};

export const saveSlaState = async (status: string) => {
  const email = localStorage.getItem("email") as string;

  const cryp = encrypt(email, JSON.stringify(status));

  return localStorage.setItem("sla", cryp);
};

export const getSlaState = () => {
  const encryptedVal = localStorage.getItem("sla");
  const email = localStorage.getItem("email");
  if (!encryptedVal || !email) return null;
  // decrypt
  const decryptedVal = decrypt(encryptedVal, email);
  if (!decryptedVal) return null;
  try {
    const val = CryptoJS.enc.Utf8.stringify(decryptedVal) || null;
    return val;
  } catch (error) {
    return null;
  }
};

export {};
