import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { BorrowerTab } from "../../components/tabs";
import { DashboardHeader } from "../../components/common";
import { borrowersPageSteps } from "../../constants/tourSteps";
import "./styles/loans.css";

export default function Borrowers() {
  const xTag = localStorage.getItem("x_jwt");

  useEffect(() => {
    if (xTag === null) {
      window.location.href = "/login";
    }
  }, [xTag]);

  return (
    <>
      <Box px="4" py="5">
        {/* Borrowers top start */}
        <DashboardHeader
          title="Your Borrowers"
          description="View and manage your borrowers"
          tourSteps={borrowersPageSteps}
        />

        {/* Borrowers top end */}
        {/* Borrowers Tabs start */}
        <BorrowerTab />
        {/* Borrowers Tabs end */}
      </Box>
    </>
  );
}
