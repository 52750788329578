import React from "react";
import { Modal } from "antd";
import { Heading, Button, Box } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import {
  deleteLoanOffer,
  editLoanOffer,
  getLoanOffers,
} from "redux/actions/settingsActions";

interface IConfirmDeleteLoanOfferProps {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
  offer?: any | undefined;
  isStateToggle: boolean;
}

export default function ConfirmDeleteLoanOffer({
  showModal,
  setShowModal,
  offer,
  isStateToggle,
}: IConfirmDeleteLoanOfferProps) {
  const dispatch = useDispatch();
  const xJwt = localStorage.getItem("x_jwt");
  const { _id: offerId } = offer || {};

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleClose = () => {
    dispatch(getLoanOffers(xJwt));
  };

  const handleDelete = () => {
    if (isStateToggle) {
      dispatch(
        editLoanOffer(
          xJwt,
          {
            enabled: !offer.enabled,
          },
          offerId
        )
      );
    } else dispatch(deleteLoanOffer(xJwt, offerId));
    setShowModal(false);
  };

  return (
    <>
      <Modal
        visible={showModal}
        onCancel={handleCancel}
        footer={null}
        width="40%"
        className="loan-config-modal"
        afterClose={handleClose}
      >
        <Box textAlign="center">
          <Heading as="h2" fontSize="sm" mb="4">
            Are you sure you want to{" "}
            {isStateToggle ? (offer?.enabled ? "disable" : "enable") : "delete"}{" "}
            this loan offer?
          </Heading>
          <Box d="block">
            <Button
              variant={isStateToggle && !offer.enabled ? "primary" : "danger"}
              mr="4"
              onClick={handleDelete}
            >
              {isStateToggle
                ? offer?.enabled
                  ? "Disable"
                  : "Enable"
                : "Delete"}
            </Button>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
