import axios from "axios";
import { message } from "antd";
import {
  ELIGIBILITY_LOADING_TRUE,
  ELIGIBILITY_LOADING_FALSE,
  GET_ELIGIBILITY_ACTION_SUCCESS,
  ERROR_401_ACTION,
  GET_ELIGIBLE_USERS_SUCCESS,
  GET_PAYING_EMPLOYERS_SUCCESS,
} from "./actionTypes";
import { IEligibilityStatus, IEligibleUsersView } from "types";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getEligibility =
  (xJwt: any, tab: IEligibilityStatus["tab"]) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: ELIGIBILITY_LOADING_TRUE,
      });
      const filters = {
        all: `${baseUrl}/phoneEligibilitys?sort=-updatedAt`,
        pending: `${baseUrl}/phoneEligibilitys?status=pending&sort=-updatedAt&limit=9999`,
        processing: `${baseUrl}/phoneEligibilitys?status=processing&sort=-updatedAt&limit=9999`,
        completed: `${baseUrl}/phoneEligibilitys?status=completed&sort=-updatedAt&populate=eligibility user&limit=9999`,
        failed: `${baseUrl}/phoneEligibilitys?status=failed&sort=-updatedAt&limit=9999`,
      };
      const res = await axios.get(filters[tab], config);
      dispatch({
        type: GET_ELIGIBILITY_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: ELIGIBILITY_LOADING_FALSE,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: ELIGIBILITY_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

export const getNextEligibility =
  (xJwt: any, tab: IEligibilityStatus["tab"], lastId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: ELIGIBILITY_LOADING_TRUE,
      });
      const filters = {
        all: `${baseUrl}/phoneEligibilitys?lastId=${lastId}&sort=-updatedAt`,
        pending: `${baseUrl}/phoneEligibilitys?status=pending&lastId=${lastId}&sort=-updatedAt&limit=9999`,
        processing: `${baseUrl}/phoneEligibilitys?status=processing&lastId=${lastId}&sort=-updatedAt&limit=9999`,
        completed: `${baseUrl}/phoneEligibilitys?status=completed&lastId=${lastId}&populate=eligibility user&sort=-updatedAt&limit=9999`,
        failed: `${baseUrl}/phoneEligibilitys?status=failed&lastId=${lastId}&sort=-updatedAt&limit=9999`,
      };
      const res = await axios.get(filters[tab], config);
      dispatch({
        type: GET_ELIGIBILITY_ACTION_SUCCESS,
        payload: res?.data,
      });
      dispatch({
        type: ELIGIBILITY_LOADING_FALSE,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: ELIGIBILITY_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// post numbers
export const uploadEligibility =
  (xJwt: any, phoneNumbers: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    let trimmedNumbers = phoneNumbers?.map((number: string) => {
      number = "" + number;
      return number.trim();
    });
    let body = {
      phoneNumbers: trimmedNumbers,
    };
    try {
      dispatch({
        type: ELIGIBILITY_LOADING_TRUE,
      });
      const res = await axios.post(
        `${baseUrl}/phoneEligibilitys/bulkInsert?x-tag=1`,
        body,
        config
      );
      message.success(res?.data?.data?.message);
      dispatch({
        type: ELIGIBILITY_LOADING_FALSE,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: ELIGIBILITY_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// get eligible users
export const getEligibleUsers =
  (xJwt: any, view: IEligibleUsersView["view"]) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: ELIGIBILITY_LOADING_TRUE,
      });
      const filters = {
        all: `${baseUrl}/eligible`,
        "no-current-active": `${baseUrl}/paid-and-eligible`,
        "no-active": `${baseUrl}/eligible-without-active-loans`,
        "topup-eligible": `${baseUrl}/topUpEligible`,
      };
      const res = await axios.get(filters[view], config);
      dispatch({
        type: GET_ELIGIBLE_USERS_SUCCESS,
        payload: res?.data?.data,
      });
      dispatch({
        type: ELIGIBILITY_LOADING_FALSE,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: ELIGIBILITY_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// get paying employers
export const getPayingEmployers =
  (xJwt: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: ELIGIBILITY_LOADING_TRUE,
      });

      const res = await axios.get(`${baseUrl}/loans/paying-employers`, config);
      dispatch({
        type: GET_PAYING_EMPLOYERS_SUCCESS,
        payload: res?.data?.data?.employers,
      });
      dispatch({
        type: ELIGIBILITY_LOADING_FALSE,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: ELIGIBILITY_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };
