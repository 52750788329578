import React from "react";
import { PartiallyPaidLoansTable } from "components/tables";
import { LoanTabFilters } from "components/common";

export default function PaidLoansTabPane() {
  return (
    <LoanTabFilters
      loanTab="partial"
      loanStatus="closed"
      processStatus="disbursed"
      disbursementStatus="completed"
    >
      <PartiallyPaidLoansTable />
    </LoanTabFilters>
  );
}
