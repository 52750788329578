function showframe() {
  const iFrame = `
  <div
  style="position: fixed; z-index: 50000; top: 0; left: 0; width: 100%; height: 100%; min-height: 100vh; display: none"
  id="veendIframeWrapper"
  >
    <div 
    id="closeVeendIframe"
    style="position: absolute;top: 1.5rem;right: 1.5rem;background-color: #1a1a1a;border-radius: 1.75rem;cursor: pointer;width: 2.25rem;height: 2.25rem;display: flex;align-items: center;justify-content: center;"
    >
      <span
        style="font-size: 1.15rem;font-family: sans-serif;color: #fff;font-weight: 800;"
      >X</span>
    </div>
    <iframe
    frameborder="0"
    width="100%"
    height="100%"
    id="veendLoanAppIframe"
    allowfullscreen
    ></iframe>
  </div>
  `;

  document.body.insertAdjacentHTML("beforeend", iFrame);

  function openVeendLoanApplication(appUrl) {
    let iFrameApp = document.getElementById("veendLoanAppIframe");
    iFrameApp.setAttribute("src", appUrl);
    let iframeWrapper = document.getElementById("veendIframeWrapper");
    iframeWrapper.style.display = "block";
  }

  function closeVeendLoanApplication() {
    let iframeWrapper = document.getElementById("veendIframeWrapper");
    iframeWrapper.style.display = "none";
  }

  const triggerBtn = document.getElementById(
    "open-veend-loan-application-page"
  );
  const refName = triggerBtn?.getAttribute("data-ref");
  const redirectExceptions = process.env.REACT_APP_OLD_LOAN_APPLICATION_EXCEPTIONS || ""
  const baseURL = redirectExceptions.split(",")?.includes(refName) ? process.env.REACT_APP_OLD_LOAN_APPLICATION_URL : process.env.REACT_APP_LOAN_APPLICATION_URL;
  const url = `${baseURL}/${refName}`;
  triggerBtn?.addEventListener(
    "click",
    openVeendLoanApplication.bind(null, url)
  );

  const closeIframe = document.getElementById("closeVeendIframe");
  closeIframe.addEventListener("click", closeVeendLoanApplication);
}

export default showframe;
