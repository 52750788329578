import React from "react";
import { Modal } from "antd";
import { Heading, Button, Box } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import {
  deleteLoanProduct,
  getLoanProducts,
} from "redux/actions/settingsActions";

interface IConfirmDeleteLoanproductProps {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
  product?: any | undefined;
}

export default function ConfirmDeleteLoanProduct({
  showModal,
  setShowModal,
  product,
}: IConfirmDeleteLoanproductProps) {
  const dispatch = useDispatch();
  const xJwt = localStorage.getItem("x_jwt");
  const productId = product;

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleClose = () => {
    dispatch(getLoanProducts(xJwt));
  };

  const handleDelete = () => {
    dispatch(deleteLoanProduct(xJwt, productId));
    setShowModal(false);
  };

  return (
    <>
      <Modal
        visible={showModal}
        onCancel={handleCancel}
        footer={null}
        width="40%"
        className="loan-config-modal"
        afterClose={handleClose}
      >
        <Box textAlign="center">
          <Heading as="h2" fontSize="sm" mb="4">
            Are you sure you want to delete this loan product?
          </Heading>
          <Box d="block">
            <Button variant="danger" mr="4" onClick={handleDelete}>
              Delete
            </Button>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
