import React from "react";
import { PaidLoansTable } from "components/tables";
import { LoanTabFilters } from "components/common";

export default function PaidLoansTabPane() {
  return (
    <LoanTabFilters
      loanTab="paid"
      loanStatus="closed"
      processStatus="disbursed"
      disbursementStatus="completed"
    >
      <PaidLoansTable />
    </LoanTabFilters>
  );
}
