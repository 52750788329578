import React, { useState } from "react";
import { Modal, message, Button } from "antd";
import {
  Heading,
  Text,
  Box,
  Flex,
  Stack,
  Editable,
  EditablePreview,
  EditableInput,
  Button as ButtonChakra,
  ButtonGroup,
  IconButton,
  useEditableControls,
  useDisclosure,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";

import { useDispatch } from "react-redux";
import { IUploadedContacts } from "redux/reducers/smsAutomationReducer";
import { DeleteIcon } from "@chakra-ui/icons";
import { AiFillSave } from "react-icons/ai";
import {
  deletePhoneNumber,
  editPhoneNumber,
} from "redux/actions/smsAutomationActions";

interface IEditPhoneNumbersModal {
  isOpen: boolean;
  onClose: () => void;
  uploadedContacts: any[];
  pageNumber: number;
}

export default function EditPhoneNumbersModal({
  isOpen,
  onClose,
  uploadedContacts,
  pageNumber,
}: IEditPhoneNumbersModal) {
  return (
    <Modal
      visible={isOpen}
      onCancel={() => onClose()}
      footer={null}
      width="500px"
      className="staged-phone-numbers-modal"
    >
      <Box>
        <Heading as="h2" mb="8" fontSize={{ base: "md", md: "lg", lg: "xl" }}>
          Edit Phone numbers
        </Heading>
      </Box>

      <Text align="right" mb="2" color="#656566" fontWeight="500">
        {uploadedContacts.length} phone number
        {uploadedContacts.length > 1 ? "s" : ""}
      </Text>

      <Stack
        spacing="6"
        p="4"
        border="1px dashed #DCDBEA"
        borderRadius="8px"
        overflow="auto"
      >
        <Heading as="h5" fontSize="md">
          Phone numbers
        </Heading>

        <Stack spacing="4">
          {uploadedContacts?.map(item => (
            <EditPhoneNumberField
              key={item._id}
              phoneNumber={item}
              pageNumber={pageNumber}
            />
          ))}
        </Stack>
      </Stack>
    </Modal>
  );
}

interface IEditPhoneNumberField {
  phoneNumber: IUploadedContacts;
  pageNumber: number;
}

function EditPhoneNumberField({
  phoneNumber,
  pageNumber,
}: IEditPhoneNumberField) {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const dispatch = useDispatch();
  const xJwt = localStorage.getItem("x_jwt");

  function saveChanges(number: React.FormEvent<HTMLDivElement>) {
    if (phoneNumber.number === String(number)) return;

    if (!number) {
      message.info("Cannot save empty field, delete instead");
    } else {
      setIsSaving(true);
      dispatch(
        editPhoneNumber(
          xJwt,
          phoneNumber._id,
          { number },
          pageNumber,
          dispatch,
          () => setIsSaving(false)
        )
      );
    }
  }

  function deleteNumber() {
    setIsDeleting(true);

    dispatch(
      deletePhoneNumber(xJwt, phoneNumber._id, pageNumber, dispatch, () =>
        setIsDeleting(false)
      )
    );
  }

  return (
    <Flex
      gap={2}
      as={Editable}
      defaultValue={phoneNumber.number}
      variant="solid"
      onSubmit={values => saveChanges(values)}
    >
      <Popover isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <EditablePreview flex={1} borderWidth={1} ps={2} />
        </PopoverTrigger>

        <PopoverContent>
          <PopoverHeader fontWeight="semibold">Confirmation</PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            Are you sure you want to delete this number?
          </PopoverBody>
          <PopoverFooter display="flex" justifyContent="flex-end">
            <ButtonGroup size="sm">
              <Button type="ghost" onClick={onClose}>
                Cancel
              </Button>
              <ButtonChakra
                colorScheme="red"
                // bgColor={"#e34141"}
                bgColor={"#FF8484"}
                _hover={{ backgroundColor: "#e34141" }}
                onClick={() => deleteNumber()}
                isLoading={isDeleting}
              >
                Delete
              </ButtonChakra>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Popover>

      <EditableInput flex={1} borderWidth={1} ps={2} />

      <EditableControls
        isSaving={isSaving}
        setIsSaving={setIsSaving}
        onToggle={onToggle}
      />
    </Flex>
  );
}

function EditableControls({
  isSaving,
  setIsSaving,
  onToggle,
}: {
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
  onToggle: () => void;
}) {
  const { getSubmitButtonProps } = useEditableControls();

  return (
    <ButtonGroup justifyContent="center" size="sm">
      <IconButton
        bgColor={"#FF8484"}
        _hover={{ backgroundColor: "#e34141" }}
        icon={<DeleteIcon />}
        aria-label="button"
        onClick={onToggle}
      />

      <IconButton
        icon={<AiFillSave />}
        aria-label="button"
        isLoading={isSaving}
        onClick={() => setIsSaving(true)}
        {...getSubmitButtonProps()}
      />
    </ButtonGroup>
  );
}
