import React from "react";
import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Spinner, Text, Button, Box } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { InputField, SelectInput } from "components/common";
import { IOptions, RootState } from "types";
import _ from "lodash";
import {
  updateUserSettings,
  verifyAccountNumber,
} from "redux/actions/settingsActions";
import { banksList } from "../../constants";
import { isReadOnly } from "helpers/utilFunctions";
interface Values {
  account: string;
  bank: IOptions;
}
const AccountDetails = () => {
  const dispatch = useDispatch();
  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const userSetting = settingsState?.settingData?.data;
  const verifyState = settingsState?.accountName?.data?.account_name;
  const requestLoading = settingsState?.requestLoading;
  const verifyLoading = settingsState?.verifyLoading;
  const accountID = userSetting?.apiCredentials?.id;
  const accountNumber = userSetting?.bankDetails?.accountNumber;
  const bankName = userSetting?.bankDetails?.bankName;
  const bankCode = userSetting?.bankDetails?.bankCode;
  const populateBanksArray = () => {
    let options = [];
    for (const bankCode in banksList) {
      const selectObj = {
        value: `${bankCode}`,
        label: `${banksList[bankCode]}`,
      };
      options.push(selectObj);
    }
    return options;
  };
  const banks_list = populateBanksArray();
  const readonly = isReadOnly();
  const txJwt = localStorage.getItem("tx_jwt");
  const xJwt = localStorage.getItem("x_jwt");

  const verifyAccount = (values: Values) => {
    if (values.account !== "" && values.bank.value !== "") {
      let accountData = {
        account_number: values?.account,
        bank_code: values?.bank?.value,
      };
      dispatch(verifyAccountNumber(txJwt, accountData, accountID));
    }
  };

  return (
    <Box p="4" className="webhook-tabpane">
      <Row style={{ width: "100%" }}>
        <Col lg={{ span: 16 }} sm={{ span: 20 }}>
          <Formik
            initialValues={{
              account: accountNumber !== undefined ? accountNumber : "",
              bank: {
                value: bankCode !== undefined ? bankCode : "",
                label: bankName !== undefined ? bankName : "",
              },
            }}
            onSubmit={(values: Values) => {
              let updates = {
                bankName: values.bank.label,
                bankCode: values.bank.value,
                accountNumber: values.account,
                accountName: verifyState,
              };
              dispatch(updateUserSettings(xJwt, "BankDetails", updates));
            }}
            validationSchema={yup.object().shape({
              account: yup
                .string()
                .max(10, "Number cannot be more than 10 digits")
                .required("Please provide your account number"),
              bank: yup.object({
                value: yup.string().required("Please select a bank"),
              }),
            })}
          >
            {({ values, handleChange, handleBlur, errors, setFieldValue }) => (
              <Form>
                <InputField
                  inputType="text"
                  name="account"
                  hasLabel
                  label="Account Number"
                  placeholder="Enter Account Number"
                  value={values.account}
                  onBlur={() => handleBlur}
                  onChange={handleChange}
                  error={errors.account}
                  isDisabled={readonly}
                />
                <SelectInput
                  placeholder="Select bank"
                  id="bank"
                  hasLabel
                  label="Select Bank Account"
                  error={errors.bank?.value}
                  options={banks_list}
                  onChange={setFieldValue}
                  value={values.bank}
                  isDisabled={readonly}
                />

                <Box
                  d={readonly ? "none" : "flex"}
                  justifyContent="space-between"
                >
                  <Text
                    fontSize={{
                      base: "xs-small",
                      md: "xs",
                      lg: "sm",
                    }}
                    align="left"
                  >
                    {`${_.isEmpty(verifyState) ? "" : verifyState}`}
                  </Text>
                  <Text
                    color="primaryBlue"
                    fontSize={{
                      base: "xs-small",
                      md: "xs",
                      lg: "sm",
                    }}
                    align="right"
                    fontWeight="semibold"
                    cursor="pointer"
                    onClick={() => verifyAccount(values)}
                  >
                    {verifyLoading && (
                      <Spinner colorScheme="primaryBlue" size="xs" mr="2" />
                    )}
                    Verify account
                  </Text>
                </Box>
                <Button
                  type="submit"
                  variant="primary"
                  size="md"
                  mt="4"
                  disabled={_.isEmpty(verifyState) ? true : false}
                  isLoading={requestLoading}
                >
                  Submit Details
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Box>
  );
};

export default AccountDetails;
