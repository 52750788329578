import React from "react";
import { Spinner } from "@chakra-ui/react";

export default function MainLoader() {
  return (
    <div className="loading-cont">
      <Spinner
        size="xl"
        style={{ width: "7.5rem", height: "7.5rem", borderWidth: "0.4rem" }}
        color="primaryYellow"
      />
    </div>
  );
}
