import React, { useEffect } from "react";
import { Box, Button, Grid, Link } from "@chakra-ui/react";
import "./styles/loans.css";
import LoansTab from "../../components/tabs/LoansTab";
import {
  LoanApprovedIcon,
  LoanIcon,
  LoanNairaIcon,
  LoanPendingIcon,
} from "components/common/CustomIcons";
import { CalculatorFilled } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { getLoanData } from "../../redux/actions/loanActions";
import { MainLoader, DashboardHeader, LoanStatCard } from "components/common";
import showIframe from "helpers/showframe";
import { loansPageSteps } from "../../constants/tourSteps";
import { RootState } from "types";
import { isReadOnly } from "helpers/utilFunctions";
import { showLendingLicenseModal } from "redux/actions/lendingLicenseActions";

export default function Loans() {
  const data = useSelector((state: RootState) => state.loanReducer);
  const { isLoading, settingData } = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const loanApplicationLink = localStorage.getItem("loan-application-link");
  const dispatch = useDispatch();
  const loanData = data?.loanData;
  const loading = data?.isLoading;
  const readonly = isReadOnly();
  const lenderName = loanApplicationLink?.split("/")[3];

  const xTag = localStorage.getItem("x_jwt");

  useEffect(() => {
    showIframe();
    if (xTag === null) {
      window.location.href = "/login";
    } else {
      dispatch(getLoanData(xTag));
    }
  }, [dispatch, xTag]);

  return (
    <>
      {loading && <MainLoader />}

      <Box px="4" py="5">
        <DashboardHeader
          title="Loans"
          description="View and manage all loans"
          tourSteps={loansPageSteps}
          actionButtons={
            <>
              {!isLoading && !settingData?.data?.lendingLicenseUrl ? (
                <Button
                  disabled={readonly}
                  onClick={() => {
                    dispatch(showLendingLicenseModal(true));
                  }}
                  mr="4"
                >
                  Create Loan
                </Button>
              ) : (
                <Link
                  mr="4"
                  data-ref={`create-loan/${lenderName}`}
                  id={"open-veend-loan-application-page"}
                >
                  <Button disabled={readonly}>Create Loan</Button>
                </Link>
              )}
            </>
          }
        />
        {/* Loan cards */}
        <Grid
          my="12"
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(3, 1fr)",
            "repeat(4, 1fr)",
            "repeat(5, 1fr)",
          ]}
          gap={["4", "4", "4", "24px 48px", "12"]}
          className="loan-card-container"
        >
          <LoanStatCard
            label="Total Loans"
            value={loanData?.data?.counts?.totalLoanCount}
            icon={<CalculatorFilled className="loan-card-icon" />}
          />
          <LoanStatCard
            label="New Loans"
            value={loanData?.data?.counts?.todaysLoan}
            icon={<LoanIcon className="loan-card-icon" />}
          />
          <LoanStatCard
            label="Active Loans"
            value={loanData?.data?.counts?.activeLoans}
            icon={<LoanApprovedIcon className="loan-card-icon" />}
          />
          <LoanStatCard
            label="Loans Pending Disbursement"
            value={loanData?.data?.counts?.totalPending}
            icon={<LoanPendingIcon className="loan-card-icon" />}
          />
          <LoanStatCard
            label="Paid Loans"
            value={loanData?.data?.counts?.paidLoans}
            icon={<LoanNairaIcon className="loan-card-icon" />}
          />
        </Grid>
        {/* Loan Tabs */}
        <LoansTab />
      </Box>
    </>
  );
}
