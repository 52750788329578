import React from "react";
import ReactJoyride, { CallBackProps, STATUS, Step } from "react-joyride";

interface IOnboardingTourguide {
  steps: Step[];
  setRun: (bool: boolean) => void;
  run: boolean;
}

export default function OnboardingTourguide({
  steps,
  run,
  setRun,
}: IOnboardingTourguide) {
  const callback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
  };

  return (
    <ReactJoyride
      disableScrollParentFix={true}
      continuous={true}
      callback={callback}
      showSkipButton={true}
      run={run}
      scrollToFirstStep={true}
      disableOverlayClose={true}
      scrollOffset={72}
      styles={{
        options: {
          zIndex: 5000,
        },
        buttonNext: {
          backgroundColor: "#060088",
          fontWeight: "bold",
        },
        buttonBack: {
          color: "#060088",
        },
        beaconInner: {
          backgroundColor: "#060088",
        },
      }}
      steps={steps}
    />
  );
}
