/* eslint-disable no-unused-vars */
import React from "react";
import Select from "react-select";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Box, Text } from "@chakra-ui/layout";
import { Option } from "types";

interface SelectFieldProps {
  onChange: (id: string, selectedVal: string | Option) => void;
  placeholder?: string;
  error: any;
  hasLabel?: boolean;
  label?: string;
  showSearch?: boolean;
  options: IOptions[];
  value: any;
  id: string;
  clearable?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  helperText?: React.ReactNode;
}

interface IOptions {
  value: string;
  label: string;
}

export default function SelectInput({
  options,
  placeholder,
  error,
  onChange,
  hasLabel,
  label,
  showSearch,
  value,
  id,
  clearable,
  isDisabled,
  isMulti,
  helperText,
}: SelectFieldProps) {
  const handleChange = (selectedValue: any) => {
    onChange(id, selectedValue);
  };
  return (
    <>
      <Box my="4">
        {hasLabel && (
          <Box
            d="flex"
            mb="1"
            className="label-div"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Text className="form-label">{label}</Text>
          </Box>
        )}
        <Select
          id={id}
          placeholder={placeholder}
          className="my-select"
          onChange={handleChange}
          options={options}
          my="2"
          value={value}
          isSearchable={showSearch}
          isClearable={clearable}
          isDisabled={isDisabled}
          isMulti={isMulti}
        />
        {error && (
          <Box className="error-div">
            <ExclamationCircleFilled className="error-icon" />
            <Text ml="2" className="error-text">
              {error}
            </Text>
          </Box>
        )}
        {helperText && <Box>{helperText}</Box>}
      </Box>
    </>
  );
}
