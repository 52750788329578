import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { getExpectedChart } from "redux/actions/dashboardAction";
import { getChartDataset } from "helpers/utilFunctions";
import { RootState } from "types";

export default function RepaymentBarChart() {
  const expectedChart = useSelector(
    (state: RootState) => state.dashboardReducer
  );
  const chart = expectedChart?.expectedChartData?.data;
  const dispatch = useDispatch();
  const xTag = localStorage.getItem("x_jwt");
  useEffect(() => {
    dispatch(getExpectedChart(xTag, 1));
  }, [dispatch, xTag]);
  const chartLabels = chart?.labels;
  const datasets = chart?.datasets;
  const datasetLabel = chart?.datasets?.[0]?.label;
  const datasetLabel2 = chart?.datasets?.[1]?.label;

  // loop through chart Labels
  let data = {
    // x-axis labels
    labels: [
      chartLabels?.[0],
      chartLabels?.[1],
      chartLabels?.[2],
      chartLabels?.[3],
      chartLabels?.[4],
      chartLabels?.[5],
      chartLabels?.[6],
      chartLabels?.[7],
      chartLabels?.[8],
      chartLabels?.[9],
      chartLabels?.[10],
      chartLabels?.[11],
    ],
    // y-axis datasets
    datasets: [
      {
        label: datasetLabel,
        data: getChartDataset(datasets, "Actual Repayment", "repayment"),
        backgroundColor: "#F9AC22",
        hoverOffset: 3,
      },
      {
        label: datasetLabel2,
        data: getChartDataset(
          datasets,
          "Expected Repayment",
          "totalExpectedRepayment"
        ),
        backgroundColor: "#060088",
        hoverOffset: 3,
      },
    ],
  };
  let options = {
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          padding: 20,
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          // stepSize: 1000,
          font: {
            weight: "bold",
          },
        },
        labels: {
          font: {
            size: 18,
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            weight: "bold",
          },
        },
      },
    },
  };

  return <Bar data={data} width={400} height={100} options={options} />;
}
