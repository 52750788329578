import React, { useCallback, useEffect, useState } from "react";
import { Button, Text, Box, HStack } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { RewardIcon } from "components/common/CustomIcons";
import { getBalance, getOperation } from "../../redux/actions/dashboardAction";
import {
  formatNumberAmount,
  isReadOnly,
  userAccess,
} from "helpers/utilFunctions";
import { WithdrawFundsModal } from "../../components/modals";
import { Tabs } from "antd";
import {
  AllTransactionsTabPane,
  RemitaTransactionsTabPane,
  UssdTransactionsTabPane,
  SmsTransactionsTabPane,
  WithdrawalTransactionsTabPane,
  DisbursementTransactionsTabPane,
} from "../../components/tabs";
import { BalanceCard, DashboardHeader } from "../../components/common";
import { balancePageSteps } from "../../constants/tourSteps";
import "./styles/balance.css";
import { RootState } from "types";

const { TabPane } = Tabs;

function Balance() {
  const dispatch = useDispatch();
  const balance = useSelector((state: RootState) => state.dashboardReducer);
  const settings = useSelector((state: RootState) => state.settingsReducer);
  const userSetting = settings?.settingData?.data;
  const balanceData = balance?.balanceData?.data;
  const accountID = userSetting?.apiCredentials?.id;
  const accountNumber = userSetting?.bankDetails?.accountNumber;
  const bankCode = userSetting?.bankDetails?.bankCode;
  const bankName = userSetting?.bankDetails?.bankName;
  const accountName = userSetting?.bankDetails?.accountName;
  const [showModal, setShowModal] = useState(false);
  const xTag = localStorage.getItem("x_jwt");
  const readonly = isReadOnly();

  const data = useSelector((state: RootState) => state.dashboardReducer);
  const operation = data?.operationData?.data;

  const openModal = () => {
    setShowModal(true);
  };

  const onModalClose = () => {
    dispatch(getBalance(xTag));
  };

  const formattedBalance =
    balanceData?.balance !== undefined
      ? formatNumberAmount(balanceData?.balance)
      : 0;

  useEffect(() => {
    if (xTag === null) {
      window.location.href = "/login";
    } else {
      dispatch(getBalance(xTag));
      dispatch(getOperation(xTag));
    }
  }, [dispatch, xTag]);

  const canViewBalances = useCallback(
    (val?: string | number, returnVal?: boolean) => {
      let view = false;
      if (operation && operation.link) {
        let linksArr = operation.link.split("/");
        if (userAccess() === "full" && linksArr.includes(
          process.env.REACT_APP_MASK_LENDER_READ_ONLY || "dummycov2"
        )) {
          view = true;
        }
      }

      if (returnVal) {
        return view ? (val as string) : "****";
      }

      return view;
    },
    [operation]
  );

  return (
    <>
      <Box px="4" py="5">
        <DashboardHeader
          title="Balance"
          description="Let's see how well you're doing"
          tourSteps={balancePageSteps}
        />

        {/* Row start */}
        <HStack my="4" py="3" spacing="0">
          {/* Balance Box Start */}
          <BalanceCard
            bgColor="#002e2c"
            balance={canViewBalances(formattedBalance, true) as string}
            account={balanceData?.account}
          >
            <Button
              variant="outline"
              onClick={openModal}
              className="withdraw-funds-btn"
              disabled={readonly}
            >
              Withdraw Funds
            </Button>
          </BalanceCard>

          {/* Balance Box End */}
          {/* Reward Box Start */}
          <Box d="none" w="35%" ml="5">
            <Box className="reward-box">
              <RewardIcon
                style={{
                  color: "var(--brand-blue)",
                  width: "49px",
                  height: "54px",
                }}
              />
              <Text my="3">Earn Rewards on your ideal balance</Text>
              <Button variant="secondary">Enable Rewards</Button>
            </Box>
          </Box>
          {/* Reward Box End */}
        </HStack>
        {/* Transactions tabs start */}
        <Tabs defaultActiveKey="1" className="transactions-tabs">
          <TabPane
            tab={
              <Text as="span" className="disbursements-tabkey">
                Disbursements
              </Text>
            }
            key="1"
          >
            <DisbursementTransactionsTabPane />
          </TabPane>
          <TabPane
            tab={
              <Text as="span" className="remita-tabkey">
                REMITA Charges
              </Text>
            }
            key="2"
          >
            <RemitaTransactionsTabPane />
          </TabPane>
          <TabPane
            tab={
              <Text as="span" className="ussd-tabkey">
                USSD Charges
              </Text>
            }
            key="3"
          >
            <UssdTransactionsTabPane />
          </TabPane>
          <TabPane
            tab={
              <Text as="span" className="sms-tabkey">
                SMS Charges
              </Text>
            }
            key="4"
          >
            <SmsTransactionsTabPane />
          </TabPane>
          {canViewBalances() ? (
            <TabPane
              tab={
                <Text as="span" className="withdrawals-tabkey">
                  Withdrawals
                </Text>
              }
              key="5"
            >
              <WithdrawalTransactionsTabPane />
            </TabPane>
          ) : null}
          {canViewBalances() ? (
            <TabPane
              tab={
                <Text as="span" className="statement-tabkey">
                  Account Statement
                </Text>
              }
              key="6"
            >
              <AllTransactionsTabPane />
            </TabPane>
          ) : null}
        </Tabs>
        {/* Transactions tabs end */}
        {/* Row end */}
      </Box>
      <WithdrawFundsModal
        showModal={showModal}
        setShowModal={setShowModal}
        balance={balanceData?.balance}
        accountId={accountID}
        bankCode={bankCode}
        accountNumber={accountNumber}
        bankName={bankName}
        nameOnAccount={accountName}
        afterClose={onModalClose}
      />
    </>
  );
}

export default Balance;
