import { AllBorrowersResponse } from "types/responses";
import {
  BORROWERS_DETAILS_ACTION_FAILURE,
  BORROWERS_DETAILS_ACTION_SUCCESS,
  BORROWERS_TABLE_ACTION_FAILURE,
  BORROWERS_TABLE_ACTION_SUCCESS,
  CREATE_BORROWER_ACTION_SUCCESS,
  CREATE_BORROWER_ACTION_FAILURE,
  PREAPPROVE_BORROWER_ACTION_SUCCESS,
  PREAPPROVE_BORROWER_ACTION_FAILURE,
  BORROWER_REQUEST_LOADING_TRUE,
  BORROWER_REQUEST_LOADING_FALSE,
  DOWNLOAD_BORROWERS_ACTION_SUCCESS,
  DOWNLOAD_BORROWERS_ACTION_FAILURE,
  BORROWER_PROFILE_LOADING_TRUE,
} from "../actions/actionTypes";

export interface BorrowerState {
  isLoading: boolean;
  borrowerData: AllBorrowersResponse;
  borrowerDetailsData: AllBorrowersResponse;
  newBorrowerData: {};
  requestLoading: boolean;
  borrowerProfileLoading: boolean;
  preApproveData: {};
  borrowersDownloadList: [];
}

const initialState: BorrowerState = {
  isLoading: true,
  borrowerData: {} as AllBorrowersResponse,
  borrowerDetailsData: {} as AllBorrowersResponse,
  newBorrowerData: {},
  requestLoading: false,
  borrowerProfileLoading: false,
  preApproveData: {},
  borrowersDownloadList: [],
};

function borrowerReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case BORROWERS_TABLE_ACTION_SUCCESS:
      return {
        ...state,
        borrowerData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case BORROWERS_TABLE_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestLoading: false,
      };
    case BORROWERS_DETAILS_ACTION_SUCCESS:
      return {
        ...state,
        borrowerDetailsData: payload,
        borrowerProfileLoading: false,
        requestLoading: false,
      };
    case BORROWERS_DETAILS_ACTION_FAILURE:
      return {
        ...state,
        borrowerProfileLoading: false,
        requestLoading: false,
      };
    case CREATE_BORROWER_ACTION_SUCCESS:
      return {
        ...state,
        newBorrowerData: payload,
        isLoading: false,
      };
    case CREATE_BORROWER_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case PREAPPROVE_BORROWER_ACTION_SUCCESS:
      return {
        ...state,
        preApproveData: payload,
        isLoading: false,
        requestLoading: false,
      };
    case PREAPPROVE_BORROWER_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestLoading: false,
      };
    case DOWNLOAD_BORROWERS_ACTION_SUCCESS:
      return {
        ...state,
        borrowersDownloadList: payload,
        requestLoading: false,
      };
    case DOWNLOAD_BORROWERS_ACTION_FAILURE:
      return {
        ...state,
        requestLoading: false,
      };
    case BORROWER_REQUEST_LOADING_TRUE:
      return {
        ...state,
        requestLoading: true,
      };
    case BORROWER_PROFILE_LOADING_TRUE:
      return {
        ...state,
        borrowerProfileLoading: true,
      };
    case BORROWER_REQUEST_LOADING_FALSE:
      return {
        ...state,
        requestLoading: false,
      };
    default:
      return state;
  }
}

export default borrowerReducer;
