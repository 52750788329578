import { useState } from "react";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoginImg from "../../assets/veend-logo.png";
import { InputField } from "components/common";
import { TWO_FA_NEEDED_FOR_LOGIN } from "redux/actions/actionTypes";
import { Modal } from "antd";
import { recoverTwoFA } from "redux/actions/authActions";
import { RootState } from "types";
import { Recover2FACode } from "components/security";

type Values = {
  email: string;
  password: string;
  token: string;
};

const ResetTwoFa = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.authReducer.isLoading
  );
  const [startRecoveryProcess, setStartRecoveryProcess] = useState(false);
  const { handleChange, handleSubmit, values, errors, touched, handleBlur } =
    useFormik({
      initialValues: { email: "", password: "", token: "" },
      validationSchema: yup.object().shape({
        token: yup.string().required("Enter your reset token"),
        email: yup.string().required("Enter your email"),
        password: yup.string().required("Enter your password"),
      }),
      onSubmit: values => {
        verifyToken(values);
      },
    });

  const verifyToken = async (values: Values) => {
    dispatch(
      recoverTwoFA(values.email, values.password, values.token, () => {
        setStartRecoveryProcess(true);
      })
    );
  };

  return (
    <>
      <Container maxW={"container.xl"}>
        <Flex py={8} minH="100vh" flexDir="column" justifyContent="center">
          <Box display={"flex"} justifyContent="center" alignItems={"center"}>
            <Link to="/login">
              <Image src={LoginImg} alt="analysis" width={"200px"} />
            </Link>
          </Box>

          <Flex flexDirection="column" justifyContent="center" mt={5}>
            <Box
              w="full"
              maxW={400}
              mx="auto"
              p="20px 40px"
              bgColor="white"
              borderRadius={"20px"}
              boxShadow={"0px 0px 20px 0px #0600890d"}
            >
              <Box
                mb="8"
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <Text
                  mt="4"
                  mb="2"
                  fontWeight="600"
                  fontSize="20px"
                  color="neutral.500"
                >
                  Recover Your 2FA Code
                </Text>
                <Text
                  my="2"
                  fontSize="small"
                  color="neutral.400"
                  textAlign="center"
                  lineHeight={"16px"}
                  fontWeight="semibold"
                >
                  Enter your email address, password and your recovery code to
                  reset your 2FA code.
                </Text>
              </Box>

              <form onSubmit={handleSubmit}>
                <InputField
                  inputType="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={() => handleBlur}
                  placeholder="Enter Email Address"
                  error={touched?.email && errors.email}
                />
                <InputField
                  name="password"
                  inputType="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={() => handleBlur}
                  placeholder="Enter Password"
                  error={touched?.password && errors.password}
                />
                <InputField
                  inputType="text"
                  name="token"
                  value={values.token}
                  onChange={handleChange}
                  onBlur={() => handleBlur}
                  placeholder="Enter Recovery Code"
                  error={touched?.token && errors.token}
                />

                <Flex mt={10} flexDirection={"column"} mb={5}>
                  <Button type="submit" isLoading={isLoading}>
                    Reset Code
                  </Button>

                  <Center my="4">
                    <Link to="/login">
                      <Text
                        as="a"
                        color="primary.500"
                        fontSize="small"
                        textAlign="center"
                        _hover={{
                          color: "primary.500",
                          textDecoration: "underline",
                        }}
                        onClick={() =>
                          dispatch({
                            type: TWO_FA_NEEDED_FOR_LOGIN,
                            payload: false,
                          })
                        }
                      >
                        Never mind! Take me back to Sign in
                      </Text>
                    </Link>
                  </Center>
                </Flex>
              </form>
            </Box>
          </Flex>
        </Flex>
      </Container>
      <Modal
        footer={null}
        visible={startRecoveryProcess}
        onCancel={() => setStartRecoveryProcess(false)}
      >
        <Recover2FACode />
      </Modal>
    </>
  );
};

export default ResetTwoFa;
