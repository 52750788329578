import React from "react";
import { Modal } from "antd";
import { Button, Heading, Text, Box } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { InputField, TextareaInput } from "components/common";
import { useDispatch } from "react-redux";
import { postLoanAction } from "redux/actions/loanActions";
import { formatNumberAmount } from "helpers/utilFunctions";

interface IRepaymentModalProps {
  showModal: boolean;
  loanRecord: any;
  setShowModal: (bool: boolean) => void;
  afterClose?: () => void;
}

interface RepaymentFormValues {
  amount: string;
  comment: string;
}

export default function RepaymentModal({
  showModal,
  setShowModal,
  loanRecord,
  afterClose,
}: IRepaymentModalProps) {
  const dispatch = useDispatch();
  const handleCancel = () => {
    setShowModal(false);
  };

  const lenderNumber = loanRecord?.user?.phoneNumber;
  const repayment = loanRecord?.repaymentAmount;
  const totalOutstanding = loanRecord?.totalOutstanding;
  const loanId = loanRecord?.loanId;
  const _id = loanRecord?._id;
  const tenure = loanRecord?.tenure;
  const clientId = loanRecord?.user?.clientId;
  const xTag = localStorage.getItem("x_jwt");

  return (
    <Modal
      visible={showModal}
      onCancel={handleCancel}
      footer={null}
      width="50%"
      className="loan-config-modal"
      afterClose={afterClose}
    >
      <Heading as="h2" mb="3" fontSize={{ base: "xs", md: "sm", lg: "md" }}>
        Make Repayment
      </Heading>
      <Text
        fontSize={{ base: "xx-small", md: "xs" }}
      >{`Borrower number - ${lenderNumber}`}</Text>
      <Text
        fontSize={{ base: "xx-small", md: "xs" }}
      >{`Next repayment - ${formatNumberAmount(repayment)}`}</Text>
      <Text fontSize={{ base: "xx-small", md: "xs" }}>{`Tenure - ${tenure} ${
        tenure > 1 ? "Months" : "Month"
      }`}</Text>
      <Text
        fontSize={{ base: "xx-small", md: "xs" }}
      >{`Total outstanding - ${formatNumberAmount(totalOutstanding)}`}</Text>
      <Text
        fontSize={{ base: "xx-small", md: "xs" }}
      >{`Loan Id - ${loanId}`}</Text>
      <Formik
        initialValues={{
          amount: "",
          comment: "",
        }}
        onSubmit={(values: RepaymentFormValues, { resetForm }) => {
          let repaymentActionData = {
            action: "makeRepayment",
            clientId: clientId,
            loanId: loanId,
            amount: values.amount,
            note: values.comment,
          };
          dispatch(postLoanAction(xTag, repaymentActionData, _id, dispatch));
          setShowModal(false);
          resetForm();
        }}
        validationSchema={yup.object().shape({
          amount: yup
            .number()
            .typeError("Please enter a valid number")
            .required("Please enter amount you want to repay")
            .max(
              totalOutstanding,
              `Maximum repayment on this loan is ${totalOutstanding}`
            )
            .min(1),
          comment: yup
            .string()
            .required("Please provide a comment to process repayment"),
        })}
      >
        {({ values, handleChange, handleBlur, errors }) => (
          <Box as={Form} textAlign="center">
            <InputField
              inputType="tel"
              name="amount"
              hasLabel
              label="Repayment Amount"
              placeholder="Enter Repayment Amount"
              value={values.amount}
              onBlur={() => handleBlur}
              onChange={handleChange}
              error={errors.amount}
              required
            />
            <TextareaInput
              name="comment"
              placeholder="Add Comment Here"
              hasLabel
              label="Additional Comment"
              value={values.comment}
              onBlur={() => handleBlur}
              onChange={handleChange}
              error={errors.comment}
              required
            />

            <Button type="submit" mt="3">
              Repay
            </Button>
          </Box>
        )}
      </Formik>
    </Modal>
  );
}
