import React from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import { loginUserFor2FA } from "redux/actions/authActions";
import { Link } from "react-router-dom";
import InputOTP from "components/common/InputOTP";

type Props = {
  loginDetails: {
    email: string;
    password: string;
  };
};

const EnterTwoFALoginDetails = ({
  loginDetails: { email, password },
}: Props) => {
  const dispatch = useDispatch();
  const [token, setToken] = React.useState("");
  const isLoading = useSelector(
    (state: RootState) => state.authReducer.isLoading
  );

  const startVerification = async () => {
    dispatch(loginUserFor2FA(email, password, token));
  };

  return (
    <Box>
      <Flex
        flexDir="column"
        alignItems="center"
        px={{ base: "0", md: "20px", lg: "40px" }}
      >
        <Text
          mb="16px"
          fontWeight="600"
          textAlign="center"
          color="neutral.500"
          fontSize={["20px", "20px", "24px", "28px"]}
        >
          Enter 2FA code
        </Text>
        <Text type="02" mb="50px" textAlign="center">
          Enter the 6-digit code generated by your Google Authenticator.
        </Text>
        <Box w="100%">
          <InputOTP
            numInputs={6}
            onChange={code => setToken(code)}
            onComplete={() => startVerification()}
          />
        </Box>
      </Flex>

      <Box h="50px" />

      <Flex
        gap={8}
        mb="40px"
        alignItems={"center"}
        px={{ base: "0", md: "20px", lg: "40px" }}
        flexDir={["column-reverse", "column-reverse", "row"]}
        justifyContent={["flex-start", "flex-start", "flex-end"]}
      >
        <Link to={"/recover-twofa"}>
          <Text
            mt="4px"
            as="a"
            fontSize="14px"
            width="fit-content"
            color="primary.500"
            _hover={{
              cursor: "pointer",
              color: "primary.500",
              textDecoration: "underline",
            }}
          >
            Forgot your 2FA code?
          </Text>
        </Link>
        <Button
          variant="solid"
          bgColor={"#060089"}
          color={"white"}
          isLoading={isLoading}
          onClick={startVerification}
          isDisabled={token.length !== 6}
        >
          Verify
        </Button>
      </Flex>
    </Box>
  );
};

export default EnterTwoFALoginDetails;
