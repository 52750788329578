import React, { useState } from "react";
import { Modal, Radio } from "antd";
import { Heading, Button, Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { InputField } from "components/common";
import { useDispatch } from "react-redux";
import {
  createTeamMember,
  updateTeamMember,
} from "redux/actions/settingsActions";
import { TeamMember } from "types";

interface IAddTeamMemberModalProps {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
  teamMember?: TeamMember;
}

interface TeamMemberFormValues {
  email: string;
  readonly: boolean;
}

interface ConfirmActionProps {
  adminPassword: string;
}

export default function AddTeamMemberModal({
  showModal,
  setShowModal,
  teamMember,
}: IAddTeamMemberModalProps) {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [teamMemberValues, setTeamMemberValues] =
    useState<TeamMemberFormValues>({} as TeamMemberFormValues);
  const dispatch = useDispatch();
  const xJwt = localStorage.getItem("x_jwt");

  const handleOk = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal
        visible={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width="80%"
        className="loan-config-modal"
      >
        <Box textAlign="center" key={teamMember?.email}>
          <Heading as="h2" fontSize="md" mb="3">
            {`${teamMember ? "Edit" : "Create"} Team Member`}
          </Heading>
          <Formik
            initialValues={{
              email: teamMember ? teamMember.email : "",
              readonly: teamMember ? teamMember?.readonly : true,
            }}
            onSubmit={(values: TeamMemberFormValues, { resetForm }) => {
              if (teamMember) {
                dispatch(
                  updateTeamMember(
                    xJwt,
                    values,
                    teamMember.giroAccountID,
                    dispatch
                  )
                );
              } else {
                setTeamMemberValues(values);
                setConfirmModalOpen(true);
              }

              resetForm();
              handleCancel();
            }}
            validationSchema={yup.object().shape({
              email: yup
                .string()
                .email()
                .required("Please provide your email address"),
            })}
          >
            {({ values, setFieldValue, handleChange, handleBlur, errors }) => (
              <Form>
                <HStack justify="space-between">
                  <Box className="half-form">
                    <InputField
                      inputType="text"
                      name="email"
                      hasLabel
                      label="Email Address"
                      placeholder="Enter Email Address"
                      value={values.email}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.email}
                      isDisabled={Boolean(teamMember)}
                    />
                  </Box>
                  <Box
                    as={Radio.Group}
                    onChange={(e: any) =>
                      setFieldValue("readonly", e.target.value)
                    }
                    value={values.readonly}
                    className="half-form"
                  >
                    <Box
                      d="flex"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      mb="1"
                      className="label-div"
                    >
                      <Text className="form-label">Permissions</Text>
                    </Box>

                    <VStack align="flex-start" spacing="1" my="2">
                      <Radio value={true}>Read only access</Radio>
                      <Radio value={false}>Read and Write access</Radio>
                    </VStack>
                  </Box>
                </HStack>

                <Button type="submit" mt="3">
                  {teamMember ? "Submit" : "Proceed"}
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>

      <Modal
        visible={confirmModalOpen}
        onCancel={() => setConfirmModalOpen(false)}
        footer={null}
        width="80%"
        className="loan-config-modal"
      >
        <Box textAlign="center">
          <Heading as="h2" fontSize="md" mb="3">
            Confirm Action
          </Heading>

          <Formik
            initialValues={{
              adminPassword: "",
            }}
            onSubmit={(values: ConfirmActionProps, { resetForm }) => {
              dispatch(
                createTeamMember(
                  xJwt,
                  { ...teamMemberValues, ...values },
                  dispatch
                )
              );
              resetForm();
              setTeamMemberValues({} as TeamMemberFormValues);
              setConfirmModalOpen(false);
            }}
            validationSchema={yup.object().shape({
              adminPassword: yup
                .string()
                .required("Please provide the admin password"),
            })}
          >
            {({ values, handleChange, handleBlur, errors }) => (
              <Form>
                <InputField
                  inputType="password"
                  name="adminPassword"
                  hasLabel
                  label="Admin Password"
                  placeholder="Enter admin password"
                  value={values.adminPassword}
                  onBlur={() => handleBlur}
                  onChange={handleChange}
                  error={errors.adminPassword}
                />
                <Button type="submit" mt="3">
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
}
