import React, { useState, useEffect } from "react";
import { Button, Heading, Box } from "@chakra-ui/react";
import { TeamMembersTable } from "components/tables";
import { useSelector, useDispatch } from "react-redux";
import { getTeamMembers } from "redux/actions/settingsActions";
import { Loader } from "components/common";
import { RootState } from "types";
import { AddTeamMemberModal } from "components/modals";
import { isReadOnly } from "helpers/utilFunctions";

export default function TeamMembersTabPane() {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const isLoading = settingsState?.requestLoading;
  const xJwt = localStorage.getItem("x_jwt");
  const readonly = isReadOnly();

  useEffect(() => {
    dispatch(getTeamMembers(xJwt));
  }, [dispatch, xJwt]);

  return (
    <>
      <Button
        d="block"
        mb="3"
        ml="auto"
        mr="1"
        onClick={() => setShowModal(true)}
        disabled={readonly}
      >
        Add Team Member
      </Button>
      <Box className="loan-products-table-box">
        <Box className="settings-table-header">
          <Heading as="h4" fontSize={{ base: "xs", md: "sm" }}>
            Team Members
          </Heading>
        </Box>
        {isLoading ? <Loader size="md" /> : <TeamMembersTable />}
      </Box>
      <AddTeamMemberModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
