import * as yup from "yup";
import validationSchema from "constants/validationSchema";
import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { Heading, Button, Box, HStack } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { InputField, SelectInput } from "components/common";
import { useDispatch, useSelector } from "react-redux";
import { Fee, ILoanProduct, Option, RootState } from "types";
import {
  createLoanProduct,
  getFees,
  getLoanProducts,
  updateLoanProduct,
} from "redux/actions/settingsActions";

interface ILoanProductConfigProps {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
  loanProduct?: ILoanProduct | any;
}

interface LoanProductFormValues {
  productName: string;
  defaultInterestRate: number;
  // maxInterestRate: number;
  minInterestRate: number;
  defaultTenure: number;
  maxTenure: number;
  minTenure: number;
  defaultAmount: number;
  maxAmount: number;
  minAmount: number;
  charges: Option[];
}

export default function LoanProductConfig({
  showModal,
  setShowModal,
  loanProduct,
}: ILoanProductConfigProps) {
  const dispatch = useDispatch();
  const [feeOptions, setFeeOptions] = useState<Option[]>([]);
  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const fees = settingsState?.fees;
  const xJwt = localStorage.getItem("x_jwt");

  useEffect(() => {
    dispatch(getFees(xJwt));
  }, [dispatch, xJwt]);

  useEffect(() => {
    if (fees.length) {
      setFeeOptions(
        fees.map(fee => ({
          value: fee._id,
          label: fee.name,
        }))
      );
    }
  }, [fees]);

  const handleOk = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const afterModalClose = () => {
    dispatch(getLoanProducts(xJwt));
  };

  return (
    <>
      <Modal
        visible={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={afterModalClose}
        footer={null}
        width="90%"
        className="loan-config-modal"
      >
        <Box textAlign="center" key={loanProduct?.name}>
          <Heading as="h2" fontSize="md" mb="3">
            {loanProduct ? "Edit Loan Product" : "Create Loan Product"}
          </Heading>
          <Formik
            initialValues={{
              productName: loanProduct ? loanProduct?.name : "",
              defaultInterestRate: loanProduct
                ? loanProduct?.defaultInterest
                : "",
              // maxInterestRate: loanProduct ? loanProduct?.maximumInterest : "",
              minInterestRate: loanProduct ? loanProduct?.minimumInterest : "",
              defaultTenure: loanProduct ? loanProduct?.defaultTenure : "",
              maxTenure: loanProduct ? loanProduct?.maximumTenure : "",
              minTenure: loanProduct ? loanProduct?.minimumTenure : "",
              defaultAmount: loanProduct ? loanProduct?.defaultAmount : "",
              maxAmount: loanProduct ? loanProduct?.maximumAmount : "",
              minAmount: loanProduct ? loanProduct?.minimumAmount : "",
              charges: loanProduct?.charges
                ? loanProduct.charges.map((id: string) => ({
                    value: id,
                    label: (fees.find(fee => fee._id === id) as Fee)?.name,
                  }))
                : [],
            }}
            onSubmit={(values: LoanProductFormValues, { resetForm }) => {
              let body = {
                name: values.productName,
                defaultAmount: values.defaultAmount,
                minimumAmount: values.minAmount,
                maximumAmount: values.maxAmount,
                defaultTenure: values.defaultTenure,
                minimumTenure: values.minTenure,
                maximumTenure: values.maxTenure,
                defaultInterest: values.defaultInterestRate,
                minimumInterest: values.minInterestRate,
                maximumInterest: values.defaultInterestRate * values.maxTenure,
                charges: values.charges.map(fee => fee.value),
              };

              if (loanProduct) {
                dispatch(updateLoanProduct(xJwt, loanProduct?._id, body));
              } else {
                dispatch(createLoanProduct(xJwt, body));
              }
              resetForm();
              setShowModal(false);
            }}
            validationSchema={yup
              .object()
              .shape(validationSchema.loanProductConfig)}
          >
            {({ values, setFieldValue, handleChange, handleBlur, errors }) => (
              <Form>
                <HStack justify="space-between">
                  {/* <Box className="half-form">
                    <InputField
                      inputType="number"
                      name="maxInterestRate"
                      hasLabel
                      label="Maximum Interest Rate (%)"
                      placeholder="Enter Maximum Interest Rate"
                      value={values.maxInterestRate}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.maxInterestRate}
                    />
                  </Box> */}
                  <Box className="half-form">
                    <InputField
                      inputType="text"
                      name="productName"
                      hasLabel
                      label="Product Name"
                      placeholder="Enter Product Name"
                      value={values.productName}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.productName}
                    />
                  </Box>
                  <Box className="half-form">
                    <SelectInput
                      hasLabel
                      label="Fees"
                      placeholder="Select fees"
                      onChange={setFieldValue}
                      error={errors.charges}
                      options={feeOptions}
                      value={values.charges}
                      id="charges"
                      isMulti
                    />
                  </Box>
                </HStack>
                <HStack justify="space-between">
                  <Box className="half-form">
                    <InputField
                      inputType="number"
                      name="minInterestRate"
                      hasLabel
                      label="Minimum Interest Rate (%)"
                      placeholder="Enter Minimum Interest Rate"
                      value={values.minInterestRate}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.minInterestRate}
                    />
                  </Box>
                  <Box className="half-form">
                    <InputField
                      inputType="number"
                      name="defaultInterestRate"
                      hasLabel
                      label="Default Interest Rate (%)"
                      placeholder="Enter Default Interest Rate"
                      value={values.defaultInterestRate}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.defaultInterestRate}
                    />
                  </Box>
                </HStack>
                <HStack justify="space-between">
                  <Box className="half-form">
                    <InputField
                      inputType="number"
                      name="defaultTenure"
                      hasLabel
                      label="Default Tenure"
                      placeholder="Enter Default Tenure"
                      value={values.defaultTenure}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.defaultTenure}
                    />
                  </Box>
                  <Box className="half-form">
                    <InputField
                      inputType="number"
                      name="maxTenure"
                      hasLabel
                      label="Maximum Tenure"
                      placeholder="Enter Maximum Tenure"
                      value={values.maxTenure}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.maxTenure}
                    />
                  </Box>
                </HStack>
                <HStack justify="space-between">
                  <Box className="half-form">
                    <InputField
                      inputType="number"
                      name="minTenure"
                      hasLabel
                      label="Minimum Tenure"
                      placeholder="Enter Minimum Tenure"
                      value={values.minTenure}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.minTenure}
                    />
                  </Box>
                  <Box className="half-form">
                    <InputField
                      inputType="number"
                      name="defaultAmount"
                      hasLabel
                      label="Default Amount"
                      placeholder="Enter Default Amount"
                      value={values.defaultAmount}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.defaultAmount}
                    />
                  </Box>
                </HStack>
                <HStack justify="space-between">
                  <Box className="half-form">
                    <InputField
                      inputType="number"
                      name="maxAmount"
                      hasLabel
                      label="Maximum Amount"
                      placeholder="Enter Maximum Amount"
                      value={values.maxAmount}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.maxAmount}
                    />
                  </Box>
                  <Box className="half-form">
                    <InputField
                      inputType="number"
                      name="minAmount"
                      hasLabel
                      label="Minimum Amount"
                      placeholder="Enter Minimum Amount"
                      value={values.minAmount}
                      onBlur={() => handleBlur}
                      onChange={handleChange}
                      error={errors.minAmount}
                    />
                  </Box>
                </HStack>

                <Button type="submit" mt="7">
                  Save Changes
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
}
