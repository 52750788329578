import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Box } from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { RootState } from "types";

interface IDownloadTransactionsProps {
  tab: "all" | "disbursements" | "remita" | "ussd" | "sms" | "withdrawals";
}

export default function DownloadTransactions({
  tab,
}: IDownloadTransactionsProps) {
  const userData = useSelector(
    (state: RootState) => state.authReducer.loginDataTwo
  );
  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const transactionState = useSelector(
    (state: RootState) => state.transactionReducer
  );
  const info = settingsState?.settingData?.data?.info;
  const list = transactionState?.downloadData;
  const disbursementsDownloadList = transactionState?.disbursementsDownloadData;
  const remitaDownloadData = transactionState?.remitaDownloadData;
  const ussdDownloadData = transactionState?.ussdDownloadData;
  const smsDownloadData = transactionState?.smsDownloadData;
  const withdrawalsDownloadData = transactionState?.withdrawalsDownloadData;
  const isLoading = transactionState?.requestLoading;

  const downloadList = (
    tab: "all" | "disbursements" | "remita" | "ussd" | "sms" | "withdrawals"
  ) => {
    let tabList = {
      all: list,
      disbursements: disbursementsDownloadList,
      remita: remitaDownloadData,
      ussd: ussdDownloadData,
      sms: smsDownloadData,
      withdrawals: withdrawalsDownloadData,
    };
    switch (tab) {
      case "all":
        return tabList[tab].map(transaction => ({
          transactionAmount: transaction?.amount,
          narration:
            transaction["Purchase.productsDetails"].narration ||
            transaction["Purchase.productsDetails"].description ||
            transaction.description,
          previousBalance: transaction.previousUserBalance,
          newBalance: transaction.newUserBalance,
          transactionDate: transaction.createdAt,
          transactionReference:
            transaction["Purchase.purchaseReference"] || transaction.reference,
          customerFee: transaction["Purchase.customerFee"],
          merchantFee: transaction["Purchase.merchantFee"],
          cost: transaction["Purchase.cost"],
          totalCost: transaction["Purchase.totalCost"],
          currency: transaction["Purchase.currency"],
          status: transaction.status,
          id: transaction._id,
        }));
      case "disbursements":
        return tabList[tab].map(transaction => ({
          transactionAmount: transaction.cost,
          transactionDate: transaction.createdAt,
          narration: transaction.description,
          fee: transaction.customerFee,
          transactionReference: transaction.purchaseReference,
          accountNumber: transaction.deliveryLog?.beneficiary?.accountNumber,
          accountName: transaction.deliveryLog?.beneficiary?.accountName,
          bank: transaction.deliveryLog?.bankName,
        }));
      case "remita":
        return tabList[tab].map(transaction => ({
          transactionAmount: transaction.totalCost,
          transactionDate: transaction.createdAt,
          phoneNumber: transaction.productsDetails?.phoneNumber,
          status: transaction.frontendStatus,
        }));
      case "sms":
        return tabList[tab].map(transaction => ({
          transactionAmount: transaction.cost,
          transactionDate: transaction.createdAt,
          phoneNumber: transaction.productsDetails?.to,
          message: transaction.productsDetails?.message,
          status: transaction.frontendStatus,
        }));
      case "ussd":
        return tabList[tab].map(transaction => ({
          transactionAmount: transaction.totalCost,
          transactionDate: transaction.createdAt,
          phoneNumber: transaction.productsDetails?.phoneNumber,
          sessionId: transaction._id,
          status: transaction.frontendStatus,
        }));
      case "withdrawals":
        return tabList[tab].map(transaction => ({
          transactionAmount: transaction.cost,
          transactionDate: transaction.createdAt,
          narration: transaction.productsDetails?.narration,
          fee: transaction.customerFee,
          accountNumber: transaction.deliveryLog?.beneficiary?.accountNumber,
          accountName: transaction.deliveryLog?.beneficiary?.accountName,
          bank: transaction.deliveryLog?.bankName,
          status: transaction.frontendStatus,
        }));
      default:
        return tabList[tab];
    }
  };

  const isEmpty = _.isEmpty(downloadList(tab));

  return (
    <Box className="table-btns">
      <Button
        variant="action"
        rightIcon={<DownloadOutlined />}
        isDisabled={isEmpty || isLoading}
        className="transactions-download-btn"
      >
        {isEmpty ? (
          "Download"
        ) : (
          <CSVLink
            data={downloadList(tab)}
            filename={`${
              info?.businessName || userData?.businessName || ""
            }_VeendHQAutoLending.${tab}-transactions_${moment().format(
              "YYYY/MM/DD/HH/mm"
            )}.csv`}
            target="_blank"
            className="no-hover"
          >
            Download
          </CSVLink>
        )}
      </Button>
    </Box>
  );
}
