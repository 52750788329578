import React from "react";
import { Button, Box } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { InputField } from "components/common";
import { useSelector, useDispatch } from "react-redux";
import { updateUserSettings } from "redux/actions/settingsActions";
import { RootState } from "types";
import { isReadOnly } from "helpers/utilFunctions";

interface WebhookValues {
  webhookURL: string;
}

export default function WebhooksTabPane() {
  const dispatch = useDispatch();
  const setting = useSelector((state: RootState) => state.settingsReducer);
  const userSetting = setting?.settingData?.data?.webhook;
  const xTag = localStorage.getItem("x_jwt");
  const readonly = isReadOnly();

  return (
    <Box p="4" className="webhook-tabpane">
      <Formik
        initialValues={{
          webhookURL: userSetting,
        }}
        onSubmit={(values: WebhookValues) => {
          let updateBody = {
            webhook: values.webhookURL,
          };
          dispatch(updateUserSettings(xTag, "webhook", updateBody));
        }}
        validationSchema={yup.object().shape({
          webhookURL: yup.string().required("enter a url to save changes"),
        })}
      >
        {({ values, handleChange, handleBlur, errors }) => (
          <Form>
            <InputField
              inputType="text"
              name="webhookURL"
              hasLabel
              label="Webhook URL"
              placeholder="Enter Webhook URL"
              value={values.webhookURL}
              onBlur={() => handleBlur}
              onChange={handleChange}
              error={errors.webhookURL}
              isDisabled={readonly}
            />

            <Button type="submit" mt="3" disabled={readonly}>
              Save Changes
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
