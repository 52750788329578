import React from "react";
import { message as ToastMessage } from "antd";
import { CopyIcon } from "@chakra-ui/icons";

type Props = {
  text: string;
  message?: string;
  onCopy?: () => void;
  component?: React.ReactNode;
};

const Copyable = ({ text, message, onCopy, component, ...props }: Props) => {
  const _onClick = () => {
    navigator.clipboard.writeText(text);
    ToastMessage.success(message || "Copied");
    onCopy?.();
  };

  return (
    <div style={{ cursor: "pointer" }} onClick={_onClick}>
      {component || <CopyIcon {...props} boxSize={"1.2rem"} />}
    </div>
  );
};

export default Copyable;
