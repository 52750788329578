import { DownloadOutlined } from "@ant-design/icons";
import { Box, Button, Text } from "@chakra-ui/react";
import { Radio, Table } from "antd";
import { Loader } from "components/common";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { getEligibleUsers } from "redux/actions/toolsActions";
import { DashboardHeader } from "../../components/common";
import { DateTimeFormat } from "../../constants";
import { EligibleUsersTourSteps } from "../../constants/tourSteps";
import { IEligibleUsersView, RootState } from "../../types";

const columns = [
  {
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Phone number
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "eligibleAmount",
    key: "eligibleAmount",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Eligible Amount
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "name",
    key: "name",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Name
        </Text>
      </Box>
    ),
  },
  {
    dataIndex: "date",
    key: "date",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Date
        </Text>
      </Box>
    ),
  },
];

export default function ViewEligibleUsers() {
  const dispatch = useDispatch();
  const eligibilityState = useSelector(
    (state: RootState) => state.toolsReducer
  );
  const eligibleBorrowersList = eligibilityState?.eligibleBorrowersList;
  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const userData = useSelector(
    (state: RootState) => state.authReducer.loginDataTwo
  );
  const info = settingsState?.settingData?.data?.info;
  const xJwt = localStorage.getItem("x_jwt");
  const loading = eligibilityState?.requestLoading;
  const isEmpty = _.isEmpty(eligibleBorrowersList);
  const [view, setView] = useState<IEligibleUsersView["view"]>("all");
  const topupDownloadList = () =>
    eligibleBorrowersList?.map(borrower => ({
      amount: borrower?.user?.preApprovedAmount,
      dateCreated: borrower?.user?.createdAt,
      firstName: borrower?.user?.firstName,
      lastName: borrower?.user?.lastName,
      phoneNumber: borrower?.user?.phoneNumber,
      _id: borrower?.user?._id,
    }));

  const onRadioToggle = (e: any) => {
    setView(e.target.value);
  };

  useEffect(() => {
    dispatch(getEligibleUsers(xJwt, view));
  }, [dispatch, xJwt, view]);

  const data: any[] | undefined = [];

  for (let i = 0; i < eligibleBorrowersList?.length; i++) {
    const item = eligibleBorrowersList[i];
    data?.push({
      key: item?._id,
      phoneNumber: item?.phoneNumber || item?.user?.phoneNumber,
      eligibleAmount: item?.amount || item?.user?.preApprovedAmount,
      name: `${item?.firstName || item?.user?.firstName} ${
        item?.lastName || item?.user?.lastName
      }`,
      date: moment(item?.dateCreated || item?.user?.createdAt).format(
        DateTimeFormat
      ),
    });
  }

  return (
    <Box px="4" py="5">
      <DashboardHeader
        title="View Eligible Users"
        description="A list of eligible borrowers"
        tourSteps={EligibleUsersTourSteps}
      />

      <Box mt="3" p="3" className="loan-tab-pane">
        <Box className="transactions-table-header">
          <Radio.Group
            onChange={onRadioToggle}
            value={view}
            className="eligibilities-radio"
          >
            <Radio value="all" className="all-eligible">
              All
            </Radio>
            <Radio
              value="no-current-active"
              className="noCurrentActive-eligible"
            >
              Eligible With No Loan
            </Radio>
            <Radio value="no-active" className="noActive-eligible">
              Eligible Without Active Loan
            </Radio>
            <Radio value="topup-eligible" className="topup-eligible">
              Eligible for Top Up
            </Radio>
          </Radio.Group>
          <Button
            variant="action"
            mr="3"
            rightIcon={<DownloadOutlined />}
            isDisabled={loading || isEmpty}
            className="download-btn"
          >
            {isEmpty ? (
              "Download"
            ) : (
              <CSVLink
                data={
                  view === "topup-eligible"
                    ? topupDownloadList()
                    : eligibleBorrowersList
                }
                filename={`${
                  info?.businessName || userData?.businessName || ""
                }_VeendHQAutoLending.eligible-users_${moment().format(
                  "YYYY/MM/DD/HH/mm"
                )}.csv`}
                target="_blank"
                className="no-hover"
              >
                Download
              </CSVLink>
            )}
          </Button>
        </Box>
        {loading ? (
          <Loader size="lg" />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              defaultPageSize: 50,
              hideOnSinglePage: true,
              position: ["bottomLeft"],
            }}
          />
        )}
      </Box>
    </Box>
  );
}
