import React, { useEffect } from "react";
import { Modal } from "antd";
import { Heading, Button, Text, Box } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleColending,
  getCoLendingInterestRate,
  getUserSettings,
} from "redux/actions/settingsActions";
import { RootState } from "types";

interface IConfirmColendingToggleProps {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
  status: boolean;
}

export default function ConfirmColendingToggle({
  showModal,
  setShowModal,
  status,
}: IConfirmColendingToggleProps) {
  const dispatch = useDispatch();
  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const colendingInterestRate =
    settingsState?.colendingInterestRate?.interestRate;
  const xJwt = localStorage.getItem("x_jwt");

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleClose = () => {
    dispatch(getUserSettings(xJwt));
  };

  const handleConfirm = (status: boolean) => {
    dispatch(toggleColending(xJwt, status));
    setShowModal(false);
  };

  useEffect(() => {
    dispatch(getCoLendingInterestRate(xJwt));
  }, [dispatch, xJwt]);

  return (
    <>
      <Modal
        visible={showModal}
        onCancel={handleCancel}
        footer={null}
        width="50%"
        className="loan-config-modal"
        afterClose={handleClose}
      >
        <Box textAlign="center">
          <Heading as="h2" fontSize="sm" mb="4">
            {status ? "Disable " : "Enable "} Originate AI
          </Heading>
          <Text fontSize={{ base: "xs", md: "sm", lg: "md" }} mb="2">
            Recommended interest rate -{" "}
            <Text as="span" fontWeight={"bold"}>
              {colendingInterestRate}%
            </Text>
          </Text>
          <Text fontSize={{ base: "xs", md: "sm", lg: "md" }} mb="2">
            Minimum balance:{" "}
            <Text as="span" fontWeight={"bold"}>
              ₦1,000,000
            </Text>
          </Text>
          {/* <Text
          as="a"
          href="/settings"
          fontSize={{ base: "xx-small", md: "xs" }}
          style={{ color: "var(--hover-blue)" }}
        >
          click here to see how to configure interest rate
        </Text> */}
          <Box d="block" my="4">
            <Button variant="outlineDanger" mr="4" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="action" onClick={() => handleConfirm(status)}>
              {status ? "Disable" : "Enable"}
            </Button>
          </Box>
          {/* <Text fontSize={{ base: "xx-small", md: "xs" }}>
          by enabling Originate AI, you agree to the{" "}
          <Text as="a" href="/settings" style={{ color: "var(--hover-blue)" }}>
            terms and conditions
          </Text>
        </Text> */}
        </Box>
      </Modal>
    </>
  );
}
