import React, { useEffect, useState } from "react";
import { Box, Text, Link } from "@chakra-ui/react";
import moment from "moment";
import { LoanProfile } from "components/modals";
import { useDispatch, useSelector } from "react-redux";
import {
  getProcessingDisbursementLoan,
  getLoanRecord,
  getMoreProcessingDisbursementLoan,
} from "redux/actions/loanActions";
import { Table } from "antd";
import {
  WebIcon,
  UssdPhoneIcon,
  DotIcon,
  PrimeIcon,
} from "components/common/CustomIcons";
import { DateTimeFormat, disburseColor } from "../../constants";
import { popLastId } from "helpers/utilFunctions";
import { Loader } from "components/common";
import { RootState } from "types";
import { defaultColumns, getDefaultColumnsData } from "helpers/loans";
import { capitalize } from "lodash";

const columns = [
  ...defaultColumns,
  {
    dataIndex: "expectedRepayment",
    key: "expectedRepayment",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Expected Repayment
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "repaymentDate",
    key: "repaymentDate",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Repayment Date
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "remitaMandateReference",
    key: "remitaMandateReference",
    render: (text: string) => (
      <Box onClick={e => e.stopPropagation()}>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Mandate
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "tenure",
    key: "tenure",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Tenure
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "bvn",
    key: "bvn",
    render: (text: string) => (
      <Box>
        <Text
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="bold"
          title={text}
        >
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          BVN
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "loanId",
    key: "loanId",
    render: (text: string) => (
      <Box onClick={e => e.stopPropagation()}>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Loan ID
        </Text>
      </Box>
    ),
    className: "table-cell-display",
  },
  {
    dataIndex: "loanChannel",
    key: "loanChannel",
    render: (text: string) => (
      <Box onClick={e => e.stopPropagation()}>
        <Text fontSize={{ base: "xx-small", md: "xs" }} fontWeight="bold">
          {text.toLowerCase() === "onlending" ? "Prime" : capitalize(text)}
        </Text>
        <Text fontSize={{ base: "xx-small" }} fontWeight="normal">
          Channel
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "channel",
    key: "channel",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small" }} fontWeight="bold">
          {text}
        </Text>
      </Box>
    ),
    className: "table-cell-display table-cell-display--md",
  },
  {
    dataIndex: "disbursementStatus",
    key: "disbursementStatus",
    render: (text: string) => (
      <Box>
        <Text fontSize={{ base: "xx-small" }} fontWeight="bold">
          {text}
        </Text>
      </Box>
    ),
  },
];

export default function ProcessingLoansTable() {
  const loanState = useSelector((state: RootState) => state.loanReducer);
  const processingDisbursementData = loanState?.processingDisbursementData;
  const loans = processingDisbursementData?.data;
  const loading = loanState?.requestLoading;
  const dispatch = useDispatch();
  const xJwt = localStorage.getItem("x_jwt");
  const [lastIds, setLastIds] = useState<(string | null)[]>([null]);
  const [showModal, setShowModal] = useState(false);
  // const [loanID, setLoanID] = useState("");

  useEffect(() => {
    dispatch(getProcessingDisbursementLoan(xJwt));
  }, [dispatch, xJwt]);

  useEffect(() => {
    if (
      processingDisbursementData !== undefined &&
      processingDisbursementData?.lastId !== undefined
    ) {
      setLastIds([...lastIds, processingDisbursementData?.lastId]);
    }
  }, [processingDisbursementData]); //eslint-disable-line

  const openLoanProfile = () => {
    setShowModal(true);
  };

  const loadNextPage = () => {
    dispatch(
      getMoreProcessingDisbursementLoan(
        xJwt,
        processingDisbursementData?.lastId
      )
    );
  };

  const loadPrevPage = () => {
    if (lastIds.length === 3) {
      dispatch(getProcessingDisbursementLoan(xJwt));
      setLastIds([null]);
    } else {
      dispatch(
        getMoreProcessingDisbursementLoan(xJwt, lastIds[lastIds.length - 3])
      );
      popLastId(lastIds, setLastIds);
    }
  };

  const data: any[] | undefined = [];

  for (let i = 0; i < loans?.length; i++) {
    data?.push({
      ...getDefaultColumnsData(loans[i]),
      bvn: loans[i]?.user?.bvn,
      expectedRepayment: loans[i]?.repaymentAmount,
      repaymentDate: `${moment(loans[i]?.repaymentDate).format(
        DateTimeFormat
      )}`,
      remitaMandateReference: loans[i]?.remitaMandateReference
        ? loans[i]?.remitaMandateReference
        : "no mandate",
      loanChannel: loans[i]?.channel,
      channel:
        loans[i]?.channel === "MOBILE" || loans[i]?.channel === "USSD" ? (
          <UssdPhoneIcon title={loans[i]?.channel} />
        ) : loans[i]?.channel.toLowerCase() === "onlending" ||
          loans[i]?.channel.toLowerCase() === "prime" ? (
          <PrimeIcon title={loans[i]?.channel} />
        ) : (
          <WebIcon title={loans[i]?.channel} />
        ),
      loanId: loans[i]?.loanId,
      tenure: loans[i]?.tenure,
      isTopUp: loans[i]?.isTopup,
      disbursementStatus: (
        <DotIcon
          title={`Disburse status: ${loans[i]?.disbursementStatus}`}
          style={disburseColor(loans[i]?.disbursementStatus)}
          className="status-dot"
        />
      ),
    });
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 50,
            hideOnSinglePage: true,
            position: ["bottomLeft"],
          }}
          onRow={(record: any, rowIndex) => ({
            onClick: async (e: any) => {
              // setLoanID(record.key);
              dispatch(getLoanRecord(record.key, xJwt, record.loanId));
              openLoanProfile();
            },
          })}
          className="row-select"
        />
      )}
      {!processingDisbursementData?.isLastPage ? (
        <Box className="pagination-items">
          {lastIds.length <= 2 ? null : (
            <Link onClick={loadPrevPage} className="pagination-item-link prev">
              Previous page
            </Link>
          )}
          <Link onClick={loadNextPage} className="pagination-item-link next">
            Next page
          </Link>
        </Box>
      ) : null}
      <LoanProfile showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
