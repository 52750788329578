import axios from "axios";
import { message } from "antd";
import {
  ERROR_401_ACTION,
  CAMPAIGNS_LOADING_TRUE,
  CAMPAIGNS_LOADING_FALSE,
  GET_CAMPAIGNS_SUCCESS,
  CAMPAIGNS_REQUEST_LOADING_TRUE,
  CAMPAIGNS_REQUEST_LOADING_FALSE,
  OPEN_ADD_MANUALLY_MODAL,
  CLOSE_ADD_MANUALLY_MODAL,
  OPEN_UPLOAD_CONTACTS_MODAL,
  CLOSE_UPLOAD_CONTACTS_MODAL,
  OPEN_STAGED_PHONE_NUMBERS_MODAL,
  CLOSE_STAGED_PHONE_NUMBERS_MODAL,
  UPLOAD_PHONE_NUMBERS_SUCCESS,
  GET_CAMPAIGNS_SUMMARY_SUCCESS,
  GET_CAMPAIGN_TO_EDIT_SUCCESS,
  RESET_CAMPAIGN_TO_EDIT,
  GET_UPLOADED_CONTACTS_SUCCESS,
  GET_AMOUNT_SPENT_ON_CAMPAIGNS_SUCCESS,
  GET_AMOUNT_SPENT_ON_CAMPAIGNS_LOADING_TRUE,
  GET_AMOUNT_SPENT_ON_CAMPAIGNS_LOADING_FALSE,
} from "./actionTypes";
import { UPLOADED_CONTACTS_LIMIT_PER_PAGE } from "constants/index";
import { filterUsernameOut } from "helpers/utilFunctions";

const baseUrl = process.env.REACT_APP_BASE_URL;

axios.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  "x-no-cache": "yes",
  Expires: "0",
};

// Get campaigns
export const getCampaigns =
  (xJwt: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: CAMPAIGNS_LOADING_TRUE,
      });

      const res = await axios.get(`${baseUrl}/campaigns`, config);

      dispatch({
        type: GET_CAMPAIGNS_SUCCESS,
        payload: res?.data?.data,
      });

      dispatch({
        type: CAMPAIGNS_LOADING_FALSE,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: CAMPAIGNS_LOADING_FALSE,
          });

          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// Get a campaign to Edit
export const getCampaignToEdit =
  (xJwt: any, campaignID: string | null) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: CAMPAIGNS_LOADING_TRUE,
      });

      dispatch({
        type: RESET_CAMPAIGN_TO_EDIT,
      });

      const res = await axios.get(`${baseUrl}/campaigns/${campaignID}`, config);

      dispatch({
        type: GET_CAMPAIGN_TO_EDIT_SUCCESS,
        payload: res?.data?.data,
      });

      dispatch({
        type: CAMPAIGNS_LOADING_FALSE,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: CAMPAIGNS_LOADING_FALSE,
          });

          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// Create a new campaign
export const addCampaigns =
  (xJwt: any, body: any, username: string, historyAction: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
        "x-tag": "veend-campaigns",
      },
    };
    try {
      dispatch({
        type: CAMPAIGNS_REQUEST_LOADING_TRUE,
      });

      const res = await axios.post(`${baseUrl}/campaigns`, body, config);

      message.success(
        res?.data?.data?.message || "Campaign created successfully"
      );

      dispatch({
        type: CAMPAIGNS_REQUEST_LOADING_FALSE,
      });

      historyAction.push("/sms-automation");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: CAMPAIGNS_LOADING_FALSE,
          });

          message.error(
            filterUsernameOut(error?.response?.data?.message, username)
          );
        }
      }
      return error;
    }
  };

//   Toggle campaign status btw Active | Paused
export const editCampaign =
  (
    xJwt: any,
    campaignID: string,
    body: any,
    dispatchAction: any,
    historyAction: any
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      const res = await axios.patch(
        `${baseUrl}/campaigns/${campaignID}`,
        body,
        config
      );

      message.success(res?.data?.data?.message);

      // Update the campaign lists
      dispatchAction(getCampaigns(xJwt));

      historyAction.push("/sms-automation");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: CAMPAIGNS_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

//   Toggle campaign status btw Active | Paused
export const updateCampaignStatus =
  (xJwt: any, campaignID: string, body: any, dispatchAction: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      await axios.put(`${baseUrl}/campaigns/${campaignID}`, body, config);

      if (body?.enabled === true) {
        message.success("Campaign has been resumed successfully");
      } else {
        message.success("Campaign has been paused successfully");
      }

      // Update the campaign lists
      dispatchAction(getCampaigns(xJwt));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: CAMPAIGNS_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// Delete campaign
export const deleteCampaign =
  (xJwt: any, campaignID: string, dispatchAction: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      const res = await axios.delete(
        `${baseUrl}/campaigns/${campaignID}`,
        config
      );

      message.success(res?.data?.data?.message);

      // Update the campaign lists
      dispatchAction(getCampaigns(xJwt));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: CAMPAIGNS_LOADING_FALSE,
          });

          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// Open the upload contact modal
export const openUploadContactsModal =
  () => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    dispatch({
      type: OPEN_UPLOAD_CONTACTS_MODAL,
    });
  };

// Close the upload contact modal
export const closeUploadContactsModal =
  () => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    dispatch({
      type: CLOSE_UPLOAD_CONTACTS_MODAL,
    });
  };

// Open the add recipients manually modal
export const openAddManuallyModal =
  () => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    dispatch({
      type: OPEN_ADD_MANUALLY_MODAL,
    });
  };

// Close the add recipients manually modal
export const closeAddManuallyModal =
  () => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    dispatch({
      type: CLOSE_ADD_MANUALLY_MODAL,
    });
  };

// Open the staged phone numbers modal
export const openStagedPhoneNumbersModal =
  (phoneNumbers: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    dispatch({
      type: OPEN_STAGED_PHONE_NUMBERS_MODAL,
      payload: phoneNumbers,
    });
  };

// Close the staged phone numbers modal
export const closeStagedPhoneNumbersModal =
  () => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    dispatch({
      type: CLOSE_STAGED_PHONE_NUMBERS_MODAL,
    });
  };

// Upload Phone numbers
export const uploadPhoneNumbers =
  (xJwt: any, body: any, dispatchAction: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
        "x-tag": "veend-phone-number",
      },
    };
    try {
      dispatch({
        type: CAMPAIGNS_REQUEST_LOADING_TRUE,
      });
      const res = await axios.post(`${baseUrl}/mobilenumbers`, body, config);

      message.success(res?.data?.data);

      dispatch({
        type: CAMPAIGNS_REQUEST_LOADING_FALSE,
      });

      // Close all the open modals
      dispatch({
        type: UPLOAD_PHONE_NUMBERS_SUCCESS,
      });

      // Update the campaign summary
      dispatchAction(getCampaignsSummary(xJwt));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: CAMPAIGNS_REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// Fetch the campaigns summary
export const getCampaignsSummary =
  (xJwt: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: CAMPAIGNS_REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/campaigns/lender/summary`,
        config
      );

      dispatch({
        type: GET_CAMPAIGNS_SUMMARY_SUCCESS,
        payload: res?.data?.data,
      });

      dispatch({
        type: CAMPAIGNS_REQUEST_LOADING_FALSE,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: CAMPAIGNS_REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

// Fetch the amount spent on campaigns
export const getAmountSpentOnCampaigns =
  (xJwt: any, filter: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: GET_AMOUNT_SPENT_ON_CAMPAIGNS_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/campaigns/sms/report?filter=${filter}`,
        config
      );

      dispatch({
        type: GET_AMOUNT_SPENT_ON_CAMPAIGNS_SUCCESS,
        payload: res?.data?.data?.totalAmount,
      });

      dispatch({
        type: GET_AMOUNT_SPENT_ON_CAMPAIGNS_LOADING_FALSE,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: GET_AMOUNT_SPENT_ON_CAMPAIGNS_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

export const getUploadedContacts =
  (xJwt: any, pageNumber: number) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      dispatch({
        type: CAMPAIGNS_LOADING_TRUE,
      });
      dispatch({
        type: CAMPAIGNS_REQUEST_LOADING_TRUE,
      });
      const res = await axios.get(
        `${baseUrl}/mobilenumbers?page=${pageNumber}&limit=${UPLOADED_CONTACTS_LIMIT_PER_PAGE}`,
        config
      );

      dispatch({
        type: GET_UPLOADED_CONTACTS_SUCCESS,
        payload: res?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: CAMPAIGNS_REQUEST_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

export const editPhoneNumber =
  (
    xJwt: any,
    id: string,
    body: any,
    pageNumber: number,
    dispatchAction: any,
    onSuccess: () => void
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      await axios.patch(`${baseUrl}/mobilenumbers/${id}`, body, config);

      message.success("Changes Saved");

      dispatchAction(getUploadedContacts(xJwt, pageNumber));

      onSuccess();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: CAMPAIGNS_LOADING_FALSE,
          });
          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };

export const deletePhoneNumber =
  (
    xJwt: any,
    id: string,
    pageNumber: number,
    dispatchAction: any,
    onSuccess: () => void
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let config = {
      headers: {
        "x-jwt": xJwt,
      },
    };
    try {
      await axios.delete(`${baseUrl}/mobilenumbers/${id}`, config);

      message.success("Phone Number Deleted successfully");

      dispatchAction(getUploadedContacts(xJwt, pageNumber));

      onSuccess();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          dispatch({
            type: ERROR_401_ACTION,
          });
        } else {
          dispatch({
            type: CAMPAIGNS_LOADING_FALSE,
          });

          message.error(error?.response?.data?.message);
        }
      }
      return error;
    }
  };
