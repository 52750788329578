import React from "react";
import { Box, Grid } from "@chakra-ui/react";
import { DashboardHeader, ToolsCard } from "components/common";
import PayingEmployersBG from "../../assets/paying-employers-bg.png";
import SMSAutomationBG from "../../assets/message-text.png";
import "./styles/tools.css";

export default function Tools() {
  return (
    <>
      <Box px="4" py="5">
        <DashboardHeader
          title="Lending Tools"
          description="Helper utilities to improve efficiency."
        />

        <Grid
          pt="4"
          gap={["5", "5", "8", "10"]}
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
          ]}
          overflow="hidden"
          className="tools-card-container"
        >
          <ToolsCard
            title="Bulk Eligibility Check"
            redirectTo="/tools/eligibilities"
            bgColor="#060088"
          />
          <ToolsCard
            title="View Eligible Users"
            redirectTo="/tools/eligible-users"
            bgColor="#f9ac22"
          />
          <ToolsCard
            title="Paying Employers"
            redirectTo="/tools/paying-employers"
            bgColor="#3c5f95"
            bgImage={PayingEmployersBG}
          />
          <ToolsCard
            title="SMS Automation"
            redirectTo="/sms-automation"
            bgColor="#12A894"
            bgImage={SMSAutomationBG}
          />
        </Grid>
      </Box>
    </>
  );
}
