import React, { useState } from "react";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Button, Text, Box } from "@chakra-ui/react";
import { EditIcon } from "../common/CustomIcons";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { InputField } from "../common";
import { zeroToOneDigitRegExp } from "../../constants";
import { useSelector, useDispatch } from "react-redux";
import { updateDTI } from "../../redux/actions/settingsActions";
import { RootState } from "types";
import { isReadOnly } from "helpers/utilFunctions";

interface IDTIConfigProps {
  dti: string | number;
}

export default function DTIConfig() {
  const dispatch = useDispatch();
  const settingsState = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const dti = settingsState?.settingData?.data?.dti;
  const xJwt = localStorage.getItem("x_jwt");
  const [edit, setEdit] = useState(false);
  const readonly = isReadOnly();

  return (
    <>
      <Box d="flex" alignItems="center">
        <Text fontSize={{ base: "xs", md: "sm" }} fontWeight={"medium"}>
          DTI ratio:{" "}
          <Text as="span" fontWeight={"bold"}>
            {dti}
          </Text>
        </Text>
        <Tooltip title="Debt-to-Income Ratio">
          <QuestionCircleFilled
            style={{
              color: "var(--brand-blue)",
              fontSize: "1rem",
              marginLeft: "6px",
            }}
          />
        </Tooltip>
        <Box
          ml="3"
          className="edit-cont"
          d={readonly ? "none" : "flex"}
          onClick={() => setEdit(!edit)}
        >
          <EditIcon />
        </Box>
        {edit && (
          <Formik
            initialValues={{
              dti: dti,
            }}
            onSubmit={(values: IDTIConfigProps) => {
              dispatch(updateDTI(xJwt, values.dti));
              setEdit(false);
            }}
            validationSchema={yup.object().shape({
              dti: yup
                .string()
                .required("please enter a value between 0 and 1")
                .matches(
                  zeroToOneDigitRegExp,
                  "please enter a value between 0 and 1"
                ),
            })}
          >
            {({ values, handleChange, handleBlur, errors }) => (
              <Box as={Form} ml="3" d="flex" alignItems="center">
                <InputField
                  inputType="text"
                  name="dti"
                  placeholder="e.g. 0.6"
                  value={values.dti}
                  onBlur={() => handleBlur}
                  onChange={handleChange}
                  error={errors.dti}
                />
                <Button type="submit" ml="3">
                  Save
                </Button>
              </Box>
            )}
          </Formik>
        )}
      </Box>
    </>
  );
}
